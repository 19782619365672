export default {
  setFunnelStats: (state, stats) => {
    state.funnelStats = stats;
  },
  setFunnelStatsLoading: (state, loading) => {
    state.funnelStatsLoading = loading;
  },
  setCampaignMetrics: (state, metrics) => {
    state.campaignMetrics = metrics;
  },
  setCampaignMetricsLoading: (state, loading) => {
    state.campaignMetricsLoading = loading;
  },
};
