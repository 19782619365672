import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    contacts: null,
    contactsLoading: false,
    contactsInfo: {},
    contactLoading: false,
    activeContact: null,
    suggestionsLoading: false,
    boardsLoaded: false,
    boards: [],
    boardsConfig: null,
  },

  mutations,
  actions,
  getters,
};
