import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    messages: [],
    threads: null,
    threadsLoading: false,
    threadsFilters: {},
    threadsScrollPosition: 0,
    messagesLoaded: false,
    messageLoading: false,
    messagesSchemas: {},
    messagesSchemasLoaded: false,
    conversation: [],
    conversationLoading: false,
    portalRecipients: [],
    portalRecipientsLoaded: false,
  },

  mutations,
  actions,
  getters,
};
