export default {
  toggleSidebar: (state) => {
    state.sidebarOpen = !state.sidebarOpen;
  },
  showInfoModal(state, message) {
    state.infoMessage = message;
  },
  setPreviousRoute(state, route) {
    if (!route.name) return;
    state.previousRoute = route;
  },
  setPageLoading(state, loading) {
    state.pageLoading = loading;
  },
  setInstallAppPrompt(state, promptEvent) {
    state.installAppPrompt = promptEvent;
  },
};
