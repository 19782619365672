import { render, staticRenderFns } from "./AppSidebar.vue?vue&type=template&id=74fffd82"
import script from "./AppSidebar.vue?vue&type=script&lang=js"
export * from "./AppSidebar.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/app/app-sidebar.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports