<template>
  <router-view />
</template>

<script>
// support old versions of IE
import { detectIE } from '@/filters/helpers';

// support new UI in legacy code
import { initParentConnection } from '@/lib/parentConnection';

// update vuetify theme globally
import theme from '@/factories/theme';

import initTrackJs from '@/lib/trackjs';
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations(['setInstallAppPrompt']),
    addThemeListener() {
      theme.on('customThemeSet', (config) => {
        const isThemePassed = window.location.search.indexOf('theme') !== -1;

        if (!config?.colors || isThemePassed) return;
        this.$vuetify.theme.dark = config.isDark;
        this.$vuetify.theme.themes[config.isDark ? 'dark' : 'light'] =
          config.colors;
      });
    },

    addInstallPromptListener() {
      window.addEventListener('beforeinstallprompt', (event) => {
        this.setInstallAppPrompt(event);
      });
    },
  },

  created() {
    this.addInstallPromptListener();
    detectIE();
    initParentConnection();
    this.addThemeListener();
    initTrackJs();
  },
};
</script>

<style lang="less" src="@/assets/styles/app/app-main.less"></style>
<style lang="less" src="@/assets/styles/basic/animations.less"></style>
<style lang="less" src="@/assets/styles/basic/vuetify/index.less"></style>
<style lang="less" src="@/assets/styles/basic/item-card.less"></style>
