// Models, only available for nesting, don't appear in sidebar
export default {
  model: true,
  type: 'ContactField',
  schema: {
    contact_field: {
      type: 'ContactFieldList',
      title: 'Contact Field',
      help: 'Select the contact field you want to make updatable',
      validators: ['editable'],
    },
    hide_value: {
      type: 'Checkbox',
      title: 'Hide existing value from contact',
      default: false,
      help: 'Helpful if the information is sensitive or you want a fresh answer.',
    },
    skip_already_set: {
      type: 'Checkbox',
      title: 'Skip if already set',
      default: false,
      help: "Don't require a submission if there is already a value saved.",
    },
    required: {
      title: 'Required',
      type: 'Checkbox',
      default: true,
      help: '',
    },
  },
};
