<template>
  <div class="link-preview item-card d-flex mb-3" @click="openLink">
    <div class="spinner-wrap" v-if="loading">
      <spinner small />
    </div>
    <template v-else-if="linkDetails">
      <div class="item-card__side-image">
        <v-responsive :aspect-ratio="16 / 9">
          <img v-lazy="linkDetails.img" alt="" />
        </v-responsive>
      </div>
      <div class="item-card__description">
        <content-line :text="linkDetails.title" class="pr-1">
          <v-btn
            slot="actions"
            v-if="isVideoUrl"
            color="appMain"
            class="mr-2"
            small
          >
            Watch
          </v-btn>
        </content-line>
        <div class="item-card__content">
          {{ linkDetails.description }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { vueEnv } from '@/globals';
import axios from 'axios';
import parseVideoUrl from '@/lib/videoPlayer/parseVideoUrl';

export default {
  name: 'LinkPreview',
  props: {
    url: String,
  },
  data() {
    return {
      linkDetails: null,
      loading: false,
    };
  },
  computed: {
    isVideoUrl() {
      return !!parseVideoUrl(this.url, 'clean');
    },
  },
  methods: {
    getEmbedlyPath() {
      let params = [
        'url=' + encodeURIComponent(this.url),
        'key=' + encodeURIComponent(vueEnv('embedly_key')),
      ];

      return 'https://api.embedly.com/1/oembed?' + params.join('&');
    },
    async loadSummary() {
      this.loading = true;

      try {
        const res = await axios.get(this.getEmbedlyPath());
        if (!res.data) return;

        const { thumbnail_url, title, description, provider_name, url } =
          res.data;

        this.linkDetails = {
          img: thumbnail_url,
          title: title || provider_name,
          description: description || url,
        };
      } catch (error) {
        console.warn('error loading embedly data', err);
      } finally {
        this.loading = false;
      }
    },

    openLink() {
      if (this.isVideoUrl) {
        this.$emit('watchVideo', {
          title: this.linkDetails.title,
          path: this.url,
          mimetype: 'video',
        });
        return;
      }

      window.open(this.url, '_blank');
    },
  },
  mounted() {
    this.loadSummary();
  },
};
</script>

<style lang="less">
.link-preview.item-card {
  cursor: pointer !important;
  position: relative;
  min-height: 116px;

  .item-card__side-image {
    width: 35%;
    display: flex;
    align-items: center;
  }

  .item-card__description {
    max-width: 65%;
  }

  .item-card__content {
    overflow: hidden;
    max-height: 56px;
    height: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 12px 16px;
  }

  &:hover {
    background-color: var(--v-borderColor-base) !important;
    .item-card__description {
      background-color: var(--v-darkGray-base);
    }
  }

  .spinner-wrap {
    width: 100%;
    min-height: 116px;
  }
}
</style>
