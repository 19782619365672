import Axios from 'axios';
import { vueEnv } from '@/globals';
import startCase from 'lodash/startCase';
import cloneDeep from 'lodash/cloneDeep';
import { askNotificationsPermission } from '@/lib/notification';
import isEqual from 'lodash/isEqual';

const roomsApi = () => {
  let url = vueEnv('vroom_path');
  const axios = Axios.create({ baseURL: url });
  axios.interceptors.response.use(
    (response) => {
      const { data } = response || {};
      return data?.data || data;
    },
    ({ response }) => {
      throw response;
    }
  );

  return axios;
};

export default {
  async loadRoomInfo({ commit, state }, query) {
    try {
      const path = `rooms/info${getRoomInfoParams(query)}`;
      const data = await roomsApi().get(path);

      if (data?.config) {
        commit('setRoomConfig', data.config);
        delete data.config;
      }

      commit('setRoomInfo', {
        ...data,
        avatar: state.roomInfo?.avatar || '',
        name: startCase((data.name || '').toLowerCase()),
      });

      askNotificationsPermission();

      return data;
    } catch (error) {
      const { data, status } = error || {};

      if (status === 403 && (data?.message || '').includes('banned')) {
        // show interview-finished dialog
        commit('setRoomInfo', { finished: true });
      } else if (data) {
        // show error in top right corner
        commit('setRoomError', data);
      }
    }
  },

  async banRoomGuest({ commit }, data) {
    try {
      await roomsApi().post('rooms/ban_guest', data);
    } catch (error) {
      console.log('failed to ban guest: ', error);
    }
  },

  async updateRoomSubscriptions({}, data) {
    try {
      await roomsApi().post('rooms/subscribe', data);
    } catch (error) {
      console.log('failed to subscribe: ', error);
    }
  },

  async createMeetingToken({ state }, { room, participant, room_name }) {
    const data = {
      token: state.roomInfo?.token,
      payload: {
        room,
        room_name,
        //if an existing host is invited, make sure
        //identity doesn't have host
        identity: participant.identity.replace('host_', ''),
        type: participant.type,
        name: participant.name,
      },
    };

    try {
      const res = await roomsApi().post('rooms/token', data);
      return res?.token;
    } catch (error) {
      console.log('failed to create meeting token: ', error);
    }
  },

  updateQuestionnaireAnswers({ commit, state }, answer) {
    const answers = updateQuestionnaireAnswers(
      cloneDeep(state.questionnaireAnswers),
      answer
    );
    commit('setQuestionnaireAnswers', answers);
  },

  addSharedQuestionnaireAnswers({ commit, state }, receivedRecords) {
    const answers = mergeQuestionnaireRecords(
      cloneDeep(state.questionnaireAnswers),
      receivedRecords
    );

    if (answers) {
      commit('setQuestionnaireAnswers', answers);
    }
  },
};

const getRoomInfoParams = (query) => {
  const params = [];
  Object.keys(query).forEach((key) => {
    params.push(`${key}=${query[key]}`);
  });

  return params.length ? '?' + params.join('&') : '';
};

const mergeQuestionnaireRecords = (currentRecords, receivedRecords) => {
  if (!receivedRecords.length) return null;

  const isRecordIdentical = isEqual(currentRecords, receivedRecords);

  const isRecordAlreadyMerged = receivedRecords.every((receivedRecord) => {
    const currentRecord = currentRecords.find((record) => {
      return record.identity === receivedRecord.identity;
    });

    return isEqual(receivedRecord, currentRecord);
  });

  if (isRecordIdentical || isRecordAlreadyMerged) return null;

  return receivedRecords.reduce((acc, receivedRecord) => {
    const currentRecordIndex = acc.findIndex((record) => {
      return record.identity === receivedRecord.identity;
    });

    if (currentRecordIndex === -1) {
      acc.push(receivedRecord);
    } else {
      const currentRecord = acc[currentRecordIndex];
      const isReceivedRecordFresh =
        receivedRecord.timestamp > currentRecord.timestamp;

      if (isReceivedRecordFresh) {
        acc[currentRecordIndex] = receivedRecord;
      }
    }

    return acc;
  }, currentRecords);
};

const updateQuestionnaireAnswers = (records, newRecord) => {
  const THREE_HOURS_IM_MS = 1000 * 60 * 60 * 3;

  let partIndex = records.findIndex(
    (a) => a.identity === newRecord.from.identity
  );

  let answerData = {
    title: newRecord.title,
    answer: newRecord.answer,
    key: newRecord.key,
  };

  if (partIndex < 0) {
    const { identity, name, room } = newRecord.from;
    records.unshift({
      identity,
      name,
      room,
      answers: [answerData],
      timestamp: new Date().getTime(),
    });
  } else {
    let answerIndex = records[partIndex].answers.findIndex(
      (a) => a.key === answerData.key
    );

    if (answerIndex < 0) {
      records[partIndex].answers.push(answerData);
    } else {
      records[partIndex].answers.splice(answerIndex, 1, answerData);
    }

    records[partIndex].timestamp = new Date().getTime();
  }

  records = records
    .filter(({ timestamp }) => {
      return !timestamp || timestamp + THREE_HOURS_IM_MS > new Date().getTime();
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return records;
};
