const TASK_STATUS = [
  {
    key: 'not_started',
    title: 'Not Started',
    color: '#909090',
  },
  {
    key: 'planning',
    title: 'Planning',
    color: '#fb8c00',
  },
  {
    key: 'todo',
    title: 'To Do',
    color: '#e9d73f',
  },
  {
    key: 'doing',
    title: 'Doing',
    color: '#1bc0af',
  },
  {
    key: 'reviewing',
    title: 'Reviewing',
    color: '#ce5ce2',
  },
  {
    key: 'ready',
    title: 'Ready',
    color: '#004e75',
  },
  {
    key: 'done',
    title: 'Done',
    color: '#71bdf9',
  },
];

export { TASK_STATUS };
