<template>
  <div class="item-card">
    <content-line
      icon="sidebar/conversations"
      text="New Conversations"
      class="pr-1"
    />
    <div class="item-card__content pt-0">
      <div class="spinner-wrap" v-if="loading">
        <spinner small />
      </div>
      <template v-else>
        <div class="threads-list" v-if="threadsList.length">
          <div
            class="threads-item"
            v-for="(thread, idx) in threadsList"
            :key="idx"
            @click="openConversation(thread)"
          >
            <div class="threads-item__icon">
              <avatar size="micro" />
            </div>
            <div class="threads-item__content">
              <div class="threads-item__content-author">
                {{ thread.name || 'Unknown' }}
              </div>
              <div class="threads-item__content-text">
                {{ thread.body }}
              </div>
              <div class="threads-item__content-date">
                {{ thread.time }}
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <router-link to="/conversations"> Start a conversation </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/basic/avatar/Avatar';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeConversations',
  components: {
    Avatar,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['threads']),
    threadsList() {
      return (this.threads || []).slice(0, 3);
    },
  },
  methods: {
    ...mapActions(['loadThreads']),
    checkThreads() {
      this.loading = true;
      this.loadThreads({
        filters: {
          users: null,
          search: null,
        },
      }).then(() => {
        this.loading = false;
      });
    },
    openConversation(thread) {
      const params = {
        typeId: thread.type_id,
        threadId: thread.thread_id || thread.class,
      };
      const route = `/conversations/${this.isMobile ? 'chat/' : ''}${
        params.typeId
      }/${params.threadId}`;
      this.parentNavigate(route, true, false);
    },
  },
  mounted() {
    this.checkThreads();
  },
};
</script>

<style lang="less">
.threads-list {
  padding-bottom: 6px;
}

.threads-item {
  display: flex;
  position: relative;

  & + .threads-item {
    margin-top: 16px;
  }

  &__content {
    padding: 8px;
    margin-left: 16px;
    background: var(--v-borderColor-base);
    border-radius: @base-radius;
    flex: 1;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background: var(--v-darkGray-base);
    }

    &-author {
      font-size: 12px;
      color: var(--v-appMain-base);
    }

    &-text {
      font-size: 14px;
    }

    &-date {
      position: absolute;
      top: 8px;
      right: 8px;
      font-weight: bold;
      font-size: 12px;
      opacity: 0.5;
    }
  }
}
</style>
