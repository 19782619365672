export default {
  type: 'social',
  title: 'Prep Social Post',
  icon: 'broadcast',
  engagement: true,
  canAdvance: true,
  help: 'Encourage audience sharing.',
  schema: {
    body: {
      type: 'Html',
      title: 'Some Intro Content',
      help: [
        'Prepped social posts are great for showing milestones and accomplishments.',
        'So perhaps you want to pat your students on the back a bit and then encourage them to',
        'share their progress! Do that here. <i>What they share will go in "Your Post" below.</i>',
      ].join(' '), // Use array just for cleaner view,
      default: '',
      hidden: true,
    },
    url: {
      title: 'URL',
      type: 'Text',
      help: 'URL of that awesome thing you want people to share on social media.',
    },
    tweet: {
      title: 'Your Post',
      type: 'TextArea',
      help: [
        'Prep your post that you want people to share on Twitter or FB.',
        'You do not need to include the url above. We will add that. Remember to keep',
        'your post short and sweet leaving room for your shortened URL above.',
      ].join(' '),
    },
    share_points: {
      title: 'Points for Sharing',
      type: 'Number',
      default: 1200,
      help: 'Number of points you want to award a contact for sharing on each social network.',
    },
    click_points: {
      title: 'Points for a click through',
      type: 'Number',
      default: 10,
      help: `Each contact gets a unique link back to this post URL. We track
        the unique clicks and award points to your contact!'`,
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },

  maxPoints(v) {
    return v.share_points || 0;
  },
};
