import richText from './richText';
import video from './video';
import audio from './audio';
import embed from './embed';
import files from './files';
import button from './button';
import challenge from './challenge';
import poll from './poll';
import assessment from './assessment';
import blank from './blank';
import statsTracker from './statsTracker';
import order from './order';
import checklist from './checklist';
import openEnded from './openEnded';
import videoUpload from './videoUpload';
import social from './social';
import missingWords from './missingWords';
import custom from './custom';
import scorm from './scorm';
import typeform from './typeform';
import jotform from './jotform';
import contactUpdate from './contactUpdate';
import contactUpdates from './contactUpdates';
import calendly from './calendly';
import cal from './cal';
import signwell from './signwell';

export default [
  {
    group: 'presentation',
    title: 'Presentation',
    types: [richText, video, audio, files, scorm],
  },

  {
    group: 'quizzing',
    title: 'Quizzing',
    types: [challenge, openEnded, blank, missingWords, order, assessment],
  },

  {
    group: 'pulsing',
    title: 'Pulsing',
    types: [videoUpload, poll, statsTracker, checklist],
  },

  {
    group: 'scheduling',
    title: 'Scheduling',
    types: [cal, calendly],
  },

  {
    group: 'dataCollection',
    title: 'Data Collection',
    types: [contactUpdates, jotform, typeform, signwell, contactUpdate],
  },

  {
    group: 'other',
    title: 'Other',
    types: [button, embed, social, custom],
  },
];
