<template>
  <v-fade-transition>
    <div
      v-if="hasUnfinishedOnboardTasks"
      class="home-progress-bar base-shadow"
      @click="openTasks"
    >
      <div class="home-progress-bar__title">
        Make sure to finish your getting started tasks!
      </div>

      <v-progress-linear
        class="home-progress-bar__progress"
        :value="progress"
        color="primary"
        height="8"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeProgressBar',
  computed: {
    ...mapGetters('tasks', ['all']),
    onboardTasks() {
      return this.all.filter(({ program_class }) => {
        return program_class === 'OrgLaunch';
      });
    },
    finishedOnboardTasks() {
      return this.onboardTasks.filter(this.isTaskDone);
    },
    hasUnfinishedOnboardTasks() {
      return this.onboardTasks.some((task) => !this.isTaskDone(task));
    },
    progress() {
      const tasksCount = this.onboardTasks.length;
      const finishedTasksCount = this.finishedOnboardTasks.length;

      return (finishedTasksCount / tasksCount) * 100;
    },
  },
  methods: {
    ...mapActions('tasks', ['load']),
    isTaskDone({ status }) {
      return ['done', 'ready'].includes(status);
    },
    openTasks() {
      this.$router.push({
        name: 'tasks',
        query: {
          program_class: 'OrgLaunch',
        },
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="less">
.home-progress-bar {
  background: #fff;
  padding: 12px 16px 16px;
  border-radius: @base-radius;
  cursor: pointer;
  transition: all 0.25s ease;

  &__title {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 12px;
    color: var(--v-appMain-base);
  }

  &:hover {
    background: var(--v-lightGray-base);
  }
}
</style>
