import { extend } from 'lodash';
import { compareStrings, highlightSearch } from '@/filters/helpers';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

export const updateArrInStore = (state, obj, type, insertFirst) => {
  if (!obj) return;

  let arr = state[type] || [];
  let idx = arr.findIndex((a) => {
    return obj.id ? a.id == obj.id : a.con_id === obj.con_id;
  });

  if (idx < 0) {
    let method = insertFirst ? 'unshift' : 'push';
    arr[method](obj);
  } else {
    arr.splice(idx, 1, obj);
  }
};

export const searchInArray = (arr = [], params = [], search = '') => {
  return arr.reduce((acc, obj) => {
    const objClone = cloneDeep(obj);

    if (params.some((p) => compareStrings(objClone[p], search))) {
      params.forEach((p) => {
        objClone[p] = highlightSearch(objClone[p], search);
      });

      acc.push(objClone);
    }

    return acc;
  }, []);
};

export const searchInArrayOfArrays = (arr, subArray, params, search) => {
  return arr.reduce((acc, folder) => {
    const folderClone = cloneDeep(folder);
    folderClone[subArray] = (folderClone[subArray] || []).filter((i) => {
      return params.some((p) => compareStrings(i[p], search));
    });
    if (folderClone[subArray].length) {
      acc.push(folderClone);
    }
    return acc;
  }, []);
};

export const searchInArrayOfObjects = (arr, field, params, search) => {
  return arr.reduce((acc, folder) => {
    const folderClone = cloneDeep(folder);
    const fields = {};
    for (let key in folderClone[field]) {
      let fieldObj = folderClone[field][key];
      if (params.some((p) => compareStrings(fieldObj[p], search))) {
        params.forEach((p) => {
          fieldObj[p] = highlightSearch(fieldObj[p], search);
        });
        fields[key] = fieldObj;
      }
    }

    if (!isEmpty(fields)) {
      folderClone[field] = fields;
      acc.push(folderClone);
    }

    return acc;
  }, []);
};

const mapModelKey = function (model_key, key, update = {}) {
  let computed = {};

  computed[key] = {
    get() {
      let m = this[model_key] || {};
      return m[key];
    },

    set(value) {
      update[key] = value;

      let message = {
        model: this[model_key],
        update,
      };

      //this uses update mapped action
      return this.update(message);
    },
  };

  return computed;
};

export const mapModel = function (model_key, keys, update) {
  let computed = {};
  keys.forEach((key) => {
    extend(computed, mapModelKey(model_key, key, update));
  });
  return computed;
};
