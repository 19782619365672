export default {
  type: 'jotform',
  engagement: true,
  icon: 'jotform',
  title: 'Jotform',
  help: 'Embed Jotform',
  is_new: false,
  schema: {
    form_id: {
      type: 'Text',
      title: 'Form ID',
      default: '222686729200053',
    },

    prefilled_fields: {
      type: 'KeyValues',
      title: 'Prefilled Fields',
      help: 'Pass learner profile information into your JotForm',
      default: [
        {
          key: 'cvyr_pid',
          value: '{{pid}}',
        },
        {
          key: 'name[first]',
          value: '{{first_name}}',
        },
        {
          key: 'name[last]',
          value: '{{last_name}}',
        },
        {
          key: 'feedback',
          value: "It's great!",
        },
      ],
    },

    success_text: {
      type: 'Text',
      title: 'Success Message',
      help: 'Keep this short and sweet.',
      default: 'Thank you! You are all set here.',
    },

    can_advance: {
      type: 'Checkbox',
      default: false,
      title: 'Can Skip. Do not require.',
      help: 'Do not require the form to be submitted.',
    },
  },
};
