<template>
  <field-block title="Resources" class="task-resources">
    <div
      v-for="(link, idx) in resources"
      class="link-preview-wrap d-flex"
      :key="link.value"
    >
      <link-preview :url="link.value" @watchVideo="selectedVideo = $event" />

      <v-btn icon text class="ml-1" @click="removeResource(idx)">
        <v-icon>clear</v-icon>
      </v-btn>
    </div>

    <v-form
      ref="form"
      class="add-resource-form mb-2 mt-2"
      v-model="isFormValid"
    >
      <v-text-field
        class="no-label"
        placeholder="Link"
        v-model="newUrl"
        :rules="[rules.isUrl]"
      />
      <v-btn color="appMain" class="ml-3" @click="addResource">Add</v-btn>
    </v-form>

    <v-dialog v-model="showVideoModal" max-width="980px">
      <media-card
        v-if="selectedVideo"
        :media="selectedVideo"
        @close="selectedVideo = null"
      />
    </v-dialog>
  </field-block>
</template>

<script>
import LinkPreview from '@/components/basic/link-preview/LinkPreview';
import MediaCard from '@/views/lessons/lessonWidgets/videoWidget/MediaCard';
import validation from '@/lib/validationRules';

export default {
  name: 'TaskResources',
  components: {
    LinkPreview,
    MediaCard,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newUrl: '',
      isFormValid: false,
      selectedVideo: null,
      rules: {
        required: (v) => validation.checkRequired(v) || '',
        isUrl: (v) => validation.checkUrl(v) || '',
      },
    };
  },
  computed: {
    resources: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    showVideoModal: {
      get() {
        return !!this.selectedVideo;
      },
      set(val) {
        this.selectedVideo = val || null;
      },
    },
  },
  methods: {
    removeResource(idx) {
      this.resources.splice(idx, 1);
    },
    isAlreadyAdded(url) {
      return this.resources.some(({ value }) => {
        return value === url;
      });
    },
    addResource() {
      this.$refs.form.validate();
      if (!this.isFormValid) return;

      if (this.isAlreadyAdded(this.newUrl)) {
        this.$refs.form.reset();
        return;
      }

      this.resources = [
        ...this.resources,
        {
          type: 'url',
          value: this.newUrl,
        },
      ];

      setTimeout(() => {
        this.$refs.form.reset();
      });
    },
  },
};
</script>

<style lang="less">
.add-resource-form {
  display: flex;
  margin-right: 38px;
}

.link-preview-wrap {
  .player,
  .player__canvas-wrap {
    height: 100%;
  }
}

.task-video {
  flex: 1;
  margin-bottom: 12px;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: @content-shadow;
}
</style>
