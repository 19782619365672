import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

import { FIREBASE_CONFIG } from './firebaseConfig';

const app = initializeApp(FIREBASE_CONFIG);
let messaging = null;
isSupported().then((supported) => {
  if (supported) {
    messaging = getMessaging();
  } else {
    console.log('firebase messaging is not supported');
  }
});

export const getDeviceToken = async () => {
  try {
    if (!messaging) {
      console.log('firebase messaging is not initialized');
      return '';
    }

    const vapidKey =
      'BDGC5F4Selp3-CzTCyUuMuCbWzDpwjEjIuqmRcBYRAYMoPU1ecN2NZsKT9lVBbAUPYcmiE0gHZKNCwxT-kfW3S8';
    return await getToken(messaging, { vapidKey });
  } catch (err) {
    console.log('An error occurred while retrieving device token.', err);
  }
};
