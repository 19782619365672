import Module from '@/store/resource/common/module';
import SnippetsFolders from '@/store/resource/snippetsFolders';
import { searchInArrayOfArrays } from '@/store/storeHelpers';

let module = new Module(new SnippetsFolders());

module.add('mutations', {
  updateSnippetInFolders(state, value) {
    let currentSnippetFolder = state.all.find((f) =>
      (f.snippets || []).some((i) => i.id === value.id)
    );
    let snippetFolder = state.all.find((i) => i.id === value.folder_id);

    if (currentSnippetFolder && currentSnippetFolder.id !== value.folder_id) {
      currentSnippetFolder.snippets = currentSnippetFolder.snippets.filter(
        (i) => i.id != value.id
      );
    }

    if (!snippetFolder.snippets) {
      snippetFolder.snippets = [];
    }

    let idx = snippetFolder.snippets.findIndex((a) => a.id == value.id);
    if (idx < 0) {
      snippetFolder.snippets.push(value);
    } else {
      snippetFolder.snippets.splice(idx, 1, value);
    }
  },

  deleteSnippetInFolder(state, value) {
    let snippetFolder = state.all.find((i) => i.id === value.folder_id);
    snippetFolder.snippets = snippetFolder.snippets.filter(
      (i) => i.id != value.id
    );
  },
});

module.add('getters', {
  filteredSnippetsFolders(state, getters) {
    if (!getters.search) return state.all;

    return searchInArrayOfArrays(
      state.all,
      'snippets',
      ['name', 'shortcut', 'text_content'],
      getters.search
    );
  },

  snippetsFolders(state) {
    return state.all.map((i) => {
      return {
        text: i.name,
        value: i.id,
      };
    });
  },

  singleSnippet(state) {
    return (snippetId) =>
      state.all.reduce((acc, i) => {
        let snippet = i.snippets.find((i) => i.id === snippetId);
        if (snippet) return snippet;
        return acc;
      }, null);
  },
});

export default module.create();
