export default {
  type: 'audio',
  title: 'Audio',
  icon: 'audio',
  help: 'Upload or record an audio file.',
  schema: {
    audio: {
      type: 'Audio',
      title: 'Audio',
      help: 'Upload or record an audio file.',
    },
    image: {
      type: 'Image',
      title: 'Banner',
      help: 'Select an image to attach to this audio',
      short: true,
    },
    text: {
      type: 'TextArea',
      title: 'Transcript',
      collapsible: true,
      help: 'Add text version of this audio',
    },
    standnew: {
      type: 'Checkbox',
      title: 'Start of new section',
      default: false,
    },
    required: {
      title: 'Must listen',
      type: 'Checkbox',
      default: false,
      help: '',
    },
  },
};
