const importLib = () => {
  return import(/* webpackChunkName: "ably" */ 'ably');
};

import EventBus from '@/factories/eventBus';
import { showBrowserNotification } from '@/lib/notification';
import { parentNavigate } from '@/lib/parentConnection';
import store from '@/store';
import router from '@/router';

export const initAbly = (profile) => {
  if (!profile || !profile.id) return;

  let user_channel = profile.pn_user_channel;
  let org_channel = profile.pn_org_channel;
  let sub_key = profile.pn_sub_key;

  if (!user_channel || !org_channel || !sub_key) {
    console.log('ably auth failed', user_channel, org_channel, sub_key);
    return false;
  }

  const publishMessage = (message) => {
    let data = Object.assign(message.data, { clientId: message.clientId });
    let channel = data.channel || data.event;
    const channelHandler = channelMiddleware[channel];
    if (channelHandler) {
      data = channelHandler(data);
      if (!data) {
        return false;
      }
    }

    if (['threadAssigned'].includes(channel)) {
      return store.dispatch('handleThreadUpdates', data);
    }

    EventBus.$emit(channel, data);
    if (channel === 'portal.incoming.message') {
      store.dispatch('refreshPortalThreads', data);
    }
  };

  importLib().then((Ably) => {
    let ably = new Ably.Realtime({ key: sub_key, clientId: profile.id + '' });

    const addChannel = (chan) => {
      let channel = ably.channels.get(chan);
      channel.subscribe(publishMessage);
    };

    addChannel(user_channel);
    addChannel(org_channel);

    window.ablyChannel = ably.channels.get(org_channel);
    ablyChannel.attach(function (err) {
      if (err) return console.error('Error attaching to the channel');
      ablyChannel.presence.enter(null, function (err) {
        if (err) return console.error('Error entering presence');
      });
    });

    ablyChannel.presence.subscribe((member) => {
      const newMessage = member.data?.newMessage;
      if (newMessage && !messageIsRelevant(newMessage)) {
        delete member.data.newMessage;
      }
      EventBus.$emit('presenceUpdated', member);
    });
  });
};

export const initAblyInPortal = (profile) => {
  if (!profile) return;

  const { org_meta, pn_contact_channel, pn_sub_key } = profile;
  if (!org_meta?.features?.portal_messaging || !pn_sub_key) return;

  const ablySettings = {
    id: profile.id,
    pn_sub_key,
    pn_user_channel: pn_contact_channel,
    pn_org_channel: 'learner.presence',
  };

  initAbly(ablySettings);
};

export const ablyAction = (method, data) => {
  if (!window.ablyChannel || !ablyActions[method]) return;

  return ablyActions[method](data);
};

const ablyActions = {
  updatePresence: (data) => {
    ablyChannel.presence.update(data);
  },
  getPresence: () => {
    return new Promise((resolve) => {
      ablyChannel.presence.get((err, members) => resolve(members));
    });
  },
  publish: (data) => {
    ablyChannel.publish(data.event, data);
  },
  leave: () => {
    ablyChannel.presence.leave();
  },
  enter: () => {
    ablyChannel.presence.enter();
  },
};

const incomingMessageNotification = (message) => {
  if (!message || message?.name === 'threadToggled') return;

  const isMobile = window.innerWidth < 980;
  const isPortalMessage = message?.channel === 'portal.incoming.message';
  const { type_id, thread_id, body, creator_name, user_name } = message;

  let path = [
    '/conversations' + (isMobile ? '/chat' : ''),
    type_id,
    thread_id || 'sms',
  ].join('/');

  if (isPortalMessage) {
    const route = router.resolve({
      name: 'portal-conversations-chat',
      params: { threadId: thread_id },
    });
    path = route?.route?.fullPath;
  }

  let notification = {
    type: 'inverse',
    title: creator_name || user_name || 'New Message!',
    body,
    audio: true,
    clicked: function () {
      parentNavigate(path, true, true);
    },
  };

  showBrowserNotification(notification);

  return message;
};

const messageIsRelevant = (data) => {
  if (data.team_id) {
    const currentTeam = store.getters['teams/currentTeam'] || {};
    if (currentTeam.id !== data.team_id) {
      return false;
    }
  }
  return true;
};

const incomingMessageGuard = (data) => {
  if (!messageIsRelevant(data)) {
    return false;
  }
  return incomingMessageNotification(data);
};

const channelMiddleware = {
  'incoming.message': incomingMessageGuard,
  'portal.incoming.message': incomingMessageNotification,
};
