<template>
  <div class="smart-tooltip__content" :class="{ 'has-video': !!tooltipVideo }">
    <v-btn
      fab
      small
      color="rgba(90, 90, 90, 1)"
      class="tooltip-expand-button"
      @click="$emit('toggleFullSize')"
    >
      <v-icon color="light">
        {{ fullSize ? 'close_fullscreen' : 'open_in_full' }}
      </v-icon>
    </v-btn>

    <video-player
      v-if="tooltipVideo"
      ref="player"
      :item="videoItem"
      :active="true"
      :popup="false"
      :taking="false"
      :testing="false"
      :options="clientPlayerOptions"
      class="mb-2"
    />
    <p class="mb-0">{{ content.description }}</p>

    <div v-if="resources.length" class="mt-3 tooltip-resources">
      <strong>Read More</strong>
      <ul>
        <li v-for="(link, idx) in resources" :key="idx">
          <a :href="link.url" target="_blank">
            {{ link.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/views/lessons/lessonWidgets/videoWidget/VideoPlayer';

export default {
  name: 'SmartTooltipContent',
  components: {
    VideoPlayer,
  },
  props: {
    content: {},
    link: String,
    fullSize: Boolean,
  },
  data() {
    return {
      clientPlayerOptions: {
        presentMode: true,
        autoPlay: false,
      },
    };
  },
  computed: {
    resources() {
      const links = (this.content.resources || []).slice(0);

      if (this.link && !links.some(({ url }) => url === this.link)) {
        links.push({
          url: this.link,
          name: this.link,
        });
      }
      return links;
    },
    tooltipVideo() {
      return this.content?.video_link?.url || '';
    },
    videoItem() {
      return {
        values: {
          url: this.tooltipVideo,
          summary: {},
        },
      };
    },
  },
};
</script>

<style lang="less">
.tooltip-resources {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.tooltip-expand-button {
  position: absolute !important;
  top: -14px;
  right: -14px;
  z-index: 1;
}

.smart-tooltip__content {
  &.has-video {
    width: 456px;
  }
}
</style>
