import VideoPlayer from './VideoPlayer.js';

class GoVideoPlayer extends VideoPlayer {
  api_url = '//play.vidyard.com/embed/v4.js';
  script_id = 'vidyard-js-api';

  _apiLoaded() {
    return !!window.Vidyard;
  }

  _bindApi() {
    VidyardV4.api.renderDOMPlayers();
    let player = (this.player = VidyardV4.api.getPlayersByUUID(
      this._videoId()
    )[0]);

    player.on('ready', () => {
      this.trigger(this.ready_event);
      this.setStart();
    });

    player.on('timeupdate', (currentTime) => {
      if (this.opts.preview) return;
      this.setLatestPlayTime(currentTime);
    });

    player.on('playerComplete', () => {
      this.stop();
    });
  }

  // only 2, 1.5, 1.25, 1 & 0.5 are accepted decimal values
  _changePlaybackRate(speed = 1) {
    this.player.setPlaybackSpeed(speed);
  }

  _duration() {
    return this.player.metadata.length_in_seconds;
  }

  _videoId() {
    let matches = this.constructor.test(this.url);
    return matches[1] || '';
  }

  _play() {
    this.player.play();
  }

  _pause() {
    this.player.pause();
  }

  _stop() {
    this._pause();
  }

  _remove() {
    if (!window?.VidyardV4) return;
    VidyardV4.api.destroyPlayer(this.player);
  }

  _seek(seconds) {
    this.player.seek(seconds);
  }

  buildHtml() {
    return `<img
              class="vidyard-player-embed"
              src="https://play.vidyard.com/${this._videoId()}.jpg"
              data-uuid="${this._videoId()}"
              data-v="4"
              data-type="inline"
            />`;
  }
}

let goVideoPlayerRegex =
  /(?:share|embed)\.vidyard\.com\/(?:watch|share)\/(\w+)/;
// let goVideoPlayerRegex = /share\.vidyard\.com\/watch\/(\w+)/
GoVideoPlayer.test = function (url) {
  return url.match(goVideoPlayerRegex);
};

export default GoVideoPlayer;
