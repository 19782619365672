<template>
  <div
    class="field-block"
    :class="{
      'field-block--inline': inline,
      'field-block--dark': dark,
    }"
  >
    <div
      v-if="hasTitle"
      class="field-block__title"
      :class="{ 'field-block__title--large': largeTitle }"
    >
      <slot name="title">
        <span v-html="title" class="pr-1"></span>

        <smart-tooltip
          v-if="helpTooltip"
          small
          :category="helpTooltipCategory"
          :type="helpTooltip"
        />
      </slot>
    </div>
    <div
      v-if="!!description"
      class="field-block__description"
      v-html="description"
    ></div>
    <div v-if="hasContent" class="field-block__content">
      <slot name="content"></slot>
    </div>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
import SmartTooltip from '@/components/basic/smart-tooltip/SmartTooltip.vue';

export default {
  name: 'FieldBlock',
  components: {
    SmartTooltip,
  },
  props: {
    title: String,
    helpTooltip: String,
    helpTooltipCategory: String,
    description: String,
    largeTitle: Boolean,
    inline: Boolean,
    dark: Boolean,
  },
  computed: {
    hasContent() {
      return !!this.$slots.content;
    },
    hasTitle() {
      return !!this.$slots.title || this.title;
    },
  },
};
</script>

<style lang="less">
.field-block {
  & + .field-block {
    margin-top: 30px;
  }

  &__title {
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    color: var(--v-appMain-base);
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    &:first-letter {
      text-transform: uppercase;
    }

    &--large {
      font-size: 16px;
      font-family: var(--v-headers-font-base);
      margin-bottom: 8px;
    }
  }

  & > img {
    height: auto;
    max-width: 100%;
    display: block;
  }

  &__description {
    margin-bottom: 6px;
  }

  &__content {
    border-radius: @base-radius;
    background-color: #fff;
    overflow: hidden;
    padding: 16px;
    font-weight: 600;
    font-size: 14px;

    img {
      max-width: 400px;
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }

    &--dark {
      background-color: var(--v-background-base);
    }
  }

  .large-text {
    font-style: italic;
    font-weight: 300;
    font-size: 40px;
    line-height: 1;
  }

  .medium-text {
    font-style: italic;
    font-weight: 300;
    font-size: 20px;
    line-height: 1;
  }

  &--inline {
    margin-top: 8px !important;
    .field-block__title {
      display: none;
    }
  }

  &--dark {
    .field-block__content {
      background-color: var(--v-background-base);
    }
  }
}
</style>
