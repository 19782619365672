export default {
  courseStats(state) {
    return state.courseStats;
  },
  courseStatsMeta(state) {
    return state.courseStatsMeta;
  },
  userStats(state) {
    return state.userStats;
  },
  loadingUserStats(state) {
    return state.loadingUserStats;
  },
  loadingCourseStats(state) {
    return state.loadingCourseStats;
  },
};
