export default [
  'writing-notes-idea-conference.jpg',
  'workers-construction-site-hardhats-38293.jpg',
  'wood-architect-table-work.jpg',
  'woman-hand-desk-office.jpg',
  'Warsaw-at-night-free-license-CC0.jpg',
  'tee-farm.jpg',
  'pexels-photo-97976.jpg',
  'startup-photos.jpg',
  'space-desk-workspace-coworking.jpg',
  'restaurant-man-person-coffee.jpg',
  'pexels-photo.jpg',
  'pexels-photo-64776.jpg',
  'pexels-photo-70292.jpg',
  'pexels-photo-102170.jpg',
  'pexels-photo-105952.jpg',
  'pexels-photo-97050.jpg',
  'pexels-photo-64775.jpg',
  'pexels-photo-64609.jpg',
  'pexels-photo-63574.jpg',
  'pexels-photo-30732.jpg',
  'pexels-photo-29596.jpg',
  'pexels-photo-30267.jpg',
  'person-cute-vintage-playing.jpg',
  'pexels-photo-28456.jpg',
  'pexels-photo-12628.jpg',
  'people-woman-girl-writing.jpg',
  'people-woman-coffee-meeting.jpg',
  'people-coffee-tea-meeting.jpg',
  'people-eiffel-tower-lights-night.jpg',
  'people-coffee-notes-tea.jpg',
  'pen-contract.jpg',
  'painter.jpg',
  'notebook-hero-workspace-minimal.jpg',
  'money-card-business-credit-card-50987.jpg',
  'man-relax-couch-study.jpg',
  'man-person-people-train.jpg',
  'man-people-office-writing.jpg',
  'long-coffee-table.jpg',
  'lights-night-dark-abstract.jpg',
  'laptops-on-desk.jpg',
  'imacs-two-women.jpg',
  'imac-code.jpg',
  'hands-people-woman-working.jpg',
  'hands-people-woman-meeting.jpg',
  'hand-palm-baby.jpg',
  'construction-work-carpenter-tools.jpg',
  'fashion-men-vintage-colorful.jpg',
  'city-flying-vacation-working.jpg',
  'child-father-sunset.jpg',
  'businessman-fashion-suit-jacket.jpg',
];
