export default {
  type: 'embed',
  title: 'Embed URL',
  help: 'Embed just about anything on the web!',
  icon: 'embed',
  schema: {
    url: {
      type: 'Text',
      default: 'https://soundcloud.com/conveyour/fruit-fly-attention-spans',
      title: 'URL to embed',
      help: 'You can embed just about anything here like a soundcloud URL, a twitter profile, or an amazon product',
    },
    standnew: {
      type: 'Checkbox',
      title: 'Start of new section',
      default: false,
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },
};
