export const prepareFields = (group) => {
  return Object.keys(group.fields)
    .map((key) => {
      const field = group.fields[key];
      const fieldText = field.label === 'name' ? field.name : field.label;
      return {
        fixed: false,
        text: fieldText,
        value: `${group.id}.${key}`,
        type: field.type,
        field: key,
        group: group.type,
        is_event: field.is_event,
        is_property: field.is_property,
        options: field.values,
        editable: field.editable,
        deletable: field.field_deletable,
      };
    })
    .sort((a, b) => a.text.localeCompare(b.text));
};

export const isInViewport = (elem) => {
  let bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom - 200 <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const detectIE = () => {
  var ua = window.navigator.userAgent;
  if (checkString('MSIE ') || checkString('Trident/') || checkString('Edge/')) {
    document.body.classList.add('ie-support');
  }

  function checkString(str) {
    return ua.indexOf(str) > 0;
  }
};

export const formatMobile = (phone) => {
  if (!phone) return;

  let ukMobileRegex = /^(\+?44)(\d{4})\D*?(\d{5,6})/;
  if (phone.match(ukMobileRegex)) {
    return phone.replace(ukMobileRegex, '+44 $2 $3');
  }

  let usMobileRegex = /(\+?\d{1})?\D*?(\d{3})\D*?(\d{3})\D*?(\d{4})/;
  return phone.replace(usMobileRegex, '($2) $3-$4');
};

export const formatNumber = (number) => {
  number = (number || '') + '';
  if (!number) return '';

  number = number.replace('+', '');
  if (number.indexOf('1') !== 0) return number;

  number = number.slice(1);
  return [number.slice(0, 3), number.slice(3, 6), number.slice(6)].join('-');
};

export const compareStrings = (text = '', search = '') => {
  if (!text) return false;
  return text.toLowerCase().includes(search.toLowerCase());
};

export const highlightSearch = (text = '', search = '') => {
  if (!search) return text;
  const re = new RegExp(search, 'i');
  return text.replace(re, `<span class="highlighted">${search}</span>`);
};

export const convertTimeToAmPm = (time) => {
  if (!time) return '00:00';

  let hour = time.split(':')[0];
  let convertedHour =
    hour > 12 ? (hour % 12 < 10 && '0') + (hour % 12) || 12 : hour;
  let ampm = hour < 12 || hour === 24 ? 'AM' : 'PM';

  return `${convertedHour}:${time.split(':')[1]} ${ampm}`;
};

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const createDateFromStr = (timeStr) => {
  if (!timeStr) return new Date();
  return new Date(timeStr.replaceAll('-', '/'));
};

export const prepareTag = (str, uppercase = false) => {
  let tag = str
    .replace(/[^A-Za-z0-9_-]/g, ' ')
    .trim()
    .replace(/\s+/g, '_');
  if (!uppercase) {
    tag = tag.toLowerCase();
  }

  return tag;
};

export const getPublicPath = () => {
  return process.env.BASE_URL || process.env.VUE_APP_BASE_URL;
};

export const inPortal = () => {
  return window.location.href.includes('/portal/');
};

export const getFilePath = (filePath) => {
  return `${getPublicPath()}static/${filePath}`;
};

export const getIconPath = (svgPath) => {
  return `${getPublicPath()}static/svg/${svgPath}.svg`;
};

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const getPrettyTime = (time) => {
  const seconds = time % 60;
  const remaining = time - seconds;
  const minutes = remaining ? remaining / 60 : 0;
  let str = '';

  if (minutes) {
    if (minutes == 1) {
      str = '1 min, ';
    } else {
      str = `${minutes} min, `;
    }
  }
  return `${str}${seconds} sec`;
};

export const isTextOverflow = (el) => {
  if (!el) return;

  const temp = el.cloneNode(true);
  temp.style.position = 'fixed';
  temp.style.overflow = 'visible';
  temp.style.whiteSpace = 'nowrap';
  temp.style.visibility = 'hidden';
  el.parentElement.appendChild(temp);

  const fullWidth = temp.getBoundingClientRect().width;
  const displayWidth = el.getBoundingClientRect().width;
  const showTitleTooltip = fullWidth > displayWidth;
  temp.remove();

  return showTitleTooltip;
};
