import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,

  state: {
    users: [],
    usersLoaded: false,
    userLoading: false,
    usersView: 'list',
    usersSearch: '',
    currentUser: null,
    currentUserLoaded: false,
    allPermissions: [],
    usersTimezones: null,
    recentActivity: [],
  },

  mutations,
  actions,
  getters,
};
