<template>
  <div class="item-card" v-if="hasPermission('automations')">
    <content-line
      icon="sidebar/automations"
      text="Scheduled Automations"
      class="pr-1"
    />

    <div class="item-card__content pt-0">
      <template v-if="triggersLoaded">
        <div class="content-list" v-if="scheduledAutomations.length">
          <div
            class="content-list__item content-list__item--small content-list__item--action"
            v-for="(automation, idx) in scheduledAutomations"
            :key="idx"
            @click="openAutomation(automation)"
          >
            <div class="content-list__item-main text-ellipsis">
              {{ automation.name }}
              <p class="mb-0 date-str">
                {{ getAutomationDate(automation.start_time) }}
              </p>
            </div>
          </div>
        </div>

        <div v-else>
          <router-link to="/automations/create">
            Create an automation
          </router-link>
        </div>
      </template>

      <div class="spinner-wrap" v-else>
        <spinner small />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { format } from 'date-fns';

export default {
  name: 'HomeScheduledAutomations',
  computed: {
    ...mapState('campaigns', ['defaultCampaign']),
    ...mapGetters(['triggers', 'triggersLoaded']),
    scheduledAutomations() {
      return this.triggers.filter((i) => i.status === 'scheduled').slice(0, 6);
    },
  },
  watch: {
    defaultCampaign: {
      handler() {
        this.checkAutomations();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['loadTriggers']),
    ...mapActions('campaigns', ['loadDefaultCampaign']),
    getAutomationDate(timestamp) {
      return format(new Date(timestamp * 1000), 'EEE MMM do yyyy, hh:mm aaa');
    },
    openAutomation(automation) {
      this.$router.push(`/automations/edit/${automation.id}`);
    },
    checkAutomations() {
      if (this.defaultCampaign.id && !this.triggersLoaded) {
        this.loadTriggers();
      }
    },
  },
  mounted() {
    this.loadDefaultCampaign();
    this.checkAutomations();
  },
};
</script>

<style scoped>
.date-str {
  font-size: 12px;
  opacity: 0.5;
}
</style>
