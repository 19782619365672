import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    lessonsLoaded: false,
    loadingSingleLesson: false,
    lessons: [],
    lessonsView: '',
    lessonsSearch: '',
    lessonsSearchResult: [],
    lessonsSearchLoading: false,
    lessonsFilter: '',
    lessonMeta: {},
    lessonsNamesLoaded: false,
    lessonsNames: [],
    lessonsTrackerNames: [],
    lessonFolderModal: {
      value: false,
      lessons: [],
      folder: null,
      type: '',
    },

    itemMetrics: {},
    lessonMetrics: {},
    lessonMetricsFilter: {
      start_time: null,
      end_time: null,
    },
  },

  mutations,
  actions,
  getters,
};
