import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('tasks');

const tasksRoutes = [
  {
    path: '/tasks',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: ':slug?',
        name: 'tasks',
        meta: { showNav: true },
        props: true,
        component: loadView('Tasks'),
      },
    ],
  },
];

export default tasksRoutes;
