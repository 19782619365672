<template>
  <v-tooltip right :disabled="disabledTooltip">
    <template v-slot:activator="{ on }">
      <div v-on="on" v-if="showTeamsPicker">
        <div class="app-sidebar__link" @click="openTeamPicker">
          <div class="app-sidebar__link-icon">
            <icon-base icon="sidebar/teams" :style="{ fill: teamColor }" />
          </div>
          <div class="app-sidebar__link-text text-ellipsis">
            Team: {{ teamName }}
          </div>
        </div>
      </div>
    </template>
    <span>Team: {{ teamName }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/factories/eventBus';

export default {
  name: 'TeamPickerButton',
  props: {
    disabledTooltip: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('users', ['isFeatureEnabled', 'isAdmin']),
    ...mapGetters('teams', ['currentTeam']),
    showTeamsPicker() {
      return this.isFeatureEnabled('teams');
    },
    teamColor() {
      const team = this.currentTeam;
      return team && team.color ? team.color : 'appMain';
    },
    teamName() {
      return this.currentTeam?.label || 'None';
    },
  },
  methods: {
    openTeamPicker() {
      EventBus.$emit('showTeamPicker', {});
    },
  },
};
</script>

<style scoped>
.app-sidebar__link {
  cursor: pointer;
}
</style>
