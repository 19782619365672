import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('campaigns');

const campaignsRoutes = [
  {
    path: '/campaigns',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '/',
        meta: { showNav: true },
        component: loadView('Campaigns'),
        children: [
          {
            path: '',
            name: 'campaigns',
            component: loadView('CampaignsMain'),
          },
          {
            path: 'templates',
            name: 'campaigns-templates',
            component: () =>
              import(
                /* webpackChunkName: "app-campaign-templates" */ '@/views/campaignTemplates/CampaignTemplates.vue'
              ),
          },
        ],
      },

      {
        path: 'create',
        name: 'campaign-create',
        props: true,
        component: loadView('CampaignCreate'),
      },
      {
        path: ':campaignId/test/:triggerId?',
        name: 'campaign-test',
        props: true,
        component: loadView('CampaignTest'),
      },
      {
        path: ':campaignId/:type?',
        name: 'campaign-edit',
        props: true,
        component: loadView('CampaignForm'),
      },
      {
        path: ':campaignId/triggers/:id',
        props: true,
        name: 'campaign-trigger-edit',
        component: () =>
          import(
            /* webpackChunkName: "app-campaigns-form" */ '@/views/automations/form/AutomationForm'
          ),
      },
    ],
  },
];

export default campaignsRoutes;
