<template>
  <install-app-wrap>
    <app-sidebar-link
      :link="INSTALL_APP_LINK"
      :hideTooltips="disabledTooltip"
    />
  </install-app-wrap>
</template>

<script>
import AppSidebarLink from './AppSidebarLink.vue';
import InstallAppWrap from '@/components/basic/InstallAppWrap.vue';

const INSTALL_APP_LINK = {
  text: 'Add to Device',
  icon: 'install',
  path: '/install',
  disabled: true,
};

export default {
  name: 'InstallAppLink',
  components: {
    AppSidebarLink,
    InstallAppWrap,
  },
  props: {
    disabledTooltip: Boolean,
  },
  data() {
    return {
      INSTALL_APP_LINK,
    };
  },
};
</script>
