import { sortLessons } from '@/filters/lessons';
import i18n from '@/lib/i18n';

export default {
  availableCourses(state) {
    return state.courses.filter((i) => i.status !== 'playing');
  },
  activeCourses(state) {
    return state.courses.filter((i) => i.status === 'playing');
  },
  joinedCourses(state, getters) {
    return getters.activeCourses.filter((i) => !isCourseCompleted(i));
  },
  unfinishedCourses(state, getters) {
    return getters.activeCourses.filter((i) => !isCourseCompleted(i));
  },
  coursesWithBilling(state, getters) {
    return getters.joinedCourses.filter((i) => i.billing);
  },
  hasCourses(state) {
    return state.courses?.length;
  },
  getCoursesByCategory(state) {
    return (category) => {
      let categoryFilter = ({ portal_section }) => {
        return portal_section === category;
      };

      if (category === 'joined') {
        categoryFilter = (course) => {
          return !course.portal_section && isCourseActive(course);
        };
      }

      if (category === 'available') {
        categoryFilter = (course) => {
          return !course.portal_section && !isCourseActive(course);
        };
      }

      return state.courses.filter(categoryFilter);
    };
  },
  courseCategories(state, getters, rootState, rootGetters) {
    // return getCourseCategories(getters.orgMeta);
    const defaultCategories = [
      {
        title: i18n.t('portal.course.joinedCourses'),
        slug: 'joined',
      },
      {
        title: i18n.t('portal.course.availableCourses'),
        slug: 'available',
      },
    ];

    const { portal_sections = [] } = rootGetters.orgMeta || {};

    return [...defaultCategories, ...portal_sections];
  },
  courseCategory(state, getters) {
    return (categorySlug) => {
      return getters.courseCategories.find(({ slug }) => slug === categorySlug);
    };
  },
  singleCourse(state) {
    return (courseId) => {
      return state.courses.find((i) => i.id === courseId) || null;
    };
  },
  portalFolders(state) {
    return state.portalFolders.filter(
      (i) => i.shared_bookmarks && !i.folder_id
    );
  },
  singlePortalFolder(state) {
    return (folderId) => {
      return state.portalFolders.find((i) => i.id === folderId) || null;
    };
  },
  portalCourseChildLessons(state, getters) {
    return ({ campaign_id, id }, unfinished = false) => {
      let course = getters.singleCourse(campaign_id);
      if (!course) return [];
      return sortLessons(course.triggers).filter(
        ({ parent_trigger, finished }) => {
          return parent_trigger === id && (unfinished ? !finished : true);
        }
      );
    };
  },
};

const getCourseCategories = (orgMeta) => {
  const defaultCategories = [
    {
      title: i18n.t('portal.course.joinedCourses'),
      slug: 'joined',
    },
    {
      title: i18n.t('portal.course.availableCourses'),
      slug: 'available',
    },
  ];

  const { portal_sections = [] } = orgMeta || {};

  return [...defaultCategories, ...portal_sections];
};

const isCourseActive = ({ status }) => status === 'playing';

const isCourseCompleted = (course) => {
  const { schemaLevel, triggers } = course;
  const isSchemaLevelFull = schemaLevel === 'full';
  const allLessonsFinished = triggers.every((i) => i.finished);

  return isSchemaLevelFull && allLessonsFinished;
};
