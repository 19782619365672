<template>
  <v-dialog
    :value="showModal"
    @input="closeModal(true)"
    :max-width="680"
    content-class="fixed-height-lg"
  >
    <modal-card
      fixed
      padding
      :title="title"
      :loading="loading"
      @close="closeModal(true)"
    >
      <v-form v-model="isFormValid" ref="form">
        <div class="task-name-field mb-2">
          <v-text-field
            class="no-label"
            v-model="form.name"
            :rules="[rules.required]"
            placeholder="Task Name"
            autofocus
          />
        </div>

        <div class="mb-4">
          <v-select
            label="Status"
            v-model="form.status"
            :items="statusOptions"
            :rules="[rules.required]"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :color="transformStatusColor(data.item.color)"
                class="light--text"
                small
              >
                {{ data.item.text }}
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-chip
                :color="transformStatusColor(item.color)"
                small
                class="light--text"
              >
                {{ item.text }}
              </v-chip>
            </template>
          </v-select>
        </div>

        <div class="mb-4">
          <form-field
            class="mt-4"
            :schema="getFieldSchema('assigned_to')"
            v-model="form.assigned_to"
          />
        </div>

        <task-related-records
          v-if="relatedRecords.length"
          :records="relatedRecords"
        />

        <task-resources v-model="form.resources" />

        <form-field
          class="task-body mt-5 mb-5"
          :schema="getFieldSchema('body')"
          v-model="form.body"
        />
      </v-form>

      <template v-slot:leftActions v-if="taskId">
        <v-btn color="error" @click="removeTask" text>Delete</v-btn>
      </template>

      <template v-slot:actions>
        <v-btn text disabled color="primary">
          {{ saving ? 'SAVING...' : 'SAVED' }}
        </v-btn>
      </template>
    </modal-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import { TASK_STATUS } from './resources';
import validation from '@/lib/validationRules';
import { mapActions, mapGetters } from 'vuex';

import FormField from '@/components/formFields/FormField';
import TaskRelatedRecords from '@/views/tasks/components/TaskRelatedRecords';
import TaskResources from '@/views/tasks/components/TaskResources';

import cloneDeep from 'lodash/cloneDeep';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import theme from '@/factories/theme';

const EDITABLE_TASK_FIELDS = [
  'name',
  'status',
  'assigned_to',
  'body',
  'resources',
];

export default {
  name: 'TaskForm',
  components: {
    FormField,
    TaskRelatedRecords,
    TaskResources,
  },
  data() {
    return {
      isFormValid: false,
      form: {},
      rules: {
        required: (v) => validation.checkRequired(v) || '',
      },
      bioFieldSchema: {
        type: 'Html',
        title: 'Text',
      },
      showModal: false,
      taskId: '',
    };
  },
  computed: {
    ...mapGetters('tasks', ['byId', 'schema', 'loading', 'saving']),
    title() {
      return this.taskId ? 'Edit Task' : 'Create Task';
    },
    statusOptions() {
      return TASK_STATUS.map((i) => {
        return {
          value: i.key,
          text: i.title,
          color: i.color,
        };
      });
    },
    taskInfo() {
      return this.byId(this.taskId) || {};
    },
    isDirty() {
      return EDITABLE_TASK_FIELDS.some((key) => {
        return !isEqual(this.taskInfo[key], this.form[key]);
      });
    },
    relatedRecords() {
      return this.buildRelatedRecords();
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.setData();
      },
    },

    taskId(val) {
      if (!val) return;
      this.loadOne({ path: this.taskId });
      this.setData();
    },

    form: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.taskId) return;
        this.debounceSaveForm();
      },
    },
  },
  methods: {
    ...mapActions('tasks', ['save', 'deleteOne', 'loadOne', 'loadSchema']),

    buildRelatedRecords() {
      const { related_record_summary, related_records } = this.taskInfo || {};

      return (related_record_summary || []).reduce((acc, i) => {
        const key = i?.related_record_key || '';
        if (!!key && (related_records || []).includes(key)) {
          acc.push(i);
        }

        return acc;
      }, []);
    },

    async closeModal(save = false) {
      if (save && this.taskId && this.isDirty) {
        this.saveForm();
        await this.$nextTick();
      }

      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.showModal = false;
      this.$emit('close');
    },

    removeTask() {
      this.openConfirmationModal({
        title: 'Remove Task',
        text: 'Are you sure you want to delete this task?',
        onConfirm: () => {
          this.deleteOne({ id: this.taskId }).then(() => {
            this.closeModal(false);
          });
        },
      });
    },

    getFieldSchema(key) {
      const schema = this.schema[key] || {};
      return {
        ...schema,
        hide_header: key === 'assigned_to',
        noPlaceholder: key === 'body',
      };
    },

    transformStatusColor(color) {
      return theme.convertHex(color, '0.8');
    },

    async setData() {
      if (!this.taskInfo) return;

      EDITABLE_TASK_FIELDS.forEach((key) => {
        Vue.set(this.form, key, clone(this.taskInfo[key]));
      });
    },

    debounceSaveForm: debounce(function () {
      this.saveForm();
    }, 500),

    saveForm() {
      if (!this.isFormValid || !this.isDirty) return;

      this.save({
        model: {
          ...cloneDeep(this.taskInfo),
          ...cloneDeep(this.form),
        },
      });
    },

    openModal(taskId) {
      this.taskId = taskId;
      this.showModal = true;
    },
  },
  created() {
    this.loadSchema();
  },
};
</script>

<style lang="less">
.task-name-field {
  div.v-input.v-text-field.no-label .v-input__slot {
    padding: 0;
    background: none;
    border: none;

    input {
      font-size: 24px;
      font-weight: bold;
      max-height: 40px;
    }
  }

  .v-text-field > .v-input__control > .v-input__slot {
    &:before,
    &:after {
      display: none !important;
    }
  }
}

.task-resources + .task-body {
  border-top: 2px dashed #ccc;
  padding-top: 18px;
}

.task-body {
  .quill-wrap {
    box-shadow: @base-shadow;
  }

  .html-field .ql-editor {
    min-height: 100px;
  }
}
</style>

<style lang="less" src="@/assets/styles/basic/field-row.less"></style>
