import Command from './command';

class CreateContact extends Command {
  id = 'createContact';
  icon = 'campaigns/person';
  title = 'Create Contact';
  permission = 'add_contacts';

  run() {
    let vm = this.vm;
    vm.$router.push('/contacts/create');
    return true;
  }
}

export default CreateContact;
