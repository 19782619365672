export default {
  currentSearch: (state) => {
    return state.currentSearch;
  },
  searchResults: (state) => {
    return state.searchResults;
  },
  loadingSearch(state) {
    return state.loadingSearch;
  },
};
