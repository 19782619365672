import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,

  state: {
    courses: [],
    loadingCourses: null,
    loadingSingleCourse: false,
    portalFolders: [],
    portalFoldersLoaded: false,
    portalFolderLessonLoading: false,
  },

  mutations,
  actions,
  getters,
};
