import { fetchAll } from '@/store/common/actions';
import mockAdventure from './mockAdventure';
import uuid from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

const unix = () => {
  return new Date().getTime() / 1000;
};

const storePreviousState = (commit, adventure) => {
  let nodes = adventure.nodes.map(cloneDeep);

  commit('update', {
    model: adventure,
    update: {
      previousNodes: nodes,
    },
  });
};

export default {
  fetchAll,
  fetchMock({ commit }) {
    commit('setKey', { key: 'all', value: [mockAdventure] });
  },

  storePreviousState({ commit }, { adventure }) {
    storePreviousState(commit, adventure);
  },

  restorePreviousState({ commit, state }, { adventure }) {
    let previousNodes = adventure.nodes.map(cloneDeep);

    commit('update', {
      model: adventure,
      update: {
        previousNodes,
        nodes: adventure.previousNodes,
        updated_at: unix(),
      },
    });
  },

  //todo move most of this logic into a mutation
  addNode({ commit, state }, { adventure, parentNode, node }) {
    node.id = uuid.v1();
    node.pid = parentNode.id;

    storePreviousState(commit, adventure);

    let nodes = adventure.nodes.map((n) => {
      n = cloneDeep(n);

      //filter out nodes that aren't effected by this change
      if (n.pid !== parentNode.id) {
        return n;
      }

      if (
        parentNode.type === 'action' &&
        node.type === 'cond' &&
        node.if === undefined
      ) {
        n.pid = node.id;
        n.if = true;
        return n;
      }

      if (parentNode.type !== 'cond') {
        n.pid = node.id;
        return n;
      }

      if (typeof n.if !== 'undefined' && n.if !== node.if) {
        return n;
      }

      n.pid = node.id;
      return n;
    });

    nodes.push(node);

    commit('update', {
      model: adventure,
      update: {
        nodes,
        updated_at: unix(),
      },
    });
  },

  updateNode({ commit, state }, { adventure, node, updates }) {
    //sometimes multiple nodeUpdates are called as a transaction
    //like a node drag and drop interaction produces updates on the
    //moving node and the displacedNodes, so do not call storePreviousState
    //on every updateNode request.
    // storePreviousState( commit, adventure )

    let nodes = adventure.nodes.map((n) => {
      n = cloneDeep(n);
      if (n.id === node.id) {
        n = merge(n, updates);
      }
      return n;
    });

    console.log('updateNode', {
      adventure,
      nodes,
    });

    commit('update', {
      model: adventure,
      update: {
        nodes,
        updated_at: unix(),
      },
    });
  },
};
