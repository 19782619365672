<template>
  <div class="page-layout" :class="pageClasses">
    <app-header
      v-if="header"
      :title="title"
      :back="backRoute"
      :help="help"
      :helpCategory="helpCategory"
    >
      <template slot="chip">
        <slot name="chip" />
      </template>
      <slot name="header" />
    </app-header>
    <v-fade-transition mode="out-in">
      <div class="page-layout__content" v-if="loaded || spinnerOverlay">
        <slot />

        <div class="spinner-overlay" v-if="!loaded">
          <spinner />
        </div>
      </div>

      <div class="spinner-wrap pa-5" v-else>
        <spinner />
      </div>
    </v-fade-transition>

    <slot name="modals" />
  </div>
</template>

<script>
import AppHeader from '@/components/layouts/AppHeader';

export default {
  name: 'PageLayout',
  components: {
    AppHeader,
  },
  props: {
    title: String,
    metaTitle: String,
    help: String,
    helpCategory: String,
    back: {
      type: [String, Object, Function],
    },
    previous: Boolean,
    loaded: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    chip: {
      type: [String, Number],
    },
    spinnerOverlay: Boolean,
  },
  watch: {
    documentTitle(val) {
      if (!val) return;
      this.setDocumentTitle();
    },
  },
  computed: {
    pageClasses() {
      return { 'has-header': this.header };
    },
    backRoute() {
      const prevPath = this.previous && this.previousRoute?.fullPath;
      if (prevPath && !prevPath.startsWith('/reports/usage')) {
        return prevPath;
      }
      return this.back;
    },
    documentTitle() {
      return this.metaTitle || this.title || 'Conveyour';
    },
  },
  methods: {
    setDocumentTitle() {
      document.title = this.documentTitle;
    },
  },
  beforeMount() {
    this.setDocumentTitle();
  },
};
</script>

<style lang="less">
.app-sidebar + .page-layout {
  margin: 0 0 0 72px;

  .app-header {
    left: 72px;
    width: calc(100% - 72px);
  }
}

.app-sidebar.open.full:not(.rooms-sidebar) + .page-layout {
  margin: 0 0 0 224px;

  .app-header {
    left: 224px;
    width: calc(100% - 224px);
  }
}

.page-layout {
  height: 100%;
  overflow: hidden;
  position: relative;

  &.has-header {
    padding-top: 64px;
  }

  &__content {
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    position: relative;

    & > .layout {
      min-height: 100%;
    }
  }

  .content-main {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;

    &__centered {
      min-height: 100%;
      max-width: 640px;
      padding: 40px 20px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      &--max {
        max-width: 980px;
      }
    }

    &__header {
      margin-bottom: 55px;

      img {
        height: 50px;
      }

      h5 {
        margin: 5px 0 0;
        color: var(--v-appMain-base);
      }
    }
  }

  .side-nav.large + .content-main {
    margin-left: 360px;
    max-width: calc(100% - 360px);
  }

  .side-nav + .content-main {
    flex: 1;
    margin-left: 260px;
    max-width: calc(100% - 260px);
    z-index: 0;

    @media only screen and (max-width: 960px) {
      margin-left: 260px;
      max-width: calc(100% - 260px);
    }
  }

  .form-field + .field-block {
    margin-top: 40px;
  }

  .v-input {
    .v-input__append-inner {
      .v-icon {
        color: var(--v-appMain-base);
      }
    }
  }
}

.content__dropdown {
  .v-list {
    min-width: 105px;
    background: var(--v-appMain-base) !important;

    &-item {
      &__title {
        color: var(--v-light-base) !important;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.24) !important;
      }
    }
  }
}

.placeholder-block {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  height: 112px;
  background-color: var(--v-darkGray-base);
}

.circle-icon {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--v-appMain-base);
  color: var(--v-light-base);
  margin: 0 8px;
}

.format-list {
  -webkit-overflow-scrolling: touch;

  &__item {
    cursor: move;
    box-shadow: @content-shadow;
    border-radius: @base-radius;
    margin-top: 16px;
    transition: background 0.2s ease;

    &:hover {
      background-color: var(--v-lightGray-base);
    }
  }
}

.lesson-widget__header,
.lesson-items-list .format-list {
  .content-line__icon svg {
    height: 32px;
    width: 32px;
    max-height: 32px;
    max-width: 32px;
  }
}

.media-container {
  background-color: var(--v-darkGray-base);
  border-radius: @base-radius;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-bottom: 16px;

  & > img {
    display: block;
    max-width: 400px;
    width: 100%;
    height: auto;
  }
}

.fields-block {
  display: block;
  text-align: left;
  background-color: var(--v-darkGray-base);
  border-radius: @base-radius;
  padding: 16px;
  overflow: hidden;
  color: var(--v-main-base);
}

.fields-block + .fields-block {
  margin-top: 16px;
}

.hint-block {
  display: block !important;
  text-align: left;
  padding: 32px;
  position: relative;
  background-color: var(--v-darkGray-base);
  border-radius: @base-radius;
  overflow: hidden;
  color: var(--v-main-base);
  font-size: 14px;

  p {
    font-weight: 600;
  }
}
</style>
