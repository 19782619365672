import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,

  state: {
    roomInfo: {},
    roomConfig: {},
    roomChat: [],
    roomError: null,
    questionnaireAnswers: [],
    guestAnswers: null,
    finishedInterviewInfo: null,
  },

  mutations,
  actions,
  getters,
};
