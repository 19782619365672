import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

export default {
  // will be used to update remote lesson
  UPDATE_LESSON: (context, { lesson, updates }) => {
    let _lesson = cloneDeep(lesson);
    merge(_lesson, updates);
    context.dispatch('updateLesson', lesson);
  },

  NEXT_ITEM: (context) => {
    let idx = context.state.currentIndex || 0;
    context.commit('setLessonIndex', idx + 1);
  },

  PREV_ITEM: (context) => {
    let idx = context.state.currentIndex;
    if (!idx) {
      return;
    }
    context.commit('setLessonIndex', idx - 1);
  },

  RESET_LESSON: (context) => {
    context.commit('setLessonIndex', 0);
    context.commit('UNCOMPLETE');
  },
};
