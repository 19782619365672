import { withNestedView } from '@/router/routerHelpers';

const loadView = withNestedView('reports');

export default [
  {
    path: '/reports/shared',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: ':pid/:id?',
        name: 'reportShared',
        props: true,
        component: loadView('ReportShared'),
      },
    ],
  },
];
