import api from '@/factories/api';
import map from 'lodash/map';
import clone from 'lodash/clone';
import qs from 'qs';

export default {
  async loadCourseStats({ commit, state }, data) {
    commit('setLoadingCourseStats', true);
    try {
      const { courseId, filter, limit, global } = data || {};
      const params = {
        campaign_id: courseId,
        page: (state.courseStatsMeta?.page || 0) + 1,
        timeframe: filter,
        limit: limit,
      };

      if (global) {
        delete params.campaign_id;
      }

      const path = `cg/stats?${qs.stringify(params)}`;
      const courseStats = await api().get(path);
      if (!courseStats) {
        commit('setCourseStats', []);
        return;
      }

      let statsArr = map(state.courseStats, clone);
      if (state.courseStatsMeta) {
        if (courseStats.results && courseStats.results.length) {
          statsArr.push(...courseStats.results);
        }
      } else {
        statsArr = courseStats.results;
      }

      commit('setCourseStats', statsArr);
      commit('setCourseStatsMeta', {
        pages: courseStats.pages,
        page: courseStats.page,
      });
    } catch (error) {
      throw error;
    } finally {
      commit('setLoadingCourseStats', false);
    }
  },

  async loadUserStats({ commit, getters }, data) {
    commit('setLoadingUserStats', true);
    try {
      const { courseId, filter, global } = data;
      const params = {
        campaign_id: courseId,
        timeframe: filter,
        con_pid: getters.profilePid,
        page: '1',
      };

      if (global) {
        delete params.campaign_id;
      }

      const path = `cg/stats?${qs.stringify(params)}`;
      const res = await api().get(path);
      const stats = (res?.results || [])[0] || null;
      commit('setUserStats', stats);
    } catch (error) {
      commit('setUserStats', null);
      throw error;
    } finally {
      commit('setLoadingUserStats', false);
    }
  },

  clearStatsStore({ commit }) {
    commit('setCourseStats', []);
    commit('setCourseStatsMeta', null);
    commit('setUserStats', null);
  },
};
