import find from 'lodash/find';

const first = (state) => {
  return state.all[0] || state.defaults || {};
};

const findBy = (state) => {
  return (params) => {
    return find(state.all, params);
  };
};

export { first, findBy };
