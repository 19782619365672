class Command {
  isCommand = true;

  setVM(vm) {
    this.vm = vm;
  }

  constructor(props) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
}

export default Command;
