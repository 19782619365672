import Resource from './common/resource';

class Fields extends Resource {
  path = 'fields';

  onDeleteOne({ commit }, { model }) {
    commit('fields/deleteFieldInBucket', { model, commit }, { root: true });
  }

  onCreateOne({ dispatch, commit }, { model, res }) {
    model.id = res.data.id;
    commit('fields/updateFieldInBucket', { model, commit }, { root: true });
    dispatch('fields/loadOne', { path: res.data.id }, { root: true });
  }

  onLoadOne({ dispatch, commit }, { model, res }) {
    if (model && model.id) {
      dispatch('fields/loadOne', { path: model.id }, { root: true });
      return;
    }
    const data = model && model.id ? model : res.data;
    commit(
      'fields/updateFieldInBucket',
      { model: data, commit },
      { root: true }
    );
  }
}

export default Fields;
