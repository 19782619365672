import { inIframe } from '@/globals';
import store from '@/store';
import { getFilePath } from '@/filters/helpers';

const isNotificationsEnabled = () => {
  return window?.Notification?.permission === 'granted';
};

const isNotificationsUnsupported = () => {
  return !window.Notification;
};

export const playAudioNotification = () => {
  const audio = new Audio(getFilePath('audio/boop-notification.mp3'));
  if (audio?.play) {
    audio.play();
  }
};

const createNotification = (options) => {
  let n = new Notification(options.title, options);
  n.onclick = function () {
    window.focus();
    options.clicked();
  };
};

const runCallback = (callback) => {
  if (typeof callback === 'function') {
    callback();
  }
};

const askNotificationsPermission = (callback) => {
  if (isNotificationsUnsupported() || inIframe) {
    return;
  }

  if (isNotificationsEnabled()) {
    runCallback(callback);
    return;
  }

  const requestPermission = () => {
    Notification.requestPermission().then(() => {
      runCallback(callback);
      window.removeEventListener('click', requestPermission);
    });
  };

  requestPermission();
  window.addEventListener('click', requestPermission);
};

const showBrowserNotification = (config = {}) => {
  const options = Object.assign(
    {
      title: '',
      body: '',
      fadeOut: 10000,
      audio: false,
      clicked: () => ({}),
      icon: window.location.origin + '/favicon/favicon.png',
    },
    config
  );

  if (options.audio) {
    playAudioNotification();
  }

  if (isNotificationsEnabled() && !inIframe) {
    createNotification(options);
    return;
  }

  store.commit('showInfoModal', {
    ...options,
    type: 'info',
  });
};

export {
  showBrowserNotification,
  askNotificationsPermission,
  isNotificationsEnabled,
};
