import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('settings');

const snippetsRoutes = [
  {
    path: '/snippets',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '',
        name: 'snippets',
        props: true,
        component: loadView('snippets/SnippetsPage'),
      },
      {
        path: ':folderId/create',
        name: 'snippets-create',
        props: true,
        component: loadView('snippets/SnippetForm'),
      },
      {
        path: ':folderId/:id',
        name: 'snippet-edit',
        props: true,
        component: loadView('snippets/SnippetForm'),
      },
    ],
  },
];

export default snippetsRoutes;
