<template>
  <div class="spring-spinner" :class="{ 'spring-spinner--small': small }">
    <div class="spring-spinner-part top">
      <div class="spring-spinner-rotator"></div>
    </div>
    <div class="spring-spinner-part bottom">
      <div class="spring-spinner-rotator"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    small: {
      type: Boolean,
    },
  },
};
</script>

<style lang="less">
.spring-spinner,
.spring-spinner * {
  box-sizing: border-box;
}

.spring-spinner {
  height: 4.3em;
  width: 4.3em;

  &--small {
    font-size: 7px !important;
  }
}

.spring-spinner .spring-spinner-part {
  overflow: hidden;
  height: calc(4.2em / 2);
  width: 100%;
}

.spring-spinner .spring-spinner-part.bottom {
  transform: rotate(180deg) scale(-1, 1);
}

.spring-spinner .spring-spinner-rotator {
  width: 4.2em;
  height: 4.2em;
  border: calc(4.2em / 7) solid transparent;
  border-right-color: var(--v-primary-base);
  border-top-color: var(--v-primary-base);
  border-radius: 50%;
  box-sizing: border-box;
  animation: spring-spinner-animation 3s ease-in-out infinite;
  transform: rotate(-200deg);
}

@keyframes spring-spinner-animation {
  0% {
    border-width: calc(4.2em / 7);
  }
  25% {
    border-width: calc(4.2em / 23.33);
  }
  50% {
    transform: rotate(115deg);
    border-width: calc(4.2em / 7);
  }
  75% {
    border-width: calc(4.2em / 23.33);
  }
  100% {
    border-width: calc(4.2em / 7);
  }
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.spinner-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
