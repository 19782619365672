<template>
  <field-block title="Help Documentation">
    <div class="item-card">
      <div class="item-card__content">
        <v-row class="links-list pb-2 pt-2">
          <v-col
            cols="12"
            sm="6"
            class="pl-4 pr-4 pt-3 pb-2"
            v-for="(link, idx) in helpDocumentations"
            :key="idx"
          >
            <div>
              <a
                :href="fullHelpRoute(link.link)"
                target="_blank"
                class="help-link"
              >
                <span class="help-link__icon">
                  <icon-base :icon="`sidebar/${link.icon}`" />
                </span>
                {{ link.text }}
              </a>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </field-block>
</template>

<script>
const helpDocumentations = [
  {
    text: 'Automations',
    icon: 'automations',
    link: 'categories/automations',
  },
  {
    text: 'Groups',
    icon: 'groups',
    link: 'categories/groups',
  },
  {
    text: 'Campaigns',
    icon: 'campaigns',
    link: 'categories/campaigns',
  },
  {
    text: 'Lessons',
    icon: 'lessons',
    link: 'categories/lessons',
  },
  {
    text: 'Contacts',
    icon: 'contacts',
    link: 'categories/contacts',
  },
  {
    text: 'Messaging',
    icon: 'conversations',
    link: 'categories/messaging',
  },
  {
    text: 'Engagement Formats',
    icon: 'lessons',
    link: 'engagements-content',
  },
  {
    text: 'View all help articles',
    icon: 'help',
    link: '',
  },
];

export default {
  name: 'HomeGetHelp',
  data() {
    return {
      helpDocumentations,
    };
  },
  methods: {
    fullHelpRoute(route) {
      const base = 'https://conveyour.com/help';
      if (route) {
        return `${base}/${route}`;
      }
      return base;
    },
  },
};
</script>

<style scoped lang="less">
.links-list {
  .help-link {
    display: flex;
    align-items: center;
    margin-left: 0;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &__icon {
      height: 22px;
      width: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }

    img {
      display: block;
      height: auto;
      width: auto;
    }
  }
}
</style>
