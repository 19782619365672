import router from '@/router';
import Penpal from 'penpal';
import theme from '@/factories/theme';
import localStore from 'store';
import vuexStore from '@/store';
import { inIframe, embedApiEnabled } from '@/globals';

export const connect = (options = {}) => {
  options = addDefaultMethods(options);
  return Penpal.connectToParent(options);
};

export const connectToChild = (options) => {
  return Penpal.connectToChild(options);
};

export const bindMethods = (_this, methods = []) => {
  let bound = {};
  methods.forEach((m) => {
    bound[m] = _this[m].bind(_this);
  });
  return bound;
};

const addDefaultMethods = (options = {}) => {
  let methods = options.methods || {};

  methods.route = (path) => {
    return router.push('/' + path);
  };

  methods.setTheme = (config) => {
    return theme.setCustomTheme(config);
  };

  methods.setAuth = (auth) => {
    localStore.set('portal_auth', auth);
  };

  methods.setCSRF = (csrfToken) => {
    localStore.set('csrf_token', csrfToken);
  };

  methods.initScormCourse = (params) => {
    const { app_key, token } = params;
    if (app_key && token) {
      localStore.set('conveyour_appkey', app_key);
      localStore.set('conveyour_token', token);
    }

    vuexStore.dispatch('loginScormLearner', params);
  };

  options.methods = methods;

  return options;
};

export const onParent = (method, cb, fallback) => {
  if (!inIframe) {
    if (fallback) {
      return fallback();
    }
    return false;
  }

  connect().promise.then((parent) => {
    if (!parent || !parent[method]) return;
    cb(parent[method].bind(parent));
  });
};

export const parentNavigate = (path, trigger = false, replace = true) => {
  return onParent(
    'navigate',
    (navigate) => {
      navigate(path, trigger, replace);
    },
    () => {
      router.push(path);
    }
  );
};

export const parentTrack = (event = 'ui2-event', properties = {}) => {
  return onParent('track', (track) => {
    track(event, properties);
  });
};

export class InitialConnection {
  resolvedIn = 0;

  constructor(options = {}) {
    this.options = options;
  }

  getMethods() {
    let methods = this.options.methods || {};

    methods.route = (path) => {
      return router.push('/' + path);
    };

    methods.setTheme = (config) => {
      return theme.setCustomTheme(config);
    };

    return methods;
  }

  init() {
    let options = this.options;

    options = addDefaultMethods(options);
    this.connection = connect(options);

    this.then(() => {
      this.resolved = true;
    });

    // this.trackTimeToResolve()

    this.publishRoutes();

    return this;
  }

  timeout(cb, time = 1000) {
    if (!cb) return false;
    setTimeout(() => {
      if (!this.resolved) {
        cb.call(this);
      }
    }, time);
  }

  /**
   * helper method to check time it takes to
   * resolve promise.
   */
  trackTimeToResolve() {
    if (this.resolved) {
      console.log({
        resolvedIn: this.resolvedIn,
      });
      return true;
    }

    let interval = 10;
    this.resolvedIn += interval;
    setTimeout(() => {
      this.trackTimeToResolve();
    }, interval);
  }

  promise() {
    return this.connection.promise;
  }

  then(cb) {
    return this.promise().then(cb);
  }

  catch(cb) {
    return this.promise().catch(cb);
  }

  publishRoutes() {
    router.afterEach((to) => {
      this.then((parent) => {
        if (!parent || !parent.navigate) return;
        let path = to.path;
        if (to.query && Object.keys(to.query).length) {
          path += '?' + new URLSearchParams(to.query).toString();
        }
        parent.navigate(path);
      });
    });
  }
}

export const initParentConnection = () => {
  if (embedApiEnabled()) {
    let parentConnection = new InitialConnection();
    parentConnection.init();
  }
};
