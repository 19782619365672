import Module from '@/store/resource/common/module';
import FieldsBuckets from '@/store/resource/fieldsBuckets';
import api from '@/factories/api';
import { searchInArrayOfObjects } from '@/store/storeHelpers';

let module = new Module(new FieldsBuckets());

module.add('actions', {
  async getRepSettings({ commit, getters }) {
    if (getters.repSettings) return;

    try {
      const res = await api().get('buckets/rep');
      commit('setRepSettings', res);
    } catch (err) {
      throw err;
    }
  },

  async updateRepSettings({ commit, getters }, settings) {
    if (getters.snippetSchema) return;
    try {
      await api().put('buckets/rep', settings);
      commit('setRepSettings', settings);
    } catch (err) {
      throw err;
    }
  },
});

module.add('mutations', {
  setRepSettings(state, settings) {
    state.repSettings = settings;
  },
});

module.add('state', {
  repSettings: null,
});

module.add('getters', {
  filteredFieldsBuckets(state, getters) {
    const buckets = state.all.filter(({ type }) => type !== 'http');
    if (!getters.search) return buckets;

    return searchInArrayOfObjects(
      buckets,
      'fields',
      ['name', 'label'],
      getters.search
    );
  },

  repSettings(state) {
    return state.repSettings;
  },
});

export default module.create();
