import map from 'lodash/map';
import clone from 'lodash/clone';

export const sortLessons = (lessons, ignoreFinished = false) => {
  if (!lessons || !lessons.length) return [];

  let lessonsClone = map(lessons, clone);

  function isFinished(trigger) {
    if (ignoreFinished) return false;
    const childTriggers = lessons.filter(
      ({ parent_trigger }) => parent_trigger === trigger.id
    );
    return trigger.finished && childTriggers.every(({ finished }) => finished);
  }

  function getOrder(trigger) {
    return (trigger.sorting_time || 0) + (trigger.order || 0);
  }

  function sortLessons(a, b) {
    let af, bf, ao, bo;

    af = isFinished(a);
    bf = isFinished(b);

    if (af && !bf) {
      return 1;
    }

    if (!af && bf) {
      return -1;
    }

    ao = getOrder(a);
    bo = getOrder(b);

    return ao < bo ? -1 : 1;
  }

  return lessonsClone.sort(sortLessons);
};

export const sortCourses = (courses) => {
  const bottom = Infinity;

  return courses.sort((a, b) => {
    let groupA = getCourseStatusGroup(a);
    let groupB = getCourseStatusGroup(b);
    if (groupA !== groupB) {
      return groupA - groupB;
    }

    let showOrderA = a.show_order ?? bottom;
    let showOrderB = b.show_order ?? bottom;

    if (showOrderA !== showOrderB) {
      return showOrderA - showOrderB;
    }

    // new campaigns first
    return b.created_at - a.created_at;
  });
};

const getCourseStatusGroup = (course) => {
  if (course.schemaLevel !== 'full') return 1;
  const triggers = getValidCourseTriggers(course);
  const isStarted = triggers.some((t) => t.finished);
  const isFinished = triggers.every((t) => t.finished);

  return isStarted ? (isFinished ? 3 : 2) : 1;
};

const getValidCourseTriggers = (course) => {
  return (course.triggers || []).filter((l) => {
    if (!l.parent_trigger) return true;
    return course.triggers.some(({ id, include_extra_lessons }) => {
      return id === l.parent_trigger && include_extra_lessons;
    });
  });
};
