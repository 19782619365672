import Command from './command';

class SwitchTeams extends Command {
  id = 'switchTeams';
  icon = 'group_work';
  fontIcon = true;
  title() {
    return 'Switch Team';
  }

  async run() {
    let vm = this.vm;
    vm.parentCommand = this;
    vm.search = '';
    vm.placeholder = this.title;
    vm.title = this.title;
  }

  if() {
    return this.vm.currentUser && this.vm.currentUser?.features?.teams;
  }

  asParent() {
    let search = this.vm.search.trim();
    this.addTeams();
  }

  addTeams(contacts) {
    let vm = this.vm;
    //remove previous
    let commands = vm.commands.filter((existing) => {
      return existing.pid !== this.id;
    });

    const teams = this.vm.$store.getters['teams/all'];

    teams.forEach((team) => {
      let cmd = new Command({
        title: team.label,
        id: `team-${team.id}`,
        pid: this.id,
        fontIcon: true,
        icon: 'group_work',
        run: () => {
          this.vm.$store.dispatch('users/updateUserTeam', team?.id);

          setTimeout(() => {
            this.vm.$store.dispatch('teams/safeReload');
          });
          return true;
        },
      });

      cmd.setVM(vm);
      commands.push(cmd);
    });

    vm.commands = commands;
  }
}

export default SwitchTeams;
