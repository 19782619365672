let state = {
  records: [],
};

let nextPurge = null;

const unix = () => {
  return new Date().getTime() / 1000;
};

let mutations = {
  setValue(state, { key, value, expires_at }) {
    const records = state.records.filter((record) => record.key !== key);
    records.push({ key, value, expires_at });
    state.records = records;
  },
  purgeStaleRecords(state) {
    state.records = state.records
      .filter((record) => {
        return record.expires_at > unix();
      })
      .slice(-100);
  },
};

const getters = {
  records(state) {
    return state.records;
  },
  cacheGet(state) {
    return (key) => {
      const stateValue = state.records.find((record) => record.key === key);
      if (stateValue && stateValue.expires_at > unix()) {
        return stateValue.value;
      }
      return null;
    };
  },
};

const actions = {
  cacheSet({ commit, dispatch, state }, [key, value, length = 3600]) {
    commit('setValue', {
      key,
      value,
      expires_at: unix() + length * 60,
    });
    dispatch('purge');
    return value;
  },

  purge({ commit }) {
    if (nextPurge) clearTimeout(nextPurge);
    nextPurge = setTimeout(() => {
      commit('purgeStaleRecords');
    }, 1000);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
