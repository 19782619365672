export default {
  type: 'button',
  icon: 'button',
  title: 'Button',
  help: 'Add a call to action button.',
  schema: {
    // TODO validation?
    cta: {
      type: 'TextArea',
      title: 'small blurb, call to action',
      help: 'Enter a short call to action for the button.',
      default: 'Click the button to take the next step',
    },

    // TODO validation?
    url: {
      type: 'Text',
      title: 'URL for button',
      help: 'Enter the full URL for the button',
      default: 'https://conveyour.com',
    },

    text: {
      type: 'Text',
      title: 'Button Text',
      help: 'Keep this short and sweet.',
      default: 'Button Text',
    },

    standnew: {
      type: 'Checkbox',
      title: 'Start of new section',
      default: false,
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },
};
