import { compareStrings, highlightSearch } from '@/filters/helpers';
import clone from 'lodash/clone';

export default {
  users: (state) => {
    return state.users;
  },
  usersLoaded: (state) => {
    return state.usersLoaded;
  },
  activeUsers: (state) => {
    return state.users.filter(({ status }) => {
      return status == '1' || typeof status === 'undefined';
    });
  },
  archivedUsers: (state) => {
    return state.users.filter((user) => user.status == '0');
  },
  filteredUsers: (state, getters) => {
    return (active = true) => {
      const users = active ? getters.activeUsers : getters.archivedUsers;
      return filterUsers(users, state.usersSearch);
    };
  },
  usersOptions: (state, getters) => {
    return formatUsers(getters.activeUsers);
  },
  usersView: (state) => {
    return state.usersView;
  },
  usersSearch: (state) => {
    return state.usersSearch;
  },
  singleUser(state) {
    return (userId) => state.users.find((i) => i.id == userId);
  },
  userLoading(state) {
    return state.userLoading;
  },
  currentUser: (state) => {
    return state.currentUser;
  },
  userTeam: (state) => {
    return state.currentUser?.selected_team;
  },
  isUserLoggedIn: (state) => {
    return !!state.currentUser;
  },
  currentUserLoaded: (state) => {
    return state.currentUserLoaded;
  },
  isAdmin: (state) => {
    return (state.currentUser || {}).role === 'admin';
  },
  // check features obj
  isFeatureEnabled: (state) => {
    return (feature) => {
      const features = state?.currentUser?.features || {};
      return features[feature] || false;
    };
  },
  hasPermission: (state) => {
    return (feature) => {
      if (!state.currentUser) return false;
      const { feature_flags } = state.currentUser;
      return (feature_flags || []).includes(feature);
    };
  },
  hasAccessToPage: (state, getters) => {
    return (pageMeta) => {
      const { feature, permission } = pageMeta;
      const featureEnabled = !feature || getters.isFeatureEnabled(feature);
      const hasPermission = !permission || getters.hasPermission(permission);
      return featureEnabled && hasPermission;
    };
  },
  allPermissions: (state) => {
    return state.allPermissions || [];
  },
  usersTimezones: (state) => {
    return state.usersTimezones;
  },
  recentActivity: (state) => {
    return state.recentActivity;
  },
};

const formatUsers = (arr) => {
  return arr.map((user) => {
    return {
      label: user.name,
      value: user.id,
      role: user.role,
      photo: user.photo,
    };
  });
};

const getName = (user) => {
  return user?.name;
};

const filterUsers = (arr, search) => {
  const users = arr
    .map((user) => {
      const fullName = getName(user);
      return Object.assign(clone(user), {
        fullName: highlightSearch(fullName, search),
      });
    })
    .sort((a, b) => a.fullName.localeCompare(b.fullName));

  if (!search) return users;

  return users.filter(({ name }) => {
    return compareStrings(name, search);
  });
};
