import Resource from './common/resource';

class Snippets extends Resource {
  path = 'snippets';

  onDeleteOne({ state, commit }, { model }) {
    commit('snippetsFolders/deleteSnippetInFolder', model, { root: true });
  }

  onCreateOne({ dispatch, commit }, { model, res }) {
    commit('snippetsFolders/updateSnippetInFolders', res.data, { root: true });
  }

  onLoadOne({ dispatch, commit }, { model, res }) {
    commit('snippetsFolders/updateSnippetInFolders', res.data, { root: true });
  }
}

export default Snippets;
