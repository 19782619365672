<template>
  <v-alert
    :value="showModal"
    :type="modalType"
    transition="scale-transition"
    class="info-alert"
    @click="handleCLick"
    @input="clearMessage"
  >
    <template v-if="showModal">
      <template v-if="modalType === 'info'">
        <h5>{{ infoMessage.title }}</h5>
        <p class="mb-0 text-ellipsis">{{ infoMessage.body }}</p>
      </template>

      <template v-else>
        <template v-if="errorDescription">
          <h5>{{ infoMessage.data.message }}</h5>
          <ul v-if="errorDescription">
            <li v-for="(item, key) in errorDescription" :key="key">
              {{ item[0] }}
            </li>
          </ul>
        </template>

        <template v-else>
          {{ infoMessage.data.message }}
        </template>
      </template>
    </template>
  </v-alert>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'InfoModal',
  computed: {
    ...mapGetters(['infoMessage']),
    errorDescription() {
      const errData = this.infoMessage?.data?.data;

      if (Array.isArray(errData)) {
        return errData[0]?.messages || null;
      }

      return errData?.messages || null;
    },
    modalType() {
      return this.infoMessage?.type || 'error';
    },
    showModal() {
      return !!this.infoMessage;
    },
  },
  watch: {
    infoMessage(val) {
      if (!val) return;

      const timer = val.fadeOut || 5000;

      setTimeout(() => {
        this.clearMessage();
      }, timer);
    },
  },
  methods: {
    ...mapMutations(['showInfoModal']),
    clearMessage(state) {
      if (state) return;
      this.showInfoModal(null);
    },
    handleCLick() {
      if (!this.infoMessage?.clicked) return;
      this.infoMessage.clicked();
      this.clearMessage();
    },
  },
};
</script>

<style lang="less">
.v-alert.info-alert {
  max-width: 420px;
  position: fixed !important;
  z-index: 9999;
  top: 12px;
  right: 12px;
  margin-bottom: 0;
  padding: 12px;

  .v-alert__content {
    font-size: 14px;
    overflow: hidden;
    flex: 1;
  }
}
</style>
