import { getTriggerStatus } from './actions';
import { updateArrInStore } from '@/store/storeHelpers';

export default {
  setTriggers: (state, triggers) => {
    state.triggers = triggers;
  },
  updateTriggerInList: (state, trigger) => {
    trigger.status = getTriggerStatus(trigger);
    delete trigger.nodes;
    updateArrInStore(state, trigger, 'triggers');
  },
  setTriggersSchemas: (state, schemas) => {
    state.triggersSchemas = schemas;
  },
  setTriggerPicker: (state, data) => {
    state.triggerPicker = data;
  },
  setTriggersLoaded: (state, loading) => {
    state.triggersLoaded = loading;
  },
  setTriggerLoading: (state, loading) => {
    state.triggerLoading = loading;
  },
  setTriggersNames: (state, names) => {
    state.triggersNames = prepareTriggersNames(names);
  },
  setTriggersNamesLoaded: (state, loading) => {
    state.triggersNamesLoaded = loading;
  },

  setNamesSuggestions: (state, { type, names }) => {
    state.namesSuggestions[type] = names;
  },

  // automations
  setAutomationsView: (state, view) => {
    state.automationsView = view;
    localStorage.setItem('automationsView', view);
  },
  setAutomationsFilter: (state, filter) => {
    state.automationsFilter = filter;
  },
  setAutomationsSearch: (state, search) => {
    state.automationsSearch = search;
  },

  // workflow
  setWorkflowSettings: (state, settings) => {
    state.workflowSettings = settings;
  },
};

const prepareTriggersNames = (names) => {
  if (!names || !names.length) {
    return [];
  }

  return names
    .sort((a, b) => a.group - b.group)
    .reduce((acc, item) => {
      if (!acc.find((i) => i.header === item.group) && item.group) {
        acc.push({ header: item.group });
      }
      acc.push({
        value: item.id,
        text: item.name || item.id,
      });
      return acc;
    }, []);
};
