import localStore from 'store';

export default {
  setUsers: (state, users) => {
    state.users = users;
  },

  setUsersLoaded: (state, val = true) => {
    state.usersLoaded = val;
  },

  setUsersTimezones: (state, usersTimezones) => {
    state.usersTimezones = usersTimezones;
  },

  setUser: (state, user) => {
    let users = state.users.slice(0);
    let idx = users.findIndex((i) => i.id == user.id);
    if (idx === -1) {
      users.push(user);
    } else {
      for (let key in user) {
        users[idx][key] = user[key];
      }
    }

    state.users = users;
  },

  setUserLoading: (state, loading) => {
    state.userLoading = loading;
  },

  setPermissions: (state, permissions) => {
    state.allPermissions = permissions;
  },

  setCurrentUser: (state, user) => {
    state.currentUser = Array.isArray(user) ? null : user;
  },

  updateCurrentUser: (state, { param, value }) => {
    state.currentUser[param] = value;
  },

  setCurrentUserLoaded: (state, loaded) => {
    state.currentUserLoaded = loaded;
  },

  setUsersView: (state, view) => {
    state.usersView = view;
  },

  setUsersSearch: (state, search) => {
    state.usersSearch = search;
  },

  addUserActivity: (state, activity) => {
    if (Array.isArray(activity)) {
      state.recentActivity = activity;
      return;
    }

    let idx = (state.recentActivity || []).findIndex(
      (a) => a.data && a.data.id === activity.data.id
    );
    if (idx < 0) {
      state.recentActivity.unshift(activity);
    } else {
      state.recentActivity.splice(idx, 1, activity);
    }

    localStore.set('recentActivity', state.recentActivity.slice(0, 6));
  },

  removeUserActivity: (state, itemId) => {
    state.recentActivity = (state.recentActivity || []).filter(
      ({ data }) => data.id !== itemId && data.uuid !== itemId
    );
    localStore.set('recentActivity', state.recentActivity.slice(0, 6));
  },
};
