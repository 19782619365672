export default {
  custom_feedback: {
    type: 'Checkbox',
    title: 'Customize Feedback',
    help: 'Provide custom feedback on correct & incorrect answers.',
    default: false,
  },

  feedback: {
    type: 'CustomFeedback',
    model: 'Feedback',
    title: 'Feedback',
    title_singular: 'Feedback',
    help: 'Provide feedback on specific answers',
    showOnHaving: 'custom_feedback',
    answers: (item) => {
      const answers = item?.values?.answers || [];
      return answers.map((answer) => {
        return typeof answer === 'object' ? answer?.answer : answer;
      });
    },
    correctAnswer: (item) => {
      const answer = item?.values?.correct;
      if (!answer) return [];
      return Array.isArray(answer) ? answer : [answer];
    },
  },
};
