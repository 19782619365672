<template>
  <v-autocomplete
    :label="label"
    item-text="label"
    :items="teamsFormatted"
    v-model="selectedTeam"
    :rules="[rules.required]"
    :class="{ 'background-dark': dark }"
    :multiple="isMultiple"
    :hint="hint"
    persistent-hint
    class="mb-2"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import validation from '@/lib/validationRules';

export default {
  name: 'TeamSelect',
  props: {
    value: {},
    hint: {
      type: String,
      default: 'User will be associated with this team',
    },
    label: {
      type: String,
      default: 'Team',
    },
    requiredText: {
      type: String,
      default: 'Team is required for "Guest" user',
    },
    dark: Boolean,
  },
  data() {
    return {
      rules: {
        required: (v) => validation.checkRequired(v, 'Team', this.requiredText),
      },
    };
  },
  computed: {
    ...mapGetters('users', ['isAdmin']),
    ...mapGetters('teams', ['currentTeam', 'all']),
    isMultiple() {
      return Array.isArray(this.value);
    },
    selectedTeam: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    teamsFormatted() {
      return this.all.map((i) => {
        return {
          label: i.label,
          value: i.id,
        };
      });
    },
  },
  methods: {
    setDefaultValue() {
      if (!this.currentTeam) return;

      const currentTeamId = this.currentTeam?.id;
      if (this.isMultiple && !this.value.length) {
        this.value.push(currentTeamId);
      }

      if (!this.value) {
        this.value = currentTeamId;
      }
    },
  },
  mounted() {
    this.setDefaultValue();
  },
};
</script>
