export default {
  funnelStats: (state) => {
    return state.funnelStats;
  },
  funnelStatsLoading: (state) => {
    return state.funnelStatsLoading;
  },
  campaignMetrics: (state) => {
    return state.campaignMetrics;
  },
  campaignMetricsLoading: (state) => {
    return state.campaignMetricsLoading;
  },
};
