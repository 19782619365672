import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('groups');

const groupsRoutes = [
  {
    path: '/groups',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '',
        name: 'groups',
        meta: { showNav: true },
        component: loadView('Groups'),
      },
      {
        path: 'folders/:folderId',
        name: 'groups-folder',
        props: true,
        component: loadView('Groups'),
      },
      {
        path: 'edit/:id',
        name: 'group-edit',
        props: true,
        component: loadView('form/GroupForm'),
      },
      {
        path: 'create',
        name: 'group-create',
        props: true,
        component: loadView('form/GroupForm'),
      },
    ],
  },
];

export default groupsRoutes;
