import cloneDeep from 'lodash/cloneDeep';
import api from '@/factories/api';

export default {
  async loadFunnelStats({ commit }, campaignId) {
    commit('setFunnelStatsLoading', true);
    try {
      const funnelReport = await api().get(`campaigns/funnel/${campaignId}`);
      commit('setFunnelStats', funnelReport?.funnel);
    } catch (error) {
      throw error;
    } finally {
      commit('setFunnelStatsLoading', false);
    }
  },

  async loadCampaignMetric({ commit, getters }, data) {
    const { timeframe } = data;
    const metrics = getters.campaignMetrics || {};
    if (metrics[timeframe]) return;

    commit('setCampaignMetricsLoading', true);
    try {
      const params = getMetricFilters(data);
      const results = await api().get('contacts/metrics', { params });

      let metricsClone = cloneDeep(metrics);
      metricsClone[timeframe] = results;
      commit('setCampaignMetrics', metricsClone);
    } catch (error) {
      throw error;
    } finally {
      commit('setCampaignMetricsLoading', false);
    }
  },
};

const getMetricFilters = (data) => {
  const { bucketId, campaignId, timeframe } = data;

  return {
    user_id: '',
    title: '',
    order: 'desc',
    dash: false,
    shared: false,
    type: 'rank',
    operator: 'sum',
    resource: 'stats',
    limit: 250,
    by: 'name',
    timeframe: timeframe === 'Total' ? '' : timeframe,
    field: bucketId + '.points',
    filters: {
      campaigns: campaignId,
    },
    record_filters: {
      [bucketId + '.campaign']: campaignId,
    },
  };
};
