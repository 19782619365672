<template>
  <page-layout
    :header="isMobile"
    :loaded="currentUserLoaded"
    class="home-page"
    title="Home"
  >
    <div class="content-main">
      <div class="content-main__centered">
        <h4 class="appMain--text mb-2">Hello {{ userName }}!</h4>
        <p class="mb-6">
          <template v-if="currentUser">
            Welcome to your ConveYour dashboard, here is a brief overview of
            your account.
          </template>
          <template v-else> Welcome to ConveYour dashboard. </template>
        </p>

        <home-progress-bar />

        <template v-if="currentUser">
          <home-stats />

          <home-steps />

          <field-block class="mt-3">
            <home-conversations />
            <v-row class="mt-2 mb-0" v-if="isAdmin">
              <v-col sm="6" cols="12">
                <home-scheduled-automations />
              </v-col>
              <v-col sm="6" cols="12">
                <home-recent-activity />
              </v-col>
            </v-row>
          </field-block>
        </template>

        <home-get-help />

        <home-updates />
      </div>
    </div>
  </page-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import HomeProgressBar from './HomeProgressBar.vue';
import HomeStats from './HomeStats';
import HomeSteps from './HomeSteps';
import HomeConversations from './HomeConversations';
import HomeScheduledAutomations from './HomeScheduledAutomations';
import HomeRecentActivity from './HomeRecentActivity';
import HomeGetHelp from './HomeGetHelp';
import HomeUpdates from './HomeUpdates';

export default {
  name: 'Home',
  components: {
    HomeProgressBar,
    HomeStats,
    HomeSteps,
    HomeConversations,
    HomeScheduledAutomations,
    HomeRecentActivity,
    HomeGetHelp,
    HomeUpdates,
  },
  computed: {
    userName() {
      return this.currentUser?.first_name || '';
    },
    ...mapGetters('users', ['currentUser', 'currentUserLoaded', 'isAdmin']),
  },
};
</script>

<style lang="less">
.home-page {
  .field-block + .field-block {
    margin-top: 50px;
  }

  .item-card:not(.link-card) {
    background-color: #fff;
    cursor: default;

    &:hover {
      box-shadow: @content-shadow;
      background-color: #fff;

      .content-line,
      .item-card__content {
        background-color: #fff;
      }
    }
  }

  .item-card__content {
    .content-list {
      margin-top: -8px;
    }
  }

  a:hover {
    text-decoration: none;
  }
}
</style>
