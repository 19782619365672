import VideoPlayer from './VideoPlayer.js';

class MSFTPlayer extends VideoPlayer {
  _bindApi() {
    this.trigger(this.ready_event);
  }

  _apiLoaded() {
    return true;
  }

  makeEmbeddable(url) {
    return url;
  }
}

let msftRegex = /\.sharepoint.com\/personal\/(\w+)/;

MSFTPlayer.test = function (url) {
  return url.match(msftRegex);
};

export default MSFTPlayer;
