import api from '@/factories/api';
import EventBus from '@/factories/eventBus';
import { ablyAction } from '@/lib/ably';
import router from '@/router';
import clone from 'lodash/clone';

export default {
  async loadMessages({ commit }) {
    try {
      const messages = await api().get('messages');
      commit('setMessages', messages);
    } catch (err) {
      throw err;
    } finally {
      commit('setMessagesLoaded', true);
    }
  },

  async loadSingleMessage({ commit }, messageId) {
    commit('setMessageLoading', true);
    try {
      let message = await api().get(`messages/${messageId}`);
      commit('updateMessageInList', message);
    } catch (error) {
      throw error;
    } finally {
      commit('setMessageLoading', false);
    }
  },

  async loadMessageFeed({ commit, getters }, { messageId, event }) {
    commit('setMessageLoading', true);
    try {
      const path = `messages/events?message_id=${messageId}&event=${event}`;
      const messageFeed = await api().get(path);
      const message = getters.singleMessage(messageId);
      const updatedMessage = { ...message, feed: messageFeed?.results };
      commit('updateMessageInList', updatedMessage);
    } catch (error) {
      throw error;
    } finally {
      commit('setMessageLoading', false);
    }
  },

  async loadThreads({ commit, getters }, { filters, fromPortal = false }) {
    commit('setThreadsLoading', true);
    try {
      let path = 'messages/conversations';
      let params = null;
      if (fromPortal) {
        path = 'cg/conversations';
      } else {
        commit('setThreadsFilters', filters);
        params = getters.threadsFilters;
      }

      const threads = await api().get(path, { params });
      commit('setThreads', threads);
    } finally {
      commit('setThreadsLoading', false);
    }
  },

  async searchThreads({ commit, getters }, params) {
    return await api().get('messages/conversations', { params });
  },

  trackMessage({ commit, getters }, data) {
    api().post('cg/track_message', data);
    let message = getters.conversation.find(
      ({ pid }) => pid === data.message_pid
    );
    if (message && !message.open_count) {
      commit('updateConversation', {
        ...message,
        open_count: 1,
      });
    }
  },

  refreshPortalThreads({ dispatch, getters }, newMessage) {
    // if new message belongs to an open thread - ignore
    if (newMessage) {
      const route = router.currentRoute || {};
      if (newMessage?.thread_id === route?.params?.threadId) {
        return;
      }
    }

    dispatch('loadThreads', {
      fromPortal: true,
    });
  },

  async getRecipientsForPortal({ commit, getters }) {
    if (getters.portalRecipientsLoaded) return;
    try {
      const recipients = await api().get('cg/recipients');
      commit('setPortalRecipients', recipients);
    } catch (err) {
      throw err;
    }
  },

  async changeThread({ commit, getters, dispatch }, data) {
    try {
      await api().put(`messages/${data.id}`, data);
      const originalThread = clone(getters.singleThread(data.id));

      if (data.closed) {
        let threads = getters.threads.filter((i) => i.id !== data.id);
        commit('setThreads', threads);
      }

      ablyAction('publish', Object.assign(data, { event: 'threadToggled' }));

      EventBus.$emit('reloadThreads');
      EventBus.$emit('threadToggled', data);

      // unassign users
      if (data.closed && originalThread?.assigned_to.length) {
        dispatch('assignUsersToThread', {
          thread_id: data.id,
          users: [],
        });
      }
    } catch (err) {
      throw err;
    }
  },

  async loadConversation({ commit }, data) {
    const { filters, fromPortal = false, reload = false } = data;

    if (!reload) {
      commit('setConversationLoading', true);
    }

    try {
      let basePath = fromPortal ? 'cg' : 'messages';
      let params = filters;
      let path = `${basePath}/conversations`;
      const conversation = await api().get(path, { params });
      commit('setConversation', conversation);
      return conversation;
    } catch (err) {
      throw err;
    } finally {
      commit('setConversationLoading', false);
    }
  },

  async assignUsersToThread({}, data) {
    try {
      await api().post('messages/assignments', data);
      ablyAction('publish', Object.assign(data, { event: 'threadAssigned' }));
    } catch (err) {
      throw err;
    }
  },

  handleThreadUpdates({ commit, getters, dispatch }, data = {}) {
    if (data?.clientId === getters['users/currentUser']?.id) return;

    const conversationsRoutes = ['conversations-chat', 'conversations'];
    if (!conversationsRoutes.includes(router.currentRoute?.name)) return;

    const isThreadExists = getters.threads.some(({ thread_id }) => {
      return thread_id === data.thread_id;
    });

    if (isThreadExists) {
      commit('updateThread', {
        thread_id: data.thread_id,
        data: {
          assigned_to: data.users || [],
        },
      });
      return;
    }

    if (getters.threadsFilters.assigned_to) {
      dispatch('loadThreads', getters.threadsFilters);
    }
  },

  async createMessage({}, message) {
    try {
      let path = 'messages';
      if (message.fromPortal) {
        path = 'cg/messages';
        delete message.fromPortal;
      }

      return await api().post(path, message);
    } catch (err) {
      throw err;
    }
  },

  async closeMessage(_, messageId) {
    await api().put(`messages/${messageId}`, {
      closed: true,
    });

    EventBus.$emit('reloadThreads');
  },

  async updateMessage({ commit }, { update, id }) {
    await api().put(`messages/${id}`, update);
  },

  async updateMessageTags({ commit, dispatch }, { tags, message }) {
    dispatch('updateMessage', {
      update: { tags },
      id: message.id,
    });

    commit('updateConversation', {
      ...clone(message),
      tags: formatTagsForConversation(tags, message?.tags),
    });
  },

  async loadMessagesSchemas({ commit, getters }) {
    if (getters.messagesSchemasLoaded) return;

    try {
      const messageSchemas = await api().get('messages/schemas');
      commit('setMessagesSchemas', messageSchemas?.schemas);
    } catch (err) {
      throw err;
    } finally {
      commit('setMessagesSchemasLoaded');
    }
  },

  async getTwilioMediaAuth(_, url) {
    const signed = await api().post('twilio/sign_url', { url });
    return signed.url || url;
  },
};

const formatTagsForConversation = (update, messageTags) => {
  let tags = (messageTags || []).slice();
  if (update?.remove?.length) {
    tags = tags.filter((tagId) => !update.remove.includes(tagId));
  }

  if (update?.add?.length) {
    tags.push(...update.add);
  }

  return tags;
};
