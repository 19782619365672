<template>
  <v-tooltip :disabled="hideTooltips" right>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <router-link
          class="app-sidebar__link"
          :class="link.customClass || ''"
          :to="link.path"
          :exact="link.exact"
          :event="link.disabled ? '' : 'click'"
        >
          <div class="app-sidebar__link-icon">
            <slot name="link-icon">
              <icon-base
                :fontIcon="link.fontIcon"
                :icon="link.fontIcon ? link.icon : `sidebar/${link.icon}`"
              />
            </slot>
          </div>
          <slot name="link-text">
            <span class="app-sidebar__link-text">
              {{ link.text }}
            </span>
          </slot>
        </router-link>
      </div>
    </template>
    <span>{{ link.text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AppSidebarLink',
  props: {
    hideTooltips: Boolean,
    link: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    const onMounted = this.link?.onMounted;
    if (onMounted) {
      onMounted.call(this);
    }
  },
};
</script>
