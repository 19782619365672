let vm = null;

export const setCommandInstance = (instance) => {
  vm = instance;
};

export const addCommands = (commands, action = 'prepend', temp = true) => {
  if (vm) {
    setTimeout(() => {
      vm.addCommands(commands, action, temp);
    }, 100);
  }
};
