import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,

  state: {
    defaultCampaign: {},
    defaultCampaignLoaded: false,

    campaigns: [],
    campaignSchemas: null,
    campaignsLoaded: false,
    campaignLoading: false,
    campaignsFilter: {
      status: null,
      owners: [],
      recentlyEdited: false,
    },
    campaignsView: '',
    campaignSearch: null,

    campaignTest: null,
    campaignContacts: {
      count: 0,
      items: [],
    },
    campaignContactsFilters: null,
    campaignContactsLoading: false,

    campaignNames: [],
    campaignNamesLoaded: false,
  },

  mutations,
  actions,
  getters,
};
