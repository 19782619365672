<template>
  <modal-card title="Create Contact" fixed padding @close="$emit('close')">
    <v-fade-transition>
      <div class="success-message" v-if="createdContact">
        <v-icon color="primary">check</v-icon>
        <h4 class="mb-5">"{{ contactName }}" has been added!</h4>

        <template v-if="!returnContact">
          <v-btn color="appMain" @click="openContact">
            Go to {{ contactName }}
          </v-btn>
          <p class="mb-1 mt-1 font-weight-bold">or</p>
        </template>

        <v-btn color="appMain" @click="clearData"> Add Another Contact </v-btn>
      </div>
    </v-fade-transition>

    <contact-form
      class="pa-6 pt-4"
      ref="contactForm"
      @created="onContactCreate"
      :returnContact="returnContact"
      :search="search"
      modal
    />

    <template v-slot:actions>
      <portal-target name="createContactAction" />
    </template>
  </modal-card>
</template>

<script>
import { mapGetters } from 'vuex';

import ContactForm from '@/views/contacts/components/ContactForm';

export default {
  name: 'ContactCreateModal',
  components: {
    ContactForm,
  },
  props: {
    returnContact: Boolean,
    search: String,
  },
  data() {
    return {
      createdContact: null,
    };
  },
  computed: {
    ...mapGetters('fields', {
      fieldsLoaded: 'loaded',
    }),
    contactName() {
      return this.createdContact?.name;
    },
  },
  methods: {
    onContactCreate(contact) {
      this.createdContact = contact;
      if (this.returnContact) {
        this.$emit('created', contact);
      }
    },
    openContact() {
      this.parentNavigate(`/contacts/${this.createdContact.con_id}`, true);
    },
    clearData() {
      this.$refs.contactForm.clearData();
      this.createdContact = null;
    },
  },
};
</script>

<style lang="less">
.success-message {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .v-icon {
    font-size: 80px;
  }
}
</style>
