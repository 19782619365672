export default {
  type: 'cal',
  engagement: true,
  icon: 'cal',
  title: 'Cal.com',
  help: 'Embed Cal.com',
  is_new: true,
  schema: {
    url: {
      type: 'Text',
      title: 'Full Event URL',
      help: 'The entire URL to your cal.com event(s)',
      default: 'https://cal.conveyour.com/srhyne/30min',
    },

    toggle_text: {
      type: 'Text',
      title: 'Schedule Cal.com Event Button Text',
      help: 'Keep this short and sweet.',
      default: 'Schedule a Time',
    },

    success_text: {
      type: 'Text',
      title: 'Success Message',
      help: 'Keep this short and sweet.',
      default: 'Thank you! You are all set here.',
    },

    skip_text: {
      type: 'Text',
      title: 'Skip Text',
      help: 'Include text to allow someone to skip this engagement',
      default: '',
    },

    prefill_fields: {
      type: 'KeyValues',
      title: 'Prefill Fields',
      help: 'Pass learner profile information to your form.',
      default: [],
    },

    contact_field: {
      type: 'ContactFieldList',
      title: 'Contact Field',
      help: 'Store the scheduled time into a specific contact field.',
      allow_types: ['time'],
      validators: ['editable'],
    },
  },
};
