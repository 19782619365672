<template>
  <div class="form-group" :class="[componentName]">
    <component
      :is="fieldComponent"
      :item="item"
      :meta="meta"
      :schema="schema"
      :field="field"
      :rules="rules"
      :disabled="disabled"
      :lessonId="lessonId"
      :errorMessages="errorMessages"
      :successMessages="successMessages"
      v-model="computedValue"
      @customEvent="handleCustomEvent"
    />
  </div>
</template>

<script>
import validationRules from '@/lib/validationRules';

export default {
  name: 'FormField',
  props: ['item', 'field', 'schema', 'meta', 'value', 'disabled', 'lessonId'],
  data() {
    return {
      errorMessages: [],
      successMessages: [],
      fieldComponent: null,
    };
  },
  watch: {
    componentName: {
      immediate: true,
      handler(val, oldVal) {
        if (val === oldVal) return;
        this.loadComponent();
      },
    },
  },
  computed: {
    componentName() {
      return this.getComponentName();
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    rules() {
      let rulesArr = [];
      let validators = this.schema?.validators || [];

      if (!validators || !validators.length) {
        return rulesArr;
      }

      validators.forEach((rule) => {
        let type = rule.charAt(0).toUpperCase() + rule.slice(1);
        if (validationRules[`check${type}`]) {
          rulesArr.push((v) => {
            const result = validationRules[`check${type}`].call(this, v);
            if (['object', 'undefined'].includes(typeof result)) return true;
            return result;
          });
        }
      });
      return rulesArr;
    },
  },
  methods: {
    loadComponent() {
      if (!this.componentName) {
        this.fieldComponent = '';
      }
      this.fieldComponent = () => import(`./${this.componentName}`);
    },
    getComponentName() {
      if (!this.schema) return '';

      const { group, type } = this.schema || {};

      if (group === 'tags' && type === 'List') {
        return 'TagsField';
      }

      if (type === 'Banner') {
        return 'ImageField';
      }

      if (type === 'KeyValues') {
        return 'AnswersField';
      }

      return type + 'Field';
    },
    handleCustomEvent({ type, value }) {
      this.$emit(type, value);
    },
  },
};
</script>

<style lang="less">
.field__inner-title {
  margin-bottom: 8px;
  div {
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    color: var(--v-appMain-base);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  p {
    margin: 0;
  }
}

.field-block__title ~ .form-group {
  .field__inner-title {
    display: none;
  }
}
</style>
