export default {
  type: 'video_upload',
  title: 'Video Upload',
  icon: 'video',
  help: 'Let your learners upload their own video.',
  engagement: true,
  schema: {
    question: {
      type: 'TextArea',
      title: 'Question',
      help: 'Question you want to ask.',
      default: 'Record a video of you practicing...',
    },

    core_comps: {
      type: 'Answer',
      title: 'Core Competencies',
      help: 'Setup how you will score this video based on competencies.',
      default: [
        { answer: 'completeness', points: 400 },
        { answer: 'accuracy', points: 400 },
        { answer: 'delivery', points: 400 },
      ],
      filters: ['tagify'],
      tagify: ['answer'],
    },

    reviewing_users: {
      type: 'UserList',
      title: 'Reviewing Users',
      help: 'Select the users that will review the responses.',
      // default : []
    },
  },
  maxPoints(v) {
    let comps = v.core_comps || [];
    if (!comps.length) {
      return 0;
    }

    let total = 0;
    comps.forEach((comp) => {
      total += parseInt(comp.points || 0);
    });
    return total;
  },
};
