import store from '@/store';

export default {
  campaignTemplates: (state, getters) => {
    const { campaignTemplates, campaignTemplatesSearch } = state;
    return filterTemplates(
      campaignTemplates,
      campaignTemplatesSearch,
      store.getters['users/isFeatureEnabled']
    );
  },
};

const filterTemplates = (campaigns, search, isFeatureEnabled) => {
  return campaigns.filter(({ internal_name, name, type }) => {
    if (type === 'workflow' && !isFeatureEnabled('workflows')) {
      return false;
    }

    if (!search) return true;

    let campaignName = internal_name || name;
    return compareStrings(campaignName, search);
  });
};

const compareStrings = (text, search) => {
  if (!text) return false;
  const regex = new RegExp(search, 'i');
  return text.match(regex);
};
