export default {
  type: 'calendly',
  engagement: true,
  icon: 'calendly',
  title: 'Calendly',
  help: 'Embed Calendly',
  is_new: false,
  schema: {
    calendly_url: {
      type: 'Text',
      title: 'Full Calendly URL',
      help: 'The entire URL to your calendly event(s)',
      default: 'https://calendly.com/mojo-customer-success-1/qa-session',
    },

    toggle_text: {
      type: 'Text',
      title: 'Schedule Calendly Event Button Text',
      help: 'Keep this short and sweet.',
      default: 'Schedule a Time',
    },

    success_text: {
      type: 'Text',
      title: 'Success Message',
      help: 'Keep this short and sweet.',
      default: 'Thank you! You are all set here.',
    },

    skip_text: {
      type: 'Text',
      title: 'Skip Text',
      help: 'Include text to allow someone to skip this engagement',
      default: '',
    },

    prefill_fields: {
      type: 'List',
      title: 'Prefill Fields',
      help: 'Pass learner profile information to your Calendly custom answers. Order matters!',
      default: ['name', 'email'],
    },
  },
};
