import VideoPlayer from './VideoPlayer.js';

class LoomPlayer extends VideoPlayer {
  _loadApi() {
    return import(/* webpackChunkName: "loom.js" */ '@loomhq/loom-embed').then(
      (player) => {
        window.loomApi = player;
      }
    );
  }

  async _bindApi() {
    this.el.innerHTML = await this.customBuild();
    this.trigger(this.ready_event);
  }

  _apiLoaded() {
    return !!window.loomApi;
  }

  cleanUrl() {
    let matches = this.constructor.test(this.url || '');
    return matches[0] || this.url;
  }

  getIframeSize() {
    const width = 960;
    const aspectRatio = this.opts?.aspect_ratio;
    let height = 540;

    if (aspectRatio === '4-5') {
      height = Math.round((width / 80) * 100);
    }

    if (aspectRatio === '1-1') {
      height = width;
    }

    if (aspectRatio === '9-16') {
      height = Math.round((width / 100) * 177.8);
    }

    return {
      width,
      height,
    };
  }

  async customBuild() {
    if (!window.loomApi) return '';
    const loomPlayer = await window.loomApi.oembed(
      this.cleanUrl(),
      this.getIframeSize()
    );
    return loomPlayer?.html || '';
  }

  buildHtml() {
    return '';
  }
}

let loomRegex = /https:\/\/(.*).loom\.com\/share\/(\w+)/;

LoomPlayer.test = function (url) {
  return url.match(loomRegex);
};

export default LoomPlayer;
