import debounce from 'lodash/debounce';
import { format } from 'date-fns';
import store from '@/store';

export default {
  checkRequired: function (val, fieldName = 'Field', message) {
    if (Array.isArray(val)) {
      if (val.length) return true;
    } else {
      let clearVal = typeof val === 'string' ? val.trim() : val;
      if (!!clearVal && clearVal !== false) return true;
    }

    return message || `${fieldName} is required`;
  },
  checkUrl: function (val, fieldName = 'Url') {
    let urlRegex =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
    return urlRegex.test(val) || 'Url is invalid';
  },
  checkRequiredMin(val) {
    return !!val;
  },
  checkMinLength(val, minLength) {
    val = val || '';
    return val.length >= minLength;
  },
  checkHasNumbers(val) {
    return /\d/.test(val);
  },
  checkUpperCase(val) {
    return /[A-Z]/.test(val);
  },
  checkLowerCase(val) {
    return /[a-z]/.test(val);
  },
  checkSpecialCharacters: function (val) {
    return /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(val);
  },
  checkPhone(val) {
    if (!val) return true;
    const phoneNumber = val.trim().replace(/\s/g, '');
    return /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(
      phoneNumber
    );
  },
  checkAreaCode(val) {
    return /^\d{3}$/.test(val);
  },
  checkEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  checkStrtotime: debounce(function (value) {
    const validate = () => store.dispatch('stringToTime', value);

    const errorMessage = `"${value}" is not a valid format`;
    return asyncValidate.call(this, validate, value, errorMessage);
  }, 500),
};

async function asyncValidate(validate, value, errorMessage) {
  this.errorMessages = ['Validating...'];
  const res = await validate(value);

  if (!res?.output && value) {
    this.errorMessages = [errorMessage];
  } else {
    this.errorMessages = [];
    this.successMessages = !res?.output ? [] : [formatDate(res.output)];
  }
  return false;
}

function formatDate(timestamp) {
  return format(new Date(timestamp * 1000), 'MMM dd yyyy, hh:mm aa');
}
