<template>
  <v-app :dark="theme.isDark">
    <v-layout v-if="!currentUserLoaded" row justify-center align-center>
      <spinner />
    </v-layout>

    <template v-if="isUserLoggedIn">
      <div class="app-layout">
        <app-sidebar v-if="!inIframe" />
        <router-view />
      </div>

      <app-modals />
    </template>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import theme from '@/factories/theme';
import { inIframe } from '@/globals';
import intercom from '@/lib/intercom';
import { initAppAnalytics } from '@/lib/analytics';

import AppSidebar from '@/components/layouts/sidebar/AppSidebar';
import AppModals from '@/components/layouts/AppModals';

export default {
  name: 'AppLayout',
  components: {
    AppSidebar,
    AppModals,
  },
  data() {
    return {
      theme,
      inIframe,
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentUserLoaded']),
    ...mapGetters('users', ['isUserLoggedIn']),
  },
  methods: {
    ...mapActions('users', ['loadCurrentUser']),
    ...mapActions('programs', ['loadPrograms']),
    redirectToLogin() {
      if (this.isUserLoggedIn) return;
      this.$router.push({ name: 'app-login' });
    },
    async checkCurrentUser() {
      await this.loadCurrentUser();

      if (this.isUserLoggedIn) {
        this.loadPrograms();
      } else {
        this.redirectToLogin();
      }
    },
  },
  created() {
    initAppAnalytics();
    this.checkCurrentUser();
  },
  mounted() {
    intercom.init();
  },
};
</script>
