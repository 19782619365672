import { vueEnv, inIframe } from '@/globals';
const init = (config) => {
  const intercomId = vueEnv('intercom_id');
  const settings = {
    app_id: intercomId,
    hide_default_launcher: true,
    ...config,
  };

  if (!settings.app_id) {
    console.warn('Intercom initialized without id ');
    return false;
  }

  if (inIframe) {
    console.log('not using intercom when in child context');
    return false;
  }

  window.intercomSettings = settings;

  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var load = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + intercomId;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };

    load();
  }
};

export default { init };
