import api from '@/factories/api';

export default {
  async loadCampaignTemplates({ commit, state }) {
    if (state.campaignTemplatesLoaded) return;

    try {
      const templates = await api().get('campaigns/templates');
      commit('setCampaignTemplates', templates);
    } finally {
      commit('setCampaignTemplatesLoaded', true);
    }
  },

  async removeCampaignTemplate({ commit, state }, templateId) {
    await api().delete(`campaigns/templates/${templateId}`);
    let templates = state.campaignTemplates.filter((i) => i.id !== templateId);
    commit('setCampaignTemplates', templates);
  },

  async createCampaignTemplate({ commit }, data) {
    const template = await api().post('campaigns/templates', data);
    if (template) {
      commit('updateTemplateInList', template);
    }
  },
};
