import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('contacts');

const contactsRoutes = [
  {
    path: '/contacts',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '',
        meta: { showNav: true },
        component: loadView('Contacts'),
        children: [
          {
            path: '',
            name: 'contacts-main',
            component: loadView('ContactsMain'),
          },
          {
            path: 'archived',
            name: 'contacts-archived',
            component: loadView('ContactsMain'),
            props: {
              archived: true,
            },
          },
          {
            path: 'notes',
            name: 'contacts-notes',
            component: loadView('notes/ContactsNotes'),
          },
          {
            path: 'tasks',
            name: 'contacts-tasks',
            component: loadView('tasks/ContactsTasks'),
          },
        ],
      },

      {
        path: 'create',
        name: 'contact-create',
        props: true,
        component: loadView('contact/ContactCreate'),
      },
      {
        path: 'import',
        name: 'contacts-import',
        props: true,
        component: loadView('import/ContactsImport'),
      },
      {
        path: 'import/status/:importId',
        name: 'contacts-import-status',
        props: true,
        component: loadView('import/ContactsImportStatus'),
      },
      {
        path: 'boards',
        name: 'contacts-boards',
        props: true,
        component: loadView('boards/ContactsBoards'),
      },
      {
        path: ':id',
        name: 'contact-edit',
        props: true,
        component: loadView('contact/ContactPage'),
      },
    ],
  },
];

export default contactsRoutes;
