<template>
  <div class="item-card" v-if="hasPermission('campaigns')">
    <content-line
      fontIcon
      icon="access_time_filled"
      text="Recent Activity"
      class="pr-1"
    />

    <div class="item-card__content pt-0">
      <div class="content-list" v-if="recentActivity.length">
        <div
          class="content-list__item content-list__item--small content-list__item--action"
          v-for="(activity, idx) in recentActivity"
          :key="idx"
          @click="openActivity(activity.route)"
        >
          <div class="content-list__item-main">
            <span class="text-ellipsis text-capitalize">
              {{ activity.type }}
            </span>
            <p class="mb-0 text-ellipsis">{{ activity.data.name }}</p>
          </div>
          <div class="content-list__item-date fixed">
            {{ getDate(activity.timestamp) }}
          </div>
        </div>
      </div>

      <div v-else>
        <p class="text--disabled mb-0">No recorded activities</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { formatDistance } from 'date-fns';
import localStore from 'store';

export default {
  name: 'HomeRecentActivity',
  computed: {
    ...mapGetters('users', ['recentActivity']),
  },
  methods: {
    ...mapMutations('users', ['addUserActivity']),
    getDate(timestamp) {
      if (!timestamp) return;

      return formatDistance(new Date(timestamp), new Date(), {
        addSuffix: true,
      });
    },
    openActivity(path) {
      this.$router.push(path);
    },
    checkRecentActivity() {
      const storedActivity = localStore.get('recentActivity');
      if (!this.recentActivity.length && storedActivity) {
        this.addUserActivity(storedActivity);
      }
    },
  },
  mounted() {
    this.checkRecentActivity();
  },
};
</script>
