<template>
  <v-dialog :value="openModal" @input="closeModal" max-width="480px">
    <modal-card title="Get Quick Win!" full padding @close="closeModal">
      <h3 v-if="programTitle" class="mb-2">
        {{ programTitle }}
      </h3>

      <template v-if="showForm">
        <v-form ref="form" v-model="isFormValid">
          <div class="mt-4 mb-4">
            <form-field
              v-for="(fieldSchema, field) in programSchema"
              :key="field"
              :schema="{ ...fieldSchema, dark: true }"
              v-model="programForm[field]"
            />
          </div>

          <div class="mt-4 mb-4" v-if="showTeamsSelect">
            <team-select
              label="Which team are you targeting?"
              class="background-dark"
              v-model="selectedTeamId"
              @input="switchTeam"
              hint=""
              requiredText=""
            />
          </div>
        </v-form>

        <v-btn
          color="primary"
          class="mb-2 mt-2"
          @click="enableProgram"
          block
          :loading="loading"
          :disabled="!isFormValid"
        >
          Get It!
        </v-btn>
      </template>

      <template v-else>
        <template v-if="!canEnable">
          <p class="mb-1">You can't enable this quick win</p>
          <p class="mb-1">
            Please reach out to
            <span class="clickable" @click="showIntercom">support</span>.
          </p>
        </template>
        <template v-if="isAlreadyEnabled">
          <p class="mb-1">
            <strong>{{ programTitle }}</strong> is already enabled
          </p>
        </template>
      </template>

      <div class="quick-win__success" v-if="showSuccessMessage">
        <h3 class="mb-4">Success! &#127881;</h3>
        <p v-if="!programSuccessMessage.length">
          <strong>{{ programTitle }}</strong> is enabled
        </p>
        <ul v-else>
          <li v-for="(message, idx) in programSuccessMessage" :key="idx">
            {{ message }}
          </li>
        </ul>
      </div>
    </modal-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import FormField from '@/components/formFields/FormField';
import TeamSelect from '@/views/settings/teams/TeamSelect';

export default {
  name: 'QuickWinModal',
  components: {
    FormField,
    TeamSelect,
  },
  data() {
    return {
      isFormValid: false,
      openModal: false,
      programForm: {},
      showSuccessMessage: false,
      selectedTeamId: '',
      oldTeamId: '',
    };
  },
  watch: {
    programSchema() {
      this.buildProgramForm();
    },
    isUserLoggedIn() {
      this.initQuickWin();
    },
    openModal(newState, oldState) {},
  },
  computed: {
    ...mapGetters('programs', ['getProgram']),
    ...mapState('programs', ['loading']),
    ...mapGetters('users', ['isUserLoggedIn', 'isFeatureEnabled', 'isAdmin']),
    ...mapGetters('teams', ['currentTeam', 'byId']),
    routeQuery() {
      return this.$route?.query || {};
    },
    programId() {
      return this.routeQuery?.program_id;
    },
    programTitle() {
      return this.routeQuery?.program_title || this.programId;
    },
    program() {
      return this.getProgram(this.programId);
    },
    showForm() {
      return this.canEnable && !this.isAlreadyEnabled;
    },
    isAlreadyEnabled() {
      return this.program?.enabled;
    },
    canEnable() {
      return this.program?.can_enable;
    },
    programSchema() {
      return this.program?.schema || {};
    },
    programSuccessMessage() {
      return this.program?.meta || [];
    },
    showTeamsSelect() {
      return this.isFeatureEnabled('teams') && this.isAdmin;
    },
  },
  methods: {
    ...mapActions('programs', ['updateProgram', 'loadSingleProgram']),
    ...mapActions('users', ['updateUserTeam']),
    ...mapActions('teams', ['safeReload']),
    buildProgramForm() {
      for (let key in this.programSchema) {
        const defaultValue = this.programSchema[key]?.default || '';
        Vue.set(this.programForm, key, defaultValue);
      }
    },

    closeModal() {
      this.onModalClose();
      this.openModal = false;
    },

    enableProgram() {
      if (!this.isFormValid) {
        this.$refs.form.validate();
        return;
      }

      this.updateProgram({
        id: this.programId,
        action: 'enable',
        ...this.programForm,
      })
        .then(() => {
          this.showSuccessMessage = true;
        })
        .catch(() => {
          this.$refs.form.reset();
        });
    },

    onModalClose() {
      if (!this.showTeamsSelect) return;

      const teamHasChanged = this.selectedTeamId !== this.oldTeamId;
      // if team was changed and program was enabled - reload the page
      if (teamHasChanged && this.showSuccessMessage) {
        // remove quick win modal params
        history.pushState(null, '', window.location.pathname);
        this.safeReload();
        return;
      }

      // if team has changed, but program doesn't enable - switch to old team
      if (teamHasChanged && !this.showSuccessMessage) {
        this.selectedTeamId = this.oldTeamId;
        this.switchTeam(this.oldTeamId);
      }
    },

    // switch team in background and reload program description
    async switchTeam(teamId) {
      if (teamId === this.currentTeam?.id) return;

      if (!this.oldTeamId) {
        this.oldTeamId = this.currentTeam?.id;
      }

      await this.updateUserTeam(teamId);
      await this.loadSingleProgram(this.programId);
    },

    initQuickWin() {
      if (!this.programId || !this.isUserLoggedIn) return;
      this.openModal = true;
    },

    showIntercom() {
      if (!window.Intercom) return;
      Intercom('show');
    },
  },
  created() {
    this.initQuickWin();
  },
};
</script>

<style lang="less">
.quick-win__success {
  position: absolute;
  top: 64px;
  left: 0;
  height: calc(100% - 64px);
  width: 100%;
  padding: 24px;
  background: #fff;

  p,
  li {
    font-size: 16px;
  }
}

.clickable {
  cursor: pointer;
  color: var(--v-primary-base);
  text-decoration: underline;

  &:hover {
    color: var(--v-primary-base);
  }
}
</style>
