import uuid from 'uuid';
import times from 'lodash/times';

const id = () => {
  return uuid.v1();
};

let ids = times(100, () => {
  return id();
});

const mockTriggers = [
  {
    id: ids[1],
    automatic: false,
    banner_img: 'https://cdn.filestackcontent.com/wNMwS297Rxeisd5CtkEU',
    bookmark: null,
    bookmark_id: '',
    campaign_id: '607f1bb5819e2aa191564d1c',
    days: [],
    delay: 0,
    end_days: 0,
    end_time: 1776668400,
    etl: true,
    expire_days: 0,
    first: '95 Days ago',
    interval: '* * * * *',
    is_wf_trigger: false,
    join_delay: false,
    last: '59 Days ago',
    lesson_banner_img:
      'https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F627406744_1280x720.jpg&src1=https%3A%2F%2Ff.vimeocdn.com%2Fimages_v6%2Fshare%2Fplay_icon_overlay.png',
    lesson_id: '607f1bb5819e2aa191564d20',
    lesson_items_count: 3,
    lesson_length: '1 min, 43 sec',
    lesson_name: '01 - Who is nervous about plus stats tracker',
    name: 'Lesson 1 - video and stats tracker',
    notify_email_body:
      '<p>{{first name OR "Hi"}}, </p><p><br></p><p>Your first {{campaign_name}} question is available for you. Here\'s your link to participate:</p><p><br></p><p><a href="{{url}}" rel="noopener noreferrer" target="_blank">Here\'s your link to answer</a></p>',
    notify_email_subject:
      '{{first name OR "Hi"}}, there\'s new {{campaign_name}} content available!',
    notify_sms:
      '{{first name OR "Hi"}}, your first {{campaign_name}} question is available for you. Here\'s your link to participate:\n\n{{url}}',
    order: 1,
    org_id: 147,
    paused: false,
    previewable: true,
    reply_id: '',
    send_alert: 'sms',
    sender_id: '',
    sorting_time: 1618902060,
    start_at: '00:01',
    start_date: null,
    start_time: 1618902060,
    timezone: 'America/Los_Angeles',
    type: 'lesson',
    user_id: 209,
    valid: true,
    wf_if: '',
    wf_pid: '',
  },

  {
    id: ids[2],
    pid: ids[1],
    type: 'cond',
    description: 'Points > 500',
  },

  {
    id: ids[3],
    pid: ids[2],
    if: true,
    type: 'lesson',
    description: 'Lesson 2',
  },

  {
    id: ids[4],
    pid: ids[2],
    if: false,
    type: 'cond',
    description: 'Points > 250',
  },

  {
    id: ids[5],
    pid: ids[4],
    if: true,
    type: 'jump',
    jumpId: ids[9],
  },

  {
    id: ids[6],
    pid: ids[3],
    if: true,
    type: 'cond',
    description: 'Lesson 2 > 1200',
  },

  {
    id: ids[8],
    pid: ids[4],
    if: false,
    automatic: false,
    banner_img: 'https://cdn.filestackcontent.com/pOOgCDDKStWn65ACLluS',
    bookmark: null,
    bookmark_id: '',
    campaign_id: '607f1bb5819e2aa191564d1c',
    days: [],
    delay: 0,
    end_days: 0,
    end_time: 1776668400,
    etl: true,
    expire_days: 0,
    first: '95 Days ago',
    interval: '* * * * *',
    is_wf_trigger: false,
    join_delay: false,
    last: '59 Days ago',
    lesson_banner_img: 'https://cdn.filestackcontent.com/v3HO8snuTcu5dW6tGO0e',
    lesson_id: '607f1bb5819e2aa191564d26',
    lesson_items_count: 1,
    lesson_length: '30 sec',
    lesson_name: '04 - Sample Assessment',
    name: 'Lesson 4 - assessment',
    notify_email_body:
      '{{first name OR "Hi"}}, <br><br>There is new {{campaign_name}} content available for you. <br><br><a href="{{url}}">Here\'s your link to answer &amp; get more points!</a><br>',
    notify_email_subject:
      '{{first name OR "Hi"}}, there\'s new {{campaign_name}} content available!',
    notify_sms:
      '{{first name OR "Hi"}}, there\'s new {{campaign_name}} content available for you. Here\'s your link. \n\n{{url}}',
    order: 5,
    org_id: 147,
    previewable: true,
    reply_id: '',
    send_alert: '',
    sender_id: '',
    sorting_time: 1618902060,
    start_at: '00:01',
    start_date: null,
    start_time: 1618902060,
    timezone: 'America/Los_Angeles',
    type: 'lesson',
    user_id: 209,
    valid: true,
    wf_if: null,
    wf_pid: null,
  },

  {
    id: ids[9],
    pid: ids[6],
    if: true,
    automatic: false,
    body: 'You can also schedule a text message to go out to your learners, like this one was scheduled for 1 day after the learner joins this course. Link to the example lessons course {{url}}',
    bookmark: null,
    bookmark_id: '',
    campaign_id: '607f1bb5819e2aa191564d1c',
    days: [],
    delay: 1,
    end_days: 0,
    end_time: 1776668400,
    etl: true,
    first: '94 Days ago',
    interval: '* * * * *',
    is_wf_trigger: false,
    join_delay: true,
    last: '58 Days ago',
    media: '',
    message_id: '607f1bcb819e2a87e64918ce',
    name: 'Send Text Message',
    order: 2,
    org_id: 147,
    previewable: false,
    sorting_time: 1619020800,
    start_at: '09:00',
    start_date: null,
    start_time: 1618934400,
    timezone: 'America/Los_Angeles',
    type: 'sms',
    user_id: 209,
    valid: true,
    wf_if: null,
    wf_pid: null,
  },
  {
    id: ids[10],
    pid: ids[6],
    if: false,
    automatic: false,
    body: '<p>Hi {{first name OR "there"}},</p><p>This email is just an example showing how you can send a planned email from within a course/campaign. You set when the email will be delivered to all campaign participants using trigger scheduling.</p><p><strong><em>Can I just send an email to a group of contacts?</em></strong></p><p>Yes! You can also simply create a group automation to send an email to a group of contacts outside of a series of triggers in a course/campaign.</p>',
    bookmark: {},
    bookmark_id: '607f1bb5819e2aa191564d1d',
    campaign_id: '607f1bb5819e2aa191564d1c',
    days: [],
    delay: 1,
    end_days: 0,
    end_time: 1776668400,
    etl: true,
    first: '94 Days ago',
    interval: '* * * * *',
    is_wf_trigger: false,
    join_delay: true,
    last: '76 Days ago',
    message_id: '607f1bcb819e2a87e40083c0',
    name: 'Send Email',
    order: 1,
    org_id: 147,
    previewable: false,
    reply_id: '',
    sender_id: '',
    sorting_time: 1619020800,
    start_at: '09:00',
    start_date: null,
    start_time: 1618934400,
    subject: 'This is an example of an email send trigger from ConveYour',
    template_id: null,
    timezone: 'America/Los_Angeles',
    type: 'email',
    user_id: 209,
    valid: true,
    wf_if: null,
    wf_pid: null,
  },
  {
    id: ids[11],
    pid: ids[8],
    type: 'cond',
    description: 'Is Innovator',
    options: ['< 100 points', '> 100 points', '> 500 points', '> 1000 points'],
  },
];

export const prepareMock = (campaignId) => {
  return mockTriggers.map((t) => {
    t.campaign_id = campaignId;
    return t;
  });
};
