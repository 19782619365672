import Vue from 'vue';
import uuid from 'uuid/v4';
import Promise from 'promise';
import { on, once, trigger } from '@/lib/events';
import throttle from 'lodash/throttle';

const triggerCustomProgress = throttle(function (currentTime) {
  // for required videos save only max play time
  if (
    !this.opts.required ||
    (this.opts.required && currentTime > this.maxPlayTime)
  ) {
    this.trigger('customProgress', {
      //api wants underscore
      current_time: currentTime,
      url: this.url,
    });
  }
}, 1000 * 15);

export default class VideoPlayer {
  iframeHtml = `<iframe 
                  id="$ID" src="$URL" frameborder="0" 
                  allow="encrypted-media"
                  webkitAllowFullScreen mozAllowFullScreen allowFullScreen msallowfullscreen></iframe>`;
  ready = false;
  ready_event = 'player_ready';
  latestPlayTime = 0;
  maxPlayTime = 0;
  playbackRate = 1;

  constructor(url, el = null, opts = null) {
    this.el = el;
    this.url = url;

    opts = opts || {};
    opts.state = opts.state || {};
    this.opts = opts;

    // Delay init by one tick to ensure that
    // all instance variables have actually been set
    // due to a quirk in ES6 classes
    Vue.nextTick(() => {
      this.init();
    });

    return this;
  }

  init() {
    let opts = this.opts;

    this.id = uuid();
    this.wireEvents();
    this.insertVideo();
    this.latestPlayTime = opts.state.current_time || opts.start || 0;
    this.maxPlayTime = opts.state.current_time || opts.start || 0;
    return this;
  }

  // util
  warnUnimplemented(method, ...args) {
    console.warn(
      '[UNIMPLEMENTED]',
      method,
      'not yet implemented on',
      this.constructor.name,
      'args are',
      args
    );
  }

  // "Public" methods, usually left as is
  insertVideo() {
    if (this.ready_event) {
      this.on(this.ready_event, () => {
        this.setReady();
      });
    }
    this._insertVideo();
    this.trigger('inserted');
    return this;
  }

  loadApi() {
    if (this._apiLoaded()) {
      if (!this.api_url) {
        console.info(this.constructor.name, 'does not have an api defined');
      }
      return this;
    }
    this._beforeApiLoad();

    if (this._loadApi) {
      this._loadApi();
      return this;
    }

    let existingScript = document.querySelector('#' + this.script_id);
    if (existingScript) {
      return this;
    }

    let src = this.api_url,
      script = document.createElement('script'),
      body = document.getElementsByTagName('body')[0];

    script.id = this.script_id;

    script.async = script.defer = true;
    script.src = src;

    body.appendChild(script);
    return this;
  }

  bindApi() {
    this._bindApi();
    this.trigger('events_bound');
    return this;
  }

  buildHtml() {
    let url = this.makeEmbeddable(this.url);
    return this.iframeHtml.replace('$URL', url).replace('$ID', this.id);
  }

  play() {
    if (!this.ready) {
      this.once('ready', () => {
        this.play();
      });
      return this;
    }
    this._play();
    this.trigger('play');
    return this;
  }

  pause() {
    if (!this.ready) {
      this.once('ready', () => {
        this.pause();
      });
      return this;
    }
    this._pause();
    this.trigger('pause');
    return this;
  }

  seek(seconds, type) {
    this.latestPlayTime = seconds;

    if (!this.ready) {
      this.once('ready', () => {
        this._seek(seconds, 'initial');
      });
      return this;
    }

    this._seek(seconds, type);
    return this;
  }

  sync(diffSeconds, seconds) {
    var diff = Math.abs(this.latestPlayTime - seconds);

    if (diff >= diffSeconds) {
      this.seek(seconds);
    }
  }

  // Some players have a more final "stop"
  // Pass in terminal = true to trigger that
  stop(terminal = false) {
    if (!this.ready) {
      this.once('ready', () => {
        this.stop();
      });
      return this;
    }
    this._stop(terminal);
    this.trigger('stop');
    // We trigger "ended" on stop
    this.trigger('ended');
    return this;
  }

  remove() {
    this.trigger('remove');
    this._remove();
  }

  savePlaybackRate(speed) {
    this.playbackRate = speed;
    this.trigger('savePlaybackRate', speed);
  }

  setPlaybackRate(speed) {
    this.playbackRate = +speed;
    this._changePlaybackRate(+speed);
  }

  setLatestPlayTime(currentTime) {
    let opts = this.opts;

    if (!this.ready) {
      return false;
    }

    this.trigger('currentTime', { seconds: currentTime });

    this.triggerCustomProgress(currentTime);

    if (opts.required) {
      // prevent scrub forward
      if (currentTime - this.maxPlayTime > 1) {
        console.log('preventingScrubForward');
        return this._seek(this.maxPlayTime);
      }

      // prevent scrub backward
      if (opts.custom_range && opts.start) {
        if (opts.start - currentTime > 1) {
          console.log('preventingScrubBackward', {
            start: opts.start,
            currentTime,
          });
          return this._seek(this.opts.start);
        }
      }
    }

    if (opts.custom_range && opts.end && currentTime > opts.end) {
      return this.stop();
    }

    if (currentTime > this.maxPlayTime) {
      this.maxPlayTime = currentTime;
    }

    this.latestPlayTime = currentTime;
  }

  setReady() {
    this.ready = true;
    this.trigger('ready');
    return this;
  }

  setOpts(opts) {
    let prevOpts = this.opts;
    this.opts = opts;
    this.trigger('opts', opts, prevOpts);
  }

  // Since some players have async duration checks
  // Return a promise that resolves to seconds of duration
  duration() {
    let fn = (res, rej) => {
      let d = this._duration();
      if (!Number.isNaN(d)) {
        // Already a number, just resolve it
        res(d);
        return;
      }
      // Requires an async operation, wait for d to resolve
      d.then(res, rej);
    };
    return new Promise((res, rej) => {
      if (!this.ready) {
        this.once('ready', () => {
          fn(res, rej);
        });
      } else {
        fn(res, rej);
      }
    });
  }

  // *Important* This is for wiring to events on our class
  // NOT on the player itself. This is called before inserting
  // video player, so it won't be available yet
  wireEvents() {} // default blank

  // Internal methods, usually overloaded in each child class
  _apiLoaded() {
    return !this.api_url;
  }

  // If an api doesn't have an actual script loaded callback
  // we have to just keep checking via interval
  _beforeApiLoad() {
    let interval = setInterval(() => {
      if (this._apiLoaded()) {
        this.trigger('api_loaded');
        clearInterval(interval);
      }
    }, 100);
  }

  _duration() {
    this.warnUnimplemented('_duration');
    return Promise.reject('unimplemented');
  }

  _play() {
    this.warnUnimplemented('_play');
  }

  _pause() {
    this.warnUnimplemented('_pause');
  }

  //@param seconds
  _seek() {
    this.warnUnimplemented('_seek');
  }

  _stop() {
    this.warnUnimplemented('_stop');
  }

  _remove() {
    this.warnUnimplemented('_remove');
  }

  _insertVideo() {
    this.el.innerHTML = this.buildHtml();

    if (this._apiLoaded()) {
      this.bindApi();
    } else {
      this.loadApi().once('api_loaded', () => {
        this.bindApi();
      });
    }
  }

  setStart() {
    let opts = this.opts;
    let state = opts.state;

    if (this.latestPlayTime) {
      return this.seek(this.latestPlayTime, 'initial');
    }

    let stateCurrentTime = state && state.current_time ? state.current_time : 0;

    if (!opts.custom_range && stateCurrentTime) {
      this.duration().then((duration) => {
        if (stateCurrentTime < duration) {
          return this.seek(stateCurrentTime);
        }
      });
    }

    if (opts.custom_range && opts.start) {
      let seakTime = opts.start;

      if (stateCurrentTime > opts.start) {
        if (!opts.end || opts.end > stateCurrentTime) {
          seakTime = stateCurrentTime;
        }
      }

      return this.seek(seakTime);
    }
  }
}

Object.assign(VideoPlayer.prototype, {
  on,
  once,
  trigger,
  triggerCustomProgress,
});
