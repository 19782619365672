<template>
  <div v-if="isInstallable" @click="installPWA">
    <slot />

    <v-dialog v-model="isInstallGuideOpen" :max-width="480">
      <modal-card
        title="How To Install"
        class="ml-4 mr-4"
        full
        :portal="insidePortal"
        @close="isInstallGuideOpen = false"
      >
        <ol class="install-instructions">
          <template v-if="isIosDevice">
            <li>
              Tap the <strong>"Share"</strong> button(<v-icon
                small
                color="appMain"
                >ios_share</v-icon
              >)
            </li>
            <li>
              Scroll down and select <strong>"Add to Home Screen"</strong>.
            </li>
            <li>Edit the name (if you wish) and tap <strong>"Add"</strong>.</li>
          </template>

          <template v-else>
            <li>
              Tap the <strong>"Menu"</strong> button (<v-icon color="appMain"
                >more_vert</v-icon
              >) in top-right corner.
            </li>
            <li>
              Select <strong>"Install App"</strong> or
              <strong>"Add to Home Screen"</strong>.
            </li>
            <li>
              Confirm by tapping <strong>"Install"</strong> or
              <strong>"Add"</strong>.
            </li>
          </template>
        </ol>
      </modal-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'InstallAppLink',
  props: {
    insidePortal: Boolean,
  },
  data() {
    return {
      isInstallable: false,
      isInstallGuideOpen: false,
    };
  },
  computed: {
    ...mapGetters(['installAppPrompt']),
    isIosDevice() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent) && !this.isInStandaloneMode;
    },

    isAndroidDevice() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /android/.test(userAgent);
    },

    isInStandaloneMode() {
      return 'standalone' in window.navigator && window.navigator.standalone;
    },
  },
  methods: {
    ...mapMutations(['setInstallAppPrompt']),
    async installPWA() {
      if (this.isIosDevice || this.isAndroidDevice) {
        this.isInstallGuideOpen = true;
        return;
      }

      if (!this.installAppPrompt) {
        console.log('install prompt not available');
        return;
      }

      try {
        const result = await this.installAppPrompt.prompt();
        console.log(`Install prompt was: ${result?.outcome}`);
      } catch (error) {
        console.error('install prompt failed', error);
      } finally {
        this.setInstallAppPrompt(null);
      }
    },

    checkIsAppInstallable() {
      if (this.installAppPrompt) {
        this.isInstallable = true;
      }

      if (this.isIosDevice || this.isAndroidDevice) {
        this.isInstallable = true;
      }
    },
  },
  created() {
    this.checkIsAppInstallable();
  },
};
</script>

<style lang="less">
.install-instructions {
  padding-left: 20px;
  padding-top: 12px;

  li {
    margin-bottom: 10px;
    font-size: 16px;
  }
}
</style>
