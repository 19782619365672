import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('lessons');

const lessonsRoutes = [
  {
    path: '/lessons',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '',
        component: loadView('Lessons'),
        children: [
          {
            path: '/',
            name: 'lessons',
            props: true,
            component: loadView('LessonsMain'),
          },
          {
            path: 'folder/:folder_id',
            name: 'lessonsInFolder',
            props: true,
            component: loadView('LessonsMain'),
          },
        ],
      },
      {
        path: 'new/:folder_id?',
        name: 'lessonCreate',
        component: loadView('LessonCreate'),
      },
      {
        path: 'edit/:id/:flags?',
        name: 'lessonEdit',
        props: true,
        component: loadView('lessonEdit/LessonEdit'),
      },
      {
        path: 'take/:id/:action?',
        name: 'lessonTake',
        props: true,
        component: loadView('LessonTake'),
      },
      {
        path: 'test/:id',
        name: 'lessonTest',
        props: true,
        component: loadView('LessonTake'),
      },
      {
        path: 'metrics/:lessonId/:triggerId?',
        name: 'lessonMetrics',
        props: true,
        component: loadView('metrics/LessonMetrics'),
      },
    ],
  },
];

export default lessonsRoutes;
