import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('settings');

const settingsRoutes = [
  {
    path: '/settings',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '',
        meta: { showNav: true },
        component: loadView('Settings'),
        children: [
          {
            path: 'profile',
            name: 'settings-profile',
            component: loadView('users/components/UserEditForm'),
          },
          {
            path: 'users',
            name: 'settings-users',
            component: loadView('users/UsersMain'),
          },
          {
            path: 'archived-users',
            name: 'settings-archived-users',
            props: { archived: true },
            component: loadView('users/UsersMain'),
          },
          {
            path: 'account',
            name: 'settings-account',
            component: loadView('account/Account'),
          },
          {
            path: 'snippets',
            name: 'settings-snippets',
            redirect: { name: 'snippets' },
          },
          {
            path: 'fields',
            name: 'settings-fields',
            component: loadView('fields/FieldsMain'),
          },
          {
            path: 'links',
            name: 'settings-links',
            component: loadView('links/SettingsLinks'),
          },
          {
            path: 'integrations',
            name: 'settings-integrations',
            component: loadView('integrations/Integrations'),
          },
          {
            path: 'email-templates',
            name: 'settings-email-templates',
            component: loadView('emailTemplates/EmailTemplatesMain'),
          },
          {
            path: 'email-addresses',
            name: 'settings-email-addresses',
            component: loadView('emailAddresses/EmailAddressesMain'),
          },
          {
            path: 'other/:section?',
            props: true,
            name: 'settings-other',
            component: loadView('other/SettingsOther'),
          },
          {
            path: 'backend',
            name: 'settings-backend',
            component: loadView('backend/SettingsBackend'),
          },
          {
            path: 'teams',
            name: 'settings-teams',
            props: true,
            component: loadView('teams/Teams'),
            meta: {
              feature: 'teams',
              permission: 'teams',
            },
          },
          {
            path: 'tags',
            name: 'settings-tags',
            props: true,
            component: loadView('tags/Tags'),
            meta: {
              feature: 'tags',
              permission: 'tags',
            },
          },
          {
            path: 'custom-objects',
            name: 'settings-custom-objects',
            props: true,
            redirect: { name: 'custom-objects' },
          },
          {
            path: 'permissions',
            name: 'settings-permissions',
            props: true,
            component: loadView('permissions/AllUsersPermissions'),
          },
        ],
      },
      {
        path: 'teams/:team_id',
        name: 'settings-team-edit',
        props: true,
        component: loadView('teams/TeamEdit'),
      },
      {
        path: 'integrations/:type',
        name: 'integration-edit',
        props: true,
        component: loadView('integrations/IntegrationEdit'),
      },
      {
        path: 'custom-objects/:id',
        name: 'settings-custom-object-edit',
        props: true,
        redirect: { name: 'custom-object-edit' },
      },
      {
        path: 'custom-objects/:id/import',
        name: 'settings-custom-object-import',
        props: true,
        redirect: { name: 'custom-object-import' },
      },
      {
        path: 'users/create',
        name: 'users-create',
        props: true,
        component: loadView('users/UserCreate'),
      },
      {
        path: 'users/:id',
        name: 'user-edit',
        props: true,
        component: loadView('users/UserEdit'),
      },
      {
        path: 'users/:user_id/permissions/:type?',
        name: 'user-permissions',
        props: true,
        component: loadView('permissions/SingleUserPermissions'),
      },
      {
        path: '/settings/account/plans',
        name: 'billing-plans',
        props: true,
        component: loadView('account/Plans'),
      },
    ],
  },
];

export default settingsRoutes;
