import Resource from './common/resource';

class Activity extends Resource {
  path = 'schedule';

  onLoad({ state, commit }, { req, res }) {
    commit('setPagination', res.data);
    let activityArr = res.data.results;
    if (res.data.page > 1) {
      activityArr = (state.all || []).concat(res.data.results || []);
    }
    commit('setAll', activityArr);
  }
}

export default Activity;
