import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    courseStats: [],
    courseStatsMeta: null,
    userStats: null,
    loadingCourseStats: false,
    loadingUserStats: false,
  },

  mutations,
  actions,
  getters,
};
