import VideoPlayer from './VideoPlayer.js'; // root player

import YoutubePlayer from './YoutubePlayer.js';
import VimeoPlayer from './VimeoPlayer.js';
import WistiaPlayer from './WistiaPlayer.js';
import SproutPlayer from './SproutPlayer.js';
import HtmlPlayer from './HtmlPlayer.js';
import GoVideoPlayer from './GoVideoPlayer.js';
import LoomPlayer from './LoomPlayer.js';
import MSFTPlayer from './MSFTPlayer.js';

let players = [
  YoutubePlayer,
  VimeoPlayer,
  WistiaPlayer,
  SproutPlayer,
  HtmlPlayer,
  GoVideoPlayer,
  LoomPlayer,
  MSFTPlayer,
];

export const createPlayer = function (url, el = null, opts = null) {
  // define player in players by url and create
  for (let player of players) {
    if (player.test(url)) {
      return new player(url, el, opts);
    }
  }
  // Default to blah video player
  return new VideoPlayer(url, el, opts);
};

export const validatePlayer = function (url) {
  for (let player of players) {
    if (player.test(url)) {
      return true;
    }
  }
  return false;
};
