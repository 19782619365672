let getParam = function (url, base) {
  let re = new RegExp('(\\?|&)' + base + '\\=([^&]*)(&|$)');
  let matches = url.match(re);
  if (matches) {
    return matches[2];
  }
  return '';
};

function capitalize(str) {
  return str.replace(/^./, (c) => c.toUpperCase());
}

function lowerFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

function camelCase(snake) {
  return snake
    .toLowerCase()
    .split('_')
    .map((str) => capitalize(str))
    .join('');
}

function exitFullscreen() {
  let d = document;

  let isFullScreen =
    d.fullScreen ||
    d.mozFullScreen ||
    d.webkitIsFullScreen ||
    d.msFullscreenElement != null;

  if (!isFullScreen) {
    return false;
  }

  if (d.exitFullscreen) {
    return d.exitFullscreen();
  } else if (d.mozCancelFullScreen) {
    /* Firefox */
    return d.mozCancelFullScreen();
  } else if (d.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    return d.webkitExitFullscreen();
  } else if (d.msExitFullscreen) {
    /* IE/Edge */
    return d.msExitFullscreen();
  }

  return false;
}

function openFullscreen(el) {
  if (!el) return;

  if (el.requestFullscreen) {
    el.requestFullscreen({ navigationUI: 'hide' });
  } else if (el.msRequestFullscreen) {
    el.msRequestFullscreen();
  } else if (el.mozRequestFullScreen) {
    el.mozRequestFullScreen();
  } else if (el.webkitRequestFullscreen) {
    el.webkitRequestFullscreen({ navigationUI: 'hide' });
  }
}

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const getScriptPath = (fileName) => {
  if (fileName.includes('https')) return fileName;
  let publicPath = process.env.BASE_URL || process.env.VUE_APP_BASE_URL;
  return `${publicPath}static/js/${fileName}`;
};

const loadStaticScript = (scriptId, fileName, callback) => {
  let script = document.querySelector('#' + scriptId);
  if (script) {
    callback();
    return script;
  }

  script = document.createElement('script');
  script.id = scriptId;
  script.src = getScriptPath(fileName);
  script.onload = callback;
  document.querySelector('head').append(script);
};

const addCommas = (nStr) => {
  nStr += '';
  var x = nStr.split('.'),
    x1 = x[0],
    x2 = x.length > 1 ? '.' + x[1] : '',
    rgx = /(\d+)(\d{3})/;

  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

const waitUntil = async (check, every = 100, times = 10) => {
  return new Promise((resolve, reject) => {
    const checker = () => {
      if (!times) {
        return reject(false);
      }

      times--;

      const value = check();
      if (value) {
        return resolve(value);
      }

      setTimeout(checker, every);
    };

    checker();
  });
};

export {
  getParam,
  capitalize,
  camelCase,
  exitFullscreen,
  openFullscreen,
  asyncForEach,
  lowerFirstLetter,
  loadStaticScript,
  addCommas,
  waitUntil,
};
