import api from '@/factories/api';
import isEqual from 'lodash/isEqual';

export default {
  async loadGroups({ commit, getters }, search = '') {
    if (getters.groupsLoaded && getters.groupSearch === search) return;

    commit('setGroupsLoaded', false);
    commit('setGroupSearch', search);

    try {
      let path = 'bookmarks';
      if (search) {
        path += `/search?query=${search}`;
      }

      const groups = await api().get(path);
      commit('setGroups', groups);
    } finally {
      commit('setGroupsLoaded', true);
    }
  },

  async loadSingleGroup({ commit, getters }, groupId) {
    if (!groupId) return;

    commit('setGroupLoading', true);
    try {
      let group = getters.singleGroup(groupId);
      if (!group) {
        group = await api().get(`bookmarks/${groupId}`);
      }

      commit('updateGroupInList', group);
    } catch (err) {
      throw err;
    } finally {
      commit('setGroupLoading', false);
    }
  },

  async loadGroupContacts(
    { commit },
    { group, countOnly = false, page = 1, teamFilterOff = false }
  ) {
    if (!group) {
      return;
    }

    commit('setGroupContactsLoading', true);
    try {
      let params = {
        filters: group.filters,
        count: countOnly ? '1' : '',
      };
      const groupContacts =
        (await api({ teamFilterOff }).get('contacts', { params })) || {};

      if (!group.id && countOnly) {
        return groupContacts;
      }

      const groupClone = {
        id: group.id,
        count: groupContacts.count,
        page: +groupContacts.page || 1,
        pages: groupContacts.pages || 1,
        users:
          page > 1
            ? group.users.concat(groupContacts.results)
            : groupContacts.results || [],
      };

      if (groupClone.id) {
        commit('updateGroupContactsInfo', groupClone);
      }
    } catch (err) {
      throw err;
    } finally {
      commit('setGroupContactsLoading', false);
    }
  },

  async createGroup({ commit }, data) {
    commit('setGroupLoading', true);
    try {
      delete data.users;

      const group = await api().post('bookmarks', data);
      commit('updateGroupInList', group);
      return group;
    } catch (err) {
      throw err;
    } finally {
      commit('setGroupLoading', false);
    }
  },

  async updateGroup({ commit, getters }, group) {
    try {
      const editedGroup = await api().put(`bookmarks/${group.id}`, group);
      const currentGroup = getters.singleGroup(group.id);

      if (group.count && !editedGroup.count) {
        editedGroup.count = group.count;
      }

      commit('updateGroupInList', editedGroup);
      if (!isEqual(editedGroup.filters, currentGroup.filters)) {
        commit('deleteGroupContactsInfo', group.id);
      }
    } catch (err) {
      throw err;
    }
  },

  async deleteGroup({ commit, getters }, groupId) {
    try {
      await api().delete(`bookmarks/${groupId}`);
      let groups = getters.groups.filter((i) => i.id !== groupId);
      commit('setGroups', groups);
    } catch (err) {
      throw err;
    }
  },

  async updateGroupsOrder({ commit, getters }, groups) {
    groups.forEach((g, idx) => {
      const originalGroup = getters.singleGroup(g.id);
      commit('updateGroupInList', { ...originalGroup, order: idx });
    });

    try {
      const update = { ids: groups.map(({ id }) => id) };
      await api().post('bookmarks/order', update);
    } catch (err) {
      throw err;
    }
  },
};
