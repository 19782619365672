export default {
  state: {
    mobileBreakpoints: ['xs', 'sm'],
  },

  actions: {
    updateMobileBreakpoints: ({ commit }, breakpoints) => {
      commit('setMobileBreakpoints', breakpoints);
    },
  },

  mutations: {
    setMobileBreakpoints: (state, val) => {
      state.mobileBreakpoints = val;
    },
  },

  getters: {
    mobileBreakpoints(state) {
      return state.mobileBreakpoints;
    },
  },
};
