import clone from 'lodash/clone';
import { camelCase } from '@/lib/misc';

import groupedTypes from './types';
import models from './models';

const types = groupedTypes.flatMap((group) => group.types);

function formatType(t) {
  t.widgetType = t.widget || camelCase(t.type) + 'Widget';
  t.title = t.title || t.type.toUpperCase();
  t.help = t.help || `Create a ${t.type} section`;
  t.default = t.default || '';
  return t;
}

function formattedTypes(state) {
  return state.types.map((t) => formatType(clone(t)));
}

// get the regular non engagement formats
const regularFormats = (state, getters) => {
  return getters.formattedTypes.filter((t) => {
    return !t.engagement && !t.exclude;
  });
};

const engagementFormats = (state, getters) => {
  return getters.formattedTypes.filter((t) => {
    return t.engagement && !t.exclude;
  });
};

const schema = (state, getters) => (itemType) => {
  let t = getters.type(itemType);
  return t ? t.schema : {};
};

const type = (state) => (itemType) => {
  if (!itemType) return false;

  let t = [...state.types, ...state.models].find((t) => {
    return t.type === itemType;
  });

  if (!t) {
    return false;
  }
  // Ensure it's been processed
  if (!t.widgetType) {
    formatType(t);
  }
  return t;
};

const getMaxPoints = (state, getters) => (item) => {
  let def = getters.type(item.type);
  return def.maxPoints();
};

export default {
  namespaced: true,

  state: {
    types,
    groupedTypes,
    models,
  },

  getters: {
    engagementFormats,
    formattedTypes,
    regularFormats,
    getMaxPoints,

    schema,
    type,
  },
};
