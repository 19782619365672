import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    profile: {},
    profilePid: null,
    profileLoading: false,
    profileUpdating: false,

    scormParameters: null,
  },

  mutations,
  actions,
  getters,
};
