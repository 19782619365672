import Vue from 'vue';
import Vuex from 'vuex';

import folders from './modules/folders';
import lessons from './modules/lessons';
import users from './modules/users';
import lesson from './modules/lesson';
import items from './modules/items';
import profile from './modules/profile';
import triggers from './modules/triggers';
import contacts from './modules/contacts';
import groups from './modules/groups';
import device from './modules/device';
import campaigns from './modules/campaigns';
import campaignTemplates from './modules/campaignTemplates';
import courses from './modules/courses';
import leaderboard from './modules/leaderboard';
import search from './modules/search';
import workflow from './modules/workflow';
import helpers from './modules/helpers';
import stats from './modules/stats';
import conversations from './modules/conversations';
import notes from './modules/notes';
import reports from './modules/reports';
import contactTasks from './modules/contactTasks';
import teams from './modules/teams';
import tags from './modules/tags';
import snippetsFolders from './modules/snippetsFolders';
import snippets from './modules/snippets';
import integrations from './modules/integrations';
import links from './modules/links';
import fields from './modules/fields';
import fieldsBuckets from './modules/fieldsBuckets';
import files from '@/store/modules/files';
import groupsFolders from './modules/groupsFolders';
import emailTemplates from './modules/emailTemplates';
import emailAddresses from './modules/emailAddresses';
import activity from './modules/activity';
import messages from './modules/messages';
import org from './modules/org';
import rooms from './modules/rooms';
import customObjects from './modules/customObjects';
import customRecords from './modules/customRecords';
import auth from './modules/auth';
import cache from './modules/cache';
import programs from './modules/programs';
import cms from './modules/cms';
import tasks from './modules/tasks';
import cyBot from './modules/cyBot';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    lessons,
    lesson,
    items,
    profile,
    users,
    triggers,
    device,
    campaigns,
    campaignTemplates,
    contacts,
    groups,
    courses,
    leaderboard,
    search,
    folders,
    helpers,
    stats,
    auth,

    workflow,
    notes,
    reports,
    contactTasks,
    conversations,
    teams,
    tags,
    snippetsFolders,
    snippets,
    integrations,
    links,
    fields,
    fieldsBuckets,
    files,
    groupsFolders,
    emailTemplates,
    emailAddresses,
    activity,
    messages,
    org,
    rooms,
    customObjects,
    customRecords,
    cache,
    programs,
    cms,
    tasks,
    cyBot,
  },

  strict: true,
});

export default store;
