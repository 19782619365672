import Module from '@/store/resource/common/module';
import Tasks from '@/store/resource/tasks';
import { compareStrings } from '@/filters/helpers';

let module = new Module(new Tasks());

const SEARCHABLE_FIELDS = ['name', 'body', 'slug'];

module.add('getters', {
  filteredTasks(state, getters) {
    if (!getters.search) return state.all;

    return state.all.filter((task) => {
      return SEARCHABLE_FIELDS.some((key) => {
        return compareStrings(task[key], getters.search);
      });
    });
  },
});

export default module.create();
