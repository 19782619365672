import mediaFields from './mediaFields';
export default {
  type: 'open_ended',
  title: 'Open Ended Question',
  icon: 'open-ended',
  help: 'Create an open ended question.',
  engagement: true,
  schema: {
    question: {
      type: 'TextArea',
      title: 'Question',
      help: 'Question you want to ask.',
      default: 'What did you think...',
    },
    ...mediaFields,
    share_answers: {
      title: 'Share the Answers',
      type: 'Checkbox',
      default: false,
      help: "Share everyone's answer in a feed that is visible only to others answering this question.",
    },
    include_points: {
      title: 'Include Points',
      type: 'Checkbox',
      default: true,
      help: 'Include points earned in this question on the score leaderboard.',
    },
    answer_points: {
      title: 'Points for answering',
      type: 'Number',
      default: 1200,
      showOnHaving: 'include_points',
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },

  maxPoints(v) {
    return v.include_points ? v.answer_points : 0;
  },
};
