import api from '@/factories/api';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';

export default {
  async loadFolders({ commit, getters }, { deleted = 0 }) {
    if (getters.foldersLoaded && !deleted) return;

    commit('setFoldersLoaded', false);
    const params = {
      schema: 'small',
      deleted,
    };

    try {
      let res = await api().get('lessons/folders', { params });
      let folders = [];
      if (Array.isArray(res)) {
        folders = res.reduce((acc, folder) => {
          if (getters.singleFolder(folder.id)) return acc;
          folder.schemaLevel = 'small';
          acc.push(folder);
          return acc;
        }, cloneDeep(getters.folders));
      }
      commit('setFolders', folders);
    } catch (error) {
      throw error;
    } finally {
      commit('setFoldersLoaded', true);
    }
  },

  async updateFolder({ commit, getters }, folder) {
    let folderData = await api().put(`lessons/folders/${folder.id}`, folder);
    let mergedFolder = Object.assign(
      clone(getters.singleFolder(folder.id)),
      clone(folder),
      folderData
    );
    commit('updateFolderInList', mergedFolder);
  },

  restoreFolder({ dispatch, getters }, folder) {
    let data = Object.assign(clone(folder), { deleted_at: null });
    if (data.folder_id) {
      const parentFolder = getters.singleFolder(folder.folder_id);
      if (parentFolder.deleted_at) {
        data.folder_id = '';
      }
    }
    dispatch('updateFolder', data);
  },

  async loadSingleFolder({ commit, state }, folderId) {
    const folder = state.folders.find((i) => i.id === folderId);
    if (folder && folder.schemaLevel === 'full') {
      return false;
    }

    commit('setLoadingSingleFolder', true);
    try {
      let folderData = await api().get(
        `lessons/folders/${folderId}?schema=full`
      );
      folderData.schemaLevel = 'full';

      commit('updateFolderInList', folderData);
      folderData.lessons.forEach((lesson) => {
        if (!lesson.deleted_at) {
          commit('updateLessonInList', lesson);
        }
      });
    } catch (error) {
      throw error;
    } finally {
      commit('setLoadingSingleFolder', false);
    }
  },

  toggleFolderItemsCount({ commit, getters }, params) {
    const { folderId, increase = true, count = 1, type = 'lessons' } = params;
    if (!folderId) return;

    const folder = clone(getters.singleFolder(folderId));
    if (!folder) return;

    const field = `${type}_count`;
    let itemsCount = folder[field] || 0;

    folder[field] = itemsCount += increase ? count : -count;
    commit('updateFolderInList', folder);
  },

  deleteFolder({ getters, commit, dispatch }, folder) {
    return api()
      .delete(`lessons/folders/${folder.id}`)
      .then(() => {
        let folders = getters.folders.filter((i) => i.id !== folder.id);
        commit('setFolders', folders);

        let lessons = getters.allLessons.filter(
          (i) => i.folder_id !== folder.id
        );
        commit('setLessons', lessons);
        dispatch('toggleFolderItemsCount', {
          folderId: folder.folder_id,
          increase: false,
          type: 'folders',
        });
      });
  },

  async createFolder({ commit }, data) {
    let folderData = await api().post('lessons/folders', data);
    folderData = Object.assign(folderData, {
      lessons_count: 0,
      editable: true,
      deletable: true,
    });

    commit('updateFolderInList', folderData);
    return folderData;
  },
};
