import mediaFields from './mediaFields';
export default {
  type: 'checklist',
  icon: 'checklist',
  title: 'Checklist Challenge',
  engagement: true,
  schema: {
    question: {
      type: 'TextArea',
      title: 'Question',
      help: 'Question you want to ask.',
      default: 'Check off items you have completed.',
    },
    ...mediaFields,
    answers: {
      type: 'List',
      title: 'Checklist Items',
      help: 'A list of items for participants to check off',
      default: ['this', 'that', 'the other thing'],
    },

    can_advance: {
      type: 'Checkbox',
      default: true,
      title: 'Can Advance in Lesson',
      help: 'Do not require all items on checklist to be completed before advancing in lesson.',
    },

    include_points: {
      title: 'Include Points',
      type: 'Checkbox',
      default: true,
      help: 'Include points earned in this question on the score leaderboard.',
    },

    each_points: {
      title: 'Points for each completed item',
      type: 'Number',
      default: 100,
      showOnHaving: 'include_points',
      help: 'Give some points for each completed item.',
    },

    answer_points: {
      title: 'Points for FINISHING checklist',
      type: 'Number',
      default: 500,
      showOnHaving: 'include_points',
      help: 'Give some points for each completed item.',
    },

    participation_points: {
      title: 'Participation Points',
      type: 'Number',
      default: 100,
      showOnHaving: 'include_points',
      help: 'Give a one-time points award just for participating regardless of how many items are checked off.',
    },

    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },
  canAdvance: false,
  maxPoints(v, def) {
    let points = 0;

    if (!v.include_points) {
      return points;
    }

    if (v.answers.length && v.each_points) {
      points += v.answers.length * v.each_points;
    }

    let participationPoints = parseInt(v.participation_points || 0);
    if (participationPoints) {
      points += participationPoints;
    }

    let answerPoints = parseInt(v.answer_points || 0);

    if (answerPoints) {
      points += answerPoints;
    }

    return points;
  },
};
