const ANSWERS_STORAGE_KEY = 'apex-questionnaire-answers';
const FINISHED_INTERVIEW_STORAGE_KEY = 'apex-interview-finished';

export default {
  setRoomInfo: (state, info) => {
    state.roomInfo = info;
  },
  setRoomConfig: (state, config) => {
    state.roomConfig = config;
  },
  setRoomError: (state, data) => {
    state.roomError = data;
  },
  setQuestionnaireAnswers: (state, answers = []) => {
    state.questionnaireAnswers = answers;
    storage.save(ANSWERS_STORAGE_KEY, answers);
  },
  extractStoredQuestionnaireAnswers: (state) => {
    state.questionnaireAnswers = storage.extract(ANSWERS_STORAGE_KEY) || [];
  },
  setGuestAnswers: (state, record) => {
    const storageKey = getGuestStorageKey(state.roomInfo.identity);
    if (storageKey) {
      state.guestAnswers = record;
      storage.save(storageKey, record);
    }
  },
  extractGuestAnswers: (state) => {
    const storageKey = getGuestStorageKey(state.roomInfo.identity);
    state.guestAnswers = storage.extract(storageKey) || null;
  },
  addRoomChatMessage: (state, record) => {
    state.roomChat.push(record);
  },

  setFinishedInterviewInfo: (state, info) => {
    state.finishedInterviewInfo = info;
    storage.save(FINISHED_INTERVIEW_STORAGE_KEY, info);
  },

  extractFinishedInterviewInfo: (state) => {
    state.finishedInterviewInfo =
      storage.extract(FINISHED_INTERVIEW_STORAGE_KEY) || null;
  },
};

const getGuestStorageKey = (identity) => {
  if (!identity) return '';

  const key = `apex-questionnaire-guest-answers`;
  return `${key}_${identity}`;
};

export const storage = {
  save(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  extract(key, defaultValue = null) {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  },
};
