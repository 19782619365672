import Spinner from '@/components/basic/spinner/Spinner';
import HelpLink from '@/components/basic/help-link/HelpLink';
import ModalCard from '@/components/basic/modal-card/ModalCard';
import DropdownMenuButton from '@/components/basic/dropdown-menu-button/DropdownMenuButton';
import ContentLine from '@/components/basic/content-line/ContentLine';
import PageLayout from '@/components/layouts/PageLayout';
import IconBase from '@/components/icons/IconBase';
import CreateButton from '@/components/basic/create-button/CreateButton';
import FieldBlock from '@/components/basic/field-block/FieldBlock';

import { parentNavigate, parentTrack, onParent } from '@/lib/parentConnection';
import { mapGetters } from 'vuex';
import { minifyFilestackImage } from '@/lib/filestackLib';
import { trackEvent } from '@/lib/analytics';
import EventBus from '@/factories/eventBus';

export default {
  components: {
    ContentLine,
    CreateButton,
    HelpLink,
    ModalCard,
    Spinner,
    DropdownMenuButton,
    PageLayout,
    IconBase,
    FieldBlock,
  },
  computed: {
    ...mapGetters(['mobileBreakpoints', 'previousRoute']),
    ...mapGetters('users', ['isFeatureEnabled', 'hasPermission']),
    publicPath() {
      return process.env.BASE_URL || process.env.VUE_APP_BASE_URL;
    },
    isMobile() {
      let name = this.$vuetify.breakpoint.name;
      return this.mobileBreakpoints.includes(name);
    },
  },
  data() {
    return {
      renderAwait: false,
    };
  },
  methods: {
    trackEvent,
    minifyFilestackImage,
    formatOptions(arr) {
      return arr.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    },

    getFilePath(filePath) {
      return `${this.publicPath}static/${filePath}`;
    },

    getIconPath(svgPath) {
      return `${this.publicPath}static/svg/${svgPath}.svg`;
    },

    openConfirmationModal(params) {
      EventBus.$emit('openConfirmationModal', params);
    },

    setRenderAwait(time = 0) {
      this.renderAwait = true;
      setTimeout(() => {
        this.renderAwait = false;
      }, time);
    },

    parentNavigate,
    parentTrack,
    onParent,
  },
};
