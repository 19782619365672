import clone from 'lodash/clone';
import merge from 'lodash/merge';

import { on, trigger, once } from '@/lib/events';
import cssVars from 'css-vars-ponyfill';

const defaultFonts = {
  main_font: 'Nunito',
  headers_font: 'Raleway',
  icons_font: 'Material Icons',
};

const light = {
  background: '#f2f2f3',
  primary: '#1bc0af',
  success: '#1bc0af',
  secondary: '#fff',
  inactive: '#757575',
  light: '#fff',
  dark: '#1e1e1e',
  main: '#1e1e1e',
  mainInvert: '#fff',
  itemBackground: '#fff',
  defaultHover: '#d6d6d6',
  defaultActive: '#c2c2c2',
  appMain: '#004e75',
  lightGray: '#f7f7f7',
  darkGray: '#e6e7e9',
  borderColor: '#f2f2f3',
  borderColorDark: '#e1e1e1',
  error: '#cd5c5c',
};

const dark = {
  background: '#1e1e1e',
  primary: '#1bc0af',
  success: '#1bc0af',
  secondary: '#343434',
  inactive: '#757575',
  light: '#fff',
  dark: '#1e1e1e',
  main: '#fff',
  mainInvert: '#1e1e1e',
  itemBackground: '#343434',
  defaultHover: '#545454',
  defaultActive: '#282828',
  appMain: '#004e75',
  lightGray: '#f7f7f7',
  darkGray: '#e6e7e9',
  borderColor: '#f2f2f3',
  borderColorDark: '#e1e1e1',
  error: '#cd5c5c',
};

const appConfig = {
  colors: {
    background: '#f2f2f3',
    main: '#23374F',
  },
};

const highlightColors = ['primary', 'success', 'secondary'];

function hexToR(h) {
  return parseInt(cutHex(h).substring(0, 2), 16);
}
function hexToG(h) {
  return parseInt(cutHex(h).substring(2, 4), 16);
}
function hexToB(h) {
  return parseInt(cutHex(h).substring(4, 6), 16);
}
function cutHex(h) {
  return h.charAt(0) == '#' ? h.substring(1, 7) : h;
}

export default {
  isDark: false,

  on,
  trigger,
  once,

  default_themes: { light, dark },

  colors: {
    ...light,
  },

  fonts: {
    ...defaultFonts,
  },

  getThemeConfigFromQuery() {
    let search = window.location.search;
    if (!search) {
      return;
    }
    let theme = {};
    let parts = search.split(/[&?]/);
    for (let p of parts) {
      if (!p) {
        continue;
      } // skip blanks
      let kv = p.split('='),
        key = kv[0];
      if (kv.length != 2) {
        continue;
      } // not a key pair

      theme[key] = kv[1];
    }

    for (let key in appConfig) {
      theme[key] = theme[key] || appConfig[key];
    }

    return theme;
  },

  getColors() {
    return this.colors;
  },

  getFonts() {
    return this.fonts;
  },

  toggleDefaultTheme() {
    return this.setDefaultTheme(this.isDark ? 'light' : 'dark');
  },

  setDefaultTheme(key = 'light') {
    this.fonts = defaultFonts;
    this.isDark = key === 'dark';
    const colors = this.default_themes[key];
    this.colors = this.addAdditionalColors(this.isDark, this.colors);
    return this;
  },

  themeSet(config) {
    this.uploadThemeFonts();
    this.setThemeUpdates(config);
    this.trigger('customThemeSet', config);
    return new Promise((resolve) => {
      resolve(config);
    });
  },

  setCustomTheme(config = null) {
    var self = this;

    //TODO getThemConfigFromQuery is going to break if other inputs other than
    //theme passed
    config = config || this.getThemeConfigFromQuery() || appConfig;

    if (!config) {
      return this.themeSet({
        isDark: this.isDark,
        colors: this.colors,
        fonts: this.fonts,
      });
    }

    config.colors = config.colors || {};

    //light by default..
    let colors = clone(this.colors);
    let colorKeys = Object.keys(colors);
    let allKeys = colorKeys.slice(0).concat(['theme', 'highlight']);
    let changedColors = {};

    allKeys.forEach((key) => {
      //colors might be coming from orgStyles object OR from the query param
      let value = config[key] || config?.colors[key];

      if (!value) {
        return;
      }

      if (key === 'theme') {
        if (value === 'dark') {
          self.isDark = true;
          colors = clone(dark);
        } else {
          self.isDark = false;
          colors = clone(light);
        }
      }

      value = '#' + value.replace('#', '');

      if (key === 'highlight') {
        return highlightColors.forEach((color) => {
          changedColors[color] = value;
        });
      }

      if (colorKeys.indexOf(key) !== -1) {
        changedColors[key] = value;
      }
    });

    colors = merge(colors, changedColors);
    this.colors = this.addAdditionalColors(this.isDark, colors);

    this.fonts = merge(this.fonts, config.fonts);

    return self.themeSet({
      isDark: this.isDark,
      colors: this.colors,
      fonts: this.fonts,
    });
  },

  setThemeUpdates(config) {
    let convertedFontsVars = {};
    for (let key in config.fonts) {
      convertedFontsVars[key.replace(/_/g, '-')] = config.fonts[key];
    }

    cssVars({
      watch: true,
      variables: this.setCssVariables({
        ...config.colors,
        ...convertedFontsVars,
      }),
    });
  },

  setCssVariables(varsObj) {
    let variables = {};
    for (let key in varsObj) {
      variables[`--v-${key}-base`] = varsObj[key];
    }
    return variables;
  },

  RGB_MATCH: /rgb\(\s?(\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})\s?\)/,

  getColor(key, alpha, fallback) {
    let color = this.colors[key] || fallback;

    if (!alpha) {
      return color;
    }

    if (color.indexOf('#') === 0) {
      return this.convertHex(color, alpha);
    }

    let matches = this.RGB_MATCH.exec(color);

    if (matches.length < 4) {
      return color;
    }

    let args = matches.slice(1);
    args.push(alpha);

    return this.rgba.apply(this, args);
  },

  convertHex(hex, a) {
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return this.rgba(r, g, b, a);
  },

  rgba(r, g, b, a) {
    let func = 'rgb';
    let values = [r, g, b];
    if (a !== undefined) {
      func += 'a';
      values.push(a);
    }
    values = values.join(', ');
    return `${func}(${values})`;
  },

  uploadThemeFonts() {
    let root = document.documentElement;
    for (let key in this.fonts) {
      let font = this.fonts[key];
      if (font) {
        this.appendFont(font);
        let type = `--v-${key.replace(/_/g, '-')}-base`;
        root.style.setProperty(type, font);
      }
    }
  },

  appendFont(font) {
    if (Object.values(defaultFonts).includes(font)) {
      return false;
    }

    let link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `https://fonts.googleapis.com/css?family=${font}:300,400,500,700,900`;
    link.media = 'all';
    link.crossOrigin = 'anonymous';
    document.getElementsByTagName('head')[0].appendChild(link);
  },

  shouldUseDarkForBackground(color = '#ffffff') {
    let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

    let red, green, blue;

    if (color.includes('rgb')) {
      let colors = color.match(/(\d+)/g);
      if (colors && colors.length >= 3) {
        colors = colors.map((c) => +c);
        red = colors[0];
        green = colors[1];
        blue = colors[2];
      }
    } else {
      red = hexToR(color);
      green = hexToG(color);
      blue = hexToB(color);
    }

    let cBrightness = (red * 299 + green * 587 + blue * 114) / 1000;
    if (cBrightness > threshold) {
      return true;
    }

    return false;
  },

  getVuetifyBaseConfig() {
    return {
      icons: {
        iconfont: 'mdi',
      },
      theme: {
        dark: this.isDark,
        themes: {
          light: this.getColors(),
        },
        options: {
          customProperties: true,
        },
      },
    };
  },

  addAdditionalColors(isDark, colors) {
    const toInvert = ['primary', 'secondary', 'success', 'error'];

    const constrastingColors = [colors.main, colors.mainInvert];

    if (isDark) {
      constrastingColors.reverse();
    }

    const [dark, light] = constrastingColors;

    toInvert.forEach((color) => {
      if (colors[color]) {
        const shouldBeDark = this.shouldUseDarkForBackground(colors[color]);
        colors[`${color}Invert`] = shouldBeDark ? dark : light;
      }
    });

    return colors;
  },
};
