<template>
  <v-btn
    color="primary"
    :class="{ 'create-button--small': isMobile }"
    :to="path"
    exact
  >
    <span v-if="!isMobile">{{ 'New ' + text }}</span>
    <v-icon v-else color="light">{{ icon || 'add' }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'CreateButton',
  props: {
    text: {
      type: String,
      default: '',
    },
    path: {},
    icon: {},
  },
};
</script>

<style>
.v-btn.create-button--small {
  min-width: 36px !important;
  padding: 0 8px !important;
}
</style>
