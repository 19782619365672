export default {
  all(state) {
    return state.all;
  },

  schema(state) {
    return state.schema;
  },

  schemaSet(state) {
    return !state.schema._unset;
  },

  loading(state) {
    return state.loading;
  },

  loaded(state) {
    return state.loaded;
  },

  saving(state) {
    return state.saving;
  },

  search(state) {
    return state.search;
  },

  first(state) {
    return state.all[0] || state.resource.defaults();
  },

  count(state) {
    return state.count;
  },

  page(state) {
    return state.page;
  },

  pages(state) {
    return state.pages;
  },

  byId(state) {
    return (id) => {
      if (!id) return null;
      return state.all.find((r) => r.id === id);
    };
  },
};
