import pick from 'lodash/pick';
import clone from 'lodash/clone';
import merge from 'lodash/merge';

import uuid from 'uuid';
import localStore from 'store';
import store from '@/store';
import { format as dateFormat } from 'date-fns';
import api from '@/factories/api';
import cloneDeep from 'lodash/cloneDeep';

const getTriggerMetricData = async ({
  triggerId = null,
  path,
  params = {},
}) => {
  let headers = {};

  if (triggerId) {
    headers['x-conveyour-trigger'] = triggerId;
  }

  for (let key in store.getters.lessonMetricsFilter) {
    if (store.getters.lessonMetricsFilter[key]) {
      params[key] = store.getters.lessonMetricsFilter[key];
    }
  }

  return await api({ headers, params }).get(path);
};

export default {
  async loadLessons({ commit, getters }, data) {
    const deleted = data?.deleted || '0';
    if (getters.lessonsLoaded && !deleted) return;

    commit('setLessonsLoaded', false);
    try {
      const params = {
        schema: 'small',
        folder_id: null,
        deleted,
      };

      let lessons = await api().get('lessons', { params });

      if (!Array.isArray(lessons)) {
        lessons = [];
      }

      lessons = lessons.reduce((acc, lesson) => {
        if (getters.singleLesson(lesson.id)) return acc;
        lesson.schemaLevel = 'small';
        acc.push(lesson);
        return acc;
      }, cloneDeep(getters.allLessons));

      commit('setLessons', lessons);
    } catch (error) {
      throw error;
    } finally {
      commit('setLessonsLoaded', true);
    }
  },

  async searchLessons({ commit }, { query, folder_id, deleted = 0 }) {
    commit('setLessonsSearchLoading', true);
    commit('setLessonsSearch', query);

    const params = {
      query,
      folder_id,
      deleted,
    };

    try {
      const res = await api().get('lessons/search', { params });
      commit('setLessonsSearchResult', res);
    } catch (error) {
      throw error;
    } finally {
      commit('setLessonsSearchLoading', false);
    }
  },

  async loadSingleLesson({ commit }, lessonId) {
    commit('setLoadingSingleLesson', true);
    try {
      let lesson = await api().get(`lessons/${lessonId}`);
      lesson = Object.assign(
        {
          schemaLevel: 'full',
          banner_img: '',
          items: [],
          tags: [],
        },
        lesson
      );

      lesson.items.forEach((item) => {
        item.editing = false; // removes the editing true|false that use to be on the old lessons
        item.name = item.name || '';
      });

      commit('updateLessonInList', lesson);
      return lesson;
    } catch (error) {
      throw error;
    } finally {
      commit('setLoadingSingleLesson', false);
    }
  },

  saveLessons({ commit }, { lessons }) {
    localStore.set('vue-lessons', lessons);
    commit('setLessons', lessons);
  },

  async updateLesson({ commit }, lesson) {
    commit('setLoadingSingleLesson', true);
    try {
      let lessonClone = Object.assign(clone(lesson), { isDirty: false });
      let updatedLesson = await api().put('lessons', lessonClone);
      commit('updateLessonInList', updatedLesson);
      return updatedLesson;
    } catch (error) {
      if (error?.status === 413) {
        commit('showInfoModal', {
          type: 'error',
          data: {
            message:
              'Your Lesson is too large. Please make sure your imagery is a JPG or PNG image.',
          },
        });
      }
      throw error;
    } finally {
      commit('setLoadingSingleLesson', false);
    }
  },

  async deleteLesson({ commit, state, getters }, lesson) {
    try {
      await api().delete(`lessons/${lesson.uuid}`);
      const lessons = state.lessons
        .map((l) => clone(l))
        .filter((i) => i.id !== lesson.id);
      commit('setLessons', lessons);

      // delete lesson from search results
      if (getters.lessonsSearch) {
        const filteredResults = (state.lessonsSearchResult || [])
          .map((l) => clone(l))
          .filter((i) => i.id !== lesson.id);
        commit('setLessonsSearchResult', filteredResults);
      }
    } catch (error) {
      throw error;
    }
  },

  async removeLessonItem({}, { lessonId, itemUuid }) {
    try {
      await api().delete(`lessons/${lessonId}/items/${itemUuid}`);
    } catch (error) {
      throw error;
    }
  },

  // this will change to hitting an api, then adding
  // the resulting record to the lessons store
  // this is in lessons because it's mutating the lesson
  // list NOT updating a specific lesson
  async createLesson({ commit, dispatch }, newLesson) {
    let now = new Date();
    let dateNotice = `${dateFormat(now, 'MMM do')} at ${dateFormat(
      now,
      'h:mmaaa'
    )}`;

    const lessonDefaults = {
      name: 'New Lesson: ' + dateNotice,
      items: [],
      folder_id: null,
    };

    const newLessonOverrides = {
      uuid: uuid.v1(),
      isDirty: false,
    };

    let lesson = merge(lessonDefaults, newLesson);
    lesson = merge(lesson, newLessonOverrides);

    let savedLesson = await api().post('lessons', lesson);
    commit('updateLessonInList', savedLesson);
    dispatch('toggleFolderItemsCount', {
      folderId: newLesson.folder_id,
      increase: true,
    });
    return savedLesson;
  },

  async copyLesson({ dispatch }, existingLesson) {
    let lesson = existingLesson;
    if (lesson.schemaLevel === 'small') {
      lesson = await dispatch('loadSingleLesson', lesson.uuid);
    }

    let newLesson = pick(lesson, [
      'banner_img',
      'folder_id',
      'items',
      'name',
      'length',
      'metadata',
      'tags',
    ]);

    newLesson.name = `COPY - ${newLesson.name}`;
    newLesson.items_count = newLesson.items.length;
    newLesson.items = newLesson.items.map((item) => {
      item = pick(item, ['type', 'values', 'editing']);
      item.uuid = uuid.v1();
      return item;
    });

    return dispatch('createLesson', newLesson);
  },

  async loadItemMetrics(
    { commit, getters },
    { lessonId, itemUuid, triggerId }
  ) {
    const path = `lessons/${lessonId}/metrics/${itemUuid}`;
    const metrics = await getTriggerMetricData({ triggerId, path });
    commit('setItemMetrics', { itemUuid, metrics });
    return metrics;
  },

  async loadLessonMetrics({ commit }, { lessonId, triggerId }) {
    const path = `lessons/${lessonId}/metrics`;
    const metrics = await getTriggerMetricData({ triggerId, path });
    commit('setLessonMetrics', { lessonId, metrics });
    return metrics;
  },

  async loadSharedAnswers(context, data) {
    const {
      lessonId,
      itemId,
      conId,
      limit = 25,
      offset = 0,
      triggerId,
      noReviews,
    } = data;

    const params = {
      limit,
      offset,
      ...(conId && { con_id: conId }), // Conditionally include 'con_id' if it's provided
      ...(noReviews && !conId && { reviews_count: 0 }), // Include 'reviews_count' if 'noReviews' is true and 'conId' is not provided
    };

    const path = `lessons/${lessonId}/shared/${itemId}`;

    return await getTriggerMetricData({ triggerId, path, params });
  },

  async loadLessonsNames({ commit }) {
    try {
      const names = await api().get('lessons/names');
      commit('setLessonsNames', names);
    } catch (err) {
      throw err;
    } finally {
      commit('setLessonsNamesLoaded', true);
    }
  },

  async loadLessonMeta({ commit, getters }, lessonId) {
    if (getters.lessonMeta.lessonId === lessonId)
      return getters.lessonMeta.items;

    try {
      const items = await api().get(`lessons/${lessonId}/items/metadata`);
      commit('setLessonMeta', { lessonId, items });
      return items;
    } catch (err) {
      throw err;
    }
  },

  async loadLessonsTrackerNames({ commit }) {
    let res = await api().get('lessons/tracker_names');
    commit('setLessonsTrackerNames', res);
  },

  async loadLessonRelations({ commit }, lessonId) {
    let relatedItems = await api().get(`lessons/${lessonId}/related`);
    return relatedItems || [];
  },

  async getItemsCount({}, type) {
    try {
      const res = await api().get(`${type}?count=1`);
      return res.count;
    } catch (err) {
      throw err;
    }
  },
};
