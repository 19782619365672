export const on = function (event, fn) {
  if (!this._callbacks) {
    this._callbacks = {};
  }
  if (!this._callbacks[event]) {
    this._callbacks[event] = [];
  }
  this._callbacks[event].push(fn);
  return this;
};

export const once = function (event, callback) {
  let self = this,
    fn = function () {
      let args = Array.prototype.slice.call(arguments);
      callback.apply(self, args);
      let idx = self._callbacks[event].indexOf(fn);
      self._callbacks[event].splice(idx, 1); // remove fn
    };
  this.on(event, fn);
  return this;
};

export const trigger = function (event, ...args) {
  if (!this._callbacks || !this._callbacks[event]) {
    return this;
  }
  let fns = this._callbacks[event];
  for (let fn of fns) {
    fn.apply(this, args);
  }
  return this;
};

export const off = function (event, fnToRemove) {
  if (!this._callbacks || !this._callbacks[event]) {
    return this; // Nothing to remove, because event doesn't exist
  }

  // Find the index of the function to remove
  const index = this._callbacks[event].findIndex(
    (fn) => fn === fnToRemove || fn.originalCallback === fnToRemove
  );

  // If the function is found, remove it from the array
  if (index !== -1) {
    this._callbacks[event].splice(index, 1);
  }

  return this;
};

export const eventBus = {
  on,
  once,
  trigger,
  off,
};
