export default {
  currentLesson: (state, rootState, store) => {
    if (
      (!state.lessonsLoaded && !state.lessons.length) ||
      !store.route.params.id
    ) {
      return false;
    }
    const uuid = store.route.params.id;

    return state.lessons.find((l) => {
      return l.uuid == uuid || l.id == uuid;
    });
  },
  allLessons: (state) => {
    return state.lessons;
  },
  filteredLessons: (state) => {
    return filterLessons(state.lessons, state.lessonsFilter);
  },
  lessonsView: (state) => {
    return state.lessonsView || localStorage.getItem('lessonsView') || 'grid';
  },
  selectedLessons: (state) => {
    return state.lessons.filter((i) => i.selected);
  },
  lessonsLoaded: (state) => {
    return state.lessonsLoaded;
  },
  loadingSingleLesson: (state) => {
    return state.loadingSingleLesson;
  },
  singleLesson(state) {
    return (lessonId) => {
      return (
        state.lessons.find((i) => i.uuid == lessonId || i.id == lessonId) ||
        null
      );
    };
  },
  lessonFolderModal(state) {
    return state.lessonFolderModal;
  },
  folderLessonsCount(state) {
    return (folderId) => {
      if (!state.lessons) {
        return 0;
      }

      return state.lessons.filter((i) => i.folder_id === folderId).length;
    };
  },

  lessonsSearch: (state) => {
    return state.lessonsSearch;
  },

  lessonsSearchResult: (state) => {
    return state.lessonsSearchResult;
  },

  lessonsSearchLoading(state) {
    return state.lessonsSearchLoading;
  },

  getMetricsByItem(state) {
    return (itemUuid) => {
      return state.itemMetrics[itemUuid];
    };
  },

  getMetricsByLesson(state) {
    return (lessonId) => {
      return state.lessonMetrics[lessonId];
    };
  },

  lessonMetricsFilter(state) {
    return state.lessonMetricsFilter;
  },

  lessonsNamesLoaded(state) {
    return state.lessonsNamesLoaded;
  },

  lessonsNames(state) {
    return state.lessonsNames;
  },

  lessonsTrackerNames(state) {
    return state.lessonsTrackerNames;
  },

  lessonsFilter(state) {
    return state.lessonsFilter;
  },

  lessonMeta(state) {
    return state.lessonMeta;
  },
};

const filterLessons = (lessons, filter) => {
  if (!filter) return lessons.filter(({ deleted_at }) => !deleted_at);

  const isArchived = filter === 'archived';
  return lessons.filter(({ user_id, deleted_at }) => {
    return isArchived ? deleted_at : user_id == filter && !deleted_at;
  });
};
