import Module from '@/store/resource/common/module';
import CustomRecords from '@/store/resource/customRecords';
import api from '@/factories/api';
import isEqual from 'lodash/isEqual';
let module = new Module(new CustomRecords());

module.add('getters', {
  whereObjectId(state) {
    return (objectId) => {
      if (!objectId) return [];
      return state.all.filter((r) => r.obj_id === objectId);
    };
  },
});

module.add('actions', {
  async addManyCustomRecords({ commit }, records) {
    try {
      const res = await api().post(`custom/records/add`, records);
      return res;
    } catch (err) {
      throw err;
    }
  },
});

module.add('state', {
  importedRecords: [],
  importedFields: [],
});

module.add('mutations', {
  setImportedRecords(state, records) {
    state.importedRecords = records;
  },
  setImportedFields(state, fields) {
    state.importedFields = fields;
  },
  deleteImportedRecord(state, record) {
    state.importedRecords = state.importedRecords.filter(
      (i) => !isEqual(record, i)
    );
  },
});

export default module.create();
