import Module from '@/store/resource/common/module';
import EmailTemplates from '@/store/resource/emailTemplates';
import api from '@/factories/api';

let module = new Module(new EmailTemplates());

module.add('actions', {
  async loadEmailTemplateSchema({ commit, getters }) {
    if (getters.emailTemplatesSchema) return;
    try {
      const res = await api().get('messages/templates_schema');
      commit('setEmailTemplatesSchema', res);
    } catch (err) {
      throw err;
    }
  },
});

module.add('mutations', {
  setEmailTemplatesSchema(state, schema) {
    state.emailTemplatesSchema = schema;
  },
});

module.add('state', {
  emailTemplatesSchema: null,
});

module.add('getters', {
  emailTemplatesSchema(state) {
    return state.emailTemplatesSchema;
  },
});

export default module.create();
