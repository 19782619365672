const navigation = [
  {
    text: 'Home',
    icon: 'welcome',
    path: '/',
    exact: true,
  },
  {
    text: 'Contacts',
    icon: 'contacts',
    path: '/contacts',
    permission: 'contacts',
  },
  {
    text: 'Groups',
    icon: 'groups',
    path: '/groups',
    permission: 'contacts',
  },
  {
    text: 'Conversations',
    icon: 'conversations',
    path: '/conversations',
    permission: 'conversations',
  },
  {
    text: 'Campaigns',
    icon: 'campaigns',
    path: '/campaigns',
    permission: 'campaigns',
  },
  {
    text: 'Lessons',
    icon: 'lessons',
    path: '/lessons',
    permission: 'lessons',
  },
  {
    text: 'Automations',
    icon: 'automations',
    path: '/automations',
    permission: 'automations',
  },
  {
    text: 'Reports',
    icon: 'reports',
    path: '/reports',
  },
];

// hidden in more bucket
const secondaryNavigation = [
  {
    text: 'Tasks',
    icon: 'tasks',
    path: '/tasks',
  },
  {
    text: 'Snippets',
    icon: 'snippets',
    path: '/snippets',
  },
  {
    text: 'Custom Objects',
    icon: 'data_object',
    fontIcon: true,
    path: '/custom-objects',
    permission: 'custom_objects',
    roles: ['admin'],
  },
];

const resources = [
  {
    text: 'Help Docs',
    icon: 'help',
    href: 'https://conveyour.com/help',
  },
  {
    text: 'Video Gallery',
    icon: 'videos',
    href: 'https://conveyour.com/videos',
  },
];

export { navigation, secondaryNavigation, resources };
