import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('customObjects');

const customObjectsRoutes = [
  {
    path: '/custom-objects',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '',
        name: 'custom-objects',
        props: true,
        component: loadView('CustomObjects'),
      },
      {
        path: ':id',
        name: 'custom-object-edit',
        props: true,
        component: loadView('CustomObjectEdit'),
      },
      {
        path: ':id/import',
        name: 'custom-object-import',
        props: true,
        component: loadView('import/CustomObjectRecordImport'),
      },
    ],
  },
];

export default customObjectsRoutes;
