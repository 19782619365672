export default {
  sidebarOpen: (state) => {
    return state.sidebarOpen;
  },
  infoMessage(state) {
    return state.infoMessage;
  },
  previousRoute: (state) => {
    return state.previousRoute;
  },
  installAppPrompt: (state) => {
    return state.installAppPrompt;
  },
  pageLoading: (state) => {
    return state.pageLoading;
  },
};
