const placeholder_name = {
  type: 'Text',
  title: 'Placeholder title (Contractor, Client, etc.)',
  default: '',
  required: true,
};

export default {
  type: 'signwell',
  engagement: true,
  icon: 'signwell',
  title: 'Signwell',
  help: 'Embed Signwell',
  is_new: true,
  schema: {
    template_id: {
      type: 'Text',
      title: 'Template ID',
      default: '',
      required: true,
    },

    placeholder_name,

    button_text: {
      type: 'Text',
      title: 'Custom Button Text',
      help: 'Customize the text of the button that opens the page.',
      default: 'Sign Agreement',
    },

    success_text: {
      type: 'Text',
      title: 'Success Message',
      help: 'Keep this short and sweet.',
      default: 'Thank you! You are all set here.',
    },

    prefilled_fields: {
      type: 'KeyValues',
      title: 'Prefilled Fields',
      help: 'Pass additional fields',
      default: [],
    },

    test_mode: {
      type: 'Checkbox',
      title: 'Test Mode',
      default: false,
    },

    open_popup: {
      type: 'Checkbox',
      title: 'Show form immediately',
      default: true,
    },

    can_advance: {
      type: 'Checkbox',
      default: false,
      title: 'Can Skip. Do not require.',
      help: 'Do not require the form to be submitted.',
    },

    other_recipients: {
      type: 'Checkbox',
      default: false,
      title: 'Include other Signers',
      help: 'Does this document have other signers? If so, include them in your setup.',
    },

    recipients: {
      type: 'NestedModel',
      title: 'Other Signers',
      showOnHaving: 'other_recipients',
      model: {
        placeholder_name,
        name: {
          type: 'Text',
          title: 'Name',
        },
        email: {
          type: 'Text',
          title: 'Email',
        },
      },
    },

    include_metadata: {
      type: 'Checkbox',
      default: false,
      title: 'Include Metadata',
      help: 'Advanced. Attach custom Metadata to the Signwell document. Helpful for lookup within Signwell itself.',
    },

    metadata: {
      type: 'KeyValues',
      title: 'Metadata',
      help: 'Pass Metadata',
      showOnHaving: 'include_metadata',
      default: [],
    },
  },
};
