import { compareStrings, highlightSearch } from '@/filters/helpers';

export default {
  groups: (state) => {
    return state.groups || [];
  },
  filteredGroups: (state, getters) => {
    return (folderId) =>
      filterGroups(
        state.groups,
        state.groupSearch,
        folderId,
        getters['users/currentUser']
      );
  },
  groupsByOwner: (state) => {
    return (userId) => state.groups.filter((group) => group.user_id === userId);
  },
  groupsLoaded: (state) => {
    return state.groupsLoaded;
  },
  groupContactsLoading: (state) => {
    return state.groupContactsLoading;
  },
  groupLoading: (state) => {
    return state.groupLoading;
  },
  singleGroup: (state) => {
    return (groupId) => (state.groups || []).find((i) => i.id === groupId);
  },
  groupSearch(state) {
    return state.groupSearch;
  },
  groupsView(state) {
    return state.groupsView || localStorage.getItem('groupsView') || 'grid';
  },
  singleGroupContactsInfo(state) {
    return (groupId) => state.groupContactsInfo.find((i) => i.id === groupId);
  },
  getGroupFolderChildren(state, getters) {
    return (folderId) => {
      return [getters.groups, getters['groupsFolders/all']].reduce(
        (acc, arr) => {
          acc += (arr || []).filter(
            ({ folder_id }) => folder_id === folderId
          ).length;
          return acc;
        },
        0
      );
    };
  },
};

const filterGroups = (groups = [], search = '', folderId = '', user = {}) => {
  const isSharedForGuest = (sharedTo) => {
    return (
      user?.role === 'guest' && sharedTo && sharedTo.includes(user?.id + '')
    );
  };

  groups = groups.filter(({ folder_id, share_to }) => {
    if (!folderId && (search || isSharedForGuest(share_to))) return true;
    return (folder_id || '') === folderId;
  });

  if (search) {
    groups = groups.map((g) => {
      return { ...g, name: highlightSearch(g.name, search) };
    });
  }

  return groups.slice().sort((a, b) => a.order - b.order);
};
