import Command from './command';
import SearchResult from './searchResult';
import debounce from 'lodash/debounce';
import Module from '@/store/resource/common/module';
import SearchResource from '@/store/resource/search';

class OmniSearch extends Command {
  id = 'omniSearch';
  fontIcon = 'search';
  storeNS = 'commmandOmniSearch';

  title() {
    return 'Omni Search';
  }

  async run() {
    let vm = this.vm;
    vm.parentCommand = this;
    vm.placeholder = this.title();
    vm.title = this.title();
  }

  loadSearch = debounce(function (params) {
    this.vm.$store.dispatch(`${this.storeNS}/load`, { params });
  }, 500);

  asParent() {
    let search = this.vm.search.trim();

    if (!search) return;

    let params = {
      data: 1,
      term: search,
      limit: 10,
    };

    this.loadSearch(params);
  }

  onSearchResultsChanged(searchResults) {
    let vm = this.vm;
    //remove previous
    let commands = vm.commands.filter((existing) => {
      return existing.pid !== this.id;
    });

    searchResults.forEach((searchResult) => {
      let cmd = new SearchResult({
        title: `${searchResult.name}`,
        tooltipText: searchResult.type,
        id: `${searchResult.id}`,
        pid: this.id,
        model: searchResult,
      });
      cmd.setVM(vm);
      commands.push(cmd);
    });

    vm.commands = commands;
  }

  gtr(key) {
    return this.vm.$store.getters[`${this.storeNS}/${key}`];
  }

  init() {
    let module = new Module(new SearchResource());
    let vm = this.vm;
    vm.registerStoreModule(this.storeNS, module);
    let store = vm.$store;

    store.watch(
      () => {
        return this.gtr('all');
      },
      (all) => {
        this.onSearchResultsChanged(all);
      }
    );

    store.watch(
      () => this.gtr('loading'),
      (loading) => {
        this.vm.loading = loading;
      }
    );

    return true;
  }
}

export default OmniSearch;
