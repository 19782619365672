function getYoutubeId(url) {
  url = url
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url;
}

let providers = {
  youtube: {
    test: /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/,

    embed(url) {
      return 'https://www.youtube.com/embed/' + getYoutubeId(url);
    },

    clean(url) {
      return 'https://www.youtube.com/watch?v=' + getYoutubeId(url);
    },

    id(url) {
      return getYoutubeId(url, 'v');
    },
  },

  vimeo: {
    // #todo
    //https://vimeo.com/311795145/8c4e425ae8
    // I know this is too simple of a regex here,
    //I've seen many vimeo videos that have .com/:id/:id
    //for some reason. So we will be addressing this later
    test: /^(?:https?:)?(?:\/\/)?(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:\/(\w+))?(?:$|\/|\?)/,

    embed(url, matches) {
      url = 'https://player.vimeo.com/video/' + matches[3];

      //do include this here as that's how embedly pulls info for the embed
      if (matches[4]) {
        url += '/' + matches[4];
      }

      return url;
    },

    clean(url, matches) {
      if (!matches[3]) {
        return '';
      }

      url = 'https://vimeo.com/' + matches[3];

      //do include this here as that's how embedly pulls info for the embed
      if (matches[4]) {
        url += '/' + matches[4];
      }

      return url;
    },

    id(url, matches) {
      return matches[3];
    },
  },

  wistia: {
    test: /https:\/\/(.*).wistia\.com\/medias\/(\w+)/,

    embed(url, matches) {
      let id = matches[2];
      if (!id) {
        return '';
      }
      return 'https://fast.wistia.net/embed/iframe/' + id;
    },

    clean(url, matches) {
      if (!matches[1] || !matches[2]) {
        return '';
      }
      return `https://${matches[1]}.wistia.com/medias/${matches[2]}`;
    },

    id(url, matches) {
      return matches[2] || '';
    },
  },

  sprout: {
    // https://videos.sproutvideo.com/embed/189adcb31f19e8c990/b923069cb05ab66b
    test: /videos\.sproutvideo.com\/embed\/(\w+\/\w+)/,

    embed(url, matches) {
      let path = matches[1];
      if (!path) {
        return '';
      }
      return 'https://videos.sproutvideo.com/embed/' + path;
    },

    clean(url, matches) {
      return this.embed(url, matches);
    },

    //this will JSUT get the video ID not the embed security key
    id(url, matches) {
      return (matches[1] || '').split('/').shift();
    },
  },

  goVideoPlayer: {
    // https://share.vidyard.com/watch/UkLB3xqzJ7MtLA2hFVKun2
    test: /(?:share|embed)\.vidyard\.com\/(?:watch|share)\/(\w+)/,

    embed(url) {
      return url;
    },

    clean(url) {
      return url;
    },
  },

  htmlPlayer: {
    // #todo Update regex
    test: /\.(mp4|m3u8)$/,

    embed(url) {
      return url;
    },

    clean(url) {
      return url;
    },
  },

  loomPlayer: {
    test: /https:\/\/(.*).loom\.com\/share\/(\w+)/,

    embed(url, matches) {
      return matches[0] || url;
    },

    clean(url, matches) {
      return matches[0] || url;
    },
  },

  MSFTPlayer: {
    test: /\.sharepoint.com\/personal\/(\w+)/,

    embed(url) {
      return url;
    },

    clean(url) {
      return url;
    },
  },
};

export default (url, method = 'embed') => {
  for (let p in providers) {
    let test = providers[p].test;

    if (typeof test !== 'function') {
      // Is regex, wrap it for normalization
      let regex = test;
      test = function (val) {
        return val.match(regex);
      };
    }

    let matches = test(url);
    if (matches) {
      if (providers[p][method]) {
        return providers[p][method](url, matches);
      }
    }
  }

  return method === 'clean' ? '' : url;
};
