import Command from './command';

class SearchResult extends Command {
  icon = '';

  icons = {
    contact: 'campaigns/person',
    lesson: 'sidebar/lessons',
    campaign: 'sidebar/campaigns',
    team: 'group_work',
  };

  typeSearchTerms = {
    lesson(model) {
      return model.info?.tags || [];
    },
    any(model) {
      return Object.values(model.info);
    },
  };

  constructor(props) {
    super(props);
    this.setIcon();
    this.setSearchTerms();
  }

  run() {
    let vm = this.vm;
    vm.$router.push(this.model.route);
    return true;
  }

  setIcon() {
    const type = this.model.type;
    this.icon = this.icons[type] || 'campaigns/person';
    this.fontIcon = !this.icon.includes('/');
  }

  setSearchTerms() {
    const type = this.model.type;
    const searchTerms = this.typeSearchTerms[type] || this.typeSearchTerms.any;
    if (searchTerms) {
      this.searchTerms = searchTerms(this.model);
    }
  }
}

export default SearchResult;
