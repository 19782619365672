import Command from './command';
import uuid from 'uuid/v4';
import TaskForm from '@/views/tasks/TaskForm';

class CreateTask extends Command {
  id = 'createTask';
  icon = 'sidebar/tasks';
  title = 'Create Task';
  permission = '';
  actions = TaskForm;

  async run() {
    const vueInstance = this.vm;

    const newTask = await vueInstance.$store.dispatch('tasks/createOne', {
      name: 'New Task',
      status: 'not_started',
      slug: uuid(),
      related_records: getRelatedRecords(vueInstance.$route),
    });

    const modal = vueInstance.$refs[this.id][0];
    if (modal) {
      modal.openModal(newTask?.id);
    }

    return false;
  }
}

const getRelatedRecords = (path) => {
  let records = [];
  const { params, name } = path || {};

  if (name === 'campaign-edit') {
    records.push(`api/campaigns@${params?.campaignId}`);
  }

  if (name === 'campaign-trigger-edit' || name === 'automation-edit') {
    records.push(`api/triggers@${params?.id}`);
  }

  return { add: records };
};

export default CreateTask;
