import { compareStrings } from '@/filters/helpers';

export default {
  folders: (state) => {
    return state.folders;
  },
  foldersMain: (state, getters) => {
    if (getters.lessonsFilter === 'archived') return getters.filteredFolders;
    return getters.filteredFolders.filter((i) => !i.folder_id);
  },
  filteredFolders: (state, getters) => {
    // filter folders by lessons filters and text search
    return filterFolders(
      state.folders,
      getters.lessonsSearch,
      getters.lessonsFilter
    );
  },
  folderFolders: (state, getters) => {
    return (folderId) => {
      return getters.filteredFolders.filter((i) => i.folder_id === folderId);
    };
  },
  foldersLoaded: (state) => {
    return state.foldersLoaded;
  },
  loadingSingleFolder(state) {
    return state.loadingSingleFolder;
  },
  currentFolder(state) {
    return state.currentFolder;
  },
  singleFolder(state) {
    return (folderId) => {
      return state.folders.find((i) => i.id === folderId) || null;
    };
  },
};

const filterFolders = (folders, search, filter) => {
  if (!filter || filter !== 'archived') {
    folders = folders.filter(({ deleted_at }) => !deleted_at);
  }

  if (!filter && !search) return folders;

  if (filter) {
    const isArchived = filter === 'archived';
    folders = folders.filter(({ user_id, deleted_at }) => {
      return isArchived ? deleted_at : user_id == filter;
    });
  }

  if (search) {
    folders = folders.filter(({ name }) => compareStrings(name, search));
  }
  return folders;
};
