<template>
  <div
    class="list-field"
    :class="{ colored: colored, 'with-icons': showIcons }"
  >
    <template v-for="(item, idx) in listItems">
      <div
        class="list-field__item"
        :class="getItemClasses(item)"
        :key="idx"
        :id="getItemId(item)"
        @click="toggleOption(item)"
      >
        <v-icon
          v-if="viewRoute"
          small
          class="link-icon mr-3"
          @click.stop="viewItem(item)"
          :color="isActive(item) ? 'light' : 'appMain'"
        >
          open_in_new
        </v-icon>
        <template v-if="showIcons">
          <v-icon
            v-if="item.icon"
            class="link-icon mr-3 ml-1"
            :color="isActive(item) ? 'light' : 'appMain'"
          >
            {{ item.icon }}
          </v-icon>
          <avatar v-else :img="item.photo" size="small" />
        </template>
        <span class="list-field__item-text">{{ item.label }}</span>
        <v-icon color="primary" v-show="isActive(item)">done</v-icon>
      </div>
      <list-field
        v-if="item.items && item.items.length"
        :key="item.value"
        :items="item.items"
        :value="value"
        :showIcons="showIcons"
        :multiple="multiple"
        :colored="colored"
        :expandable="expandable"
        @input="$emit('input', $event)"
      />
    </template>
    <v-btn
      v-if="hiddenItemsCount && !expanded && expandable"
      class="list-field__more-btn"
      text
      color="primary"
      @click.native="expanded = true"
    >
      {{ value ? 'Show All' : 'Show More' }} ({{ hiddenItemsCount }})
    </v-btn>
  </div>
</template>

<script>
import Avatar from '@/components/basic/avatar/Avatar';

export default {
  name: 'ListField',
  components: {
    Avatar,
  },
  props: {
    value: {
      type: [String, Number, Array],
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    arrowsNavigation: Boolean,
    viewRoute: Object,
    colored: Boolean,
    expandable: Boolean,
    multiple: Boolean,
    showIcons: Boolean,
    showSelected: Boolean,
    disableUnselect: Boolean,
    selectedFirst: Boolean,
    visibleCount: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      expanded: false,
      selectedOption: null,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal && this.expandable && !this.multiple) {
        this.expanded = false;
      }
    },
    items() {
      this.selectedOption = null;
    },
  },
  computed: {
    highlightedOption() {
      if (!this.items.length) return '';
      return this.selectedOption || this.items[0];
    },
    visibleItemsCount() {
      return this.visibleCount;
    },
    listItems() {
      const items = (this.items || []).slice();

      if (
        ((this.multiple && this.expandable && this.value.length) ||
          (!this.multiple && this.value) ||
          this.selectedFirst) &&
        !this.showSelected
      ) {
        items.sort((a, b) => this.isActive(b) - this.isActive(a));
      }

      if (this.expandable && !this.expanded) {
        return items.splice(0, this.visibleItemsCount);
      }

      return items;
    },
    hiddenItemsCount() {
      const itemsCount = this.items.length;
      if (itemsCount > this.visibleItemsCount) {
        return itemsCount - this.visibleItemsCount;
      }
      return 0;
    },
  },
  methods: {
    getItemId(item) {
      if (!this.arrowsNavigation) return '';
      return item.value;
    },

    getItemClasses(item) {
      return {
        active: this.isActive(item),
        highlighted: this.isHighlighted(item),
      };
    },

    isActive(item) {
      if (this.multiple) {
        return (this.value || []).includes(item.value);
      }
      return item.value === this.value;
    },

    isHighlighted(item) {
      if (!this.arrowsNavigation) return false;
      return item.value === this.highlightedOption?.value;
    },

    toggleOption(item) {
      let value = '';

      if (this.multiple) {
        value = (this.value || []).slice();
        if (this.isActive(item)) {
          value = value.filter((i) => i !== item.value);
        } else {
          value.push(item.value);
        }
      } else {
        if (this.isActive(item) && this.disableUnselect) return;
        value = this.isActive(item) ? '' : item.value;
      }

      this.$emit('input', value);
    },

    viewItem({ value }) {
      if (!value) return;

      const { name, param = 'id' } = this.viewRoute || {};
      const route = this.$router.resolve({
        name,
        params: { [param]: value },
      });

      if (route?.href) {
        window.open(route.href, '_blank');
      }
    },

    handleKeydown(e) {
      if (e.keyCode === 13) {
        this.toggleOption(this.highlightedOption);
      }
      if (e.keyCode === 40) {
        this.highlightItem(true);
      }
      if (e.keyCode === 38) {
        this.highlightItem(false);
      }
    },

    highlightItem(forward) {
      let itemIndex = this.items.findIndex((i) => this.isHighlighted(i));
      const itemsCount = this.items.length - 1;

      if (forward) {
        if (itemIndex === itemsCount) {
          itemIndex = 0;
        } else {
          itemIndex += 1;
        }
      } else {
        if (itemIndex === 0) {
          itemIndex = itemsCount - 1;
        } else {
          itemIndex -= 1;
        }
      }

      this.selectedOption = this.items[itemIndex];
      this.checkElementPosition();
    },

    async checkElementPosition() {
      if (!this.selectedOption) return;
      await this.$nextTick();

      const container = this.$el;
      const element = document.getElementById(this.selectedOption.value);
      if (!element || !container) return;

      let cTop = container.scrollTop;
      let cBottom = cTop + container.clientHeight;

      let eTop = element.offsetTop;
      let eBottom = eTop + element.clientHeight;

      if (eTop < cTop) {
        container.scrollTop -= cTop - eTop;
      } else if (eBottom > cBottom) {
        container.scrollTop += eBottom - cBottom;
      }
    },

    toggleEventListener(add = false) {
      if (!this.arrowsNavigation) return;

      let method = (add ? 'add' : 'remove') + 'EventListener';
      window[method]('keydown', this.handleKeydown, true);
    },
  },
  mounted() {
    this.toggleEventListener(true);
  },
  beforeDestroy() {
    this.toggleEventListener(false);
  },
};
</script>

<style lang="less">
.list-field {
  padding: 5px 0;

  &__item {
    padding: 10px 15px;
    font-size: @font-size-medium;
    color: var(--v-main-base);
    cursor: pointer;
    display: flex;
    transition: all 0.5s ease;
    position: relative;
    align-items: center;

    .list-field__item-text {
      flex: 1;
    }

    &.highlighted,
    &:focus,
    &:active,
    &:hover {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &:hover:before {
      background: var(--v-main-base);
      opacity: 0.15;
    }

    &.highlighted:before,
    &:active:before {
      background: var(--v-dark-base);
      opacity: 0.24;
    }

    &.active {
      .list-field__item-text {
        color: var(--v-primary-base);
      }
    }

    .highlighted {
      background-color: var(--v-appMain-base);
      color: #fff;
      padding: 0 2px;
    }
  }

  &__more-btn.v-btn {
    height: 44px;
    width: 100%;

    .v-btn__content {
      justify-content: left;
      padding: 4px 8px;
    }
  }

  &.colored {
    background: var(--v-itemBackground-base);
    box-shadow: @base-shadow;
    border-radius: @base-radius;
    overflow: hidden;
  }

  &.with-icons {
    margin-left: 0 !important;

    .list-field__item {
      align-items: center;
      padding-left: 8px;

      .v-avatar {
        margin-right: 8px;
      }
    }
  }
}
</style>
