import api from '@/factories/api';

export default {
  async portalSearch({ commit }, search) {
    commit('setLoadingSearch', true);
    commit('setCurrentSearch', search);

    try {
      const searchResults = await api().get(
        `cg/search?query=${search}&search_lessons=true`
      );
      const results = filterSearchResults(searchResults, search);
      commit('setSearchResults', results);
    } catch (error) {
      throw error;
    } finally {
      commit('setLoadingSearch', false);
    }
  },
};

const filterSearchResults = (results, search) => {
  if (Array.isArray(results) && !results.length) {
    return null;
  }

  const filteredResults = {
    joinedCourses: [],
    availableCourses: [],
    folders: [],
  };

  return results.reduce((acc, course) => {
    let type = !course.status
      ? 'folders'
      : (course.status === 'playing' ? 'joined' : 'available') + 'Courses';

    if (!course.triggers) {
      course.triggers = [];
      acc[type].push(course);
    } else {
      if (
        course.triggers.length ||
        (!course.triggers.length && compareStrings(course.name, search))
      ) {
        acc[type].push(course);
      }
    }

    return acc;
  }, filteredResults);
};

const compareStrings = (fullString, search) => {
  return fullString.toLowerCase().includes(search.toLowerCase());
};
