import Module from '@/store/resource/common/module';
import Snippets from '@/store/resource/snippets';
import api from '@/factories/api';

let module = new Module(new Snippets());

module.add('actions', {
  async loadSnippetSchema({ commit, getters }) {
    if (getters.snippetSchema) return;
    try {
      const res = await api().get('snippets/schema');
      commit('setSnippetSchema', res);
    } catch (err) {
      throw err;
    }
  },
});

module.add('mutations', {
  setSnippetSchema(state, schema) {
    state.snippetSchema = schema;
  },
});

module.add('state', {
  snippetSchema: null,
});

module.add('getters', {
  snippetSchema(state) {
    return state.snippetSchema;
  },
});

export default module.create();
