import Module from '@/store/resource/common/module';
import GroupsFolders from '@/store/resource/groupsFolders';
let module = new Module(new GroupsFolders());

import store from '@/store';

module.add('getters', {
  filteredFolders(state) {
    let folders = state.all || [];
    if (!store.getters.groupSearch) return folders;

    return folders.filter(({ id }) => {
      return store.getters.groups.some(({ folder_id }) => folder_id === id);
    });
  },
});

export default module.create();
