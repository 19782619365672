import api from '@/factories/api';
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    tooltipsCategoryLoading: '',
    tooltipsCategories: {},
  },

  getters: {
    getTooltipsCategory(state) {
      return (category) => state.tooltipsCategories[category];
    },
    tooltipsCategoryLoading(state) {
      return state.tooltipsCategoryLoading;
    },
    getSingleTooltip(state) {
      return (category, type) => {
        return (state.tooltipsCategories[category] || []).find((i) => {
          return i.slug === type;
        });
      };
    },
  },

  mutations: {
    setTooltipCategory(state, data) {
      const { category, tooltips, resources } = data;

      tooltips.forEach((t) => {
        t.content.resources = (t.content?.resources || []).reduce(
          (acc, story) => {
            if (typeof story === 'string' && story) {
              story = (resources || []).find(({ uuid }) => {
                return story === uuid;
              });
            }

            if (story) {
              acc.push({
                name: story.name,
                url: 'https://conveyour.com/' + story.full_slug,
              });
            }

            return acc;
          },
          []
        );
      });

      Vue.set(state.tooltipsCategories, category, tooltips);
    },
    setTooltipsCategoryLoading(state, category) {
      state.tooltipsCategoryLoading = category;
    },
  },

  actions: {
    async loadTooltips({ commit, getters }, category) {
      const categoryTooltips = getters.getTooltipsCategory(category);
      if (
        getters.tooltipsCategoryLoading === category ||
        (categoryTooltips && categoryTooltips.length)
      )
        return;

      commit('setTooltipsCategoryLoading', category);
      try {
        const res = await api().get(`cms/tooltips?tags=${category}`);

        commit('setTooltipCategory', {
          category,
          tooltips: res?.stories || [],
          resources: res?.rels || [],
        });
      } catch (err) {
        throw err;
      } finally {
        commit('setTooltipsCategoryLoading', '');
      }
    },
  },
};
