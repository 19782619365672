import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import uuid from 'uuid';
import { defaultEngagementState } from './helpers';

const updateEngagementState = (item, update) => {
  let es = item.engagementState || defaultEngagementState();
  item.engagementState = merge(es, update);
};

export default {
  updateLessonItems: (state, { lesson, items, dirty }) => {
    let _items = items.slice(0).map(function (i) {
      i = cloneDeep(i);
      i.uuid = i.uuid || uuid.v1();
      return i;
    });

    lesson.items = _items;
    lesson.isDirty = dirty;
  },

  setLessonIndex: (state, index) => {
    state.currentIndex = index;
  },

  setAnswers: (state, { item, answers }) => {
    updateEngagementState(item, { answers });
  },

  setLastAnswer: (state, { item, lastAnswer }) => {
    updateEngagementState(item, { lastAnswer });
  },

  SET_RESULTS: (state, { item, results }) => {
    updateEngagementState(item, { results });
  },

  SET_COMPLETED: (state, { item, completed }) => {
    updateEngagementState(item, { completed });
  },

  SET_CORRECT: (state, { item, correct }) => {
    updateEngagementState(item, { correct });
  },

  SET_POINTS: (state, { item, points }) => {
    updateEngagementState(item, { points });
  },

  SET_RESPONSE: (state, { item, response }) => {
    updateEngagementState(item, { response });
  },

  COMPLETE: (state) => {
    state.completed = true;
  },

  UNCOMPLETE: (state) => {
    state.completed = false;
  },

  SET_ANSWER_LOADING: (state, value) => {
    state.answerLoading = value;
  },

  setSelectOnly: (state, value) => {
    state.selectOnly = value;
  },
};
