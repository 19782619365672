// Models, only available for nesting, don't appear in sidebar
export default {
  model: true,
  type: 'Feedback',
  keyField: 'answer',
  valueField: 'response',
  schema: {
    answer: {
      type: 'ListSelect',
      title: 'Answer',
      help: 'Select Answer',
      default: '',
      multiple: false,
      list(item) {
        const answers = item.values.answers || [];
        return answers.map((answer) => {
          //handles challenges and assessments
          return typeof answer === 'object' ? answer.answer : answer;
        });
      },
    },
    response: {
      type: 'TextArea',
      title: 'Response',
      help: 'Your Response will be...',
      default: '',
    },
  },
};
