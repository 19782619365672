<template>
  <v-card
    class="styled-modal"
    :class="{
      'fixed-modal d-flex': fixed,
      'styled-modal--portal': portal,
    }"
    @keydown.esc="$emit('close')"
  >
    <v-card-title>
      <v-btn v-if="hasActionsSlot" icon class="mr-2" @click="$emit('close')">
        <v-icon color="light">close</v-icon>
      </v-btn>
      <h4 class="light--text">
        {{ title }}
        <help-link v-if="help" class="ml-2" :route="help" />
      </h4>
      <v-spacer />
      <slot name="headerActions">
        <v-btn icon class="mr-2" @click="$emit('close')" v-if="closable">
          <v-icon color="light">close</v-icon>
        </v-btn>
      </slot>
    </v-card-title>
    <v-card-text
      class="styled-modal__content"
      :class="{
        'fixed-modal__content': fixed,
        'fixed-modal__content--full': full,
        light: light,
        'pa-4': padding,
      }"
    >
      <div class="spinner-wrap pa-5" v-if="loading">
        <spinner />
      </div>
      <template v-else>
        <slot />
      </template>
    </v-card-text>
    <v-card-actions v-if="!full" class="pa-4 pt-3 pb-3">
      <slot name="leftActions"></slot>
      <v-spacer v-if="showActionsSpacer" />
      <v-btn
        v-if="closeButton && closable"
        text
        color="appMain"
        class="ml-4"
        @click="$emit('close')"
        >Cancel</v-btn
      >
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ModalCard',
  props: {
    title: String,
    help: String,
    fixed: Boolean,
    portal: Boolean,
    closeButton: Boolean,
    closable: {
      type: Boolean,
      default: true,
    },
    full: Boolean,
    padding: Boolean,
    loading: Boolean,
    light: Boolean,
    showActionsSpacer: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasActionsSlot() {
      return !!this.$slots.headerActions;
    },
  },
};
</script>
