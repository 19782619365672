import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    folders: [],
    foldersLoaded: false,
    loadingSingleFolder: false,
    currentFolder: '',
  },

  mutations,
  actions,
  getters,
};
