import mediaFields from './mediaFields';
import feedbackFields from './feedbackFields';

import maxBy from 'lodash/maxBy';
export default {
  type: 'assessment',
  icon: 'assessment',
  engagement: true,
  title: 'Assessment',
  help: 'Learner picks one weighted answer.',

  schema: {
    question: {
      type: 'TextArea',
      title: 'Question',
      help: 'Question you want to ask',
      default: 'How well did you do that thing this week?',
    },

    ...mediaFields,

    include_points: {
      title: 'Show Points Scored',
      type: 'Checkbox',
      default: true,
      help: 'Include points earned in this question on the score leaderboard.',
    },

    hide_results: {
      title: 'Hide Results',
      type: 'Checkbox',
      default: false,
      help: 'Check this to true, if you don`t want to share the total results to others.',
    },

    answers: {
      type: 'Answer',
      title: 'Answers',
      default: [
        { answer: 'exceptionally well', points: 1000 },
        { answer: 'pretty well', points: 500 },
        { answer: 'not well at all', points: 100 },
      ],
    },

    ...feedbackFields,

    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },

  maxPoints(v) {
    let maxAnswer = maxBy(v.answers, (answer) => answer.points);
    return v.include_points ? maxAnswer.points : 0;
  },
};
