<template>
  <div>
    <component :is="currentComponent" @input="currentModal = ''" />

    <smart-tooltip
      link
      hide-activator
      :category="helpCategory"
      :type="helpType"
      ref="smartTooltip"
    />
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize';
import SmartTooltip from '@/components/basic/smart-tooltip/SmartTooltip';

export default {
  name: 'HelpModal',
  components: { SmartTooltip },
  data() {
    return {
      currentModal: '',
      helpCategory: '',
      helpType: '',
    };
  },
  computed: {
    currentComponent() {
      if (!this.currentModal) return;

      return () => import(`./${this.currentModal}`);
    },
  },
  methods: {
    checkHelpLinkClick(e) {
      const link = e.target.closest('.help-link');
      if (!link) return;

      let helpRoute = link.getAttribute('data-route') || '';
      let helpCategory = link.getAttribute('data-category') || '';
      if (helpRoute.startsWith('help')) {
        e.stopPropagation();
        e.preventDefault();

        if (helpRoute.startsWith('help/modal')) {
          return this.initHelpModal(helpRoute);
        }

        if (helpCategory) {
          this.helpCategory = helpCategory;
          this.helpType = helpRoute;
          this.$refs.smartTooltip.customShow(e);
          return;
        }

        const path = `https://conveyour.com/${helpRoute}`;
        window.open(path, '_blank');
      }
    },
    initHelpModal(path) {
      let helpComponent = path.split('-').reduce((acc, item) => {
        if (!item.startsWith('help')) {
          acc += capitalize(item);
        }
        return acc;
      }, '');

      if (!this.isComponentValid(helpComponent)) return;
      this.currentModal = helpComponent;
    },
    isComponentValid(component) {
      const helpModals = ['DelayTimeInfo'];
      return helpModals.includes(component);
    },
    toggleClickListener(listen = false) {
      let method = listen ? 'addEventListener' : 'removeEventListener';
      window[method]('click', this.checkHelpLinkClick);
    },
  },
  created() {
    this.toggleClickListener(true);
  },
  beforeDestroy() {
    this.toggleClickListener();
  },
};
</script>
