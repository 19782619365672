import { findIndex } from 'lodash';

//used in mutations NOT directly from actions
const updateModel = (state, model) => {
  let all = state.all.slice(0);

  const index = findIndex(state.all, { id: model.id });

  if (index === -1) {
    all.push(model);
  } else {
    all.splice(index, 1, model);
  }

  state.all = all;

  return state;
};

const add = (state, model) => {
  updateModel(state, model);
};

const update = (state, { model, update }) => {
  let keys = Object.keys(update);

  if (keys.length) {
    keys.forEach((key) => {
      model[key] = update[key];
    });
  }
  updateModel(state, model);
};

const setKey = (state, { key, value }) => {
  state[key] = value;
};

export { add, update, setKey };
