<template>
  <app-sidebar-link
    :link="profileLink"
    :hideTooltips="disabledTooltip"
    class="mb-2 pb-1"
  >
    <template v-slot:link-icon>
      <avatar :img="currentUser.photo" size="small" />
    </template>
    <div
      slot="link-text"
      class="app-sidebar__link-text user-name-text text-ellipsis"
    >
      {{ userName }}
    </div>
  </app-sidebar-link>
</template>

<script>
import { mapGetters } from 'vuex';
import AppSidebarLink from '@/components/layouts/sidebar/links/AppSidebarLink.vue';
import Avatar from '@/components/basic/avatar/Avatar.vue';

export default {
  name: 'ProfileLink',
  components: {
    AppSidebarLink,
    Avatar,
  },
  props: {
    disabledTooltip: Boolean,
  },
  data() {
    return {
      profileLink: {
        text: 'Profile',
        path: '/profile',
      },
    };
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
    userName() {
      const { first_name, last_name } = this.currentUser || {};
      return `${first_name} ${last_name}`;
    },
  },
};
</script>
