<template>
  <modal-card
    title="Setup Text Messaging"
    fixed
    padding
    :full="twilioLineSet"
    :loading="purchaseLoading"
    @close="$emit('close')"
  >
    <template v-if="twilioLineSet">
      <v-fade-transition>
        <div class="twilio-success">
          <div class="mb-4"><v-icon color="primary">check</v-icon></div>
          <p style="font-size: 2em">
            Your text message line is now <b>{{ selectedTwilioNumber }}</b>
          </p>
          <p>
            <smart-tooltip
              type="messaging-faq"
              category="messaging"
              link
              title="Learn about Messaging"
            />
          </p>
        </div>
      </v-fade-transition>
    </template>

    <template v-else>
      <p>
        With ConveYour, you get your own <em>secondary</em> phone line
        <em>dedicated</em> for sending and receiving text messages in the
        system.
      </p>

      <v-form @submit.prevent="searchNumbers">
        <v-row>
          <v-col cols="4">
            <v-text-field
              placeholder="Area code"
              size="3"
              type="tel"
              class="no-label"
              v-model="areaCode"
            />
          </v-col>
          <v-col>
            <v-btn
              color="appMain"
              :disabled="!isAreaCodeValid"
              @click="searchNumbers()"
            >
              Search
            </v-btn>
          </v-col>
        </v-row>

        <div class="spinner-wrap pt-6 mt-6" v-if="numbersLoading">
          <spinner />
        </div>

        <div v-if="!numbersLoading && numbers">
          <template v-if="numbers.length">
            <v-data-table
              class="styled-table numbers-table mt-6"
              hide-default-footer
              disable-sort
              :headers="headers"
              :items="numbers"
              :itemsPerPage="-1"
              :mobile-breakpoint="0"
              @click:row="handleRowClick"
            >
              <template v-slot:item.phoneNumber="{ item }">
                <v-radio-group v-model="selectedTwilioNumber">
                  <v-radio :value="item.phoneNumber"></v-radio>
                </v-radio-group>
              </template>
            </v-data-table>
          </template>

          <div class="mt-4" v-else>
            <p class="mb-1">
              It looks like there are no numbers available with area code
              <strong>{{ areaCode }}</strong
              >.
            </p>
            <p>Please search again for an alternative.</p>
          </div>
        </div>
      </v-form>

      <template slot="actions">
        <feature-check feature="sms" program-id="productsSms">
          <v-btn
            color="appMain"
            @click="selectTwilioNumber"
            :disabled="!selectedTwilioNumber"
          >
            Select Number
          </v-btn>
        </feature-check>
      </template>
    </template>
  </modal-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import validationRules from '@/lib/validationRules';
import FeatureCheck from '@/components/programs/FeatureCheck.vue';
import SmartTooltip from '@/components/basic/smart-tooltip/SmartTooltip.vue';

export default {
  name: 'SetPhoneLine',
  components: {
    FeatureCheck,
    SmartTooltip,
  },
  props: {
    teamId: String,
  },
  data() {
    return {
      areaCode: '',
      country: 'US',
      mobile: '',
      countries: [
        {
          text: '+1 US',
          value: 'US',
          code: '1',
        },
        {
          text: '+1 CA',
          value: 'CA',
          code: '1',
        },
        {
          text: '+61 AUS',
          value: 'AUS',
          code: '61',
        },
      ],
      numbers: null,
      numbersLoading: false,
      purchaseLoading: false,
      twilioLineSet: false,
      selectedTwilioNumber: '',
      user_contact: '',
      headers: [
        {
          text: '',
          value: 'phoneNumber',
          width: 40,
        },
        {
          text: 'Phone Number',
          value: 'friendlyName',
        },
        {
          text: 'Region',
          value: 'region',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
    isAreaCodeValid() {
      return validationRules.checkAreaCode(this.areaCode);
    },
  },
  methods: {
    ...mapActions('users', [
      'updateUser',
      'searchTwilioNumbers',
      'purchaseTwilioNumber',
    ]),
    ...mapActions([
      'updateContactField',
      'loadFilteredContacts',
      'createMessage',
    ]),
    ...mapActions('fields', { loadFields: 'load' }),
    ...mapActions('teams', { updateTeam: 'save' }),
    ...mapMutations('users', ['updateCurrentUser']),

    async setUserContact() {
      this.user_contact = await this.loadFilteredContacts({
        filters: { search: this.currentUser.email, limit: 1 },
      });
    },

    async searchNumbers() {
      if (!this.isAreaCodeValid) return false;

      this.numbers = null;

      try {
        this.numbersLoading = true;
        this.numbers = await this.searchTwilioNumbers({
          area_code: this.areaCode,
          country: this.country,
        });
      } catch (err) {
        console.log('search numbers error: ', err);
        this.numbers = [];
      } finally {
        this.numbersLoading = false;
      }
    },

    loadResources() {
      this.setUserContact();
      if (!this.currentUser.mobile) {
        this.loadFields({ checkLoaded: true });
      } else {
        this.initialNumbersSearch();
      }
    },

    initialNumbersSearch() {
      let mobile = this.currentUser.mobile.replace(/^\+?1|\|1|\D/, '');
      this.areaCode = mobile.slice(0, 3);
      this.searchNumbers();
    },

    showSuccessMessage(val) {
      this.twilioLineSet = val;
      setTimeout(() => {
        this.$emit('close');
      }, 3000);
    },

    openConversation() {
      let route = '/conversations';
      if (this.user_contact) {
        route = `/conversations/${this.isMobile ? 'chat/' : ''}${
          this.user_contact.con_id
        }/sms`;
      }

      this.parentNavigate(route, true, false);
    },

    handleRowClick({ phoneNumber }) {
      this.selectedTwilioNumber = phoneNumber;
    },

    async selectTwilioNumber() {
      const data = {
        phone_number: this.selectedTwilioNumber,
      };

      if (this.teamId) {
        data.teams = [this.teamId];
      }

      try {
        this.purchaseLoading = true;
        const res = await this.purchaseTwilioNumber(data);

        this.$emit('input', res);

        if (this.teamId) {
          this.setPhoneForTeam(res?.phone_number);
        } else {
          this.setPhoneForCurrentUser();
        }

        setTimeout(() => {
          this.showSuccessMessage(true);
        });
      } finally {
        this.purchaseLoading = false;
      }
    },

    setPhoneForTeam(twilio_phone) {
      if (!twilio_phone) return;

      this.updateTeam({
        model: {
          id: this.teamId,
          twilio_phone,
        },
        noLoader: true,
      });
    },

    setPhoneForCurrentUser() {
      this.updateCurrentUser({
        param: 'twilio_phone',
        value: this.selectedTwilioNumber,
      });
    },
  },
  mounted() {
    this.loadResources();
  },
};
</script>

<style lang="less">
.numbers-table tbody tr:not(.v-row-group__header) {
  height: 48px;
}

.twilio-success {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
  }

  .v-icon {
    font-size: 60px;
  }
}
</style>
