import { AUTOMATIONS_STATUSES } from './actions';
import { compareStrings } from '@/filters/helpers';
import { triggerTypes } from '@/views/automations/automationsConfig';
import {
  getGraph,
  isTriggerInGraph,
} from '@/views/campaigns/components/workflow/workflow-helpers';

export default {
  triggers: (state) => {
    return state.triggers;
  },
  groupedTriggers: (state) => {
    return (triggerId) => groupTriggersByDay(state.triggers, triggerId);
  },
  triggerTypes: (_, getters) => {
    return filterTriggerTypes(
      triggerTypes,
      getters['users/currentUser'],
      getters.triggersSchemas
    );
  },
  triggerTypeDescription(_, getters) {
    return (triggerType) =>
      getters.triggerTypes.find((i) => i.type === triggerType);
  },
  triggersSchemas: (state) => {
    return state.triggersSchemas;
  },
  triggerPicker: (state) => {
    return state.triggerPicker;
  },
  triggersLoaded: (state) => {
    return state.triggersLoaded;
  },
  triggerLoading: (state) => {
    return state.triggerLoading;
  },
  singleTrigger(state) {
    return (triggerId) => state.triggers.find((i) => i.id === triggerId);
  },
  triggersNames(state) {
    return state.triggersNames;
  },
  triggersNamesLoaded(state) {
    return state.triggersNamesLoaded;
  },

  workflowSettings(state) {
    return state.workflowSettings;
  },

  workflowGraph(state) {
    return getGraph(state.triggers);
  },

  workflowRootTriggerId(state, getters) {
    const rootTrigger = getters.workflowGraph[0] || {};
    return rootTrigger?.id;
  },

  isTriggerInGraph(state, getters) {
    return (trigger) => isTriggerInGraph(trigger, getters.workflowGraph);
  },

  getConnectedJumpTriggers(state) {
    return (triggerId) =>
      state.triggers.filter(
        ({ wf_next_trigger }) => wf_next_trigger === triggerId
      );
  },

  getNestedTriggers(state, getters) {
    return (triggerId) => {
      const triggers = state.triggers.filter(({ wf_pid }) => {
        return wf_pid === triggerId;
      });

      return triggers.reduce((acc, trigger) => {
        if (!trigger.id) return acc;

        acc.push(trigger);
        const nestedTriggers = getters.getNestedTriggers(trigger.id);
        if (nestedTriggers.length) {
          return acc.concat(nestedTriggers);
        }
        return acc;
      }, []);
    };
  },

  // automations
  automationsSearch(state) {
    return state.automationsSearch;
  },
  groupAutomationsCount(state) {
    return (groupId) => {
      const groupAutomations = state.triggers.filter((i) => {
        return i.bookmark_id === groupId;
      });

      return (groupAutomations || []).length || 0;
    };
  },
  automationsFilter: (state) => {
    return state.automationsFilter;
  },
  automations: (state) => {
    return state.triggers.filter((i) => i.type !== 'lesson');
  },
  filteredAutomations: (state, getters) => {
    const search = getters.automationsSearch;
    const filter = getters.automationsFilter;

    return filterAutomations(getters.automations, search, filter);
  },
  automationsView(state) {
    return (
      state.automationsView || localStorage.getItem('automationsView') || 'list'
    );
  },
};

const filterAutomations = (automations, search, filter) => {
  return automations.filter((automation) => {
    // Filter by status
    if (filter.status) {
      if (filter.status === 'ongoing') {
        if (!(automation.status === 'live' && automation.automatic)) {
          return false;
        }
      } else if (
        !AUTOMATIONS_STATUSES[filter.status] ||
        automation.status !== filter.status
      ) {
        return false;
      }
    }

    // Filter by type
    if (filter.type && automation.type !== filter.type) {
      return false;
    }

    // Filter by group
    if (filter.group) {
      if (filter.group === 'notSet' && automation.bookmark_id) {
        return false;
      } else if (
        filter.group !== 'notSet' &&
        automation.bookmark_id !== filter.group
      ) {
        return false;
      }
    }

    // Filter by search
    if (search && !compareStrings(automation.name, search)) {
      return false;
    }

    return true;
  });
};

const groupTriggersByDay = (triggers, parentTriggerId = '') => {
  return triggers
    .slice()
    .filter(({ parent_trigger }) => {
      return (parent_trigger || '') === (parentTriggerId || '');
    })
    .sort(sortTriggers)
    .reduce((acc, trigger) => {
      let startDate = trigger.start_date || trigger.delay;
      let startTime = trigger.start_time;

      if (acc[startDate]) {
        if (acc[startDate].items[startTime]) {
          acc[startDate].items[startTime].push(trigger);
        } else {
          acc[startDate].items[startTime] = [trigger];
        }
      } else {
        acc[startDate] = {
          delay: !!trigger.delay || !trigger.start_date,
          items: {
            [startTime]: [trigger],
          },
        };
      }
      return acc;
    }, {});
};

const getNestedTriggers = (triggers, triggerId) => {
  return triggers
    .filter(({ parent_trigger }) => parent_trigger === triggerId)
    .sort(sortTriggers);
};

const sortTriggers = (a, b) => {
  let as, bs;

  as = a.sorting_time;
  bs = b.sorting_time;

  let ao = a.order || 0;
  let bo = b.order || 0;

  if (as && bs) {
    var _a = as + ao;
    var _b = bs + bo;
    return _a < _b ? -1 : 1;
  }
};

const filterTriggerTypes = (triggerTypes, currentUser = {}, schemas = {}) => {
  const filters = {
    integration_solo: () => {
      return !!schemas?.integration_solo;
    },
    integration_spotio: () => {
      return !!schemas?.integration_spotio;
    },
  };
  return triggerTypes.filter(({ type }) => !filters[type] || filters[type]());
};
