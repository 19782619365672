import api from '@/factories/api';
import isEmpty from 'lodash/isEmpty';

export default {
  namespaced: true,

  state: {
    integrationsLoading: false,
    zapierInfo: {},
    analyticsInfo: {},
    integrations: [],
  },

  mutations: {
    setZapierInfo: (state, info) => {
      state.zapierInfo = info;
    },
    setAnalyticsInfo: (state, info) => {
      state.analyticsInfo = info;
    },
    setIntegrationsLoading: (state, loading) => {
      state.integrationsLoading = loading;
    },
    setIntegrations: (state, data) => {
      state.integrations = data;
    },
  },

  actions: {
    async loadIntegrations({ commit, state }, forceReload = false) {
      if (state.integrations.length && !forceReload) return;

      commit('setIntegrationsLoading', true);
      try {
        const auths = await api().get('auths');
        commit('setIntegrations', auths || []);
      } catch (err) {
        throw err;
      } finally {
        commit('setIntegrationsLoading', false);
      }
    },

    async sendIntegration({ commit }, data) {
      commit('setIntegrationsLoading', true);
      try {
        const res = await api().post('auths', data);
      } catch (err) {
        throw err;
      } finally {
        commit('setIntegrationsLoading', false);
      }
    },

    async removeIntegration({ state, commit }, integrationId) {
      commit('setIntegrationsLoading', true);
      try {
        await api().delete(`auths/${integrationId}`);
        const integrations = state.integrations.filter(
          ({ id }) => id !== integrationId
        );
        commit('setIntegrations', integrations);
      } finally {
        commit('setIntegrationsLoading', false);
      }
    },

    async getUserToken({}) {
      try {
        const res = await api().post('users/token');
        return res?.token || '';
      } catch (err) {
        throw err;
      }
    },

    async loadZapierInfo({ commit, state }) {
      if (!isEmpty(state.zapierInfo)) return state.zapierInfo;

      commit('setIntegrationsLoading', true);
      try {
        const res = (await api().post('hooks/credentials')) || {};
        commit('setZapierInfo', res);
        return res;
      } catch (err) {
        throw err;
      } finally {
        commit('setIntegrationsLoading', false);
      }
    },

    async loadAnalyticsInfo({ commit, state }) {
      if (!isEmpty(state.analyticsInfo)) return state.analyticsInfo;

      commit('setIntegrationsLoading', true);
      try {
        const res = (await api().post('analytics')) || {};
        commit('setAnalyticsInfo', res);
        return res;
      } catch (err) {
        throw err;
      } finally {
        commit('setIntegrationsLoading', false);
      }
    },
  },

  getters: {
    singleIntegration(state) {
      return (provider) =>
        state.integrations.find((i) => i.provider === provider);
    },
  },
};
