import Module from '@/store/resource/common/module';
import Teams from '@/store/resource/teams';
import api from '@/factories/api';
import { compareStrings } from '@/filters/helpers';
import cloneDeep from 'lodash/cloneDeep';

let module = new Module(new Teams());

module.add('getters', {
  filteredTeams(state, getters) {
    if (!getters.search) return state.all;

    return state.all.filter(({ label }) => {
      return compareStrings(label, getters.search);
    });
  },

  currentTeam(state, getters, rootState, rootGetters) {
    const userTeamId = rootGetters['users/userTeam'];
    const storedTeam = JSON.parse(
      localStorage.getItem('teams.current') || '{}'
    );
    const selectedTeamId = userTeamId || storedTeam?.id;

    return getters.byId(selectedTeamId);
  },

  getUserTeams(state) {
    return (userId) => {
      return state.all.filter((t) => {
        return (t.users || []).some((id) => id == userId);
      });
    };
  },
});

module.add('actions', {
  updateUserTeams({ dispatch, getters }, updates) {
    const { userId, teams = [] } = updates;
    const userTeams = getters.getUserTeams(userId);

    const removeFromTeams = userTeams.filter((team) => {
      return !teams.includes(team.id);
    });

    removeFromTeams.forEach((team) => {
      dispatch('updateTeamUsers', {
        team_id: team.id,
        users: {
          remove: [userId],
          full: (team.users || []).filter((i) => i != userId),
        },
      });
    });

    const addToTeams = teams
      .filter((teamId) => {
        return !userTeams.some(({ id }) => teamId === id);
      })
      .map((teamId) => getters.byId(teamId));

    addToTeams.forEach((team) => {
      dispatch('updateTeamUsers', {
        team_id: team.id,
        users: {
          add: [userId],
          full: [...(team.users || []), userId],
        },
      });
    });
  },

  addNewUserToTeam({ commit, getters }, data) {
    const { userId, teams } = data;
    if (!teams.length || !userId) return;

    teams.forEach((teamId) => {
      const team = getters.byId(teamId);
      if (team) {
        const updatedTeam = {
          ...cloneDeep(team),
          users: [...(team?.users || []), userId],
        };

        commit('update', { model: {}, update: updatedTeam });
      }
    });
  },

  async updateTeamUsers({ commit }, data) {
    commit('loading', true);
    try {
      const updatedTeam = await api().post('teams/users', data);
      updatedTeam.users = data.users.full;
      commit('update', { model: {}, update: updatedTeam });
    } catch (err) {
      throw err;
    } finally {
      commit('loading', false);
    }
  },

  async getTeamReport({ commit }, team) {
    if (team.report) return;
    try {
      const report = await api().get(`teams/${team.id}/related`);
      commit('update', { model: team, update: { report } });
    } catch (err) {
      throw err;
    }
  },

  async updateResourceTeams({}, { data, resourceId, path }) {
    try {
      const res = await api().put(`${path}/${resourceId}`, data);
    } catch (err) {
      throw err;
    }
  },

  safeReload() {
    const { origin, pathname } = window.location;

    if (pathname.includes('users/create')) {
      document.location.reload();
      return;
    }

    const getSliceIndex = () => {
      const deepCategories = ['/settings/'];
      let sliceIndex = 1;
      if (pathname.includes('ui/')) sliceIndex += 1;
      if (pathname.includes('ui2/b/')) sliceIndex += 3;

      return sliceIndex + deepCategories.some((i) => pathname.includes(i));
    };

    let path = origin + '/';
    path += pathname
      .split('/')
      .filter((i) => i)
      .slice(0, getSliceIndex())
      .join('/');

    window.location.href = path;
  },
});

export default module.create();
