import api from '@/factories/api';
import { getTime, format } from 'date-fns';
import { prepareMock } from './mockWorkflow';
import { triggerTypes } from '@/views/automations/automationsConfig';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';

export default {
  async loadTriggers({ commit, rootState }, campaignId = '') {
    commit('setTriggersLoaded', false);
    try {
      campaignId = campaignId || rootState.campaigns?.defaultCampaign?.id;
      if (!campaignId) return;

      const triggersData = await api().get(`triggers?campaign=${campaignId}`);
      if (!triggersData) return;

      const { results, schemas } = triggersData;
      const triggers = (results || []).map((trigger) => {
        trigger.status = getTriggerStatus(trigger);
        return trigger;
      });

      commit('setTriggersSchemas', schemas);
      commit('setTriggers', filterTriggers(triggers, schemas));
    } catch (err) {
      throw err;
    } finally {
      commit('setTriggersLoaded', true);
    }
  },

  async loadMockTriggers({ commit, getters }, campaignId) {
    const triggers = prepareMock(campaignId);
    commit('setTriggers', triggers);
  },

  async loadTriggerUsersCount({}, params) {
    return await api().get('triggers/count', { params });
  },

  async loadSingleTrigger({ commit }, triggerId) {
    try {
      const trigger = await api().get(`triggers/${triggerId}`);
      commit('updateTriggerInList', trigger);
    } catch (err) {
      throw err;
    }
  },

  async loadTriggerFeed({}, params) {
    try {
      return await api().get('schedule', { params });
    } catch (err) {
      throw err;
    }
  },

  async loadGroupTriggers({}, params) {
    try {
      const res = await api().get('triggers', { params });
      return res.results || [];
    } catch (err) {
      throw err;
    }
  },

  async createTrigger({ commit, rootState, dispatch }, trigger) {
    commit('setTriggerLoading', true);
    try {
      const defaultCampaignId = rootState.campaigns.defaultCampaign?.id;
      const data = prepareTriggerData(trigger, defaultCampaignId);
      const newTrigger = await api().post('triggers', data);
      dispatch('ensureExtraLessons', { trigger: newTrigger });
      commit('updateTriggerInList', newTrigger);
      return newTrigger;
    } catch (err) {
      throw err;
    } finally {
      commit('setTriggerLoading', false);
    }
  },

  async createTriggerCopy({ commit, dispatch }, trigger) {
    try {
      const newTrigger = await api().post('triggers', trigger);
      dispatch('ensureExtraLessons', { trigger: newTrigger });
      commit('updateTriggerInList', newTrigger);
    } catch (err) {
      throw err;
    }
  },

  async updateTrigger({ commit, dispatch, getters }, data) {
    commit('setTriggerLoading', true);
    const updates = cloneDeep(data);
    const currentTrigger = cloneDeep(getters.singleTrigger(data.id));
    try {
      // temp update
      commit('updateTriggerInList', updates);

      const editedTrigger = await api().put(`triggers/${data.id}`, updates);
      dispatch('ensureExtraLessons', { trigger: editedTrigger });
      dispatch('ensureNestedTriggers', {
        trigger: currentTrigger,
        updates: editedTrigger,
      });
      commit('updateTriggerInList', editedTrigger);
    } catch (err) {
      commit('updateTriggerInList', currentTrigger);
      throw err;
    } finally {
      commit('setTriggerLoading', false);
    }
  },

  async validateTrigger({}, automation) {
    try {
      return await api().post('triggers/validate', automation);
    } catch (err) {
      throw err;
    }
  },

  async deleteTrigger({ commit, getters, dispatch }, trigger) {
    try {
      await api().delete(`triggers/${trigger.id}`);
      let triggers = getters.triggers.filter((i) => i.id !== trigger.id);
      commit('setTriggers', triggers);
      dispatch('ensureExtraLessons', { trigger, removed: true });
    } catch (err) {
      throw err;
    }
  },

  ensureExtraLessons({ commit, getters, dispatch }, data) {
    const { trigger = {}, removed = false } = data;

    if (
      trigger.type !== 'lesson' ||
      (!trigger.parent_trigger && !trigger.include_extra_lessons)
    ) {
      return false;
    }

    // update nested triggers
    if (trigger.include_extra_lessons) {
      const nestedTriggers = getters.triggers.filter((i) => {
        return i.parent_trigger === trigger.id;
      });
      const timingOptions = extractTimingOptions(trigger);

      for (let childTrigger of nestedTriggers) {
        if (!isEqual(timingOptions, extractTimingOptions(childTrigger))) {
          dispatch('updateTrigger', {
            ...childTrigger,
            ...timingOptions,
          });
        }
      }
      return;
    }

    // update parent trigger
    if (trigger.parent_trigger) {
      const parentTrigger = getters.singleTrigger(trigger.parent_trigger);
      if (!parentTrigger) return;

      let childTriggers = (parentTrigger.child_triggers || []).slice();
      if (removed) {
        childTriggers = childTriggers.filter((i) => i !== trigger.id);
      } else {
        if (!childTriggers.includes(trigger.id)) {
          childTriggers.push(trigger.id);
        }
      }

      commit('updateTriggerInList', {
        ...parentTrigger,
        child_triggers: childTriggers,
      });
    }
  },

  async loadTriggersNames({ commit }) {
    try {
      const triggersNames = await api().get('triggers/names');
      commit('setTriggersNames', triggersNames);
    } catch (err) {
      throw err;
    } finally {
      commit('setTriggersNamesLoaded', true);
    }
  },

  resetTrigger({}, resetData) {
    try {
      api().post('triggers/reset', resetData);
    } catch (err) {
      throw err;
    }
  },

  async loadActivitySuggestions({ state, commit, dispatch }, data) {
    let { type, query = '' } = data || {};

    if (state.namesSuggestions[type] && !query) {
      return state.namesSuggestions[type];
    }

    commit('setSuggestionsLoading', true);
    try {
      query = query ? `?${query}` : '';
      const res = await api().get(`${type}/names${query}`);
      const names = prepareSuggestionsNames(res || []);
      if (!query) {
        commit('setNamesSuggestions', { type, names });
      }
      return names;
    } catch (err) {
      throw err;
    } finally {
      commit('setSuggestionsLoading', false);
    }
  },

  ensureNestedTriggers({ commit, getters, dispatch }, data) {
    const { trigger, updates } = data;
    const conditionalTypes = ['conditional', 'wait'];
    if (!conditionalTypes.includes(trigger.type)) return;
    if (isEqual(trigger.conditions, updates.conditions)) return;

    const findChanges = (arr1, arr2) => {
      const condition = arr1.find((i) => !arr2.some((c) => c.is === i.is));
      return condition?.is || null;
    };

    const triggerId = trigger.id;
    const oldIf = findChanges(trigger.conditions, updates.conditions);
    const newIf = findChanges(updates.conditions, trigger.conditions);

    if (!oldIf) return;

    const nestedTrigger = getters.triggers.find(({ wf_if, wf_pid }) => {
      return wf_pid === triggerId && wf_if === oldIf;
    });

    if (!nestedTrigger) return;

    // delete all nested nodes if condition was removed
    if (!newIf) {
      const nestedTriggers = getters.getNestedTriggers(nestedTrigger.id);
      nestedTriggers.unshift(nestedTrigger);
      nestedTriggers.forEach((trigger) => {
        dispatch('deleteTrigger', { id: trigger.id });
      });
      return;
    }

    // update all nested nodes if condition label was changed
    dispatch('updateTrigger', {
      ...nestedTrigger,
      wf_if: newIf,
    });
  },
};

export const AUTOMATIONS_STATUSES = {
  draft: 'draft',
  paused: 'paused',
  scheduled: 'scheduled',
  finished: 'finished',
  live: 'live',
};

const filterTriggers = (triggers, schemas) => {
  return triggers.filter((trigger) => {
    return triggerTypes.some(
      ({ type }) => type === trigger.type && schemas[trigger.type]
    );
  });
};

export const getTriggerStatus = (trigger) => {
  if (trigger.draft) return AUTOMATIONS_STATUSES.draft;
  if (trigger.paused) return AUTOMATIONS_STATUSES.paused;
  if (!trigger.start_time || trigger.start_time > getTime(new Date()) / 1000)
    return AUTOMATIONS_STATUSES.scheduled;
  if (trigger.end_time && trigger.end_time < getTime(new Date()) / 1000)
    return AUTOMATIONS_STATUSES.finished;
  return AUTOMATIONS_STATUSES.live;
};

const prepareTriggerData = (trigger, defaultCampaignId) => {
  trigger.draft = true;

  if (!trigger.campaign_id) {
    trigger.campaign_id = defaultCampaignId;
  }

  // For automations
  if (trigger.campaign_id === defaultCampaignId) {
    trigger.start_date = format(new Date(), 'yyyy-MM-dd');
    trigger.start_at = '09:00';
  }

  return trigger;
};

const prepareSuggestionsNames = (names) => {
  if (!names || !names.length) {
    return [];
  }

  return names
    .sort((a, b) => a.group - b.group)
    .reduce((acc, item) => {
      if (!acc.find((i) => i.header === item.group) && item.group) {
        acc.push({ header: item.group });
      }
      acc.push({
        value: item.id,
        text: item.name || item.id,
      });
      return acc;
    }, []);
};

const extractTimingOptions = (trigger) => {
  return pick(trigger, ['start_data', 'join_delay', 'start_at', 'delay']);
};
