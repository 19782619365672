import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    sidebarOpen: false,
    infoMessage: null,
    previousRoute: null,
    pageLoading: false,
    installAppPrompt: null,
  },

  mutations,
  actions,
  getters,
};
