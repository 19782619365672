const init = () => {
  let w = window;
  let d = document;

  const loadEmbedly = () => {
    let id = 'embedly-platform';
    let n = 'script';

    if (!d.getElementById(id)) {
      w.embedly =
        w.embedly ||
        function () {
          (w.embedly.q = w.embedly.q || []).push(arguments);
        };
      var e = d.createElement(n);
      e.id = id;
      e.async = 1;
      e.src =
        ('https:' === document.location.protocol ? 'https' : 'http') +
        '://cdn.embedly.com/widgets/platform.js';
      var s = d.getElementsByTagName(n)[0];
      s.parentNode.insertBefore(e, s);
    }
  };

  if (document.readyState === 'complete') {
    loadEmbedly();
  } else {
    w.addEventListener('load', loadEmbedly, false);
  }
};

export default init;
