import { configureTrackJS } from '@/lib/trackjs';

export default {
  setProfile: (state, profile) => {
    state.profile = profile;
  },
  setProfilePid: (state, pid) => {
    configureTrackJS(pid);
    state.profilePid = pid;
  },
  setProfileLoading: (state, loading) => {
    state.profileLoading = loading;
  },
  setProfileUpdating: (state, updating) => {
    state.profileUpdating = updating;
  },
  updateProfileField: (state, { field, value }) => {
    if (!state.profile) return;
    state.profile[field] = value;
  },

  setScormParameters: (state, parameters) => {
    state.scormParameters = parameters;
  },
};
