import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    currentSearch: '',
    searchResults: [],
    loadingSearch: false,
  },

  mutations,
  actions,
  getters,
};
