import mediaFields from './mediaFields';

export default {
  type: 'poll',
  title: 'Poll',
  engagement: true,
  icon: 'poll',
  help: 'Poll on opinions, prior knowledge, etc.',
  schema: {
    question: {
      type: 'TextArea',
      title: 'Question',
      help: 'Question you want to ask.',
      default: 'What is your favorite color?',
    },
    ...mediaFields,
    answers: {
      type: 'List',
      title: 'Answers',
      default: ['chartreuse', 'mauve', 'teal'],
    },
    include_points: {
      title: 'Include Points',
      type: 'Checkbox',
      default: true,
      help: 'Include points earned in this question on the score leaderboard.',
    },
    participation_points: {
      title: 'Participation Points',
      type: 'Number',
      default: 1200,
      showOnHaving: 'include_points',
    },
    hide_results: {
      title: 'Hide Results',
      type: 'Checkbox',
      default: false,
      help: 'Check this to true, if you don`t want to share the total results to others.',
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },
  maxPoints(v) {
    return v.include_points ? v.participation_points : 0;
  },
};
