import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    groups: [],
    groupsLoaded: false,
    groupContactsLoading: false,
    groupContactsInfo: [],
    groupLoading: false,
    groupSearch: null,
    groupsView: '',
    groupsFolders: null,
  },

  mutations,
  actions,
  getters,
};
