import api from '@/factories/api';

const fetchAll = async ({ commit, state }, { path, formatter = null }) => {
  try {
    let records = await api().get(path);

    if (formatter) {
      records = formatter(records);
    }

    commit('set', 'all', records);
  } catch (error) {
    throw error;
  }
};

export { fetchAll };
