<template>
  <a :href="fullRoute" class="help-link" target="_blank">
    <v-icon small color="primary" v-if="!hideIcon">help</v-icon>
    <slot />
  </a>
</template>

<script>
export default {
  name: 'HelpLink',
  props: {
    route: String,
    hideIcon: Boolean,
  },

  computed: {
    fullRoute() {
      return `https://conveyour.com/help/${this.route}`;
    },
  },
};
</script>

<style lang="less">
.help-link {
  text-decoration: none;

  .v-icon {
    margin-bottom: 2px;
  }
}
</style>
