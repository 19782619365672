import Resource from './common/resource';
import defaultBanners from '@/data/banners';

class Files extends Resource {
  path = 'files';

  onLoad({ state, commit }, { res }) {
    const files = res.data || [];
    const defaultBanners = prepareDefaultBanners();
    files.push(...defaultBanners);

    commit('setAll', files);
  }
}

const prepareDefaultBanners = () => {
  return defaultBanners.map((banner) => {
    return {
      cdn: getBannerUrl(banner),
      extension: 'jpg',
      filename: banner,
      mimetype: 'image/jpeg',
      type: 'banner',
      url: getBannerUrl(banner),
      default: true,
    };
  });
};

const getBannerUrl = (src) => {
  const base = 'https://throwbocdn.s3.amazonaws.com/banners/';
  return src.indexOf('http') === 0 ? src : base + src;
};

export default Files;
