export default {
  type: 'video',
  title: 'Video',
  icon: 'video',
  help: 'We support Vimeo, Youtube, Wistia, Loom videos',
  schema: {
    url: {
      type: 'Text',
      default: 'https://www.youtube.com/watch?v=zlRl8sJU_4I',
      // sprout: https://videos.sproutvideo.com/embed/189adcb31f19e8c990/b923069cb05ab66b
      // vimeo: https://vimeo.com/145000185
      // wistia: https://fast.wistia.com/medias/j38ihh83m5
      title: 'Paste Video URL',
      help: "Enter your video URL and we'll do all the heavy lifting.",
      placeholder: 'Video URL',
      filters: ['video'],
    },

    gallery_url: {
      type: 'Video',
      title: 'Pick video from Media gallery ',
    },

    standalone: {
      title: 'Showcase this video by itself',
      type: 'Checkbox',
      default: true,
    },

    required: {
      title: 'Must watch',
      type: 'Checkbox',
      default: false,
      help: '',
      checkDisabled: (item) => {
        if (!item) return false;
        const provider = item.values?.summary?.provider_name || '';
        return ['Loom', 'Sharepoint'].includes(provider);
      },
    },

    custom_range: {
      title: 'Custom start and stop range',
      type: 'Checkbox',
      default: false,
      help: 'Set a custom start and stop time to show a specific clip',
      checkDisabled: (item) => {
        if (!item) return false;
        const provider = item.values?.summary?.provider_name || '';
        return ['Loom', 'Sharepoint'].includes(provider);
      },
    },

    start: {
      title: 'Define clip',
      type: 'Range',
      default: 0,
      format: 'time',
      showOnHaving: 'custom_range',
      end_field: 'end',
      range: {
        max: '=summary.length',
      },
    },
    end: {
      title: 'End Time',
      hidden: true,
      default: 0,
      type: 'Number', // This really doesn't matter, but need something or it errors out
    },

    id: { type: 'Text', hidden: true },
    type: { type: 'Text', hidden: true },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },

    aspect_ratio: {
      type: 'Select',
      title: 'Aspect ratio',
      default: '',
      options: [
        {
          text: '16:9 - desktop default',
          value: '',
        },
        {
          text: '4:5 - mobile',
          value: '4-5',
        },
        {
          text: '1:1 - mobile square',
          value: '1-1',
        },
        {
          text: '9:16 - mobile portrait',
          value: '9-16',
        },
      ],
    },
  },
};
