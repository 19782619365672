<template>
  <v-dialog
    v-model="show"
    :max-width="500"
    v-hotkey="globalKeyMap"
    @keydown.esc="show = false"
  >
    <v-card class="command-container" ref="container">
      <div style="min-height: 4px">
        <v-progress-linear indeterminate v-if="loading" />
      </div>
      <v-card-title>
        <v-btn small text class="mr-2" @click="reset" v-if="parentCommand">
          <v-icon>chevron_left</v-icon>
        </v-btn>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="search"
          @keydown.meta.k="toggleShow"
          @keydown.ctrl.k="toggleShow"
          @keydown.up="traverse(-1)"
          @keydown.down="traverse(1)"
          @keydown.enter="runCommand"
          @paste="onPaste"
          autofocus
          :autocomplete="false"
          outlined
          height="14"
          class="command-search"
          :placeholder="placeholder"
        >
        </v-text-field>
        <div
          v-for="cmd in filteredCommands"
          :key="cmd.id"
          @mouseenter="selectCommand(cmd)"
          @click="runCommand(cmd)"
        >
          <content-line
            :text="cmd.title"
            :icon="cmd.icon"
            :font-icon="cmd.fontIcon"
            :tooltip="!!cmd.tooltipText"
            :tooltip-text="cmd.tooltipText"
            class="command"
            :class="commandClasses(cmd)"
          >
            <template v-slot:actions v-if="cmd.actions">
              <component
                :is="cmd.actions"
                :cmd="cmd"
                :ref="cmd.id"
                @close="toggleShow()"
              />
            </template>
          </content-line>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import clone from 'lodash/clone';

import Command from './command';
// universal search replaces
// import SearchContacts from './searchContacts';
import CreateContact from './createContact';
import CreateTask from './createTask';
import OpenChatBoat from './openChatBoat';
import { navigation } from '@/components/layouts/sidebar/sidebarLinks.js';
import SwitchTeams from './switchTeams';
import OmniSearch from './omniSearch';

import EventBus from '@/factories/eventBus';
import { setCommandInstance } from './kit';

const registeredCommands = [
  CreateContact,
  CreateTask,
  SwitchTeams,
  OpenChatBoat,
];

import { mapGetters } from 'vuex';

const title = 'ConveYour Commands';
const placeholder = 'Search Commands, use ↓↑';

export default {
  name: 'CommandModal',
  data() {
    return {
      title,
      show: false,
      search: '',
      placeholder,
      selectedCommand: null,
      parentCommand: null,
      commands: [],
      loading: false,
      tempCommands: [],
      searchCommand: null,
    };
  },

  computed: {
    ...mapGetters('users', ['currentUser']),
    globalKeyMap() {
      return {
        'command+k': this.toggleShow,
        'ctrl+k': this.toggleShow,
      };
    },
    filteredCommands() {
      let search = this.search
        .toLowerCase()
        .trim()
        .replace(' is ', ' = ')
        .split('=')
        .pop()
        .trim();

      let filteredCommands = [];

      let commands = this.tempCommands.length
        ? this.tempCommands
        : this.commands;
      //purposefully using forEach instead of filter
      //in case we want to mutate the cmd
      commands.forEach((cmd) => {
        cmd = clone(cmd);

        cmd.title = this.getCommandValue(cmd, 'title');

        if (this.parentCommand) {
          if (cmd.pid !== this.parentCommand.id) {
            return false;
          }
        }

        if (cmd.permission && !this.hasPermission(cmd.permission)) {
          return false;
        }

        if (cmd.if && !cmd.if()) {
          return false;
        }

        let searchTerms = (cmd.searchTerms || []).slice(0);
        searchTerms.push(cmd.title);
        searchTerms = searchTerms.join(' ').toLowerCase();

        let match = !search || searchTerms.includes(search);

        if (!match) return;

        filteredCommands.push(cmd);
      });

      return filteredCommands;
    },
  },

  watch: {
    show(shown) {
      if (shown) {
        this.cleanCommands();
        this.$emit('shown');
        this.$nextTick(() => {
          this.scrollToActive();
        });
        return true;
      }

      if (!shown) {
        setTimeout(() => {
          this.toggleTempSettings();
        }, 300);
      }
      //put in a timeout to help filter out commands
      //that were for a different route
      setTimeout(() => {
        this.reset();
      }, 300);
    },
    commands() {
      this.selectedCommand = this.filteredCommands[0];
    },
    search() {
      this.searchChanged();
    },
  },

  methods: {
    reset() {
      this.title = title;
      this.placeholder = placeholder;
      this.search = '';
      this.parentCommand = null;
      this.cleanCommands();
    },

    cleanCommands() {
      let routeName = this.$route.name;
      this.commands = this.commands.filter((cmd) => {
        if (cmd.routeName && cmd.routeName !== routeName) {
          return false;
        }
        return !cmd.pid;
      });
    },

    toggleShow() {
      this.show = !this.show;
    },
    onKeyDown(event) {
      console.log({ event });
    },
    traverse(dir) {
      let commands = this.filteredCommands;
      let index = 0;

      if (this.selectedCommand) {
        let commandIndex = commands.findIndex((cmd) => {
          return cmd.id === this.selectedCommand.id;
        });
        if (commandIndex !== -1) {
          index = commandIndex;
        }
      }

      index += dir;

      if (index < 0) {
        index = 0;
      } else if (index + 1 > commands.length) {
        index = commands.length - 1;
      }

      let selectedCommand = commands[index];

      if (selectedCommand) {
        this.selectedCommand = selectedCommand;
        this.$nextTick(() => {
          this.scrollToActive({
            block: 'center',
          });
        });
      }
    },

    scrollToActive() {
      let el = this.$refs.container?.$el;
      if (!el) return;

      let active = el.querySelector('.active');
      if (!active) return;

      let elBounds = el.getBoundingClientRect();
      let activeBounds = active.getBoundingClientRect();

      if (active.offsetTop + activeBounds.height > elBounds.height) {
        el.scrollTop = active.offsetTop - activeBounds.height;
      } else {
        el.scrollTop = 0;
      }
    },

    commandClasses(cmd) {
      return {
        active: this.selectedCommand?.id === cmd.id,
      };
    },

    selectCommand(cmd) {
      this.selectedCommand = cmd;
    },

    async runCommand() {
      let cmd = this.selectedCommand;
      if (!cmd || !cmd.run) return;
      let ran = await cmd.run();
      if (ran === true) {
        this.toggleShow();
      }
    },

    getCommandValue(obj, key) {
      if (typeof obj[key] === 'function') {
        return obj[key]();
      }
      return obj[key];
    },

    registerStoreModule(ns, module) {
      if (this.$store.hasModule(ns)) {
        console.log(`${ns} already registered`);
        return false;
      }
      this.$store.registerModule(ns, module.create());
      return true;
    },

    initCommands() {
      let commands = [];
      registeredCommands.forEach((Cmnd) => {
        let cmd = new Cmnd();
        cmd.setVM(this);
        if (cmd.init && !cmd.init()) {
          return false;
        }
        commands.push(cmd);
      });

      navigation.forEach((link) => {
        if (link.check && this.hasPermission(link.check)) return;
        if (link.disabled) return;

        let cmd = new Command({
          id: link.path,
          title: link.text,
          icon: `sidebar/${link.icon}`,
          run() {
            this.vm.$router.push(link.path);
            return true;
          },
        });

        cmd.setVM(this);

        commands.push(cmd);
      });

      this.commands = commands;
    },

    addCommands(newCommands, action = 'prepend', temp = true) {
      let commands = this.commands.slice();

      let method = action === 'prepend' ? 'unshift' : 'push';

      newCommands.forEach((cmd) => {
        if (temp) {
          cmd.routeName = this.$route.name;
        }

        cmd = cmd.isCommand ? cmd : new Command(cmd);
        cmd.setVM(this);
        if (cmd.init && !cmd.init()) {
          return false;
        }
        commands = commands.filter((existing) => existing.id !== cmd.id);
        commands[method](cmd);
      });

      this.commands = commands;
    },

    toggleTempSettings(data) {
      this.tempCommands = data?.tempCommands || [];
    },

    initSearch() {
      let cmd = new OmniSearch();
      cmd.setVM(this);
      if (cmd.init) {
        cmd.init();
      }

      this.searchCommand = cmd;
    },
    searchChanged() {
      if (this.parentCommand?.asParent) {
        return this.parentCommand.asParent();
      }

      this.selectedCommand = this.filteredCommands[0];

      if (!this.selectedCommand) {
        this.searchCommand.run();
      }
    },
    onPaste(event) {
      const self = this;
      setTimeout(() => {
        self.searchChanged();
      }, 100);
    },
  },

  mounted() {
    this.initCommands();
    EventBus.$on('toggleCommandModal', (data) => {
      this.toggleShow();
      this.toggleTempSettings(data);
    });
  },

  created() {
    //we use a singleton on this command vue
    //so we are going to set sort of a global
    //that will be used as the sdk
    setCommandInstance(this);
    this.initSearch();
    window.cmdk = this;
  },
};
</script>

<style lang="less">
.command-container {
  min-height: 260px;
  height: 50vh;
  overflow-y: scroll;
}

.command-search .v-input__slot {
  padding: 0 12px !important;
}

.command {
  cursor: pointer;
  border: 2px solid white;
  border-radius: 3px;

  &.active {
    background-color: var(--v-lightGray-base);
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
}
</style>
