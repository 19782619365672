import Module from '@/store/resource/common/module';
import EmailAddresses from '@/store/resource/emailAddresses';
import api from '@/factories/api';

let module = new Module(new EmailAddresses());

module.add('actions', {
  async verifyEmail({ commit }, { id, data = {} }) {
    try {
      const res = await api().put(`senders/verify/${id}`, data);
      commit('update', {
        model: { id },
        update: res,
      });
    } catch (err) {
      throw err;
    }
  },
});

export default module.create();
