import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('automations');

const automationsRoutes = [
  {
    path: '/automations',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '',
        name: 'automations',
        meta: { showNav: true },
        component: loadView('Automations'),
      },
      {
        path: 'create',
        props: true,
        name: 'automation-create',
        component: loadView('AutomationCreate'),
      },
      {
        path: 'edit/:id',
        props: true,
        name: 'automation-edit',
        component: loadView('form/AutomationForm'),
      },
      {
        path: 'confirm',
        name: 'confirm',
        props: true,
        component: loadView('confirm/AutomationConfirm'),
      },
    ],
  },
];

export default automationsRoutes;
