<template>
  <v-menu right offset-x content-class="secondary-nav" v-model="isMenuOpen">
    <template v-slot:activator="{ on }">
      <div
        v-on="on"
        class="secondary-nav__trigger"
        :class="{ active: isMenuOpen }"
      >
        <app-sidebar-link :link="moreLink" :hideTooltips="disabledTooltip" />
      </div>
    </template>
    <v-list>
      <v-list-item v-for="(link, idx) in navigation" :key="idx" :to="link.path">
        <v-list-item-icon>
          <icon-base
            :fontIcon="link.fontIcon"
            :icon="link.fontIcon ? link.icon : `sidebar/${link.icon}`"
          />
        </v-list-item-icon>
        <v-list-item-title class="pr-2">
          {{ link.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { secondaryNavigation } from '../sidebarLinks';
import AppSidebarLink from '@/components/layouts/sidebar/links/AppSidebarLink.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SecondaryNav',
  components: {
    AppSidebarLink,
  },
  props: {
    disabledTooltip: Boolean,
  },
  data() {
    return {
      isMenuOpen: false,
      moreLink: {
        text: 'More',
        icon: 'more',
        path: '/more',
        disabled: true,
      },
    };
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
    navigation() {
      const userRole = this.currentUser?.role;

      return secondaryNavigation.filter((link) => {
        const { permission, roles = [] } = link || {};

        if (permission && !this.isFeatureEnabled(permission)) {
          return false;
        }

        if (roles?.length && !roles.includes(userRole)) {
          return false;
        }

        return true;
      });
    },
  },
};
</script>

<style lang="less">
.secondary-nav {
  .v-list-item {
    display: flex;
    align-items: center;
    color: var(--v-appMain-base) !important;
    font-weight: 600;
  }
  .v-list-item__icon {
    margin: 12px 0;
  }
}

.secondary-nav__trigger {
  &.active {
    background-color: var(--v-background-base);
    position: relative;
  }
}
</style>
