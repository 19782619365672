import Vue from 'vue';
import Router from 'vue-router';
import { vueEnv } from '@/globals';
import store from '@/store';
import { loadView } from '@/router/routerHelpers';
import { baseRoute } from '@/globals';
import EventBus from '@/factories/eventBus';
import qs from 'qs';

// layouts
import AppLayout from '@/components/layouts/AppLayout';
import SharedLayout from '@/components/layouts/SharedLayout';

// portal
import portalRoutes from '@/router/routes/portalRoutes';

// app routes
import Home from '@/views/home/Home';
import automationsRoutes from '@/router/routes/automationsRoutes';
import contactsRoutes from '@/router/routes/contactsRoutes';
import campaignsRoutes from '@/router/routes/campaignsRoutes';
import conversationsRoutes from '@/router/routes/conversationsRoutes';
import groupsRoutes from '@/router/routes/groupsRoutes';
import reportsRoutes from '@/router/routes/reportsRoutes';
import settingsRoutes from '@/router/routes/settingsRoutes';
import snippetsRoutes from '@/router/routes/snippetsRoutes';
import lessonsRoutes from '@/router/routes/lessonsRoutes';
import tasksRoutes from '@/router/routes/tasksRoutes';
import customObjectsRoutes from '@/router/routes/customObjectsRoutes';

import authRoutes from '@/router/routes/authRoutes';

// shared
import sharedReportsRoutes from '@/router/routes/sharedReports';
import billingRoutes from '@/router/routes/billingRoutes';

// apex rooms
import addRoomsRoutes from '@/components/rooms/roomsRouter';

Vue.use(Router);

const routes = [
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '/',
        name: 'home',
        meta: { showNav: true },
        component: Home,
      },
      {
        path: '/welcome',
        name: 'Welcome',
        meta: { showNav: true },
        component: Home,
      },
      {
        path: '/profile',
        name: 'Profile',
        meta: { showNav: true },
        component: loadView('settings/users/UserEdit'),
      },
      ...automationsRoutes,
      ...contactsRoutes,
      ...campaignsRoutes,
      ...groupsRoutes,
      ...conversationsRoutes,
      ...settingsRoutes,
      ...reportsRoutes,
      ...snippetsRoutes,
      ...lessonsRoutes,
      ...tasksRoutes,
      ...customObjectsRoutes,
      {
        path: '/404',
        name: 'NotFound',
        component: loadView('NotFoundPage'),
      },
    ],
  },
  {
    path: '/',
    component: SharedLayout,
    children: [...sharedReportsRoutes, ...billingRoutes],
  },
  ...authRoutes,
];

if (vueEnv('include_portal')) {
  routes.push(portalRoutes);
}

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    // ScrollTop for mobile
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    return { x: 0, y: 0 };
  }
};

let router = new Router({
  mode: 'history',
  base: baseRoute(),
  scrollBehavior,
  routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? '?' + result : '';
  },
});

const hasAccessToPage = (meta) => {
  if (!store.getters['users/currentUserLoaded']) return true;
  return store.getters['users/hasAccessToPage'](meta);
};

addRoomsRoutes(router);

const onBeforeEach = (to, from, next) => {
  const { fullPath, meta, matched, name } = to || {};

  if (!name && !matched.length) {
    next('/404');
  }

  if (!hasAccessToPage(meta)) {
    next('/');
  }

  // Redirect if fullPath begins with a hash (ignore hashes later in path)
  if (fullPath.substr(0, 2) === '/#') {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }

  // addition for our LessonField component
  if (document.querySelector('.lesson-modal.v-dialog--active')) {
    EventBus.$emit('changeComponent', to);
    return;
  }

  store.commit('setPreviousRoute', from);
  next();
};

router.beforeEach(onBeforeEach);

export default router;
