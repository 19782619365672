import { updateArrInStore } from '@/store/storeHelpers';

export default {
  setCourses: (state, { courses, schemaLevel }) => {
    courses = prepareLessons(courses, schemaLevel);
    state.courses = courses;
  },
  setLoadingSingleCourse: (state, loading) => {
    state.loadingSingleCourse = loading;
  },
  setLoadingCourses: (state, loading) => {
    state.loadingCourses = loading;
  },
  setPortalFolders: (state, folders) => {
    state.portalFolders = folders;
  },
  setPortalFoldersLoaded: (state, loaded) => {
    state.portalFoldersLoaded = loaded;
  },
  setPortalFolderLessonLoading: (state, loading) => {
    state.portalFolderLessonLoading = loading;
  },
  updatePortalFolder: (state, folder) => {
    updateArrInStore(state, folder, 'portalFolders');
  },
};

const prepareLessons = (arr, schemaLevel) => {
  return arr
    .filter((i) => !!i.triggers_count)
    .map((course) => {
      if (schemaLevel) {
        course.schemaLevel = schemaLevel;
      }

      let triggers = [];

      (course.triggers || []).forEach(function (t) {
        if (t.type !== 'lesson') {
          return;
        }

        t.banner_img = t.banner_img || course.banner_img;
        triggers.push(t);
      });

      course.triggers = triggers;

      return course;
    });
};
