export default {
  type: 'custom',
  title: 'Custom Content',
  icon: 'custom',
  help: 'Create custom content using HTML,CSS, JS',
  engagement: true,
  schema: {
    url: {
      type: 'Text',
      default:
        'https://app.conveyour.com/cyfiles/custom-content/lesson-custom-code/index.vue',
      title: 'URL of custom code',
      help: 'You can embed just about anything here like a soundcloud URL, a twitter profile, or an amazon product',
    },
    can_advance: {
      type: 'Checkbox',
      default: false,
      title: 'Can Advance in Lesson',
      help: 'Do not gate the completion of this custom content.',
    },
  },
};
