import VideoPlayer from './VideoPlayer.js';

class HtmlPlayer extends VideoPlayer {
  _loadApi() {
    return import(/* webpackChunkName: "video.js" */ 'video.js').then(
      (player) => {
        window.videojs = player.default;
      }
    );
  }

  _apiLoaded() {
    return !!window.videojs;
  }

  _bindApi() {
    this.id = `video${this.id}`;
    let player = (this.player = videojs(this.id));

    player.on('loadedmetadata', () => {
      this.trigger(this.ready_event);
    });

    player.on('ended', () => {
      this.stop();
    });

    this.setStart();

    player.on('timeupdate', () => {
      this.setLatestPlayTime(this.player.currentTime());
    });

    player.on('playbackrateschange', (e) => {
      console.log('playbackrateschange', e);
    });

    player.on('ratechange', () => {
      this.savePlaybackRate(this.player.playbackRate() || 1);
    });

    this.once('ready', () => {
      this._seek(this.latestPlayTime);
    });
  }

  _changePlaybackRate(speed = 1) {
    this.player.playbackRate(speed);
  }

  _duration() {
    return this.player.duration();
  }

  _play() {
    this.player.play();
  }

  _pause() {
    this.player.pause();
  }

  _stop() {
    this._pause();
  }

  _seek(seconds) {
    this.player.currentTime(seconds);
  }

  buildHtml() {
    let type = /\.m3u8/.test(this.url) ? 'application/x-mpegURL' : 'video/mp4';
    let preload = this.opts.preload || 'auto';

    return `<video class="video-js" id="video${this.id}" controls preload="${preload}" data-setup='{ "playbackRates": [0.5, 1, 1.5, 2] }'>
              <source src="${this.url}" type="${type}"/>
            </video>`;
  }
}

let htmlPlayerRegex = /\.(mp4|m3u8)$/;
HtmlPlayer.test = function (url) {
  if (url.indexOf('cdn.filestack') > 0) {
    return true;
  }

  return url.match(htmlPlayerRegex);
};

export default HtmlPlayer;
