import { updateArrInStore } from '@/store/storeHelpers';
import clone from 'lodash/clone';

export default {
  setMessages: (state, messages) => {
    state.messages = messages;
  },
  setMessagesLoaded: (state, loading) => {
    state.messagesLoaded = loading;
  },
  setMessageLoading: (state, loading) => {
    state.messageLoading = loading;
  },
  updateMessageInList: (state, message) => {
    updateArrInStore(state, message, 'messages');
  },
  setThreads: (state, threads) => {
    state.threads = threads.map((thread) => {
      return {
        ...thread,
        occupied: false,
        selected: false,
      };
    });
  },
  setThreadsScrollPosition: (state, scrollPosition) => {
    state.threadsScrollPosition = scrollPosition;
  },
  setThreadsFilters: (state, filters) => {
    const objFilters = clone(state.threadsFilters);
    for (let key in filters) {
      if (filters[key]) {
        objFilters[key] = filters[key];
      } else {
        delete objFilters[key];
      }
    }
    state.threadsFilters = objFilters;
  },
  setThreadsLoading: (state, loading) => {
    state.threadsLoading = loading;
  },
  updateThread: (state, { id, thread_id, data }) => {
    let threads = state.threads || [];
    let threadIdx = threads.findIndex((thread) => {
      return thread.id === id || (thread_id && thread.thread_id === thread_id);
    });
    if (threadIdx !== -1) {
      threads[threadIdx] = Object.assign(threads[threadIdx], data);
    }
  },
  updateThreadPerson: (state, thread) => {
    let threads = state.threads || [];
    threads.forEach((t) => {
      if (t.type_id === thread.type_id) {
        t.name = thread.name;
      }
    });
  },
  setMessagesSchemas: (state, schemas) => {
    state.messagesSchemas = schemas;
  },
  setMessagesSchemasLoaded: (state) => {
    state.messagesSchemasLoaded = true;
  },
  setPortalRecipients: (state, recipients) => {
    state.portalRecipients = recipients;
    state.portalRecipientsLoaded = true;
  },
  setConversation: (state, conversation = []) => {
    state.conversation = conversation;
  },
  setConversationLoading: (state, loading = false) => {
    state.conversationLoading = loading;
  },
  updateConversation: (state, message) => {
    const messageIdx = state.conversation.findIndex(({ id, tempId }) => {
      return id === message.id || (message.tempId && message.tempId === tempId);
    });

    if (messageIdx > -1) {
      const mergedMessage = {
        ...state.conversation[messageIdx],
        ...message,
        useTransition: false,
      };
      state.conversation.splice(messageIdx, 1, mergedMessage);
    } else {
      state.conversation.push(message);
    }
  },
};
