import mediaFields from './mediaFields';
import feedbackFields from './feedbackFields';

export default {
  type: 'challenge',
  title: 'Challenge Question',
  icon: 'target',
  help: 'Ask a question with only one correct answer.',
  engagement: true,
  schema: {
    question: {
      type: 'TextArea',
      title: 'Question',
      help: 'Question you want to challenge with.',
      default: 'What is the correct answer?',
    },

    ...mediaFields,

    answers: {
      type: 'List',
      title: 'Answers',
      default: ['wrong', 'nope', 'correct'],
    },

    correct: {
      type: 'ListSelect',
      list: 'answers',
      title: 'Correct Answer',
      help: 'Select the correct answer',
      default: 'correct',
      placeholder: '-- Correct Answer --',
      multiple: true,
    },

    single_submission: {
      title: 'Single Submission',
      type: 'Checkbox',
      default: false,
      help: 'All answers submitted together instead of individually.',
    },

    include_points: {
      title: 'Include Points',
      type: 'Checkbox',
      default: true,
      help: 'Include points earned in this question on the score leaderboard.',
    },

    max: {
      title: 'Max Points',
      type: 'Number',
      default: 1200,
      showOnHaving: 'include_points',
    },

    wrong_answer: {
      title: 'Wrong Answer',
      type: 'Number',
      default: 150,
      help: 'Subtract points each time someone chooses the wrong answer.',
      showOnHaving: 'include_points',
    },

    speed_bonus: {
      title: 'Speed bonus',
      type: 'Checkbox',
      default: true,
      help: 'Give users bonus points for answering quickly',
      showOnHaving: 'include_points',
    },

    ...feedbackFields,

    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },

  maxPoints(v, def) {
    return v.include_points ? v.max : 0;
  },
};
