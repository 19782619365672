// Models, only available for nesting, don't appear in sidebar
export default {
  model: true,
  type: 'StatsQuestion',
  schema: {
    question: {
      type: 'Text',
      title: 'Question',
      help: 'Your question like "How well did you perform X today?"',
      default: 'On a scale from 1-10, how well did you perform today?',
    },
    name: {
      type: 'Text',
      title: 'Identifier',
      help: "A SHORT single word (or word combination) that will be used as reference to this question's answers.",
      placeholder: 'performance',
      default: 'performance',
      filters: ['tag'],
    },
    //this is answer_type in qa/tracker
    answer_type: {
      type: 'ListSelect',
      title: 'Format',
      help: 'How should the answer be recorded?',
      list: {
        number: 'as a number',
        slider: 'as a slider with a scale from 1-10',
      },
      default: 'slider',
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },
};
