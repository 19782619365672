import mediaFields from './mediaFields';
export default {
  type: 'order',
  icon: 'order',
  title: 'Order Challenge',
  help: 'Challenge to place items in correct order.',
  engagement: true,
  schema: {
    question: {
      type: 'TextArea',
      title: 'Question',
      help: 'Question you want to ask.',
      default: 'Reorder this unordered list into the correct order.',
    },
    ...mediaFields,
    answers: {
      type: 'List',
      title: 'Ordered List',
      default: ['first', 'second', 'third'],
    },
    include_points: {
      title: 'Include Points',
      type: 'Checkbox',
      default: true,
      help: 'Include points earned in this question on the score leaderboard.',
    },
    max: {
      title: 'Max Points',
      type: 'Number',
      default: 1200,
      showOnHaving: 'include_points',
    },
    wrong_answer: {
      title: 'Wrong Answer',
      type: 'Number',
      default: 150,
      help: 'Subtract points each time someone chooses the wrong answer.',
      showOnHaving: 'include_points',
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },

  maxPoints(v) {
    return v.include_points ? v.max : 0;
  },
};
