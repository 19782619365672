<template>
  <div class="item-card" v-show="!completed">
    <content-line icon="sidebar/settings" text="Important Steps" class="pr-1" />
    <div class="item-card__content pt-0">
      <p @click.stop="confirmEmail" v-if="!hasEmail">
        <v-checkbox
          label="Confirm your email address."
          :value="hasEmail"
          readonly
        />
      </p>

      <div v-if="!smsNumberSet">
        <p>
          <b>Optionally</b> select a phone line for your user profile. This
          phone line can be used to send and receive texts in ConveYour.
          <small>
            <a href="https://conveyour.com/help/messaging-faq" target="_blank"
              >FAQ</a
            >
          </small>
        </p>
        <p @click.stop="loadPhoneNumberSettings">
          <v-checkbox
            label="Setup ConveYour SMS Text Line."
            :value="smsNumberSet"
            readonly
          />
        </p>
      </div>
    </div>

    <v-dialog
      :value="!!activeModal"
      @input="toggleModal()"
      max-width="600"
      content-class="fixed-height-lg"
    >
      <component :is="activeModal" @close="toggleModal()" />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SetPhoneLine from '@/views/settings/users/components/setPhoneLine/SetPhoneLine';

export default {
  name: 'HomeSteps',
  components: {
    SetPhoneLine,
  },
  data() {
    return {
      activeModal: '',
    };
  },
  computed: {
    ...mapGetters('users', [
      'currentUser',
      'currentUserLoaded',
      'isAdmin',
      'isFeatureEnabled',
    ]),
    hasEmail() {
      return this.currentUser.activated;
    },
    smsNumberSet() {
      if (this.isFeatureEnabled('teams')) return true;

      const { twilio_phone, twilio_ms_id } = this.currentUser;
      return twilio_phone || twilio_ms_id;
    },
    completed() {
      return !!(this.hasEmail && this.smsNumberSet);
    },
  },
  methods: {
    confirmEmail() {
      this.onParent('confirmEmail', (action) => action());
    },
    loadPhoneNumberSettings() {
      this.toggleModal('SetPhoneLine');
    },
    toggleModal(modal) {
      this.activeModal = modal;
    },
  },
};
</script>
