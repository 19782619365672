import { loadView } from '@/router/routerHelpers';

export default [
  {
    path: 'billing-portal',
    name: 'billing-portal',
    props: true,
    component: loadView('settings/account/AccountBillingShared'),
  },
];
