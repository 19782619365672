import isEqual from 'lodash/isEqual';

export const automationStatusOptions = [
  {
    text: 'All',
    value: null,
    icon: 'campaigns/all',
  },
  {
    text: 'Live',
    value: 'live',
    icon: 'campaigns/live',
  },
  {
    text: 'Live/Ongoing',
    value: 'ongoing',
    icon: 'trending_up',
    fontIcon: true,
  },
  {
    text: 'Scheduled',
    value: 'scheduled',
    icon: 'campaigns/scheduled',
  },
  {
    text: 'Draft',
    value: 'draft',
    icon: 'edit_note',
    fontIcon: true,
  },
  {
    text: 'Paused',
    value: 'paused',
    icon: 'campaigns/schedule',
  },
  {
    text: 'Finished',
    value: 'finished',
    icon: 'campaigns/ended',
  },
];

export const rerunOptions = [
  {
    value: '',
    text: 'Never',
  },
  {
    value: 'daily',
    text: 'Every Day',
  },
  {
    value: 'weekly',
    text: 'Every Week',
  },
  {
    value: 'monthly',
    text: 'Every Month',
  },
  {
    value: 'yearly',
    text: 'Every Year',
  },
];

export const endOptions = [
  {
    label: 'Run once for current contacts in the group at start of automation',
    value: 'afterAll',
  },
  {
    label:
      'Run once for current contacts in group and all added contacts until automation is paused or stopped.',
    value: 'ongoing',
  },
];

export const modalsDescription = {
  changes: {
    title: 'Changes will not be saved',
    text: 'You have made changes to this automation, if you leave without updating those changes will be lost.',
    buttonText: 'Discard changes',
  },
  pause: {
    title: 'Pause Automation',
    text: 'Are you sure you want to pause this automation?',
    buttonText: 'Pause',
  },
  resume: {
    title: 'Resume Automation',
    text: 'Are you sure you want to resume this automation?',
    buttonText: 'Resume',
  },
};

export const timingOptions = [
  {
    label: 'Release Immediately',
    value: 'immediately',
  },
  {
    label: "Delay Release by Each Contact's Join Date",
    value: 'delay',
  },
  {
    label: 'Release on Specific Date',
    value: 'date',
  },
];

export const getDayOfWeekOptions = () => {
  return [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ].map((text, idx) => {
    return { value: idx + 1, text };
  });
};

export const triggerTypes = [
  {
    icon: 'triggers/icons/all',
    title: 'All',
    titlePlaceholder: 'Streamline actions with custom Automations',
    text: '',
    btn: 'Create Your First Automation',
    type: 'index',
  },
  {
    icon: 'triggers/icons/lesson',
    title: 'Lesson',
    titlePlaceholder: 'Attach an existing lesson or create a new one',
    text: '',
    btn: 'Create Your First Lesson',
    type: 'lesson',
    hidden: true,
    main: true,
  },
  {
    icon: 'triggers/icons/sms',
    title: 'Send Text Message',
    text: 'Schedule text message to a group',
    btn: 'Create this kind of automation',
    type: 'sms',
    main: true,
  },
  {
    icon: 'triggers/icons/email',
    title: 'Send Email',
    text: 'Schedule email blast to a group',
    btn: 'Create this kind of automation',
    type: 'email',
    main: true,
  },
  {
    icon: 'triggers/icons/join',
    title: 'Add to Campaign',
    text: 'Add individual or group to course/campaign',
    btn: 'Create Your First Automation',
    type: 'join',
  },
  {
    icon: 'triggers/icons/unjoin',
    title: 'Remove from Campaign',
    text: 'Revoke access to a course or challenge',
    btn: 'Create this kind of automation',
    type: 'unjoin',
  },
  {
    icon: 'triggers/icons/remove',
    title: 'Remove/Archive Contact',
    text: 'Completely delete contact(s).',
    btn: 'Create this kind of automation',
    type: 'remove',
  },
  {
    icon: 'triggers/icons/tag',
    title: 'Add/Remove tags',
    text: "Add 'tag' label to a group. Great for organizing contacts",
    btn: 'Create this kind of automation',
    type: 'tag',
  },
  {
    icon: 'contacts/contacts',
    title: 'Update a Contact Profile',
    text: "Automate the updating of a contact's profile.",
    type: 'contactupdate',
  },
  {
    icon: 'triggers/icons/hook',
    title: 'Webhook',
    text: 'Send contact data to another web application',
    btn: 'Create this kind of automation',
    type: 'hook',
  },
  {
    icon: 'triggers/icons/email-notification',
    title: 'Email Notification',
    text: 'Send email notification to others regarding contact',
    btn: 'Create Email Notification',
    type: 'anymail',
  },
  {
    icon: 'triggers/icons/event',
    title: 'Record Custom Event',
    text: 'Record an event when a contact condition has been met',
    btn: 'Create this kind of automation',
    type: 'event',
  },
  {
    icon: 'triggers/icons/d-screenshot',
    title: 'Send Dynamic Screenshot',
    text: 'Automatically take a screenshot of a web URL and send it as an MMS.',
    btn: 'Create this kind of automation',
    type: 'screenshot',
  },
  {
    icon: 'triggers/icons/solo',
    title: 'Solo',
    text: 'Solo is a sales conversion platform that specializes in creating dynamic, customizable proposals for both roofing and solar services.',
    btn: 'Create this kind of automation',
    type: 'integration_solo',
  },
  {
    icon: 'triggers/icons/spotio',
    title: 'Spotio',
    text: 'Spotio is used by sales teams to track doors and leads. Onboard Spotio users automatically with this integration.',
    btn: 'Create this kind of automation',
    type: 'integration_spotio',
  },

  // workflow helpers
  {
    icon: 'triggers/icons/conditional',
    title: 'Condition',
    description: 'Add a condition',
    type: 'conditional',
    hidden: true,
    workflow: true,
  },
  {
    icon: 'triggers/icons/delay',
    title: 'Delay',
    description: 'Add a delay',
    type: 'delay',
    hidden: true,
    workflow: true,
  },
  {
    icon: 'triggers/icons/jump',
    title: 'Jump',
    description: 'Jump to another step',
    type: 'jump',
    hidden: true,
    workflow: true,
  },
  {
    icon: 'triggers/icons/wait',
    title: 'Wait',
    description: 'Wait until',
    type: 'wait',
    hidden: true,
    workflow: true,
  },
];

export const getTriggerCustomErrors = (trigger) => {
  const validation = triggerValidation[trigger?.type];
  if (!validation) return false;
  return validation.test(trigger) ? false : validation.text;
};

const triggerValidation = {
  tag: {
    text: 'No Tag Supplied to Add or Remove!',
    test: (trigger) => {
      const { add_tags, remove_tags } = trigger || {};
      return (add_tags || []).length || (remove_tags || []).length;
    },
  },
  conditional: {
    text: 'Please set all conditions',
    test: (trigger) => {
      const { conditions = [] } = trigger || {};
      if (!conditions) return true;

      return conditions.every((condition) => {
        if (!condition.match.length) return false;
        if (condition.is === 'no') return true;

        const match = condition.match[0];
        return (
          !isEqual(match, { bool: false }) && !isEqual(match, { bool: true })
        );
      });
    },
  },
};
