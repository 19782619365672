import { updateArrInStore } from '@/store/storeHelpers';

export default {
  setFoldersLoaded: (state, loading) => {
    state.foldersLoaded = loading;
  },
  setFolders: (state, folders) => {
    state.folders = folders;
  },
  setCurrentFolder: (state, folderId) => {
    state.currentFolder = folderId;
  },
  setLoadingSingleFolder: (state, loading) => {
    state.loadingSingleFolder = loading;
  },
  updateFolderInList: (state, folder) => {
    updateArrInStore(state, folder, 'folders');
  },
};
