import VideoPlayer from './VideoPlayer.js';

class WistiaPlayer extends VideoPlayer {
  api_url = '//fast.wistia.com/assets/external/E-v1.js';
  script_id = 'wistia-js-api';

  _apiLoaded() {
    return !!window.Wistia;
  }

  _wistiaId(url) {
    url = url || this.url;
    if (url.toLowerCase().indexOf('wistia') < 0) {
      return false;
    }
    let matches = this.constructor.test(url);
    return matches[2] || false;
  }

  _bindApi() {
    this.ensurePlayer(() => {
      this.player.bind('play', (e) => {
        setTimeout(() => {
          this.setStart();
          this.trigger('play', e);
        });
      });

      this.player.bind('timechange', (currentTime, e) => {
        this.setLatestPlayTime(currentTime);
        this.checkPlaybackRate();
      });

      this.player.bind('seek', (currentTime) => {
        this.trigger('seek', currentTime);
      });

      this.player.bind('end', this.stop.bind(this));

      this.player.bind('pause', (e) => {
        this.trigger('pause', e);
      });

      this.trigger(this.ready_event);
    });
  }

  ensurePlayer(callback, attempts = 0) {
    let containerId = this.id;
    let player = Wistia.api(containerId);
    if (!player && attempts < 30) {
      return setTimeout(() => {
        this.ensurePlayer(callback, (attempts += 1));
      }, 100);
    }

    if (player) {
      this.player = player;
      callback(player);
    }
  }

  checkPlaybackRate() {
    const playerSpeed = this.player.playbackRate();
    if (playerSpeed !== this.playbackRate) {
      this.savePlaybackRate(playerSpeed || 1);
    }
  }

  _changePlaybackRate(speed = 1) {
    this.player.playbackRate(speed);
  }

  _duration() {
    return this.player.duration();
  }

  // Playback controls
  // Top level VideoPlayer handles ready check
  // So can count on 'player' already being ready
  _play() {
    this.player.play();
  }

  _pause() {
    this.player.pause();
  }

  _seek(seconds) {
    this.player.time(seconds);
  }

  // If it's a "terminal" stop, garbage collect player as well
  _stop(terminal = false) {
    this._pause();
    if (terminal) {
      this.player.remove();
    }
  }

  _remove() {
    if (this.player) {
      this.player.remove();
    }
  }

  getPlayerOptions() {
    let opts = this.opts || {};

    let embedOptions = `playerColor=${opts.color}`;

    if (opts.required || opts.presentMode) {
      embedOptions += ` playbackRateControl=false`;
    }

    if (opts.qualityControl) {
      embedOptions += ` qualityControl=${opts.qualityControl}`;
    }

    if (opts.presentMode) {
      embedOptions += ' playbar=false';
    }

    if (opts.playSuspendedOffScreen === false) {
      embedOptions += ' playSuspendedOffScreen=false';
    }

    if (opts.autoPlay) {
      embedOptions += ` autoPlay=true`;
    }

    //allow passing 0
    if (typeof opts.time !== 'undefined') {
      embedOptions += ` time=${opts.time}`;
    }

    if (opts.qualityMax) {
      if (opts.qualityMax <= 360) {
        embedOptions += ` qualityMin=${opts.qualityMax}`;
      }

      embedOptions += ` qualityMax=${opts.qualityMax}`;
    }

    return embedOptions;
  }

  buildHtml() {
    let id = this._wistiaId();
    if (!id) {
      return '';
    }

    let options = this.getPlayerOptions();

    let containerId = this.id;

    return `<div id='${containerId}' class='wistia_embed wistia_async_${id} ${options}'></div>`;
  }

  wireEvents() {
    this.on('opts', (newOpts, oldOpts) => {
      // If the start time changed, jump to there
      if (newOpts.start != oldOpts.start) {
        this.seek(newOpts.start);
      }

      // End is handled during playback, doesn't need to be updated
    });
  }
}

let wistiaRegex = /https:\/\/(.*).wistia\.com\/medias\/(\w+)/;

WistiaPlayer.test = function (url) {
  return url.match(wistiaRegex);
};

export default WistiaPlayer;
