<template>
  <div class="app-header" :class="{ light: useLightTheme }">
    <v-layout align-center class="pa-1">
      <v-btn
        v-if="!isIframe && isMobile"
        class="button-toggle app-header__toggle--btn"
        fab
        icon
        :small="isMobile"
        @click="toggleSidebar"
      >
        <icon-base icon="custom/menu" alt="" />
      </v-btn>

      <v-btn
        v-if="back"
        class="button-back"
        fab
        icon
        :small="isMobile"
        @click="openPrevious"
      >
        <v-icon :color="!useLightTheme ? 'light' : ''">chevron_left</v-icon>
      </v-btn>

      <div class="app-header__title">
        <div
          class="app-header__title--text text-ellipsis"
          :class="useLightTheme ? 'appMain--text' : 'light--text'"
        >
          {{ title || routeTitle }}
        </div>
        <smart-tooltip
          v-if="help"
          class="ml-2"
          :type="help"
          :category="helpCategory"
          link
        />
        <slot name="chip"></slot>
      </div>

      <v-layout class="app-header__buttons" justify-end align-center>
        <slot />
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { inIframe } from '@/globals';
import SmartTooltip from '@/components/basic/smart-tooltip/SmartTooltip';

export default {
  name: 'AppHeader',
  components: {
    SmartTooltip,
  },
  props: {
    title: String,
    help: String,
    helpCategory: String,
    back: {
      type: [String, Object, Function],
    },
    chip: {
      type: [String, Number],
    },
  },
  data() {
    return {
      inIframe,
    };
  },
  computed: {
    useLightTheme() {
      return this.isIframe && this.$route.query.headerColor !== 'dark';
    },
    isIframe() {
      return this.inIframe && this.$route.name !== 'portal-lesson';
    },
    routeTitle() {
      return this.$route.name;
    },
    showNav() {
      return this.$route.meta.showNav;
    },
  },
  methods: {
    ...mapMutations(['toggleSidebar']),
    openPrevious() {
      if (typeof this.back === 'function') {
        return this.back();
      }
      this.$router.push(this.back);
    },
  },
};
</script>

<style lang="less">
.app-header {
  position: fixed;
  top: 0;
  left: 0;
  height: 64px;
  width: 100%;
  background-color: var(--v-appMain-base);
  z-index: 3;
  box-shadow: @content-shadow;

  & > .layout {
    height: 100%;
  }

  &__title {
    padding-left: 16px;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;

    &--text {
      font-size: 20px;
      line-height: 1.2;
      font-family: var(--v-headers-font-base);
      font-weight: 900;
      text-transform: capitalize;
      color: #fff !important;
    }

    @media (max-width: 600px) {
      padding-left: 8px;
    }
  }

  &__buttons {
    flex: none !important;
    margin-left: auto;
    padding-left: 8px;

    .search-toggle {
      margin-right: 16px !important;
    }

    .search-toggle,
    .v-btn {
      min-width: 36px !important;
      margin-right: 16px !important;
      @media (max-width: 980px) {
        margin-right: 12px !important;
      }
    }

    .v-btn--fab.v-size--small {
      height: 36px;
      width: 36px;
    }
  }

  .button-back.v-btn:focus:before {
    opacity: 0;
  }

  .button-back + .app-header__title {
    padding-left: 0;
  }

  .button-toggle {
    display: none;

    svg {
      fill: #fff;
    }
  }

  @media (max-width: @sm) {
    width: 100% !important;
    left: 0 !important;

    .button-toggle {
      display: block;
    }

    h4 {
      padding-left: 0;
    }
  }

  &.light {
    .v-btn:not(.appMain).primary,
    .app-layout .v-btn:not(.appMain).secondary {
      color: var(--v-light-base) !important;
    }
  }
}
</style>
