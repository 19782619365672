import Module from '@/store/resource/common/module';
import Reports from '@/store/resource/reports';
import { compareStrings, highlightSearch } from '@/filters/helpers';
import clone from 'lodash/clone';

let module = new Module(new Reports());

module.add('getters', {
  folders(state) {
    let folders = {
      activity: {
        count: 0,
        name: 'activity',
      },
    };
    state.all.forEach((r) => {
      let folder = r.folder || 'Other';

      let info = folders[folder] || { count: 0 };

      info.id = folder;
      info.name = folder;
      info.count += 1;

      folders[folder] = info;
    });

    folders = Object.values(folders);
    folders.sort((a, b) => a.name.localeCompare(b.name));

    return folders;
  },

  bySharePid(state) {
    return (pid) => {
      if (!pid) return null;
      return state.all.find((r) => r.pid === pid);
    };
  },

  filteredReports(state, getters) {
    let reports = state.all.concat([
      {
        title: 'Contact Activity & Event Feed',
        desc: 'View recent events being recorded by (or for) contacts.',
        folder: 'Activity',
        id: 'activity',
      },
    ]);

    if (!getters.search) return reports;

    return reports.filter(({ title, folder }) => {
      return compareStrings(title + ' ' + folder || '', getters.search);
    });
  },

  getWorkflowTriggerContacts(state, getters) {
    return (triggerId) => {
      const workflowContactsReport = getters.byId(
        'campaignsWorkflowContactCount'
      );

      return (workflowContactsReport?.data || []).reduce((acc, record) => {
        const { con_id, last_trigger_id } = record;
        if (last_trigger_id === triggerId) {
          acc.push(con_id);
        }

        return acc;
      }, []);
    };
  },
});

export default module.create();
