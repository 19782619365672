import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('reports');

const reportsRoutes = [
  {
    path: '/reports',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: '',
        meta: { showNav: true },
        props: true,
        component: loadView('Reports'),
      },
      {
        path: 'activity',
        name: 'reportsActivity',
        props: true,
        component: loadView('components/ReportActivityFeed'),
      },
      {
        path: ':id',
        name: 'report',
        props: true,
        component: loadView('Report'),
      },
    ],
  },
];

export default reportsRoutes;
