import api from '@/factories/api';

export default {
  namespaced: true,

  state: {
    loading: false,
    response: null,
  },

  mutations: {
    setLoading: (state, loading) => {
      state.loading = loading;
    },
  },

  actions: {
    async sendRequestToBot({ commit }, prompt) {
      commit('setLoading', true);

      try {
        const res = await api().post('cybot/liquid', { prompt });
        return Array.isArray(res) ? res[0] : res;
      } finally {
        commit('setLoading', false);
      }
    },
  },
};
