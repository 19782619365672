import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('portal');

export default {
  path: '/portal/:pid?',
  component: loadView('components/layout/PortalLayout'),
  children: [
    {
      path: '',
      name: 'portal-home',
      meta: { showNav: true },
      component: loadView('PortalHome'),
    },
    {
      path: 'p',
      component: {
        render: (c) => c('router-view'),
      },
      children: [
        {
          path: 'purchases',
          name: 'portal-purchases',
          meta: { showNav: true },
          component: loadView('PortalPurchases'),
        },
        {
          path: 'stats',
          name: 'portal-stats',
          meta: { showNav: true },
          component: loadView('PortalStats'),
        },
        {
          path: 'folders',
          name: 'portal-folders',
          meta: { showNav: true },
          component: loadView('PortalFolders'),
        },
        {
          path: 'favorites',
          meta: { showNav: true },
          redirect: { name: 'portal-home' },
        },
        {
          path: 'conversations',
          name: 'portal-conversations',
          meta: { showNav: true },
          props: true,
          component: loadView('PortalConversationThreads'),
        },
        {
          path: 'conversations/:threadId/:startThreadId?',
          name: 'portal-conversations-chat',
          meta: { showNav: true },
          props: true,
          component: loadView('PortalConversationChat'),
        },
        {
          path: 'reports',
          name: 'portal-reports',
          meta: { showNav: true },
          component: loadView('PortalReports'),
        },
        {
          path: 'c/:path',
          props: true,
          name: 'portal-custom',
          meta: { showNav: true },
          component: loadView('PortalCustom'),
        },
        {
          path: 'settings',
          name: 'portal-settings',
          meta: { showNav: true },
          component: loadView('PortalSettings'),
        },
        {
          path: 'search',
          name: 'portal-search',
          meta: { showNav: true },
          component: loadView('PortalSearch'),
        },
        {
          path: 'notifications',
          name: 'portal-notifications',
          component: loadView('PortalNotifications'),
        },
      ],
    },
    {
      path: 'n/:shortRouteHash',
      props: true,
      component: loadView('PortalHome'),
    },
    {
      path: 'purchases/:courseId',
      name: 'portal-purchase-info',
      props: true,
      component: loadView('PortalPurchaseInfo'),
    },
    {
      path: 'course/:id',
      name: 'portal-course',
      props: true,
      component: loadView('PortalCourse'),
    },
    {
      path: 'course/:id/about',
      name: 'portal-course-about',
      props: true,
      component: loadView('PortalCourseInfo'),
    },
    {
      path: 'course/:courseId/lesson/:triggerId',
      name: 'portal-lesson',
      props: true,
      //note that the webpack chunk is portal-lessons here because PortalTakeLesson
      //as this component sits on top of takeLesson
      component: loadView('PortalTakeLesson'),
    },
    {
      path: 'courses/:slug',
      name: 'portal-category',
      props: true,
      component: loadView('PortalCourseCategoryPage'),
    },
    {
      path: 'folders/:folderId',
      name: 'portal-folder',
      props: true,
      meta: { showNav: true },
      component: loadView('PortalFolderSingle'),
    },
    {
      path: 'folders/:folderId/lessons/:id',
      name: 'portal-folder-lesson',
      props: true,
      component: loadView('PortalFolderTakeLesson'),
    },

    {
      path: 'reports/:reportPid',
      name: 'portal-report',
      meta: { showNav: true },
      props: true,
      component: loadView('PortalReport'),
    },

    //old portal for info page
    { path: 'qa/:id/more', redirect: { name: 'portal-course-about' } },

    //old portal route goes to new course route
    { path: 'qa/:id', redirect: { name: 'portal-course' } },

    //old portal route goes to new lesson route
    { path: 'qa/:courseId/:triggerId', redirect: { name: 'portal-lesson' } },

    {
      path: '*',
      component: loadView('PortalNotFound'),
      meta: { showNav: false },
    },
  ],
};
