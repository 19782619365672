import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    triggers: [],
    triggersSchemas: null,
    triggerPicker: null,
    triggersLoaded: false,
    triggerLoading: false,
    triggersNames: [],
    triggersNamesLoaded: false,
    namesSuggestions: {},

    // workflow
    workflowSettings: null,

    // automations
    automationsFilter: {
      status: null,
      type: null,
    },
    automationsSearch: '',
    automationsView: '',
  },

  mutations,
  actions,
  getters,
};
