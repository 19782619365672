import store from '@/store';

export const loadView = (view) => {
  return componentLoader(() => {
    return import(
      /* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`
    );
  });
};

export const withNestedView = (feature) => (view) => {
  return componentLoader(() => {
    return import(
      /* webpackChunkName: "view-[request]" */ `@/views/${feature}/${view}.vue`
    );
  });
};

function componentLoader(fn) {
  return () => {
    store.commit('setPageLoading', true);
    const done = () => store.commit('setPageLoading', false);

    const promise = fn();
    promise.then(done, done);
    return promise;
  };
}
