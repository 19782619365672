const vueEnv = (key, _default = undefined) => {
  key = 'VUE_APP_' + key.toUpperCase();
  let value = process.env[key];
  if (typeof value === 'undefined') {
    return _default;
  }
  return value;
};

let apiHost = vueEnv('api_host');
let apiPath = vueEnv('api_path');

if (vueEnv('use_proxy') === 'no' && apiHost) {
  apiPath = apiHost + apiPath;
}

let inIframe = window.top !== window.self;

const getHeaders = (params = {}) => {
  let headers = {
    ...params,
  };

  let pid = vueEnv('conveyour_conco_pid');

  if (pid) {
    headers['conco-pid'] = pid;
  }

  const APPKEY = 'conveyour_appkey';
  const TOKEN = 'conveyour_token';

  let appkey = JSON.parse(localStorage.getItem(APPKEY)) || vueEnv(APPKEY);
  let token = JSON.parse(localStorage.getItem(TOKEN)) || vueEnv(TOKEN);

  if (appkey && token) {
    headers['x-conveyour-appkey'] = appkey;
    headers['x-conveyour-token'] = token;
  }

  return headers;
};

let embedApiEnabled = () => {
  if (!inIframe) {
    return false;
  }

  let url = new URL(window.location.href);
  let flag = url.searchParams.get('api') || '';
  return flag.replace(/\D/, '') !== '0';
};

const globalDep = (key) => {
  return window[key];
};

const value = function (v) {
  return typeof v === 'function' ? v() : v;
};

const matchMedia = (rule, yes, no) => {
  const mq = window.matchMedia(`(${rule})`) || {};
  return value(mq?.matches ? yes : no);
};

const baseUrl = () => {
  return process.env.BASE_URL || process.env.VUE_APP_BASE_URL;
};

const baseRoute = () => {
  let route = window.app?.BASE_ROUTE || process.env.VUE_APP_BASE_ROUTE;

  if (route !== undefined) {
    return route;
  }

  return baseUrl();
};

const appRoute = (path = '', includeOrigin = false) => {
  let route = baseRoute();

  //if baseRoute returns baseUrl that is set to an absolute URL value
  //then we are likely serving from a CDN and we did not properly set base_route
  //so this detects that and starts the route with the current initial starting path
  // like /ui
  if (route.indexOf('http') === 0) {
    route =
      window.location.pathname
        .split('/')
        .filter((p) => p)
        .shift() || '/';
  }

  if (path) {
    route += path;
  }

  route = route.replaceAll('//', '/');

  if (includeOrigin) {
    route = window.location.origin + '/' + route.replace('/', '');
  }

  return route;
};

export {
  apiPath as apiPath,
  inIframe,
  getHeaders,
  embedApiEnabled,
  matchMedia,
  globalDep,
  vueEnv,
  baseUrl,
  baseRoute,
  appRoute,
};
