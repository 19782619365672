import { getCampaignStatus } from './actions';
import { updateArrInStore } from '@/store/storeHelpers';

export default {
  setCampaignTemplates: (state, templates) => {
    state.campaignTemplates = templates;
  },
  setCampaignTemplatesSearch: (state, search) => {
    state.campaignTemplatesSearch = search;
  },
  updateTemplateInList: (state, template) => {
    updateArrInStore(state, template, 'campaignTemplates');
  },
  setCampaignTemplatesLoaded: (state, loading) => {
    state.campaignTemplatesLoaded = loading;
  },
};
