import Resource from './common/resource';

class Reports extends Resource {
  path = 'reports/search';
  onLoad({ state, commit }, { req, res }) {
    commit('setAll', res.data.data);
    commit('setPagination', res.data.data);
  }
}

export default Reports;
