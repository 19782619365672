import { getParam } from '@/lib/misc';
import VideoPlayer from './VideoPlayer';

class YoutubePlayer extends VideoPlayer {
  api_url = '//www.youtube.com/iframe_api';
  script_id = 'youtube-js-api';

  _apiLoaded() {
    return !!window.YT;
  }

  _beforeApiLoad() {
    window.onYouTubeIframeAPIReady = () => {
      this.trigger('api_loaded');
      clearInterval(checkApi);
    };

    // fix for multiple video on page
    let checkApi = setInterval(() => {
      if (window.YT && window.YT.Player) {
        this.trigger('api_loaded');
        clearInterval(checkApi);
      }
    }, 100);
  }

  _bindApi() {
    let states = window.YT.PlayerState,
      checkPlayTime,
      clearCheckPlayTime = () => {
        if (checkPlayTime) {
          clearInterval(checkPlayTime);
          checkPlayTime = undefined;
        }
      },
      config = {
        events: {
          onReady: () => {
            this.trigger(this.ready_event);
            this.setStart();
          },
          onStateChange: (event) => {
            let state = event.data;
            switch (state) {
              case states.ENDED:
                this.trigger('stop');
                this.trigger('ended');
                clearCheckPlayTime();
                break;
              case states.PLAYING:
                this.trigger('play');

                if (!checkPlayTime) {
                  checkPlayTime = setInterval(() => {
                    this.setLatestPlayTime(this.player.getCurrentTime());
                  }, 100);
                }
                break;
              case states.PAUSED:
                this.trigger('pause');
                clearCheckPlayTime();
                break;
              case states.BUFFERING:
                this.trigger('buffer');
                break;
              case states.CUED:
                this.trigger('cue');
                break;
            }
          },
          onPlaybackRateChange: (event) => {
            this.savePlaybackRate(event?.data || 1);
          },
          onError: (event) => {
            let str = '';
            switch (event.data) {
              case 2:
                str = 'Invalid parameter';
                break;
              case 5:
                str = 'Cannot be played in HTML5';
                break;
              case 100:
                str = 'Not found';
                break;
              case 101:
              case 150:
                str = 'Not allowed';
                break;
            }
            console.warn('YoutubePlayer error', str, event);
            this.trigger('error', str);
          },
        },
      };

    this.player = new window.YT.Player(this.id, config);

    return this;
  }

  _changePlaybackRate(speed = 1) {
    this.player.setPlaybackRate(speed);
  }

  _duration() {
    return this.player.getDuration();
  }

  // Playback controls
  // Top level VideoPlayer handles ready check
  // So can count on 'player' already being ready
  _play() {
    this.player.playVideo();
  }

  _pause() {
    this.player.pauseVideo();
  }

  _seek(seconds, type) {
    // If we seek immediately after init - we get autoplay problem
    if (type === 'initial') return;

    this.player.seekTo(seconds);
    this.player.playVideo();
  }

  // YT's "stop" fully clears out all buffer and is more
  // terminal than pause. Usually we really want pause
  _stop(terminal = false) {
    if (terminal) {
      this.player.stopVideo();
    } else {
      this.player.pauseVideo();
    }
  }

  makeEmbeddable(url) {
    if (url.indexOf('embed') < 0) {
      url = 'https://www.youtube.com/embed/' + getParam(url, 'v');
    }

    let params = [
      'enablejsapi=1',
      'modestbranding=1',
      'rel=0',
      'showInfo=0',
      'modestbranding=1',
      'rel=0',
      'show',
    ];

    if (window.location.origin) {
      params.push('origin=' + window.location.origin);
    }

    let opts = this.opts || {};

    if (opts.start || opts.end) {
      if (opts.start) {
        params.push('start=' + opts.start);
      }
      if (opts.end) {
        params.push('end=' + opts.end);
      }
    }

    let paramStr = params.join('&');
    return `${url}?${paramStr}`;
  }

  wireEvents() {
    //receives newOpts, oldOpts
    this.on('opts', () => {
      if (!this.player) {
        return;
      } // hasn't initialized yet, don't need to update anything
      let iframe = this.player.getIframe();
      iframe.src = this.makeEmbeddable(this.url);
    });
  }
}

let youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;

YoutubePlayer.test = function (url) {
  return url.match(youtubeRegex);
};

export default YoutubePlayer;
