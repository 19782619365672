<template>
  <v-app :dark="theme.isDark">
    <div class="app-layout">
      <router-view />
    </div>

    <info-modal />
  </v-app>
</template>

<script>
import theme from '@/factories/theme';
import InfoModal from '@/components/basic/info-modal/InfoModal';

export default {
  name: 'SharedLayout',
  components: {
    InfoModal,
  },
  data() {
    return {
      theme,
    };
  },
};
</script>
