export default {
  image: {
    type: 'Image',
    title: 'Image',
    help: 'Select an image to attach to this question',
    expandText: 'Add an image?',
    expandTextSet: 'Change Image?',
    collapseText: 'Collapse',
    short: true,
  },

  audio: {
    type: 'Audio',
    title: 'Audio',
    help: 'Select or record and audio file',
  },
};
