export default {
  type: 'scorm',
  title: 'Add Scorm File',
  icon: 'file',
  help: 'Add scorm file',
  engagement: true,
  schema: {
    title: {
      type: 'Text',
      title: 'Title',
      default: 'Title of SCORM Package',
    },
    scorm: {
      type: 'Scorm',
      title: 'Scorm',
      help: 'Upload a scorm file',
    },
  },
};
