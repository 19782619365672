<template>
  <div class="app-sidebar" :class="{ open: sidebarOpen, full: sidebarFull }">
    <div class="app-sidebar__content">
      <v-tooltip :disabled="hideTooltips" right>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="link-logo" @click="openCommandModal">
            <icon-base icon="logo" alt="" />
          </div>
        </template>
        <span>ConveYour Commands: {{ commandTooltip }}</span>
      </v-tooltip>

      <template v-if="currentUserLoaded">
        <profile-link v-if="currentUser" :disabledTooltip="hideTooltips" />

        <team-picker-button :disabledTooltip="sidebarFull" />

        <app-sidebar-link
          v-for="(link, idx) in navigation"
          :key="idx"
          :link="link"
          :hideTooltips="hideTooltips"
        />

        <secondary-nav :disabledTooltip="hideTooltips" />

        <app-sidebar-link
          :link="{
            text: 'Settings',
            icon: 'settings',
            path: '/settings',
          }"
          :hideTooltips="hideTooltips"
        />

        <install-app-link :disabledTooltip="hideTooltips" />

        <v-divider class="mt-2 mb-2" />

        <help-nav :disabledTooltip="hideTooltips" />

        <logout-link v-if="currentUser" :disabledTooltip="hideTooltips" />
      </template>

      <template v-else>
        <div class="spinner-wrap">
          <spinner small />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import EventBus from '@/factories/eventBus';

import AppSidebarLink from '@/components/layouts/sidebar/links/AppSidebarLink.vue';
import TeamPickerButton from '@/views/settings/teams/TeamPickerButton';
import InstallAppLink from './links/InstallAppLink';
import ProfileLink from './links/ProfileLink';
import LogoutLink from './links/LogoutLink';

import HelpNav from '@/components/layouts/sidebar/navs/HelpNav.vue';
import SecondaryNav from '@/components/layouts/sidebar/navs/SecondaryNav.vue';

import { navigation } from './sidebarLinks';

export default {
  name: 'AppSidebar',
  components: {
    InstallAppLink,
    AppSidebarLink,
    TeamPickerButton,
    ProfileLink,
    LogoutLink,
    HelpNav,
    SecondaryNav,
  },
  computed: {
    ...mapGetters('users', ['currentUser', 'currentUserLoaded']),
    ...mapGetters(['sidebarOpen']),
    routeName() {
      return this.$route.name;
    },
    sidebarFull() {
      return this.$route?.path === '/' && !this.isMobile;
    },
    navigation() {
      return navigation.filter((link) => {
        return !link.permission || this.hasPermission(link.permission);
      });
    },
    hideTooltips() {
      return this.sidebarFull || this.isMobile;
    },
    commandTooltip() {
      const isMac = navigator.platform.toUpperCase().includes('MAC');
      return isMac ? 'cmd+k' : 'ctrl+k';
    },
  },
  watch: {
    routeName() {
      this.closeSidebar();
    },
  },
  methods: {
    ...mapMutations(['toggleSidebar']),
    openCommandModal() {
      EventBus.$emit('toggleCommandModal');
    },
    closeSidebar() {
      if (!this.sidebarOpen || !this.isMobile) return;
      this.toggleSidebar();
    },
  },
};
</script>

<style lang="less" src="@/assets/styles/app/app-sidebar.less"></style>
