export const defaultEngagementState = () => {
  return {
    answers: [],
    lastAnswer: null,
    correct: null,
    completed: false,
    points: null,
    meta: null,
  };
};
