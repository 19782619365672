import size from 'lodash/size';

export default {
  currentItems: (state, root) => {
    return (root.currentLesson || {}).items || [];
  },

  currentItem: (state, root) => {
    return root.currentItems[state.currentIndex] || false;
  },

  hasNextSection: (state, root) => {
    return state.currentIndex < (root.currentItems || []).length - 1;
  },

  hasPrevSection: (state) => {
    return state.currentIndex > 0;
  },

  answerLoading: (state) => {
    return state.answerLoading;
  },

  points: (state, root) => {
    let items = root.currentItems;
    if (!items || !size(items)) {
      return 0;
    }

    return (items || []).reduce((acc, item) => {
      const options = item.values || {};
      const state = item.engagementState || {};

      if (!options.include_points) return acc;
      return parseInt(state.points || 0) + acc;
    }, 0);
  },
};
