import VideoPlayer from './VideoPlayer.js';

class SproutPlayer extends VideoPlayer {
  api_url = 'https://c.sproutvideo.com/player_api.js';
  iframeHtml =
    '<iframe class="sproutvideo-player" id="$ID" src="$URL" frameborder="0" allow="encrypted-media" ></iframe>';
  protocol = window.location.protocol;

  _apiLoaded() {
    return !!window.SV;
  }

  _bindApi() {
    let id = this._sproutId(),
      player = (this.player = new window.SV.Player({ videoId: id }));

    player.bind('ready', () => {
      this.trigger(this.ready_event);
    });

    player.bind('play', () => {
      this.trigger('play');
      this.setStart();
    });

    player.bind('progress', (e) => {
      this.trigger('progress');
      this.setLatestPlayTime(e.data.time);
    });

    player.bind('completed', () => {
      this.trigger('stop');
      this.trigger('ended');
    });

    player.bind('rateChange', (e) => {
      this.savePlaybackRate(e?.data || 1);
    });
  }

  _sproutId(url) {
    url = url || this.url;
    let matches = this.constructor.test(url),
      vts = matches[1] || false;

    if (!vts) {
      return false;
    }
    let ids = vts.split('/'),
      id = ids[0] || false;

    return id || false;
  }

  // min 0.5 - max 4
  _changePlaybackRate(speed = 1) {
    this.player.setPlaybackRate(speed);
  }

  _duration() {
    return this.player.getDuration();
  }

  // Playback controls
  // Top level VideoPlayer handles ready check
  // So can count on 'player' already being ready
  _play() {
    this.player.play();
  }

  _pause() {
    this.player.pause();
  }

  _seek(seconds) {
    this.player.seek(seconds);
  }

  _stop(terminal = false) {
    if (terminal) {
      console.info(
        "SproutPlayer doesn't support a terminal stop. Just pausing"
      );
    }
    this._pause();
  }

  // just ensure protocol matches current page
  // otherwise api calls don't work
  makeEmbeddable(url) {
    let parts = url.split('//');

    return this.protocol + '//' + parts[1];
  }

  wireEvents() {
    //receives newOpts, oldOpts
    this.on('opts', () => {
      // Clear "started" so next play starts at custom start
      // if we have one
      this.started = false;
    });
  }
}

let sproutRegex = /videos\.sproutvideo.com\/embed\/(\w+\/\w+)/;

SproutPlayer.test = function (url) {
  return url.match(sproutRegex);
};

export default SproutPlayer;
