<template>
  <field-block title="Related Records">
    <v-chip
      v-for="record in recordsList"
      :key="record.id"
      @click="openLink(record)"
      label
      color="secondary"
      append-icon="open_in_new"
      class="related-record pl-3 elevation-2"
    >
      <icon-base v-if="record.icon" :icon="record.icon" />
      {{ record.title }}
    </v-chip>
  </field-block>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'RelatedRecords',
  props: {
    records: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('campaigns', ['defaultCampaign']),
    ...mapGetters(['triggerTypeDescription', 'triggerTypes']),
    recordsList() {
      return this.records.reduce((acc, record) => {
        const recordGroup = this.getRecordGroup(record);
        if (recordGroup) {
          acc.push(this.constructRecord(record, recordGroup));
        }

        return acc;
      }, []);
    },
  },
  methods: {
    ...mapActions('campaigns', ['loadDefaultCampaign']),
    constructRecord(record, recordGroup) {
      const recordType = record?.trigger_type;
      return {
        title: record.title,
        id: record.id,
        icon: this.getIcon(recordType, recordGroup),
        link: this.getPath(record, recordGroup),
      };
    },

    // returns: campaign / automation / lesson
    getRecordGroup({ trigger_type, campaign_id }) {
      const campaignTypes = ['workflow', 'drip', 'event'];
      if (campaignTypes.includes(trigger_type)) {
        return 'campaign';
      }

      if (this.triggerTypes.some(({ type }) => type === trigger_type)) {
        return this.isDefaultCampaign(campaign_id) ? 'automation' : 'trigger';
      }

      return '';
    },

    isDefaultCampaign(id) {
      return id === this.defaultCampaign?.id;
    },

    openLink({ link }) {
      if (!link) return;
      window.open(link, '_blank');
    },

    getIcon(type, recordGroup) {
      if (['automation', 'trigger'].includes(recordGroup)) {
        const description = this.triggerTypeDescription(type);
        return description?.icon;
      }

      const icons = {
        campaign: 'sidebar/campaigns',
        default: 'custom/openLink',
      };

      return icons[recordGroup] || icons.default;
    },

    getPath(link, group) {
      let path = '';

      switch (group) {
        case 'campaign':
          path = {
            name: 'campaign-edit',
            params: {
              campaignId: link.id,
              type: 'content',
            },
          };
          break;

        case 'trigger':
          path = {
            name: 'campaign-trigger-edit',
            params: {
              id: link.id,
              campaignId: link.campaign_id,
            },
          };
          break;

        case 'automation':
          path = {
            name: 'automation-edit',
            params: { id: link.id },
          };
          break;
      }

      const pathData = this.$router.resolve(path);
      return pathData?.href;
    },
  },
  mounted() {
    this.loadDefaultCampaign();
  },
};
</script>

<style lang="less">
.v-chip.related-record {
  margin: 0 8px 8px 0;
  .v-chip__content {
    color: var(--v-appMain-base);
  }

  &:hover {
    opacity: 0.8;
  }

  svg {
    height: 16px;
    margin-right: 6px;
    margin-left: -2px;
  }
}
</style>
