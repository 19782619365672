import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  state: {
    funnelStats: null,
    funnelStatsLoading: false,
    campaignMetrics: null,
    campaignMetricsLoading: false,
  },

  mutations,
  actions,
  getters,
};
