import api from '@/factories/api';
import { getTime } from 'date-fns';
import { createDateFromStr } from '@/filters/helpers';

export default {
  async loadDefaultCampaign({ commit, state }) {
    if (state.defaultCampaignLoaded) return;

    try {
      const defaultCampaign = await api().get('campaigns/default');

      if (Array.isArray(defaultCampaign)) return;
      if ((defaultCampaign?.results || []).length) {
        commit('setDefaultCampaign', defaultCampaign.results[0]);
      }
    } catch (err) {
      throw err;
    } finally {
      commit('setDefaultCampaignLoaded');
    }
  },

  async loadCampaigns({ commit, state }) {
    if (state.campaignsLoaded) return;

    try {
      const { results = [], schemas } = await api().get('campaigns');

      const campaigns = results.map((campaign) => ({
        ...campaign,
        status: getCampaignStatus(campaign),
        portal_section: campaign.portal_section || null,
      }));

      commit('setCampaigns', campaigns);
      commit('setCampaignSchemas', schemas);
    } catch (err) {
      throw err;
    } finally {
      commit('setCampaignsLoaded', true);
    }
  },

  async loadCampaignContacts({ commit, state }, params) {
    commit('setCampaignContactsLoading', true);
    try {
      const contactsData = await api().get('contacts', { params });
      const currentContacts = state.campaignContacts.items;
      commit('setCampaignContacts', {
        items:
          params.filters.page > 1
            ? currentContacts.concat(contactsData.results)
            : contactsData.results,
        count: contactsData.count || (contactsData.results || []).length,
      });
    } catch (err) {
      throw err;
    } finally {
      commit('setCampaignContactsLoading', false);
    }
  },

  async massCampaignContactsAction({ state, dispatch }, data) {
    try {
      const response = await api().post(`campaigns/mass_action`, data);

      if (!data.bookmark_id) {
        dispatch('loadCampaignContacts', {
          filters: state.campaignContactsFilters,
        });
      }

      return response;
    } catch (err) {
      throw err;
    }
  },

  async removeCampaign({ commit, state }, campaignId) {
    try {
      await api().delete(`campaigns/${campaignId}`);
      const campaigns = state.campaigns.filter((i) => i.id !== campaignId);
      commit('setCampaigns', campaigns);
    } catch (err) {
      throw err;
    }
  },

  async updateCampaign({ commit }, updates) {
    commit('setCampaignLoading', true);
    try {
      const campaign = await api().put(`campaigns/${updates.id}`, updates);
      commit('updateCampaignInList', campaign);
    } catch (err) {
      throw err;
    } finally {
      commit('setCampaignLoading', false);
    }
  },

  async createCampaign({ commit }, data) {
    commit('setCampaignLoading', true);
    try {
      const newCampaign = await api().post('campaigns', { ...data });
      if (newCampaign) {
        commit('updateCampaignInList', newCampaign);
        return newCampaign?.id;
      }
    } catch (err) {
      throw err;
    } finally {
      commit('setCampaignLoading', false);
    }
  },

  async loadCampaignTest({ commit }, campaign_id) {
    try {
      const testData = await api().post('campaigns/test', { campaign_id });
      commit('setCampaignTest', testData);
      return testData;
    } catch (err) {
      throw err;
    }
  },

  async loadCampaignNames({ commit }) {
    try {
      const names = await api().get('campaigns/names');
      commit('setCampaignNames', names);
    } catch (err) {
      throw err;
    } finally {
      commit('setCampaignNamesLoaded', true);
    }
  },

  async loadCampaignUsersCount({ commit }, campaign) {
    try {
      let params = {
        filters: {
          $Campaigns: campaign.id,
          count: 1,
        },
      };
      const users = await api().get('contacts', { params });
      campaign.usersCount = users?.count || 0;
      commit('updateCampaignInList', campaign);
    } catch (err) {
      throw err;
    }
  },
};

export const getCampaignStatus = (campaign) => {
  const { start_time, end_time } = campaign;
  if (
    start_time &&
    getTime(createDateFromStr(start_time)) > getTime(new Date())
  ) {
    return 'scheduled';
  }
  if (end_time && getTime(createDateFromStr(end_time)) < getTime(new Date())) {
    return 'ended';
  }
  return 'live';
};
