<template>
  <div
    class="feature-check"
    :class="{ locked: isFeatureLocked, loading: firstUsageLoading }"
  >
    <slot />

    <div
      v-if="isFeatureLocked"
      class="feature-check__overlay"
      @click.stop="showFeatureInfo"
    >
      <v-icon class="feature-check__locked" color="error"> info </v-icon>
    </div>

    <div class="spinner-wrap" v-if="firstUsageLoading">
      <spinner small />
    </div>

    <component
      :is="modalComponent"
      v-model="showModal"
      :feature="feature"
      :programId="programId"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import EventBus from '@/factories/eventBus';

export default {
  name: 'FeatureCheck',
  props: {
    feature: String,
    programId: String,
    ignore: Boolean,
    update: Boolean, // reload info about feature
    iconColor: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      showModal: false,
      loading: false,
    };
  },
  computed: {
    ...mapState('org', ['featureUsage']),
    ...mapGetters('programs', ['getProgram']),
    featureLimits() {
      return this.featureUsage[this.feature];
    },
    currentProgram() {
      if (!this.programId) return '';
      return this.getProgram(this.programId);
    },
    reachedLimit() {
      if (!this.featureLimits) return false;
      const { used, cap } = this.featureLimits;
      return cap === 0 || used >= cap;
    },
    modalComponent() {
      if (!this.showModal) return '';
      return () => import(`./FeatureLimitsModal`);
    },
    isFeatureLocked() {
      return !this.ignore && this.reachedLimit;
    },
    avoidFeatureLoad() {
      if (!this.feature || this.ignore) return true;
      return this.featureLimits && !this.update;
    },
    firstUsageLoading() {
      return !this.featureLimits && this.loading;
    },
  },
  methods: {
    ...mapActions('org', ['getFeatureUsage']),
    showFeatureInfo() {
      if (this.currentProgram) {
        EventBus.$emit('showProgramModal', this.programId);
        return;
      }

      this.showModal = true;
    },
    async checkUsage() {
      if (this.avoidFeatureLoad) return;

      this.loading = true;
      try {
        await this.getFeatureUsage({ type: this.feature });
      } finally {
        this.loading = false;
        this.$emit('featureUsageLoaded');
      }
    },
  },
  mounted() {
    this.checkUsage();
  },
};
</script>

<style lang="less">
.feature-check {
  position: relative;

  .v-btn:first-child {
    margin: 0 !important;
  }

  &__overlay {
    display: none;
  }

  &.loading {
    .v-btn {
      pointer-events: none;

      .v-btn__content {
        opacity: 0;
      }
    }

    .spinner-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .spring-spinner-rotator {
        border-right-color: var(--v-light-base);
        border-top-color: var(--v-light-base);
      }
    }
  }

  &.locked {
    .v-list-item,
    .v-btn:first-child {
      opacity: 0.4;
      pointer-events: none;

      .v-list-item__title:before,
      .v-btn__content:before {
        content: '\e897';
        font-family: var(--v-icons-font-base) !important;
        font-size: 14px;
        display: inline-block;
        margin-right: 4px;
      }
    }

    .v-list-item__title {
      display: flex;
      align-items: center;
    }

    .v-list-item + .feature-check__overlay .feature-check__locked {
      top: -2px;
      right: 6px;
    }

    &:hover {
      .v-list-item:first-child,
      .v-btn:first-child {
        opacity: 0.8;
      }
    }

    .feature-check__overlay {
      position: absolute;
      display: block;
      cursor: pointer;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.feature-check__locked {
  position: absolute !important;
  top: -6px;
  right: -6px;
  z-index: 1;
}
</style>
