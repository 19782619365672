import api from '@/factories/api';

export default {
  async stringToTime({}, value) {
    let offset = '';
    let offsetMinutes = new Date().getTimezoneOffset();
    let hours = Math.floor(offsetMinutes / 60);
    let minutes = offsetMinutes - hours * 60;
    let operator = offsetMinutes > 0 ? '-' : '+';

    offset = [hours, minutes].filter((v) => v).join('');
    offset = offset ? `${operator}${offset}` : '';

    return api().post('helpers/strtotime', {
      value,
      offset,
    });
  },
};
