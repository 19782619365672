import mediaFields from './mediaFields';
export default {
  type: 'stats_tracker',
  icon: 'stats',
  title: 'Stats Tracker',
  help: 'Track goals & behavior change.',
  engagement: true,
  schema: {
    questions: {
      type: 'NestedModel',
      title: 'Questions',
      title_singular: 'Question',
      model: 'StatsQuestion',
      default: [],
    },
    ...mediaFields,
    include_points: {
      title: 'Include Points',
      type: 'Checkbox',
      default: true,
      help: 'Include points earned in this question on the score leaderboard.',
    },
    answer_points: {
      title: 'Points on Answering',
      help: 'Optional. Give some points for answering regardless of the answer value.',
      type: 'Number',
      default: 100,
      showOnHaving: 'include_points',
    },
    multiplier: {
      title: 'Points Multiplier',
      help: "Optional. Number of points to multiply the contact's inputed answers by. This value can be negative to subtract points!",
      type: 'Number',
      default: 1,
      showOnHaving: 'include_points',
    },

    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },
  //we cannot calculate accurately what maxPoints will be here
  //in the StatsTracker metrics we use the max score hit as the divisor
  //vs max points
  maxPoints(v) {
    return 0;
  },
};
