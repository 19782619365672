<template>
  <v-avatar
    v-if="count"
    class="notifications-count-badge"
    color="error"
    :size="19"
  >
    <span class="light--text">{{ count }}</span>
  </v-avatar>
</template>
<script>
export default {
  name: 'UnreadNotificationsCount',
  props: {
    count: Number,
  },
};
</script>

<style>
.notifications-count-badge {
  position: absolute !important;
  top: 4px;
  left: 28px;
  font-size: 12px;
  background-color: #fff;
  opacity: 1 !important;
  z-index: 1;
}
</style>
