<template>
  <div @click="confirmLogout">
    <app-sidebar-link :link="LOGOUT_LINK" :hideTooltips="disabledTooltip" />
  </div>
</template>

<script>
import AppSidebarLink from '@/components/layouts/sidebar/links/AppSidebarLink.vue';
import { mapActions } from 'vuex';

const LOGOUT_LINK = {
  text: 'Logout',
  icon: 'logout',
  path: '/logout',
  disabled: true,
};

export default {
  name: 'LogoutLink',
  components: {
    AppSidebarLink,
  },
  props: {
    disabledTooltip: Boolean,
  },
  data() {
    return {
      LOGOUT_LINK,
    };
  },
  methods: {
    ...mapActions('auth', ['logout']),
    confirmLogout() {
      this.openConfirmationModal({
        title: 'Log out confirmation',
        text: 'Are you sure you want to log out?',
        onConfirm: () => {
          this.logout().then(() => {
            this.$router.push({ name: 'app-login' });
          });
        },
      });
    },
  },
};
</script>
