<template>
  <field-block v-if="preparedList.length">
    <v-row
      class="user-stats"
      :class="{ short: preparedList.length < 5 }"
      justify="center"
      justify-sm="space-between"
    >
      <v-col
        v-for="(stat, idx) in preparedList"
        :key="idx"
        class="user-stats__item"
        :cols="colsSize"
        :xs="colsSize"
        sm="auto"
      >
        <div class="user-stats__item-icon">
          <icon-base :icon="`campaigns/${stat.icon}`" />
        </div>
        <div class="user-stats__item-count large-text">
          {{ stat.stat || 0 }}
        </div>
        <div class="user-stats__item-title">
          {{ stat.text }}
        </div>
        <div class="user-stats__item-button" v-if="stat.button">
          <a href="#" @click.prevent="openLink(stat.button)">
            {{ stat.button.text }}
          </a>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      :value="!!activeModal"
      @input="toggleModal()"
      max-width="600"
      content-class="fixed-height-lg"
    >
      <component :is="activeModal" @close="toggleModal()" />
    </v-dialog>
  </field-block>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { inIframe } from '@/globals';
import ContactCreateModal from '@/views/contacts/components/ContactCreateModal';

const STATS_LIST = [
  {
    text: 'Contacts',
    icon: 'person',
    type: 'contacts',
    check: 'add_contacts',
    button: {
      text: 'Add',
      link: '/contacts/create',
      parentAction: 'addContact',
      modal: 'ContactCreateModal',
    },
  },
  {
    text: 'Live Campaigns',
    icon: 'campaigns',
    type: 'liveCampaigns',
    check: 'campaigns',
    button: {
      text: 'Create',
      link: '/campaigns/create',
    },
  },
  {
    text: 'Lessons Created',
    icon: 'content',
    type: 'lessons',
    check: 'lessons',
    button: {
      text: 'Create',
      link: '/lessons/new',
    },
  },
  {
    text: 'Learners (MAL)',
    icon: 'portal',
    type: 'activeLearners',
  },
];

export default {
  name: 'HomeStats',
  components: {
    ContactCreateModal,
  },
  data() {
    return {
      STATS_LIST,
      contactsCount: 0,
      lessonsCount: 0,
      activeLearners: 0,
      activeModal: '',
    };
  },
  computed: {
    ...mapState('campaigns', ['campaigns', 'campaignsLoaded']),
    ...mapGetters('users', ['currentUser', 'isAdmin']),
    isActivated() {
      return this.currentUser.activated;
    },
    preparedList() {
      return this.prepareStatsList();
    },
    colsSize() {
      return this.preparedList.length > 3 ? '6' : '4';
    },
  },
  watch: {
    isActivated: {
      immediate: true,
      handler() {
        this.checkCampaigns();
      },
    },
  },
  methods: {
    ...mapActions('campaigns', ['loadCampaigns']),
    ...mapActions(['getItemsCount']),
    ...mapActions('org', ['getFeatureUsage']),
    prepareStatsList() {
      return this.STATS_LIST.filter((item) => {
        const statType = item.type;
        const stat = this.getStats(statType);

        if (!stat && statType === 'activeLearners') {
          return false;
        }

        if (
          statType === 'liveCampaigns' &&
          (!this.isAdmin || !this.isActivated)
        ) {
          return false;
        }

        if (item.check && !this.hasPermission(item.check)) {
          return false;
        }

        item.stat = stat;
        return true;
      });
    },
    getStats(type) {
      const stats = {
        contacts: () => this.contactsCount,
        lessons: () => this.lessonsCount,
        liveCampaigns: () =>
          this.campaigns.filter((i) => i.status === 'live').length,
        activeLearners: () => this.activeLearners,
      };

      const count = stats[type]();
      return count > 999 ? (count / 1000).toFixed(1) + 'k' : count;
    },
    setItemsCounts() {
      this.getFeatureUsage({ type: 'mal' }).then((i) => {
        this.activeLearners = i?.used || 0;
      });

      ['lessons', 'contacts'].forEach(async (type) => {
        this[`${type}Count`] = await this.getItemsCount(type);
      });
    },
    openLink({ parentAction, modal, link }) {
      if (inIframe && parentAction) {
        return this.onParent(parentAction, (action) => {
          action();
        });
      }

      if (modal) {
        return this.toggleModal(modal);
      }

      this.$router.push(link);
    },
    toggleModal(modal) {
      this.activeModal = modal;
    },
    checkCampaigns() {
      if (this.isAdmin && this.isActivated) {
        this.loadCampaigns();
      }
    },
  },
  created() {
    this.checkCampaigns();
    this.setItemsCounts();
  },
};
</script>

<style lang="less">
div.user-stats {
  margin: 0 -35px 25px;

  &.short {
    margin: 25px 0;
  }

  &__item {
    text-align: center;

    &-icon {
      height: 24px;
      svg {
        height: 24px;
        width: auto;
      }
    }

    &-count {
      margin: 10px 10px 0 0;
      color: var(--v-appMain-base);
    }

    &-title {
      font-weight: bold;
      font-size: 12px;
      opacity: 0.5;
      padding-bottom: 4px;
    }

    &-button a {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

@media only screen and (max-width: 680px) {
  div.user-stats {
    margin: 15px -15px;

    &__item {
      padding: 4px;

      &-count {
        font-size: 30px !important;
      }
    }
  }
}
</style>
