export default {
  setCourseStats: (state, stats) => {
    state.courseStats = stats;
  },
  setCourseStatsMeta: (state, meta) => {
    state.courseStatsMeta = meta;
  },
  setUserStats: (state, stats) => {
    state.userStats = stats;
  },
  setLoadingUserStats: (state, loading) => {
    state.loadingUserStats = loading;
  },
  setLoadingCourseStats: (state, loading) => {
    state.loadingCourseStats = loading;
  },
};
