export default {
  type: 'files',
  icon: 'file',
  title: 'Add Files',
  help: 'Add files for viewing or download.',
  schema: {
    text: {
      type: 'Html',
    },
    files: {
      type: 'File',
      default: [],
    },
    standnew: {
      type: 'Checkbox',
      title: 'Start of new section',
      default: false,
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },
};
