import { render, staticRenderFns } from "./VideoPlayer.vue?vue&type=template&id=676d919a"
import script from "./VideoPlayer.vue?vue&type=script&lang=js"
export * from "./VideoPlayer.vue?vue&type=script&lang=js"
import style0 from "./VideoPlayer.vue?vue&type=style&index=0&id=676d919a&prod&lang=less"
import style1 from "video.js/dist/video-js.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports