import VideoPlayer from './VideoPlayer.js';

class VimeoPlayer extends VideoPlayer {
  api_url = 'https://player.vimeo.com/api/player.js';
  script_id = 'vimeo-js-api';

  _apiLoaded() {
    return !!window.Vimeo;
  }

  _bindApi() {
    let iframe = document.getElementById(this.id);
    let player = (this.player = new window.Vimeo.Player(iframe));

    player.ready().then(() => {
      this.trigger(this.ready_event);
      window.player = player;

      this.setStart();

      // prevent vimeo autoplay
      setTimeout(() => {
        this.player.pause();
      }, 0);
    });

    player.on('ended', () => {
      this.trigger('stop');
      this.trigger('ended');
    });

    player.on('play', () => {
      this.trigger('play');
    });

    player.on('pause', () => {
      this.trigger('pause');

      // It's a hacky way to close fullScreen on iOS
      player.getDuration().then((duration) => {
        if (
          this.opts.custom_range &&
          this.opts.end &&
          this.opts.end < duration &&
          this.opts.end <= Math.round(this.latestPlayTime)
        ) {
          this.player.exitFullscreen();
        }
      });
    });

    player.on('cuechange', () => {
      this.trigger('cue');
    });

    player.on('error', (event) => {
      this.trigger('error', event);
    });

    player.on('timeupdate', (seekTime) => {
      this.setLatestPlayTime(seekTime.seconds);
    });

    player.on('playbackratechange', (event) => {
      this.savePlaybackRate(event?.playbackRate || 1);
    });

    // // Vimeo doesn't natively support an end point
    // // So use cuepoints to trigger it ourselves
    // if (this.opts.end) {
    //   this._addEndCuePoint();
    // }

    return this;
  }

  // async _nudgePlayback(player) {
  //   console.log('nudgePlayback')
  //   await player.setMuted(true);
  //   await player.play();
  //   setTimeout(async () => {
  //     await player.pause();
  //     player.setMuted(false);
  //   }, 10);
  // }

  _addEndCuePoint() {
    let player = this.player;

    player
      .addCuePoint(this.opts.end, {
        custom_end: true,
      })
      .then((cpId) => {
        this.custom_end_cuepoint = cpId;
      });

    player.on('cuepoint', (event) => {
      if (event.data.custom_end) {
        this.stop();
      }
    });
  }

  // Adjusting the playback rate is available only to members with a Vimeo Pro subscription or higher.
  // https://developer.vimeo.com/player/sdk/reference#methods-for-playback-controls
  _changePlaybackRate(speed = 1) {
    this.player.setPlaybackRate(speed);
  }

  // Note, this one is a promise, but VideoPlayer handles that
  _duration() {
    return this.player.getDuration();
  }

  // Playback controls
  // Top level VideoPlayer handles ready check
  // So can count on 'player' already being ready
  _play() {
    this.player.play();
  }

  _pause() {
    this.player.pause();
  }

  _seek(seconds) {
    this.player.setCurrentTime(seconds);
    //.then promise does not work above, using timeout instead
    setTimeout(() => {
      this.trigger('seek');
    }, 10);
  }

  _stop(terminal = false) {
    if (terminal) {
      console.info("VimeoPlayer doesn't support a terminal stop. Just pausing");
    }
    this._pause();
  }

  // [tk] - prevent duplicate call to test
  makeEmbeddable(url) {
    let matches = this.constructor.test(url);
    let id = matches[3];

    let params = new URLSearchParams({
      title: 0,
      byline: 0,
      portrait: 0,
      transparent: 0,
      share: 0,
    });

    if (matches[4]) {
      params.set('h', matches[4]);
    }

    // preload video
    if (this.opts.start) {
      params.set('autoplay', '1');
    }

    return `https://player.vimeo.com/video/${id}?` + params.toString();
  }

  wireEvents() {
    this.on('opts', (newOpts, oldOpts) => {
      // Clear "started" so next play starts at custom start
      // if we have one
      this.started = false;

      if (newOpts.start != oldOpts.start) {
        this.seek(this.opts.start);
      }

      if (newOpts.end != oldOpts.end) {
        this._addEndCuePoint();
      }
    });
  }
}

const vimeoRegex =
  /^(?:https?:)?(?:\/\/)?(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:\/(\w+))?(?:$|\/|\?)/;

VimeoPlayer.test = function (url) {
  return url.match(vimeoRegex);
};

export default VimeoPlayer;
