import { updateArrInStore } from '@/store/storeHelpers';

export default {
  setGroups: (state, groups) => {
    state.groups = groups;
  },
  setGroupsLoaded: (state, loaded) => {
    state.groupsLoaded = loaded;
  },
  setGroupLoading: (state, loading) => {
    state.groupLoading = loading;
  },
  updateGroupInList: (state, group) => {
    normalizeFilterValue(group.filters);
    updateArrInStore(state, group, 'groups');
  },
  updateGroupContactsInfo: (state, group) => {
    updateArrInStore(state, group, 'groupContactsInfo');
  },
  setGroupContactsLoading: (state, loading) => {
    state.groupContactsLoading = loading;
  },
  deleteGroupContactsInfo: (state, groupId) => {
    state.groupContactsInfo = state.groupContactsInfo.filter(
      ({ id }) => id !== groupId
    );
  },
  setGroupSearch: (state, search) => {
    state.groupSearch = search;
  },
  setGroupsView: (state, view) => {
    state.groupsView = view;
    localStorage.setItem('groupsView', view);
  },
  setGroupsFolders: (state, folders) => {
    state.groupsFolders = folders;
  },
};

// check for format mismatch for arrays
// we changed the format for filter values of certain types from string 'a, b' to array ['a', 'b']
// this function converts old string values into an array
const normalizeFilterValue = (filter) => {
  if (!filter) return;

  const arrOperators = ['$in', '$nin', '$all'];

  if (Array.isArray(filter)) {
    filter.forEach((filter) => normalizeFilterValue(filter));
  } else {
    for (let key in filter) {
      if (typeof filter[key] === 'object') {
        normalizeFilterValue(filter[key]);
      }

      if (arrOperators.includes(key) && typeof filter[key] === 'string') {
        filter[key] = filter[key].split(',');
      }
    }
  }
};
