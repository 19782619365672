<template>
  <modal-card :title="title" fixed full @close="$emit('close')">
    <video-player
      v-if="isVideo"
      ref="player"
      :item="videoItem"
      :active="true"
      :popup="false"
      :taking="false"
      :testing="false"
      :options="clientPlayerOptions"
    />
    <img v-else-if="isImage" :src="media.path" alt="" />
    <div class="file-wrap" v-else>
      <iframe :src="filestackPreviewUrl"></iframe>
    </div>
  </modal-card>
</template>

<script>
import VideoPlayer from './VideoPlayer';

export default {
  name: 'MediaCard',
  components: {
    VideoPlayer,
  },
  props: {
    media: {},
  },
  data() {
    return {
      clientPlayerOptions: {
        presentMode: true,
        autoPlay: true,
      },
    };
  },
  computed: {
    title() {
      if (this.media?.title) return this.media.title;
      if (this.isVideo) return 'Watch video';
      if (this.isImage) return 'Image';
      return 'File';
    },
    isVideo() {
      return this.checkType('video');
    },
    isImage() {
      return this.checkType('image');
    },
    videoItem() {
      return {
        values: {
          url: this.media ? this.media.path : '',
          summary: {},
        },
      };
    },
    filestackPreviewUrl() {
      if (!this.media.path) return '';

      let url = this.media.path || '';
      if (!url.includes('filestackcontent') || url.includes('/preview/')) {
        return url;
      }

      const handle = url.split('/').pop();
      return 'https://cdn.filestackcontent.com/preview/' + handle;
    },
  },
  methods: {
    checkType(type) {
      return (this.media?.mimetype || '').includes(type);
    },
    checkEscape(e) {
      if (e.keyCode == 27) {
        this.$emit('close');
      }
    },
    toggleEventListener(add = false) {
      let method = (add ? 'add' : 'remove') + 'EventListener';
      window[method]('keydown', this.checkEscape, true);
    },
  },
  mounted() {
    this.toggleEventListener(true);
  },
  beforeDestroy() {
    this.toggleEventListener();
  },
};
</script>

<style scoped lang="less">
.fixed-modal__content {
  padding: 12px !important;
  background-color: #000;
  display: flex;
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .player {
    flex: 1;
    display: flex;
    align-items: center;
  }

  video {
    display: block;
  }

  .file-wrap {
    height: 100%;
    iframe {
      height: 100%;
      width: 100%;
      display: block;
    }
  }
}
</style>
