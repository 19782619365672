<template>
  <div class="v-avatar mutable" :class="avatarSizeClass" :style="avatarStyle">
    <img
      v-if="imgPath"
      v-lazy="imgPath"
      alt=""
      :height="avatarSize"
      :width="avatarSize"
    />
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    img: {},
    size: {
      type: String,
      default: 'default',
      validator: (val) => {
        const allowedOptions = ['default', 'small', 'micro', 'large', 'xlarge'];
        return allowedOptions.includes(val);
      },
    },
    clean: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imgPath() {
      return this.getImgPath();
    },
    avatarSizeClass() {
      return this.size ? `v-avatar--${this.size}` : '';
    },
    avatarSize() {
      return this.getAvatarSize();
    },
    avatarStyle() {
      return {
        height: this.avatarSize + 'px',
        width: this.avatarSize + 'px',
      };
    },
  },
  methods: {
    getAvatarSize() {
      switch (this.size) {
        case 'micro': {
          return 24;
        }
        case 'small': {
          return 32;
        }
        case 'large': {
          return 60;
        }
        case 'xlarge': {
          return 100;
        }
        default: {
          return 40;
        }
      }
    },
    getImgPath() {
      if (this.clean) return this.img;

      if (!this.img || this.img === true || this.img.endsWith('.com/')) {
        return '';
      }

      const pattern = /^((http|https):\/\/)/;
      const fullPath = `https://www.filepicker.io/api/file/${this.img}`;
      return !pattern.test(this.img) ? fullPath : this.img;
    },
  },
};
</script>

<style lang="less">
@avatar-micro: 24px;

.v-avatar {
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: var(--v-lightGray-base);

  svg {
    height: 100%;
    width: 100%;
    fill: var(--v-primary-base);
    background: var(--v-main-base);
  }

  img {
    border: 2px solid var(--v-main-base);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  &:hover:after {
    background-color: var(--v-main-base);
    opacity: 0.16;
  }

  &:active:after {
    background-color: var(--v-dark-base);
    opacity: 0.32;
  }

  &--micro {
    min-width: @avatar-micro;

    img {
      border: none;
    }

    i.v-icon {
      font-size: calc(@avatar-micro + @avatar-micro / 20);
    }
  }
}
</style>
