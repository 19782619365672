// IE helpers
import 'babel-polyfill';
import 'intersection-observer';

// Vue core
import Vue from 'vue';
import App from '@/App';
import store from '@/store';
import router from '@/router';
import { ObserveVisibility } from 'vue-observe-visibility';

// Plugins & libs
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/styles/fonts.css';
import Vuetify from 'vuetify/lib';
import PortalVue from 'portal-vue';
import initDataUnlocker from '@/lib/dataUnlocker';
import initEmbedly from '@/lib/embedly';
import VueHotkey from 'v-hotkey';
import VueLazyload from 'vue-lazyload';
import mixins from '@/mixins';
import theme from '@/factories/theme';
import './registerServiceWorker';
import { sync } from 'vuex-router-sync';
import i18n from './lib/i18n';

// Register plugins
sync(store, router);
Vue.mixin(mixins);
Vue.use(PortalVue);
Vue.use(Vuetify);
Vue.use(VueLazyload);
Vue.use(VueHotkey);
Vue.directive('observe-visibility', ObserveVisibility);

const initApp = () => {
  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    store,
    router,
    vuetify: new Vuetify(theme.getVuetifyBaseConfig()),
    template: '<App/>',
    i18n,
    components: { App },
  });
};

// Generate app theme
theme.once('customThemeSet', () => {
  initDataUnlocker();
  initApp();
  initEmbedly();
});

theme.setCustomTheme();
