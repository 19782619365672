import isEmpty from 'lodash/isEmpty';

export default {
  messages: (state) => {
    return state.messages || [];
  },
  messagesLoaded: (state) => {
    return state.messagesLoaded;
  },
  singleMessage(state) {
    return (messageId) => state.messages.find((i) => i.id == messageId);
  },
  messageLoading(state) {
    return state.messageLoading;
  },
  threads: (state) => {
    return filterThreads(state.threads, state.threadsFilters);
  },
  singleThread(state) {
    return (threadId) => state.threads.find((i) => i.id == threadId);
  },
  threadsFilters: (state) => {
    return state.threadsFilters;
  },
  threadsLoading: (state) => {
    return state.threadsLoading;
  },
  threadsScrollPosition: (state) => {
    return state.threadsScrollPosition;
  },
  messagesSchemas(state) {
    return state.messagesSchemas;
  },
  messagesSchemasLoaded(state) {
    return state.messagesSchemasLoaded;
  },
  messageSchema(state) {
    return (type) => state.messagesSchemas[type];
  },
  conversation(state) {
    return state.conversation;
  },
  conversationLoading(state) {
    return state.conversationLoading;
  },
  unreadThreadsCount(state) {
    return getUnreadThreadsCount(state.threads);
  },
  portalRecipients(state) {
    return state.portalRecipients;
  },
  portalRecipient(state) {
    return (recipientId) => {
      return state.portalRecipients.find((i) => i.id === recipientId);
    };
  },
  portalRecipientsLoaded(state) {
    return state.portalRecipientsLoaded;
  },
};

const getUnreadThreadsCount = (threads) => {
  let count = 0;
  for (let idx in threads) {
    if (isMessageUnread(threads[idx])) {
      count += 1;
    }
  }

  return count;
};

const isMessageUnread = (message) => {
  const { creator, open_count, channel } = message;
  return (
    ['user', 'automatic'].includes(creator) && open_count === 0 && !channel
  );
};

const filterThreads = (threads, filters) => {
  if (!threads || !threads.length) return threads;

  if (!isEmpty(filters.assigned_to)) {
    const assignFilter = getAssignFilter(filters.assigned_to);
    return threads.filter((thread) => {
      return assignFilter(thread.assigned_to);
    });
  }

  return threads;
};

const getAssignFilter = (assignedTo) => {
  if (assignedTo === 'anyone') {
    return (val) => val.length;
  }

  if (assignedTo === 'none') {
    return (val) => !val || !val.length;
  }

  return (val) =>
    (val || []).some((userId) => {
      if (Array.isArray(assignedTo)) {
        return assignedTo.includes(userId);
      }

      return assignedTo === userId;
    });
};
