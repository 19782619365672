import api from '@/factories/api';

export default {
  namespaced: true,

  state: {
    programs: [],
    loading: false,
  },

  mutations: {
    setPrograms: (state, programs) => {
      state.programs = programs;
    },
    setProgram: (state, program) => {
      const programs = state.programs.filter((p) => p.id !== program.id);
      programs.push(program);
      state.programs = programs;
    },
    setLoading: (state, loading) => {
      state.loading = loading;
    },
  },

  getters: {
    getProgram(state) {
      return (programId) => state.programs.find((i) => i.id === programId);
    },
  },

  actions: {
    async loadPrograms({ commit }) {
      const programs = await api().get('programs');
      commit('setPrograms', programs);
    },

    async loadSingleProgram({ commit }, programId) {
      try {
        commit('setLoading', true);
        const res = await api().get(`programs/${programId}`);
        if (res) commit('setProgram', res);
        return res;
      } finally {
        commit('setLoading', false);
      }
    },

    async updateProgram({ commit }, data) {
      try {
        commit('setLoading', true);
        const { id } = data;
        const res = await api().post(`programs/${id}`, data);
        if (res) commit('setProgram', res);
        return res;
      } finally {
        commit('setLoading', false);
      }
    },
  },
};
