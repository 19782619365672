<template>
  <v-dialog :value="!!modalConfig" max-width="400" @input="toggleModal(null)">
    <modal-card
      class="plan-modal"
      v-if="modalConfig"
      :title="modalConfig.title"
      fixed
      padding
      :closeButton="!!currentUser.owner"
      @close="toggleModal(null)"
    >
      <div class="pt-2">
        <p>{{ modalConfig.message }}</p>
      </div>

      <template v-slot:actions>
        <v-btn color="appMain" @click="modalConfig.onConfirm()">
          {{ modalConfig.confirmTxt }}
        </v-btn>
      </template>
    </modal-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import EventBus from '@/factories/eventBus';

export default {
  name: 'CheckPlanModal',
  data() {
    return {
      modalConfig: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.checkAccountStatus(to);
      },
    },
    currentUser() {
      this.checkAccountStatus(this.$route);
    },
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
  },
  methods: {
    checkAccountStatus(route) {
      if (
        !route ||
        route.path.includes('/settings') ||
        !this.currentUser ||
        !this.currentUser.id
      )
        return;

      if (this.getTrialExpiree()) {
        return this.showPlanModal(true);
      }

      if (!this.currentUser.org_plan) {
        this.showPlanModal();
      }
    },

    getTrialExpiree() {
      const user = this.currentUser;
      const trialEnd = 1 * (user.trial_end || 0);
      const unixTime = Math.round(+new Date() / 1000);

      return !trialEnd ? false : trialEnd < unixTime;
    },

    showPlanModal(trial) {
      const modalConfig = {
        title: 'Please Select a Plan',
        message: this.getPlanMessage(trial),
        route: 'settings/account',
        confirmTxt: 'Check Out Plans',
      };
      this.toggleModal(modalConfig);
    },

    showPlanOverageModal(d) {
      const data = d.data || {};
      let opts = {
        message: d.message,
        title: 'Upgrade Plan',
        route: '/settings/account',
      };

      //trial limit reached
      if (data.trial_limit) {
        // app.user.trial_end = null;
        opts.title = 'Purchase Trialed Plan';
      } else if (data.suspect_limit) {
        opts.title = 'Reached Limit';
        opts.confirmTxt = 'Contact Support';
        opts.route = 'help';
      }

      this.toggleModal(opts);
    },

    toggleModal(data) {
      if (!data) {
        return Vue.set(this, 'modalConfig', null);
      }

      const owner = this.currentUser.owner;
      let config = {
        title: data.title,
        message: data.message,
        onConfirm: () => this.toggleModal(null),
      };

      if (owner) {
        config.confirmTxt = data.confirmTxt || data.title;
        config.onConfirm = () => {
          this.toggleModal(null);

          if (data.route === 'help') {
            this.showIntercom();
          } else {
            this.parentNavigate(data.route);
          }
        };
      } else {
        config.confirmTxt = 'Close';
        config.cancelButton = false;
      }

      Vue.set(this, 'modalConfig', config);
    },

    getPlanMessage(trial) {
      if (trial) {
        if (this.currentUser.owner)
          return 'Your trial has expired. Please select a plan.';
        return (
          "Your organization's trial has expired. " +
          "Please have your organization's admin upgrade this account."
        );
      } else {
        if (this.currentUser.owner)
          return 'You need to select a ConveYour subscription plan.';
        return (
          "Your organization isn't setup on a ConveYour subscription plan. " +
          "Please have your organization's admin upgrade this account."
        );
      }
    },

    showIntercom() {
      if (!window.Intercom) return;
      Intercom('show');
    },
  },
  mounted() {
    EventBus.$on('showPlanOverage', (response) =>
      this.showPlanOverageModal(response)
    );
  },
};
</script>

<style lang="less">
.plan-modal {
  min-height: 250px;

  p {
    font-size: 16px;
    font-weight: bold;
  }

  .styled-modal__content {
    background-color: #fff !important;
  }
}
</style>
