import Module from '@/store/resource/common/module';
import Tags from '@/store/resource/tags';
import { compareStrings } from '@/filters/helpers';

let module = new Module(new Tags());

module.add('getters', {
  filteredTags(state, getters) {
    if (!getters.search) return state.all;

    return state.all.filter(({ name }) => {
      return compareStrings(name, getters.search);
    });
  },
});

export default module.create();
