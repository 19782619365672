<template>
  <v-dialog :value="modalOpen" @input="hideModal" max-width="600px">
    <component :programId="programId" :is="modalComponent" @close="hideModal" />
  </v-dialog>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import EventBus from '@/factories/eventBus';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProgramModal',
  data() {
    return {
      modalOpen: false,
      programId: null,
    };
  },
  computed: {
    ...mapGetters('programs', ['getProgram']),
    modalComponent() {
      if (!this.programId || !this.isProgramExist(this.programId)) return;

      const modal = upperFirst(this.programId) + 'Program';
      return () => import(`./${modal}`);
    },
    programExist() {
      return !!this.getProgram(this.programId);
    },
  },
  methods: {
    ...mapActions('programs', ['loadSingleProgram']),
    isProgramExist(programId) {
      return !!this.getProgram(programId);
    },
    showModal(programId) {
      if (!this.isProgramExist(programId)) return;

      this.programId = programId;
      this.modalOpen = true;
    },
    hideModal() {
      this.programId = null;
      this.modalOpen = false;
    },
    async checkProgramInQuery() {
      const { program } = this.$route?.query || {};
      if (program) {
        await this.loadSingleProgram(program);
        this.showModal(program);
      }
    },
  },
  mounted() {
    this.checkProgramInQuery();
    EventBus.$on('showProgramModal', this.showModal);
  },
};
</script>
