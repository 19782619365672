import { findIndex } from 'lodash';

//used in mutations NOT directly from actions
const updateModel = (state, model, listAction = 'append') => {
  let all = state.all.slice(0);
  const index = all.findIndex(({ id }) => id == model.id);

  if (index === -1) {
    if (listAction === 'prepend') {
      all.unshift(model);
    } else {
      all.push(model);
    }
  } else {
    all.splice(index, 1, model);
  }

  state.all = all;

  return state;
};

export default {
  loading(state, flag = true) {
    state.loading = flag;
  },

  loaded(state, flag = true) {
    state.loaded = flag;
  },

  saving(state, flag = true) {
    state.saving = flag;
  },

  set(state, values = {}) {
    for (const key in values) {
      state[key] = values[key];
    }
  },

  setAll(state, records) {
    state.all = records;
    // if (!state.page || state.page === 1) {
    //   state.all = records
    // } else {
    //   state.all = (state.all || []).concat(records)
    // }
  },

  setPagination(state, data) {
    state.page = data.page;
    state.pages = data.pages;
    state.count = data.count * 1;
  },

  setSome(state, { action = 'append', records = [] }) {
    records.forEach((r) => {
      updateModel(state, r, action);
    });
  },

  setOne(state, { action = 'append', record = {} }) {
    updateModel(state, record, action);
  },

  setSearch(state, search) {
    state.search = search;
  },

  update(state, { model, update }) {
    let keys = Object.keys(update);

    if (keys.length) {
      keys.forEach((key) => {
        model[key] = update[key];
      });
    }

    updateModel(state, model);
  },

  delete(state, itemId) {
    state.all = state.all.filter((i) => i.id !== itemId);
  },
};
