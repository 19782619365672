<template>
  <v-icon :color="iconColor" v-if="fontIcon">{{ icon }}</v-icon>
  <inline-svg v-else :src="iconComponent" />
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'IconBase',
  components: {
    InlineSvg,
  },
  computed: {
    preparedIcon() {
      return this.icon.replace(/-([a-z])/g, (g) => {
        return g[1].toUpperCase();
      });
    },
    iconComponent() {
      return require(`./svg/${this.preparedIcon}.svg`);
    },
  },
  props: {
    fontIcon: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
      default: 'appMain',
    },
  },
};
</script>
