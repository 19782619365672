const lib = () => {
  return import(/* webpackChunkName: "trackjs" */ 'trackjs').then(
    (module) => module.TrackJS
  );
};

import { vueEnv } from '@/globals';

export const configureTrackJS = (userId) => {
  if (!window.TrackJS || !userId) return;

  TrackJS.configure({
    userId: userId + '',
    network: { error: false },
  });
};

const getApplication = () => {
  if (window.location.hostname.includes('apex.vmcteam.com')) {
    return 'Apex';
  }

  if (vueEnv('vector')) {
    return 'ui2-vector';
  }

  return vueEnv('trackjs_application');
};

const init = (id) => {
  const token = vueEnv('trackjs_token');
  const application = getApplication();

  if (!token || !application) {
    console.warn('TrackJS not installed');
    return false;
  }

  lib().then((TrackJS) => {
    TrackJS.install({ token, application });
    window.TrackJS = TrackJS;
    configureTrackJS(id);
  });
};

export default init;
