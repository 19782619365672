export default {
  type: 'contact_updates',
  engagement: true,
  icon: 'contactUpdate',
  title: 'Update Learner Profile',
  help: 'Allow the learner to update fields in their contact profile.',
  schema: {
    question: {
      type: 'TextArea',
      title: 'Question / Field Label',
      help: 'Preface your information request with a question or label.',
      default: '',
    },
    contact_fields: {
      type: 'NestedModel',
      title: 'Contact Fields',
      title_singular: 'Contact Field',
      model: 'ContactField',
      valueField: 'contact_field',
      keyField: 'contact_field',
      default: [],
    },
  },
};
