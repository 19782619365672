import actions from './actions';
import mutations from './mutations';
import getters from './getters';

import { extend } from 'lodash';

class Module {
  state = {};
  actions = {};
  mutations = {};
  getters = {};

  constructor(resource) {
    this.actions = actions;
    this.mutations = mutations;
    this.getters = getters;
    this.state = {
      search: '',
      all: [],
      schema: { _unset: true },
      loading: false,
      loaded: false,
      saving: false,
      resource: resource,
      count: 0,
      page: 1,
      pages: 1,
    };
  }

  add(key, config = {}) {
    this[key] = extend(this[key] || {}, config);
  }

  create() {
    return {
      namespaced: true,
      state: this.state,
      actions: this.actions,
      mutations: this.mutations,
      getters: this.getters,
    };
  }
}

export default Module;
