import pick from 'lodash/pick';
import { format } from 'date-fns';
import { vueEnv } from '@/globals';
import { loadStaticScript } from './misc';
import initSegment from '@/lib/segment';
import { inPortal } from '@/filters/helpers';

export const initAppAnalytics = () => {
  const segmentKey = vueEnv('segment_key');
  initSegment(segmentKey);
};

export const identify = (user) => {
  if (!user || !window.analytics) return false;

  const analyticsData = prepareAnalyticsData(user);
  const userId = user.id || user.email;

  // check cvyr analytics
  const integrations = user.analytics_integrations || {};
  if (integrations.ConveYour !== false) {
    const cvyrData = prepareCvyrData(user);
    cvyr('identify', userId, cvyrData);
  }

  return analytics.identify(userId, analyticsData, { integrations });
};

async function cvyr() {
  let args;

  if (process.env.NODE_ENV !== 'production') return false;

  args = [].slice.call(arguments);

  window.Conveyour = window.Conveyour || getCvyrConfig();

  loadStaticScript('custom-analytics', 'custom-analytics.js', () => {
    return Conveyour[args[0]].apply(Conveyour, args.slice(1));
  });
}

export const trackEvent = (eventName, eventData = {}) => {
  const options = {};

  if (window.analytics) {
    analytics.track(eventName, eventData, options);
  }

  if (!inPortal()) {
    cvyr('track', eventName, eventData, options);
  }
};

const getCvyrConfig = () => {
  return {
    config: {
      domain: vueEnv('cvyr_analytics_domain'),
      appkey: vueEnv('cvyr_analytics_key'),
      token: vueEnv('cvyr_analytics_token'),
      init: {
        page: false,
        identify: false,
      },
    },
  };
};

const prepareAnalyticsData = (user) => {
  const getCompanyInfo = () => {
    return ['id', 'name', 'plan', 'created_at'].reduce((acc, key) => {
      acc[key] = user['org_' + key];
      return acc;
    }, {});
  };

  const getFullName = () => {
    return [user.first_name, user.last_name].join(' ');
  };

  let data = pick(user, [
    'group_type',
    'email',
    'org_id',
    'org_domain',
    'org_name',
    'aid',
    'sudo',
  ]);
  return Object.assign(data, {
    firstName: user.first_name,
    lastName: user.last_name,
    name: getFullName(),
    phone: user.mobile,
    mobile: user.mobile,
    username: getFullName(),
    company: getCompanyInfo(),
  });
};

const prepareCvyrData = (user) => {
  let data = pick(user, [
    'first_name',
    'last_name',
    'email',
    'org_id',
    'org_domain',
    'org_name',
    'org_plan',
    'role',
    'mobile',
  ]);

  if (user.created_at) {
    data.created_at = format(new Date(user.created_at * 1000), 'MMM dd, yyyy');
  }

  if (user.trial_end) {
    data.trial_end = format(new Date(user.trial_end * 1000), 'MMM dd, yyyy');
  }

  if (user.id) {
    data.user_id = user.id;
  }

  return data;
};
