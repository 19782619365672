<template>
  <div class="player" v-resize="handleResize" ref="player">
    <div class="player__canvas-wrap" ref="playerCanvasWrap">
      <div class="player__canvas" :class="canvasClasses" :style="playerStyle">
        <div
          ref="playerWrapper"
          class="player__wrapper"
          :style="wrapperStyle"
        ></div>
        <v-layout align-center v-if="player_loading">
          <v-progress-circular :size="100" indeterminate color="primary" />
        </v-layout>
      </div>
      <div v-if="showControls" class="player__controls">
        <v-btn
          v-if="showFullscreenButton"
          icon
          @click="openFullscreen"
          class="animated fadeIn mr-1"
        >
          <v-icon color="primary">fullscreen_exit</v-icon>
        </v-btn>

        <v-btn
          v-if="showRewatch"
          text
          color="primary"
          @click="rewatch"
          class="animated fadeIn"
        >
          <v-icon>restore</v-icon> {{ $t('portal.lesson.replay') }}
        </v-btn>

        <v-spacer />

        <span
          class="player__skip animated fadeIn"
          v-if="taking && testing && required"
          @click.prevent="skip"
          >{{ skipText }}</span
        >
        <span
          class="player__duration pl-3 animated fadeIn"
          v-if="taking && remainingDurationText"
          >{{ remainingDurationText }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { createPlayer, validatePlayer } from '@/lib/videoPlayer/index.js';

import merge from 'lodash/merge';
import throttle from 'lodash/throttle';

const updateRemainingDuration = throttle(function (data) {
  let seconds = data.seconds;
  let vals = this.item.values;
  if (vals.custom_range) {
    seconds = seconds - vals.start;
  }

  this.remainingDuration = Math.round(this.length - seconds);
}, 1000);

export default {
  name: 'VideoPlayer',
  props: {
    active: Boolean,
    testing: Boolean,
    taking: Boolean,
    popup: Boolean,
    preview: Boolean,
    showFullscreenButton: Boolean,
    fullScreen: Boolean,
    inModal: Boolean,
    lessonId: String,
    item: Object,
    options: Object,
  },
  data() {
    return {
      inTheaterMode: false,
      player: null,
      player_loading: false,
      playing: false,
      background: '',
      skipped: false,
      length: 0,
      remainingDuration: 0,
      aspectRatios: {
        '': 9 / 16,
        '4-5': 4 / 5,
        '1-1': 1,
        '9-16': 16 / 9,
      },
    };
  },
  watch: {
    active: {
      immediate: true,
      handler() {
        this.updatePlayerSettings();
      },
    },

    range() {
      if (!this.player) return;
      const vals = this.item?.values || {};
      this.player.setOpts({
        start: vals.start,
        end: vals.end,
      });
    },
    // Might want to debounce this
    // [tk] - check if redundant due to filter

    url(url) {
      if (!url || !validatePlayer(url)) {
        // tk - clear start/stop on url change
        this.removePlayer();
        return;
      }
      this.loadVideo();
    },

    videoProviderType: {
      immediate: true,
      handler() {
        this.resetParamsForSpecificProviders();
      },
    },

    fullScreen() {
      this.handleResize();
    },
  },
  computed: {
    showControls() {
      return (
        this.showRewatch ||
        this.showFullscreenButton ||
        (this.taking &&
          ((this.testing && this.required) || this.remainingDurationText))
      );
    },
    summary: {
      get() {
        return this.item.values.summary;
      },
      set(d) {
        this.$emit('summary', d);
      },
    },

    aspectRatio() {
      return this.item?.values?.aspect_ratio;
    },

    aspectRatioFloat() {
      const ratio = this.aspectRatio || '';
      return this.aspectRatios[ratio] || this.aspectRatios[''];
    },

    canvasClasses() {
      const ratio = this.aspectRatio;
      return {
        loaded: !this.player_loading,
        theater: this.inTheaterMode,
        [`ratio${ratio}`]: !!ratio,
      };
    },

    skipText() {
      return this.skipped ? 'Skipped for Test' : 'Skip Requirement in Test';
    },

    // handy computed for watching when the range changes
    // to pass along to video
    range() {
      let vals = this.item.values;
      return (vals.end || 0) - (vals.start || 0);
    },

    url() {
      return this.item.values.url;
    },

    playerStyle() {
      return {
        borderTopColor: getComputedStyle(
          document.documentElement
        ).getPropertyValue('--v-primary-base'),
      };
    },

    wrapperStyle() {
      let d = this.summary || {};

      //once the player is created,
      //we are going to change the wrapper class
      //to loaded and change the background to transparent
      //this helps to avoid issues with the thumbnail image
      //is a little bigger then the video embed
      if (!this.player_loading || !d.thumbnail_url) {
        return {};
      }

      let styles = {
        backgroundImage: `url(${d.thumbnail_url})`,
      };

      if (this.isProvider('YouTube')) {
        styles.backgroundSize = 'cover';
      }

      return styles;
    },

    isVidyard() {
      return this.isProvider('Vidyard');
    },

    videoProviderType() {
      if (!this.summary) {
        return false;
      }
      return this.summary.provider_name;
    },

    required() {
      return this.item.values.required;
    },

    remainingDurationText() {
      let val = this.remainingDuration || this.length;

      if (!val) {
        return '';
      }

      let M = 60;
      // if( val < M ){
      //   return val + 's'
      // }

      let mins = Math.floor(val / M);
      let secs = val % M;

      mins = mins > 9 ? mins : '0' + mins;
      secs = secs > 9 ? secs : '0' + secs;

      return mins + ':' + secs;
    },

    showRewatch() {
      return this.item.engagementState && this.item.engagementState.completed;
    },
  },

  methods: {
    updatePlayerSettings() {
      const isActive = this.active;
      this.checkActiveState(isActive);

      // Reload vimeo player if it was destroyed
      if (isActive && this.isProvider('Vimeo')) {
        let playerWrapper = this.$refs.playerWrapper;
        if (playerWrapper && !playerWrapper.querySelector('iframe')) {
          this.loadVideo();
        }
      }

      this.checkVidyard();

      if (isActive) {
        this.calculateVideoWidth();
        this.checkPlaybackRate();
      }
    },

    isProvider(type) {
      return this.videoProviderType === type;
    },

    resetParamsForSpecificProviders() {
      const limitedSupportFor = ['Loom', 'Sharepoint'];
      if (limitedSupportFor.some((p) => this.isProvider(p))) {
        this.item.values.custom_range = false;
        this.item.values.required = false;
      }
    },

    async openFullscreen() {
      this.$emit('openFullscreen');
      this.handleResize();
    },

    rewatch() {
      let start = this.item?.values?.start || 0;
      this.seek(start);
      this.play();
    },

    skip() {
      this.skipped = true;
      this.ended();
    },

    ended() {
      this.playing = this.inTheaterMode = false;
      this.$emit('ended');
    },

    async loadVideo() {
      await this.$nextTick();

      this.player_loading = true;
      let url = this.url;

      if (!url) {
        console.warn('video has no url', this.item);
        return;
      }

      let opts = this.getVideoOptions();

      let wrapper = this.$el.getElementsByClassName('player__wrapper')[0];

      this.removePlayer(wrapper);

      let player = (this.player = createPlayer(url, wrapper, opts));

      player.once('ready', () => {
        this.player_loading = false;
        this.bindPlayer();
        this.loadDuration();
        this.checkPlaybackRate();

        if (this.taking) {
          this.maybeTheaterMode();
        }
      });

      player.once('play', () => {
        if (!this.length) {
          this.loadDuration();
        }
      });
    },

    getVideoOptions() {
      const color = getComputedStyle(document.documentElement).getPropertyValue(
        '--v-primary-base'
      );

      let { custom_range, start, end, required, aspect_ratio } =
        this.item?.values || {};

      let options = {
        custom_range,
        start,
        end,
        aspect_ratio,
        required: required && !this.preview,
        color,
        state: (this.item.engagementState || {}).meta,
        preview: this.preview,
        //rate: localStorage.getItem(`${this.lessonId}-playback-rate`) || 1,
      };

      if (typeof this.options === 'object') {
        options = merge(options, this.options);
      }

      return options;
    },

    async loadDuration() {
      if (!this.player) {
        console.warn('Cannot load duration until player is loaded');
        return;
      }

      try {
        const length = await this.player.duration();
        const { custom_range, start, end } = this.item?.values || {};

        if (custom_range) {
          this.length = Math.round(end - start);
        } else {
          this.length = Math.round(length);
        }
        this.$emit('length', length);
      } catch (err) {
        console.warn('Error retrieving duration for', this.player, err);
      }
    },

    bindPlayer() {
      this.player.on('ended', () => {
        this.ended();
      });

      this.player.on('play', (data) => {
        this.playing = true;
        this.$emit('played', data);
      });

      this.player.on('pause', (data) => {
        this.$emit('pause', data);
      });

      this.player.on('seek', (data) => {
        this.$emit('seek', data);
      });

      this.player.on('currentTime', (data) => {
        updateRemainingDuration.call(this, data);
        this.$emit('currentTime', data);
      });

      this.player.on('customProgress', (data) => {
        this.$emit('customProgress', data);
      });

      this.player.on('savePlaybackRate', (speed) => {
        if (!this.lessonId) return;
        localStorage.setItem(`${this.lessonId}-playback-rate`, speed);
      });
    },

    checkPlaybackRate() {
      if (!this.player || !this.active) return;
      const speed = localStorage.getItem(`${this.lessonId}-playback-rate`);
      if (speed) {
        this.player.setPlaybackRate(speed);
      }
    },

    play() {
      if (this.player) {
        this.player.play();
      }
    },

    pause() {
      if (this.player) {
        this.player.pause();
      }
    },

    stop() {
      if (this.player) {
        this.player.pause();
      }
    },

    seek(seconds) {
      if (this.player) {
        this.player.seek(seconds);
      }
    },

    sync(diffSeconds, seconds) {
      if (this.player) {
        this.player.sync(diffSeconds, seconds);
      }
    },

    maybeTheaterMode() {
      if (!this.popup || !this.active) {
        return false;
      }
      return this.theaterMode();
    },

    theaterMode() {
      if (this.inTheaterMode) {
        return false;
      }
      this.inTheaterMode = true;
    },

    exitTheaterMode() {
      this.stop();
      // We consider it ended, even if they don't watch the whole thing
      // this.ended();
    },

    checkActiveState(isActive) {
      if (!isActive) {
        this.exitTheaterMode();
        this.stop();
        return;
      }

      if (!this.summary) {
        // Probably means we're loading _really_ slowly and user got impatient
        console.warn('not first, but still no data loaded', this);
        return;
      }

      this.maybeTheaterMode();
    },
    // [tk] - do garbage cleanup to remove listeners, etc
    removePlayer(wrapper) {
      if (this.player) {
        this.player.remove();
      }

      if (!wrapper) {
        wrapper = this.$el.getElementsByClassName('player__wrapper')[0];
      }

      if (wrapper) {
        wrapper.innerHTML = '';
      }
    },

    async checkVidyard() {
      await this.$nextTick();
      if (!this.isVidyard) return;

      if (!this.active) this.removePlayer();
      if (this.active && !this.player_loading) this.loadVideo();
    },

    getContainerDimensions() {
      const playerElement = this.$refs.player;
      if (!playerElement) return;

      let maxHeightSelector = '.take-lesson';
      if (this.inModal) {
        maxHeightSelector = '.v-dialog--active';
      }
      const container = playerElement.closest(maxHeightSelector);
      if (!container) return 0;

      const props = getComputedStyle(container);
      const getProp = (prop) => parseFloat(props[prop]);
      let height =
        container.offsetHeight -
        getProp('paddingTop') -
        getProp('paddingBottom');
      //add a 100px gutter at bottom for controls
      height -= 110;
      return {
        height,
        width: playerElement.offsetWidth,
      };
    },

    async handleResize() {
      this.calculateVideoWidth();
    },

    async calculateVideoWidth() {
      await this.$nextTick();

      const containerDimensions = this.getContainerDimensions();
      const aspectRatio = this.aspectRatioFloat;

      if (
        containerDimensions.height >
        containerDimensions.width * aspectRatio
      ) {
        this.setCustomWidth('none');
      } else {
        let width = containerDimensions.height / aspectRatio;
        this.setCustomWidth(`${width}px`);
      }
    },

    setCustomWidth(val) {
      const playerElement = this.$refs.playerCanvasWrap;
      if (playerElement) {
        playerElement.style.setProperty('max-width', val);
      }
    },
  },

  mounted() {
    if (this.isVidyard && this.taking) {
      this.checkVidyard();
    } else {
      this.loadVideo();
    }
  },

  beforeDestroy() {
    this.removePlayer();
  },
};
</script>

<style lang="less">
.player {
  position: relative;
  margin: auto;
}

.player__controls {
  display: flex;
  align-items: center;
  margin-top: 12px;
  min-height: 36px;

  .v-btn {
    margin-right: auto;
  }
}

.player__skip {
  color: var(--v-primary-base);
  font-weight: bold;
  cursor: pointer;
}

.player__duration {
  color: var(--v-primary-base);
  right: 0;
  text-align: right;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
}

.player__canvas {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.ratio4-5 {
    padding-bottom: 80%;
  }

  &.ratio1-1 {
    padding-bottom: 100%;
  }

  &.ratio9-16 {
    padding-bottom: 177.8%;
  }

  .v-progress-circular {
    margin: -50px auto auto -50px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
  }

  &-wrap {
    margin: 0 auto;
    width: 100%;
  }
}

.player__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background: black no-repeat;
  background-size: contain;
  background-position: center;
  transition: all 0.5s ease-in;
}

.player__canvas.loaded .player__wrapper {
  background-color: transparent;
}

.player__canvas.theater .player__wrapper {
  z-index: 2;
}

.player iframe,
.player .wistia_embed,
.player .vidyard-player-embed,
.player .video-js {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: 0 1px 50px rgba(0, 0, 0, 0.1);
}

.player .video-js .vjs-big-play-button {
  height: 2em;
  width: 2em;
  font-size: 3em;
  line-height: 2em;
  border-radius: 50%;
  background-color: var(--v-primary-base) !important;
  top: calc(50% - 1em);
  left: calc(50% - 1em);
  outline: none;
  margin-top: 0;
  margin-left: 0;
  opacity: 1;
  box-shadow: @base-shadow;
  transition: all 0.2s ease;

  &:hover {
    font-size: 3.5em;
    transition: all 0.2s ease;
  }
}
</style>

<style src="video.js/dist/video-js.css"></style>
