import { withNestedView } from '@/router/routerHelpers';
const loadView = withNestedView('conversations');

const conversationsRoutes = [
  {
    path: '/conversations',
    component: {
      render: (c) => c('router-view'),
    },
    children: [
      {
        path: 'chat/:typeId/:threadId?',
        name: 'conversations-chat',
        meta: { showNav: true },
        props: true,
        component: loadView('chat/ConversationChatPage'),
      },
      {
        path: ':typeId?/:threadId?/:contactName?',
        name: 'conversations',
        meta: { showNav: true },
        props: true,
        component: loadView('Conversations'),
      },
    ],
  },
];

export default conversationsRoutes;
