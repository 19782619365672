export default {
  profile: (state) => {
    return state.profile;
  },
  profilePid: (state) => {
    return state.profilePid;
  },
  profileLoading(state) {
    return state.profileLoading;
  },
  profileUpdating(state) {
    return state.profileUpdating;
  },
  orgMeta(state) {
    return state.profile.org_meta || {};
  },
  stripeKey(state) {
    return state.profile.org_meta ? state.profile.org_meta.stripe_key : '';
  },
  orgStyles(state) {
    return state.profile.org_styles || {};
  },
  insideScorm(state) {
    return state.scormParameters !== null;
  },
  isTesterProfile(state) {
    return state.profile?.meta?.tester || false;
  },
};
