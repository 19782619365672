import mediaFields from './mediaFields';
export default {
  type: 'blank',
  icon: 'blank',
  title: "Fill'n the Blank",
  help: 'The old favorite-can you fill in the missing word?',
  engagement: true,
  schema: {
    question: {
      type: 'TextArea',
      title: 'Question',
      help: 'Question you want to ask.',
      default:
        '90% of all _________ messages are read within 3 minutes of receiving them. This is why you MUST consider this channel for driving mobile learning engagement!',
    },
    ...mediaFields,
    answers: {
      type: 'List',
      title: 'Answers',
      default: ['text', 'sms', 'text messages', 'sms messages', 'SMS'],
    },

    show_correct_answer: {
      title: 'Show Answer if incorrect',
      type: 'Checkbox',
      default: true,
    },

    include_points: {
      title: 'Include Points',
      type: 'Checkbox',
      default: true,
      help: 'Include points earned in this question on the score leaderboard.',
    },

    points: {
      title: 'Points',
      type: 'Number',
      default: 1200,
      showOnHaving: 'include_points',
    },

    wrong_answer: {
      title: 'Wrong Answer',
      type: 'Number',
      default: 0,
      help: 'Subtract points each time someone chooses the wrong answer.',
      showOnHaving: 'include_points',
    },

    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },

  maxPoints(v) {
    return v.include_points ? v.points : 0;
  },
};
