import cloneDeep from 'lodash/clone';

export const getGraph = (arr, rootNode) => {
  if (!arr.length) return [];
  const triggers = arr.map((t) => {
    return cloneDeep(t);
  });

  rootNode = rootNode || getRootNode(triggers);
  return rootNode ? [buildNodesGraph(rootNode, triggers)] : [];
};

const getRootNode = (triggers) => {
  const nodesWithoutParent = triggers.filter((i) => {
    return !i.wf_pid && !i.wf_detached;
  });

  nodesWithoutParent.sort((a, b) => {
    const aNestedCount = getNestedCount(a.id, triggers);
    const bNestedCount = getNestedCount(b.id, triggers);
    return aNestedCount > bNestedCount ? -1 : 1;
  });

  return nodesWithoutParent[0];
};

export const getNestedCount = (triggerId, arr) => {
  let count = 0;
  let children = arr.filter(({ wf_pid }) => wf_pid === triggerId);
  for (let node of children) {
    count += getNestedCount(node.id, arr);
  }
  return count + children.length;
};

export const isTriggerInGraph = (trigger, arr) => {
  for (let i of arr) {
    if (i.id === trigger.id || isTriggerInGraph(trigger, i.nodes)) {
      return true;
    }
  }

  return false;
};

const isConditionTrigger = ({ type }) => {
  return type === 'conditional' || type === 'wait';
};

export const buildNodesGraph = (currentNode, allNodes) => {
  if (!currentNode?.id) return currentNode;

  let childNodes = allNodes.filter((node) => {
    return node.wf_pid === currentNode.id;
  });

  if (childNodes.length > 1 && !isConditionTrigger(currentNode)) {
    childNodes = childNodes.slice(0, 1);
  }

  if (isConditionTrigger) {
    childNodes = childNodes.filter((node, index) => {
      const nodeIndex = childNodes.findIndex((n) => {
        return n.wf_if === node.wf_if;
      });
      return index === nodeIndex;
    });
  }

  currentNode.nodes = childNodes.map((node) => {
    return buildNodesGraph(node, allNodes);
  });

  return currentNode;
};
