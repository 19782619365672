export default {
  type: 'typeform',
  engagement: true,
  icon: 'typeform',
  title: 'Typeform',
  help: 'Embed Typeform',
  schema: {
    form_id: {
      type: 'Text',
      title: 'Form ID',
      help: 'You can find <form-id> from the public URL of your form: https://form.typeform.com/to/<form-id>',
      default: 'jiAPQ5OR',
    },

    toggle_text: {
      type: 'Text',
      title: 'Complete Form Button Text',
      help: 'Keep this short and sweet.',
      default: 'Complete Form',
    },

    success_text: {
      type: 'Text',
      title: 'Success Message',
      help: 'Keep this short and sweet.',
      default: 'Thank you! You are all set here.',
    },

    can_advance: {
      type: 'Checkbox',
      default: false,
      title: 'Can Skip. Do not require.',
      help: 'Do not require the form to be submitted.',
    },

    hidden_fields: {
      type: 'KeyValues',
      title: 'Hidden Fields',
      help: 'Pass learner profile information to your Typeform via hidden fields.',
      default: [
        {
          key: 'cvyr_pid',
          value: '{{pid}}',
        },
        {
          key: 'first_name',
          value: '{{first_name}}',
        },
        {
          key: 'last_name',
          value: '{{last_name}}',
        },
      ],
    },
  },
};
