<template>
  <v-dialog v-model="modalVisible" :max-width="width" @input="closeModal">
    <v-card class="confirmation-modal">
      <v-card-title class="headline">
        <h4 class="appMain--text mb-2">{{ title }}</h4>
      </v-card-title>
      <v-card-text>
        <slot><div v-html="text"></div></slot>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="onAlternative && alternativeButtonText"
          text
          color="appMain"
          @click="handleAction('onAlternative')"
        >
          {{ alternativeButtonText }}
        </v-btn>

        <v-spacer />
        <v-btn
          v-if="!hideCancelButton"
          text
          color="primary"
          @click="handleAction('onCancel')"
        >
          {{ cancelButtonText }}
        </v-btn>

        <v-btn
          v-if="onDiscard"
          text
          color="primary"
          @click="handleAction('onDiscard')"
        >
          Discard
        </v-btn>

        <v-btn
          :text="!buttonBlock"
          color="primary"
          @click="handleAction('onConfirm')"
        >
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/factories/eventBus';

const MODAL_DEFAULTS = {
  title: '',
  text: '',
  buttonText: 'Confirm',
  buttonBlock: false,
  cancelButtonText: 'Cancel',
  hideCancelButton: false,
  alternativeButtonText: '',
  width: '480px',
  onConfirm: null,
  onDiscard: null,
  onCancel: null,
  onAlternative: null,
};

export default {
  name: 'ConfirmationModal',
  data() {
    return {
      modalVisible: false,
      title: '',
      text: '',
      buttonText: 'Confirm',
      buttonBlock: false,
      cancelButtonText: 'Cancel',
      hideCancelButton: false,
      alternativeButtonText: '',
      width: '480px',
      onConfirm: null,
      onCancel: null,
      onDiscard: null,
      onAlternative: null,
    };
  },
  methods: {
    handleAction(action = 'onConfirm') {
      if (typeof this[action] === 'function') {
        this[action]();
      }
      this.closeModal();
    },
    closeModal() {
      this.toggleModal(false, MODAL_DEFAULTS);
    },
    toggleModal(visibility, params) {
      this.modalVisible = visibility;

      for (let key in params) {
        this[key] = params[key];
      }
    },
  },
  beforeMount() {
    EventBus.$on('openConfirmationModal', (params) => {
      this.toggleModal(true, params);
    });
  },
};
</script>
