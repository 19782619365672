import Resource from './common/resource';

class FieldsBuckets extends Resource {
  path = 'buckets';

  onCreateOne({ commit }, { model, res }) {
    commit('fields/update', { model, update: res.data }, { root: true });
  }

  onLoadOne({ commit }, { model, res }) {
    commit('fields/update', { model, update: res.data }, { root: true });
  }

  onDeleteOne({ commit }, { model }) {
    commit('fields/delete', model.id, { root: true });
  }
}

export default FieldsBuckets;
