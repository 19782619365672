export default {
  setCurrentSearch: (state, search) => {
    state.currentSearch = search;
  },
  setSearchResults: (state, searchResults) => {
    state.searchResults = searchResults;
  },
  setLoadingSearch: (state, loading) => {
    state.loadingSearch = loading;
  },
};
