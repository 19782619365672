import { vueEnv } from '@/globals';
import AsyncLib from '@/lib/asyncLib';

export default class FilestackLib extends AsyncLib {
  import() {
    return import(/* webpackChunkName: "filestack" */ 'filestack-js');
  }

  upload(img) {
    return this.import().then((filestack) => {
      let client = filestack.init(vueEnv('filestack_key'));
      return client.upload(img);
    });
  }

  pick(options, files) {
    // blur all active elements
    if (document.activeElement !== document.body) {
      document.activeElement.blur();
    }

    return this.import().then((filestack) => {
      if (!options.fromSources) {
        options.fromSources = [
          'local_file_system',
          'url',
          'instagram',
          'facebook',
          'googledrive',
          'unsplash',
        ];
      }

      const antiVirusWorkflow = vueEnv('filestack_av_workflow');
      //TODO, this will need to be re-tooled if storeTo or workflows
      //set from the outside.
      if (antiVirusWorkflow) {
        options.storeTo = {
          workflows: [antiVirusWorkflow],
        };
      }

      let client = filestack.init(vueEnv('filestack_key'));
      let picker = client.picker(options);
      if (files) {
        return picker.crop(files);
      } else {
        return picker.open();
      }
    });
  }
}

export const minifyFilestackImage = (path = '', mimetype = '', width) => {
  if ((mimetype || '').includes('gif') || !path) return path;

  const FILESTACK_PATH = 'filestackcontent.com';

  // set max width for filestack image
  if (path.includes(FILESTACK_PATH) && !path.includes('resize=width')) {
    const imgWidth = width || (window.innerWidth < 980 ? 600 : 1000);
    const resizeParam = `resize=width:${imgWidth}`;
    return path.replace(FILESTACK_PATH, `${FILESTACK_PATH}/${resizeParam}`);
  }

  // set desired width
  if (width && !path.includes(`width:${width}`)) {
    path = path.replace(/width:.*\//, `width:${width}/`);
  }

  return path;
};
