export default {
  type: 'rich_text',
  help: 'Include formatted text & images',
  title: 'Text',
  icon: 'text',
  inline_edits: false,
  schema: {
    body: {
      type: 'Html',
      required: true,
      default: '',
    },
    standnew: {
      type: 'Checkbox',
      title: 'Start of new section',
      default: false,
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },
};
