import mediaFields from './mediaFields';
export default {
  type: 'missing_words',
  icon: 'missing-words',
  title: 'Missing Words',
  help: 'Place missing words into text.',
  engagement: true,
  schema: {
    question: {
      type: 'TextArea',
      title: 'Text',
      help: 'Text you want learners to drag missing words into.',
      default: 'The {{rain}} in {{Spain}} stays {{mainly}} on the plain.',
    },
    ...mediaFields,

    include_points: {
      title: 'Include Points',
      type: 'Checkbox',
      default: true,
      help: 'Include points earned in this question on the score leaderboard.',
    },
    include_false_answers: {
      title: 'Include False Answers',
      type: 'Checkbox',
      default: false,
    },
    false_answers: {
      type: 'TextArea',
      title: 'False Answers (separated by comma)',
      default: 'Italy, storm, barely',
      showOnHaving: 'include_false_answers',
    },
    max: {
      title: 'Max Points',
      type: 'Number',
      default: 1200,
      showOnHaving: 'include_points',
    },
    wrong_answer: {
      title: 'Wrong Answer',
      type: 'Number',
      default: 150,
      help: 'Subtract points each time someone chooses the wrong answer.',
      showOnHaving: 'include_points',
    },
    metadata: {
      type: 'KeyValues',
      hidden: true,
      default: [],
    },
  },

  maxPoints(v) {
    return v.include_points ? v.max : 0;
  },
};
