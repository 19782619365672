const importLib = () => {
  return import(
    /* webpackChunkName: "segment" */ '@segment/analytics-next'
  ).then((module) => module?.AnalyticsBrowser);
};

const initSegment = (writeKey) => {
  if (!writeKey) return;

  importLib().then((AnalyticsBrowser) => {
    if (!AnalyticsBrowser) return;

    window.analytics = AnalyticsBrowser.load({ writeKey });
  });
};

export default initSegment;
