import Module from '@/store/resource/common/module';
import Files from '@/store/resource/files';

let module = new Module(new Files());

module.add('getters', {
  getFileByHandle(state) {
    return (handle) =>
      state.all.find((file) => {
        const { url, cdn } = file;
        return [url, cdn].some((i) => i.includes(handle));
      });
  },
});

export default module.create();
